<template>
  <v-container>
    <span v-if="showHeadline" class="px-1 text-body-1 font-weight-bold">
      {{ $t('meetCreator') }}
    </span>
    <v-sheet
      v-ripple
      class="clickable"
      :style="`background-color: ${ $vuetify.theme.dark ? '#272727' : '#F0F0F0' };
        transition: all 0.3s ease;
        width: 100%;
        border-radius: 12px;`"
    >
      <v-list-item class="px-3">
        <v-list-item-avatar
          rounded="lg"
          :class="{
            'grey lighten-2': !$vuetify.theme.dark,
            'grey darken-4': $vuetify.theme.dark
          }"
        >
          <v-img :src="getResourceUrl(creator.avatar)"></v-img>
        </v-list-item-avatar>
        
        <v-list-item-content>
          <v-list-item-title>
            {{ creator.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ creator.caption }}
          </v-list-item-subtitle>
        </v-list-item-content>

        <!-- TODO: the Creator's page -->
        <v-list-item-action v-if="showArrow && false">
          <v-btn icon>
            <v-icon>
              {{ mdiArrowRight }}
            </v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-sheet>
  </v-container>
</template>

<script>
import { mdiArrowRight } from '@mdi/js'
import { getResourceUrl } from '@utils'

export default {
  props: {
    creator: {
      type: Object,
      default: () => ({
        avatar: 'https://image.lexica.art/full_webp/671760b0-293a-4139-bdb6-dfbefe4a077a',
        name: 'Anonymous',
        caption: 'So mysterious'
      })
    },
    showHeadline: {
      type: Boolean,
      default: true
    },
    showArrow: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    mdiArrowRight
  }),

  methods: {
    getResourceUrl
  }
}
</script>