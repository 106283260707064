<template>
  <div class="pdf-viewer">
    <PDFData
      :url="url"
      @no-match="emitNoMatch"
      @page-count="updatePageCount"
      @document-rendered="onDocumentRendered"
      @document-errored="onDocumentErrored"
    >
      <template v-slot:preview="{pages}">
        <Sidebar
          v-show="isOutlineEnabled"
          class="pdf-viewer__preview"
          v-bind="{pages, scale, currentPage, pageCount, isOutlineEnabled, isPreviewEnabled, isSearchListEnabled}"
          @page-focus="updateCurrentPage"
        />
      </template>

      <template v-slot:document="{pages}">
        <PDFDocument
          class="pdf-viewer__document"
          :class="{ 'preview-enabled': isPreviewEnabled || isSearchListEnabled }"
          v-bind="{pages, scale, rotation, optimalScale, fit, currentPage, pageCount, isPreviewEnabled, isSearchListEnabled}"
          @scale-change="updateScale"
          @page-rendered="onPageRendered"
        />
      </template>
    </PDFData>
  </div>
</template>

<script>
import PDFSearchList from '@components/PDF/SearchList'
import PDFDocument from '@components/PDF/Document'
import PDFPreview from '@components/PDF/Preview'
import PDFData from '@components/PDF/Data'
import Sidebar from '@components/PDF/Sidebar'
import { mapGetters, mapMutations } from 'vuex'

function floor(value, precision) {
  const multiplier = Math.pow(10, precision || 0)
  return Math.floor(value * multiplier) / multiplier
}

export default {

  name: 'PDFViewer',

  components: {
    Sidebar,
    PDFData,
    PDFDocument,
    PDFPreview,
    PDFSearchList
  },

  props: {
    url: String,
  },

  mounted() {
    document.body.classList.add('overflow-hidden')
  },

  data() {
    return {
      optimalScale: undefined,
      pageCount: undefined,
    }
  },

  computed: {
    ...mapGetters({
      isSearchListEnabled: 'pdf/isSearchListEnabled',
      isPreviewEnabled: 'pdf/isPreviewEnabled',
      isOutlineEnabled: 'pdf/isOutlineEnabled',
      currentPage: 'pdf/currentPage',
      rotation: 'pdf/rotation',
      scale: 'pdf/scale',
      fit: 'pdf/fit'
    })
  },

  methods: {
    ...mapMutations({
      setCurrentPage: 'pdf/currentPage',
      setPageCount: 'pdf/pageCount',
      setScale: 'pdf/scale'
    }),

    emitNoMatch() {
      this.$emit('no-match', 'noMatch')  
    },

    onPageRendered() {
      this.$emit('page-rendered')
    },

    onDocumentRendered() {
      this.$emit('document-errored', this.url)
    },

    onDocumentErrored(err) {
      this.$emit('document-errored', err)
    },

    updateScale({scale, isOptimal = false}) {
      const roundedScale = floor(scale, 2)
      if (isOptimal) this.optimalScale = roundedScale
      this.setScale(roundedScale)
    },

    updatePageCount(pageCount) {
      this.setPageCount(pageCount)
      this.pageCount = pageCount
    },
    
    updateCurrentPage(pageNumber) {
      this.setCurrentPage(pageNumber)
    }
  }

}
</script>

<style>
.pdf-viewer__preview {
  display: block;
  width: 15%;
  right: 85%;
}

.pdf-viewer__document {
  top: 0;
  width: 100%;
  left: 0;
}

.pdf-viewer__document.preview-enabled {
  width: 85%;
  left: 15%;
}

.pdf-menu__scrollbar {
  height: calc(100vh - 130px);
}
</style>