<template>
  <v-card
    elevation="0"
    :color="cardColor"
    style="border-radius: 12px;"
  >
    <v-list-item>
      <v-list-item-avatar size="40">
        <v-img
          v-if="person.avatar"
          :src="getResourceUrl(person.avatar)"
        >
          <template v-slot:placeholder>
            <v-skeleton-loader
              class="mx-auto"
              height="40"
              width="40"
              type="avatar"
            />
          </template>
        </v-img>

        <v-icon 
          v-else 
          style="font-size: 40px;"
        >
          {{ mdiAccountCircle }}
        </v-icon>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title v-text="person.name" />
      </v-list-item-content>

      <v-list-item-action>
        <FriendshipRequest
          :person="person"
          :friendship="JSON.stringify(person.friendship)"
          :showDeclined="true"
          @setRipple="setRipple"
          @updateFriendship="emitUpdateFriendship"
          @newSenderRequest="emitNewSenderRequest"
          @snack="emitSnacks"
        />
      </v-list-item-action>
    </v-list-item>
  </v-card>
</template>

<script>
import FriendshipRequest from './FriendshipRequest'
import { mdiAccountCircle } from '@mdi/js'
import { getResourceUrl } from '@utils'

export default {
  components: {
    FriendshipRequest
  },

  props: {
    person: {
      type: Object,
      required: true
    },
    idx: {
      type: Number,
      required: true
    }
  },
  
  data: () => ({
    mdiAccountCircle, 
    ripple: true
  }),

  computed: {
    cardColor() {
      return this.$vuetify.theme.dark ? '#262626' : ''
    }
  },

  methods: {
    getResourceUrl,
    
    setRipple(v) {
      this.ripple = v
    },

    emitUpdateFriendship(data) {
      this.$emit('updateFriendship', {data, idx: this.idx})
    },

    emitNewSenderRequest(data) {
      this.$emit('newSenderRequest', data)
    },
    
    emitSnack(snackMsg) {
      this.$emit('snack', snackMsg)
    }
  }
}
</script>