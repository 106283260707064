<template>
  <v-container
    ref="textContainer"
    class="py-1"
  >
    <!-- <v-dialog
      v-model="mentionCardOn"
      hide-overlay
      max-width="0"
      @click:outside="mentionCardOn = false"
    >
      <MentionCard
        :mentionCardOn="mentionCardOn"
        :mentionCardStyle="mentionCardStyle"
        :upperHalfOn="mentionCardUpperHalfOn"
        :search="mentionSearch"
        @hideMentionCard="mentionCardOn = false"
        @doMention="doMention"
      />
    </v-dialog> -->
    <MentionCard
      :getPosition="getPosition"
      :editor="editor"
      :setOpen="setMentionCardOpen"
      :setMentions="setMentions"
      :getCaretIndex="getCaretIndex"
      :isSingleChat="!!$route.query.selectedProfileId"
    />

    <v-card
      :id="`textarea-container`"
      :class="colorClass"
      style="min-height: 48px; max-height: 300px; border-radius: 15px;"
      :elevation="$vuetify.theme.dark ? 4 : 0"
      :outlined="!$vuetify.theme.dark"
    >
      <v-container id="editorContainer" class="py-2">
        <v-row class="px-4" justify="space-between">
          <!-- TODO: review deletion of class custom-scroll -->
          <div
            :id="`editorjs_${EDITOR_ID}`"
            :class="{
              'px-1 hs-chat--textarea codex-editor--narrow': true,
              menuClass: true,
              hiddenClass: true
            }"
            spellcheck="false"
            :style="`width: ${textAreaWidth}%;`"
            @keydown="onKeyDown"
            @click="onClick"
            ref="textarea"
          ></div>

          <div>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-show="false"
                  v-on="on"
                  icon
                  :style="`z-index: 1;`"
                  @click="$emit('shimmer')"
                >
                  <v-icon>
                    {{ mdiEmoticonExcitedOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Emoji</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-show="false"
                  v-on="on"
                  icon
                  :style="`z-index: 1;`"
                  @click="$emit('setOptions')"
                >
                  <v-icon>
                    {{ mdiCog }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Options</span>
            </v-tooltip>

            <!-- The z-index guarantees that the button is clickable -->
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="getOnObject(on)"
                  disabled
                  icon
                  :color="$vuetify.theme.dark ? '#B388FF' : '#6200EA'"
                  :style="`z-index: 1;
                    background-color: ${
                      streaming ? '' :
                      $vuetify.theme.dark ?
                        'rgba(94, 53, 177, 0.48)' : 'rgba(125, 33, 237, 0.157)'
                    }`"
                    @click="$emit(streaming ? 'stop' : 'setStudy')"
                >
                  <v-icon>
                    {{ streaming ? mdiStopCircleOutline : mdiShimmer }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Hierarchical Solver</span>
            </v-tooltip>
          </div>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import MentionCard from '@components/Conversation/MentionCard'
// import MentionCard from '@components/MentionCard'
import { htmlToText, getBrowserName, delay } from '@utils'
import { uniq } from 'lodash'
import EditorJS from '@editorjs/editorjs'
import { v4 as uuidv4 } from 'uuid'
import API from '@api'
import {
  mdiEmoticonExcitedOutline,
  mdiStopCircleOutline,
  mdiAttachment,
  mdiShimmer,
  mdiCog
} from '@mdi/js'

import Paragraph from '@plugins/editorjs-paragraph/index.js'
import ValidatorService from '@api/validator'

export default {
  components: {
    MentionCard
  },

  props: {
    streaming: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: ''
    },
    conversation: {
      type: String,
      default: ''
    },
    inputWidth: {
      type: Number,
      default: 0
    },
    annotation: {
      type: Boolean,
      default: false
    },
    avatarOn: {
      type: Boolean,
      default: true
    },
    lighterViewerOn: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    menuClass: {
      type: String,
      default: ''
    },
    autoCall: {
      type: Boolean,
      default: false
    },
    locked: {
      type: Boolean,
      default: false
    }
  },

  async mounted() {
    this.personaOn = this.persona && this.persona.id

    const userAgent = navigator.userAgent;
    const shortcut = /Mac OS X/.test(userAgent) ? 'cmd ⌘ + return' : 'ctrl + enter';
    this.editor = new EditorJS({
      holder: `editorjs_${this.EDITOR_ID}`,
      minHeight: 0,
      autofocus: true,
      tools: {
        paragraph: {
          class: Paragraph,
          config: {
            holder: `editorjs_${this.EDITOR_ID}`,
            isChat: true,
            // Type @anna or use the shortcut ${shortcut} to call your personal AI
            placeholder: this.locale === 'en'
              ? this.autoCall ? 'Ask something....' : `Use the shortcut ${shortcut} to call your personal AI`
              : this.autoCall ? 'Pergunte alguma coisa...' : `Use o atalho ${shortcut} para chamar sua IA pessoal`
          }
        }
      }
    })

    window.editor = this.editor

    await delay(200)

    // The next section emits the Editor's height difference
    // it is useful for changing the chat container's height
    // such that TextArea doesn't stay on top of the messages
    let editorDiv = document.querySelector(`#editorjs_${this.EDITOR_ID}`)
    this.baseHeight = editorDiv.offsetHeight;

    // Function to update the height
    let updateHeight = () => {
      const height = editorDiv.offsetHeight;
      this.$emit('heightChange', height - this.baseHeight)

      // Height update is autoregressive
      this.baseHeight = height
    }

    // Initial height update
    updateHeight();

    // Observe changes in the editor div
    const observer = new MutationObserver(updateHeight);

    observer.observe(editorDiv, { childList: true, subtree: true });
  },

  created() {
    this.validatorService = ValidatorService()
  },

  data: () => ({
      mdiCog,
      mdiShimmer,
      mdiAttachment,
      mdiStopCircleOutline,
      mdiEmoticonExcitedOutline,

      cursorPosition: 0,

      baseHeight: 0,
      /*
      * Set the uuidv4 here to force a new EDITOR_ID when the component is created
      * If you set the uuidv4 elsewhere (e.g., before export default)
      * then EDITOR_ID won't refresh on created..
      */
      EDITOR_ID: uuidv4(),
      htmlCursorPosition: 0,
      hidden: false,
      editor: {},
      mentionSearch: '',
      mentionCardUpperHalfOn: true,
      mentionCardOn: false,
      mentionCardLeft: null,
      mentionCardTop: null,
      mentionData: {},
      mentions: [],
      text: '',
      focusOn: false,
      personaOn: false,
      validatorService: null,
      mentionCardOpen: false,
      position: { top: 0, left: 0 }
    }),

  computed: {
    ...mapGetters({
      persona: 'currentPersona',
      quality: 'anna-mind/quality',
      locale: 'locale',
      user: 'user',
      profileName: 'profile/name',
      profileAvatar: 'profile/avatar',
      scrollTop: 'scrollTop'
    }),

    textAreaWidth() {
      return this.$vuetify.breakpoint.xl ? 88 :
        this.$vuetify.breakpoint.lg ? 82 : 70
    },

    hiddenClass() {
      return this.hidden ? 'hidden--class' : ''
    },

    textContainerClass() {
      const { xl, width } = this.$vuetify.breakpoint

      return xl || width < 1300
        ? 'px-3'
        : 'pl-3'
    },

    colorClass() {
      return this.$vuetify.theme.dark
        ? 'viewer-dark'
        : this.lighterViewerOn
        ? 'viewer-lighter'
        : 'viewer-light'
    },

    textInputWidth() {
      return this.inputWidth
        ? this.inputWidth
        : this.$vuetify.breakpoint.xl
        ? this.avatarOn
        ? this.dense ? 91 : 94
        : this.dense ? 90 : 92
        : this.avatarOn
        ? this.dense ? 88 : 90
        : this.dense ? 84 : 86
    },

    mentionCardStyle() {
      return `position: absolute;
        z-index: 100;
        border-radius: 12px;
        left: ${this.mentionCardLeft}px;
        top: ${this.mentionCardTop}px;`
    },

    avatarClass() {
      return this.$vuetify.breakpoint.xl ? 'ml-2' : 'mr-4'
    },

    avatarSize() {
      return this.profileAvatar ? '40px' : ''
    },
  },

  watch: {
    persona: {
      deep: true,
      handler(v) {
        this.personaOn = v && v.id
      }
    },

    async mentionCardOn(activeCard) {
      if (!activeCard)
        this.resetMentionCard()
    },
  },

  methods: {
    setMentions(mentions) {
      this.mentions = mentions
    },

    setMentionCardOpen(value) {
      this.mentionCardOpen = value
    },

    getPosition() {
      if (!this.$refs || !this.$refs.textContainer) return { top: 0, left: 0 }
      const { textContainer } = this.$refs
      const containerEl = document.querySelector('#textarea-container')
      const containerElHeight = containerEl.clientHeight || 0
      const { x, y } = textContainer.getBoundingClientRect()
      return { top: y - containerElHeight, left: x }
    },
    getOnObject(on) {
      return this.streaming ? {} : on;
    },

    getCaretIndex(element) {
      let position = 0;
      const isSupported = typeof window.getSelection !== "undefined";

      if (isSupported) {
        const selection = window.getSelection();
        if (selection.rangeCount !== 0) {
          const range = window.getSelection().getRangeAt(0);
          const preCaretRange = range.cloneRange();
          preCaretRange.selectNodeContents(element);
          preCaretRange.setEnd(range.endContainer, range.endOffset);
          position = preCaretRange.toString().length;
        }
      }

      return position;
    },

    getCaretPosition() {
      if (window.getSelection && window.getSelection().getRangeAt) {
        var range = window.getSelection().getRangeAt(0);
        var selectedObj = window.getSelection();
        var rangeCount = 0;
        var childNodes = selectedObj.anchorNode.parentNode.childNodes;
        for (var i = 0; i < childNodes.length; i++) {
          if (childNodes[i] == selectedObj.anchorNode) {
            break;
          }
          if (childNodes[i].outerHTML)
            rangeCount += childNodes[i].outerHTML.length;
          else if (childNodes[i].nodeType == 3) {
            rangeCount += childNodes[i].textContent.length;
          }
        }
        return range.startOffset + rangeCount;
      }
      return -1;
    },

    async setCaretIndex(textLength) {
        const isSupported = typeof window.getSelection !== "undefined";

        if (isSupported) {
          let i = 0;

          while (i < textLength) {
            document.getSelection().modify('move', 'forward', 'character')
            i++
          }
        }
    },

    closeMentionCard() {
      this.showMentionCard = false
    },

    addMentionClass(str) {
      /*
      * We need this function because the text returned from block.data.text
      * by editorjs does not return the class attributes for <span> tags
      */
      let pattern = /<span (.*?)>(@.*?)<\/span>/g;
      let repl = '<span class="mention" contenteditable="false" $1>$2</span>';
      
      return str.replace(pattern, repl); 
    },


    decodeHtmlEntities(str) {
      return str
        .replace(/&gt;/g, '>')
        .replace(/&lt;/g, '<')
        .replace(/&amp;/g, '&')
        .replace(/&quot;/g, '"')
        .replace(/&apos;/g, "'");
    },

    async resetMentionCard() {
      this.mentionSearch = ''

      await delay(30)

      this.setCaretIndex(this.cursorPosition)

      this.showCaret()
    },

    async onKeyDown(ev) {
      if (!ev.shiftKey && ev.key === 'Enter') {

        // Validator won't activate on chat rooms
        if (this.$route.name.indexOf('rooms') === -1)
          await this.validatorService.freeTrial()

        if (this.mentionCardOpen) {
          ev.preventDefault()
          return
        }
        // // Handles both the case of user pressing enter on
        // // empty text box and submiting the content of the text box.
        // // If the user clicks Enter in the middle of the text we still send the whole text.

        // // We select the editor's inner text because the editor saver doesn't handle line breaks well
        // const ell = document.querySelector(`#editorjs_${this.EDITOR_ID}`)

        // // Removes the newline at the end of string if it has only one newline
        // // (in which case the newline is generally useless)
        // if (/\n.*\n/.test(ell.innerText)) this.text = ell.innerText
        // else this.text = ell.innerText.replace(/\n$/, "")

        const { blocks } = await this.editor.saver.save()

        // const rawText = blocks.length > 0 ? this.addMentionClass(blocks.map(block => block.data.text).join('')) : ''

        // // Changes html entities (e.g. &gt;) for the regular symbols (e.g. >) to fit markdown.
        // this.text = this.decodeHtmlEntities(rawText)
        if (this.locked) {
          // Resets the textarea in case anna is still streamming
          ev.preventDefault()
          ev.stopPropagation()
          this.editor.clear()
          this.editor.focus()
          this.editor.blocks.getBlockByIndex(0)
            .holder.firstChild.firstChild.innerHTML = rawText;
          return;
        }

        // this.hidden = true

        // let selection = window.getSelection();
        // let el = selection.anchorNode.parentNode
        // const cursorPosition = this.getCaretIndex(el)
        // this.insertTextAtCursor('', el, cursorPosition)

        // // Handles mentions
        // const mentionEls = this.$refs.textContainer.querySelectorAll('a')
        // const mentionProfiles = []

        // this.editor.clear()
        // this.editor.focus()

        let usernames = []
        // for (let el of mentionEls) {
        //   usernames.push(el.textContent.replace('@', ''))
        // }

        // if (usernames.length > 0) {
        //   const profiles = await API().get(`profiles/${this.user.id}`, {
        //     params: {
        //       query: {
        //           'username': {
        //             $in: uniq(usernames)
        //           }
        //         }
        //     }
        //   })

        //   for (let profile of profiles.map(({id}) => id)) {
        //     if (mentionProfiles.indexOf(profile) === -1) {
        //       mentionProfiles.unshift(profile)
        //     }
        //   }

        //   this.mentions = uniq(this.mentions.filter(({id}) => {
        //     return mentionProfiles.indexOf(id) != -1
        //   })).map(profileData => {
        //     return {
        //       profile: profileData.id,
        //       ...profileData
        //     }
        //   })
        // }

        // // Check if the ctrl/meta key is pressed and if Anna is not already mentioned
        // // The 'autoCall' variable indicates that the user is in a 1-1 conversation with Anna
        // if (
        //   (ev.ctrlKey || ev.metaKey || this.autoCall) &&
        //   !usernames.includes('anna')
        // ) {
        //   const [anna] = await API().get(`profiles/${this.user.id}`, {
        //     params: {
        //       query: {
        //         'username': {
        //           $eq: 'anna'
        //         }
        //       }
        //     }
        //   })

        //   if (anna) {
        //     this.mentions.unshift({
        //       profile: anna._id,
        //       ...anna
        //     })

        //     const annaSpan = ` <span class="mention" contenteditable="false">@anna</span>`
        //     this.text += annaSpan
        //   }
        // }
        
        if (blocks.length > 0) this.submit()
      }
    },

    onClick() {
      this.hidden = false
    },

    submit() {
      const { holder } = this.editor.blocks.getBlockByIndex(0)
      const { innerHTML } = holder.firstChild.firstChild
      const persona = this.mentions.reverse().find(({ model }) => model === 'persona')
      this.$emit('submit', {
        content: `${innerHTML}`,
        persona
      })
      this.editor.clear()
      this.setMentions([])
      this.text = ''
      this.editor.focus()
    },

    setTextContent({ textContent }) {
      this.text = textContent
    },

    insertTextAtCursor(text, element, htmlCursorPosition) {
      let content = element.innerHTML;
      let updatedContent = content.slice(0, htmlCursorPosition - 1) + text + content.slice(htmlCursorPosition);
      element.innerHTML = updatedContent.replace(/ \u2005/g, "\u2005"); 

      const len = htmlToText(content.slice(0, htmlCursorPosition) + text).length
      this.setCaretIndex(len)
    },

    getLastCharBeforeCaret(k = 1) {
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      const position = range.startOffset;
      if (position < k) {
        return null;
      }
      const text = range. startContainer.textContent;
      return text.charAt(position - k);
    },

    // Function to encode a string with the BEL character
    encodeWithBEL(text) {
      const BEL = '\u0007';
      return `${BEL}${text}${BEL}`;
    },

    // Function to decode a string enclosed with the BEL character
    decodeWithBEL(text) {
      const BEL = '\u0007';
      const belPattern = new RegExp(`${BEL}([^${BEL}]+)${BEL}`);
      return text.replace(belPattern, '$1');
    },

    async doMention({ username, avatar, name, id }) {
      requestAnimationFrame(() => {
        this.mentionCardOn = false
      })

      this.editor.focus()

      this.hideCaret()

      // wait for the editor focus
      await delay(30)

      const docSelection = window.getSelection();

      let i = 0;
      while (i < this.htmlCursorPosition) {
        window.getSelection().modify('move', 'forward', 'character')
        i++;
      }

      const el = docSelection.anchorNode.parentNode
      const lastChar = this.getLastCharBeforeCaret(2)

      const newChar = /\s/.test(lastChar)
        ?  getBrowserName() === 'Safari'
          ? '\u2005'
          : ''
        : '\u2005'
      this.insertTextAtCursor(newChar, el, this.htmlCursorPosition)

      const mentionRange = docSelection.getRangeAt(0)

      /*
        * Note that you don't need a condition to stop the user
        * from creating a mention inside a mention because the 
        * span containing the mention has contenteditable set to false
        * 
        * Else you would need something like
        * mentionRange.commonAncestorContainer.parentNode.nodeName != 'A'
      */
      docSelection.removeAllRanges()
      docSelection.addRange(mentionRange)

      const newParent = document.createElement('span')
      mentionRange.surroundContents(newParent)
      newParent.innerText = `@${username}`
      newParent.classList.add('mention')
      newParent.setAttribute('contenteditable', 'false');

      newParent.after('\u2005')

      docSelection.modify('move', 'forward', 'word')
      docSelection.modify('move', 'forward', 'character')
      
      this.mentions.unshift({ username, name, avatar, id })

      this.mentionData = {
        username,
        id
      }

      this.showCaret()
    },

    async setMentionCard() {
      const { textContainer } = this.$refs
      const { x, y } = textContainer.getBoundingClientRect()

      this.mentionCardLeft = x + 15
      this.mentionCardUpperHalfOn = y < window.innerHeight / 2
      this.mentionCardTop = !this.mentionCasdqwardUpperHalfOn ? y - 370 : y

      // gets the caret position
      let selection = window.getSelection();
      let el = selection.anchorNode.parentNode
      const cursorPosition = this.getCaretIndex(el)

      this.cursorPosition = cursorPosition
      
      // turns on the mention card
      this.mentionCardOn = true

      // set caret position back

      // Current version is buggy
      // the mention doesn't remove the previous piece of text after you search who you want to mention

      
      this.editor.focus()

      this.hideCaret()

      await delay(50)

      this.setCaretIndex(cursorPosition)

      await delay(50)
      
      this.showCaret()
    },

    hideCaret() {
      document.querySelector(`#${this.editor.configuration.holder}`)
      .querySelector('.ce-paragraph').classList.add('caret-off')
    },

    showCaret() {
      document.querySelector(`#${this.editor.configuration.holder}`)
        .querySelector('.ce-paragraph').classList.remove('caret-off')
    }
  }
}
</script>

<style scoped>
.viewer-dark {
 background-color: rgb(39, 39, 39);
}

.viewer-light {
 background-color: rgba(202, 199, 199, 0.12);
}

.viewer-lighter {
  /* border: thin solid rgba(0, 0, 0, 0.12); */
  background-color: rgb(255, 255, 255);
}
.codex-editor--narrow .ce-block--focused {
  margin-right: 0px !important;
  padding-right: 0px !important;
}
</style>

<style>

/* TODO: evaluate if these styles should be moved elsewhere */

/* Styling for firefox */
.theme--light .ce-paragraph {
  scrollbar-color: #9e9e9e #a50a0a00;
  scrollbar-width: thin;
}
.theme--dark .ce-paragraph {
  scrollbar-color: #6b6b6b #a50a0a00;
  scrollbar-width: thin;
}

.hs-chat--textarea {
  cursor: text;
}

/* Hides the inline toolbar (bold, italic and link) */
.hs-chat--textarea .ce-inline-toolbar {
  display: none;
}

/* Customizes the scrollbar for the hs-chat--textarea */
 .ce-paragraph::-webkit-scrollbar {
  width: 5px;
}
.hs-chat--textarea .ce-paragraph::-webkit-scrollbar-corner {
  background: transparent;
}
.hs-chat--textarea .ce-paragraph::-webkit-scrollbar-track-piece  {
  background: transparent;
}
.hs-chat--textarea .ce-paragraph::-webkit-scrollbar-track {
  background: transparent;
}
.hs-chat--textarea .ce-paragraph::-webkit-scrollbar-thumb {
  background-color: #424242;
  min-height: 40px;
  /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid #424242;  /* creates padding around scroll thumb */
}

.hs-chat--textarea .ce-paragraph, ::before, ::after {
  -webkit-box-sizing: content-box !important;
  box-sizing: content-box !important;
}

/* customizes the hs-chat-textarea paragaph */
.hs-chat--textarea .ce-paragraph {
  max-height: 250px;
  padding-right: 5px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.hs-chat--textarea
.ce-toolbar--opened
.ce-toolbar__content {
  display:none;
}

.hs-chat--textarea
.codex-editor
.codex-editor__redactor {
  margin-right: 0px;
}

/* Oher customizations for editor.js */
.menu--top .ce-settings, .menu--top .ce-toolbox {
  position: absolute;
  top: calc(-90px);
  left: 0;
}

.ce-block__content, 
.ce-toolbar__content {
  max-width: none;
}

.codex-editor__loader {
  height: 0px !important;
}

.caret-off {
  caret-color: rgba(0, 0, 0, 0);
}

.hidden--class .ce-toolbar__settings-btn {
  background-color: transparent !important;
  color: transparent !important;
}

.hidden--class .ce-toolbar__plus {
  background: transparent !important;
  color: transparent !important;
}

.ct::before, .ct::after{
  background-color: #424242;
}

.viewer-dark .ce-block--selected .ce-block__content,
.viewer-dark .ce-inline-toolbar,
.viewer-dark .codex-editor--narrow .ce-toolbox,
.viewer-dark .ce-conversion-toolbar,
.viewer-dark .ce-settings,
.viewer-dark .ce-settings__button,
.viewer-dark .ce-toolbar__settings-btn:hover,
.viewer-dark .cdx-button,
.viewer-dark .ce-popover,
.viewer-dark .ce-conversion-tool__icon,
.viewer-dark .ce-toolbar__plus:hover {
  background: #424242;
  color: inherit;
}

.viewer-dark .ce-toolbar__settings-btn {
  background: transparent;
  color: inherit;
}

.viewer-dark .ce-inline-tool,
.viewer-dark .ce-conversion-toolbar__label,
.viewer-dark .ce-toolbox__button,
.viewer-dark .cdx-settings-button,
.viewer-dark .ce-toolbar__plus,
.viewer-dark .ce-popover__item-secondary-label {
  color: inherit;
}

.viewer-dark .cdx-settings-button:hover,
.viewer-dark .ce-settings__button:hover,
.viewer-dark .ce-toolbox__button--active,
.viewer-dark .ce-toolbox__button:hover,
.viewer-dark .cdx-button:hover,
.viewer-dark .ce-inline-toolbar__dropdown:hover,
.viewer-dark .ce-inline-tool:hover,
.viewer-dark .ce-popover__item:hover,
.viewer-dark .ce-toolbar__settings-btn:hover {
  background-color: #424242;
  color: inherit;
}

.cdx-notify--error {
  background: #B71C1C !important;
}

.ce-popover__item--focused:not(.ce-popover__item--no-visible-focus) {
  box-shadow: inset 0 0 0 1px rgba(127, 17, 224, 1);
  background: rgba(127, 17, 224, 0.5) !important;
}

.ce-popover__item:hover:not(.ce-popover__item--no-visible-hover) {
  background-color: rgba(127, 17, 224, 0.3);
}

.ce-popover__item--active {
background: rgba(127, 17, 224, 0.3);
color: black;
}


.viewer-dark .ce-popover__item--active {
background: rgba(127, 17, 224, 0.3);
color: white;
}

.viewer-dark .cdx-notify__cross::after,
.viewer-dark .cdx-notify__cross::before {
  background: white;
}

.viewer-dark .ce-popover__item-icon {
  background-color: #212121;
}

.ce-block--selected .ce-block__content {
  background-color: rgba(127, 17, 224, 0.1);
}
</style>