<template>
  <v-sheet
    v-ripple="!edit"
    class="clickable"
    style="border-radius: 12px;"
    @click="emitRoom"
  >
    <v-fade-transition hide-on-leave>
      <v-hover
        v-if="!edit"
        v-slot:default="{ hover }"
      >
        <v-list-item>
          <v-list-item-avatar
            class="hs-rounded-lg"
            size="40"
          > 
            <v-img :src="getResourceUrl(room.thumbnail)">
              <template v-slot:placeholder>
                <v-skeleton-loader
                  class="mx-auto"
                  height="40"
                  width="40"
                  type="image"
                />
              </template>
            </v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              {{ room.name }}
            </v-list-item-title>
          </v-list-item-content>

          <v-fade-transition hide-on-leave>
            <v-list-item-icon
              v-if="hover"
              class="my-0"
              style="padding: 14px 0px 14px 0px;"
            >  
              <v-btn
                icon
                small
              >
                <v-icon small>
                  {{ mdiStarFourPointsOutline }}
                </v-icon>
              </v-btn>

              <v-btn
                v-if="room.owner === myProfileId"
                icon
                small
                @click.stop="onEdit"
              >
                <v-icon small>
                  {{ mdiPencil }}
                </v-icon>
              </v-btn>

              <v-icon v-if="false">
                {{ mdiSeal }}
              </v-icon>
            </v-list-item-icon>
          </v-fade-transition>
        </v-list-item>
      </v-hover>
    </v-fade-transition>

    <v-fade-transition hide-on-leave>
      <v-list-item v-if="edit">
        <v-list-item-content class="py-2">
          <v-text-field
            v-model="room.name"
            class="hs-rounded-lg"
            placeholder="pdf title"
            :color="inputColor"
            hide-details
            dense
            elevation="0"
            filled
          />
        </v-list-item-content>

        <v-list-item-icon class="pl-2" style="margin: 13px 0px 13px 0px;">
          <v-btn
            icon
            small
            @click.stop="update"
          >
            <v-icon small>
              {{ mdiCheck }}
            </v-icon>
          </v-btn>
        </v-list-item-icon>
      </v-list-item>
    </v-fade-transition>
  </v-sheet>
</template>

<script>
import { getResourceUrl } from '@utils'
import { mapGetters, mapMutations } from 'vuex'
import API from '@api'

import {
  mdiAccountCircle,
  mdiSeal,
  mdiStarFourPointsOutline,
  mdiPencil,
  mdiCheck
} from '@mdi/js'

export default {
  props: {
    room: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  
  data() {
    return {
      mdiAccountCircle,
      mdiSeal,
      mdiStarFourPointsOutline,
      mdiPencil,
      mdiCheck,

      edit: false
    }
  },

  computed: {
    ...mapGetters({
      user: 'user',
      myProfileId: 'profile/id'
    }),

    cardColor() {
      return this.$vuetify.theme.dark ? '#262626' : ''
    },

    inputColor() {
      return this.$vuetify.theme.dark ? 'white' : ''
    }
  },

  methods: {
    ...mapMutations({
      setGroupRoomToHandle: 'rooms/setGroupRoomToHandle',
      setOpenHandleGroupRoomDialog: 'rooms/setOpenHandleGroupRoomDialog',
    }),

    getResourceUrl,

    onEdit() {
      this.setGroupRoomToHandle(this.room)
      this.setOpenHandleGroupRoomDialog(true)
    },

    update() {
      API().put(`room/${this.user.id}`, {
        name: this.room.name
      }, {
        params: {
          id: this.room._id
        }
      })

      this.edit = false
    },

    emitRoom() {
      if (!this.edit) this.$emit('setCurrentRoom')
    },

    emitNewSenderRequest(data) {
      this.$emit('newSenderRequest', data)
    },

    emitSnack(snackMsg) {
      this.$emit('snack', snackMsg)
    }
  }
}
</script>