<template>
  <v-dialog
    v-model="newPostOn"
    width="600"
    @click:outside="closeDialog"
  >
    <v-card :class="{
      'mx-auto hs-rounded-xl': true,
      'editor-dark': $vuetify.theme.dark
    }">
      <v-container>
        <v-row justify="center" class="px-4">
          <span class="text-h6">
            {{ $t( postType === 'POST' ? 'community.newPost' : 'community.newThread' ) }}
          </span>
        </v-row>

        <v-divider class="mt-1" />

        <v-row
          justify="space-between"
          align="center"
          class="px-2 pr-8"
        >            
          <v-list-item style="max-width: 50%">
            <v-list-item-avatar
              size="40"
              color="grey"
            >
              <v-img :src="myProfileAvatar" />
            </v-list-item-avatar>

            <v-list-item-content>
              <div class="overline">
                {{ myProfileName }}
              </div>
            </v-list-item-content>
          </v-list-item>

          <div style="justify-content: center; display: flex;">
            <v-btn
              text
              :color="$vuetify.theme.dark ? 'tertiary' : 'primary'"
              :style="`border-radius: 12px; background-color: ${ $vuetify.theme.dark ? 'rgba(255, 191, 16, 0.23)' : 'rgba(74, 20, 140, 0.1)'};`"
              @click="personalizeAI = !personalizeAI"
            >
              AI magic
              <v-icon right>
                {{ mdiShimmer }}
              </v-icon>
            </v-btn>
          </div>
        </v-row>

        <v-fade-transition hide-on-leave>
          <v-card-text v-show="!personalizeAI" ref="postContainer">
            <Scrollbar
              thin="thinner"
              style="max-height: 300px;"
            >
              <v-container class="py-0">
                <v-row
                  align="center"
                  class="pl-15 pr-3"
                >
                  <div
                    :id="`editorjs_${EDITOR_ID}`"
                    class="hs-editorjs--post"
                    spellcheck="false"
                    style="z-index: 2;"
                    ref="postEditor"
                  ></div>
                </v-row>
              </v-container>
            </Scrollbar>
          </v-card-text>
        </v-fade-transition>

        <v-fade-transition hide-on-leave>
          <v-card-text v-show="personalizeAI" ref="postContainer">
            <Scrollbar
              thin="thinner"
              style="max-height: 300px;"
            >
              <v-container class="py-0">
                <PersonaCreation
                  @newPersona="setPersona"
                  :reset="reset"
                />
              </v-container>
            </Scrollbar>
          </v-card-text>
        </v-fade-transition>
        
        <v-card-actions class="px-6">
          <!-- :disabled="emptyPost || syncSubmission" -->
          <v-btn
            block
            large
            :text="$vuetify.theme.dark"
            :class="{ 'amber-background': $vuetify.theme.dark }"
            style="border-radius: 12px;"
            :color="$vuetify.theme.dark ? 'tertiary' : 'primary'"
            :loading="loading"
            @click="submit"
          >
            {{ edit ? 'edit' : 'post' }}
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { Scrollbar } from '@components/App'
import { PersonaCreation } from '@components/Persona'
import DefaultEditorJS from '@components/DefaultEditorJS'
import { v4 as uuidv4 } from 'uuid';
const EDITOR_ID = uuidv4()

import { mdiShimmer } from '@mdi/js'
import API from '@api'

export default {
  components: {
    Scrollbar,
    PersonaCreation
  },

  props: {
    newPostDialogOn: {
      type: Boolean,
      default: false
    },
    postType: {
      type: String,
      default: 'POST'
    },
    post: {
      type: Object,
      default: {}
    },
    currentRoom: String,
    edit: {
      type: Boolean,
      default: false
    },
    rerenderPostList: Function
  },

  created() {
    this.setEdit()
  },

  data() {
    return {
      mdiShimmer,
      personas: null,
      personalizeAI: false,
      EDITOR_ID: EDITOR_ID,
      editor: null,
      paragraphs: [''],
      newPostOn: false,
      loading: false,
      reset: false
    }
  },

  computed: {
    ...mapGetters({
      user: 'user',
      myProfileId: 'profile/id',
      myProfileName: 'profile/name',
      myProfileAvatar: 'profile/avatar',
      myProfileBio: 'profile/bio'
    }),

    sender() {
      return {
        profile: this.myProfileId,
        name: this.myProfileName,
        avatar: this.myProfileAvatar,
        bio: this.myProfileBio
      }
    }
  },
  
  watch: {
    newPostDialogOn(dialog) {
      this.newPostOn = dialog

      if (dialog) {
        if (!this.editor)
          this.setEditor()
        this.setEdit()
      }
    }
  },

  methods: {
    async setEditor() {
      const editorPromise = DefaultEditorJS.newEditor(EDITOR_ID)
      await editorPromise.isReady;
      this.editor = editorPromise;
    },

    setEdit() {
      // TODO
    },

    async submitUpdate() {
      // TODO
      return;

      const paragraphs = this.paragraphs.filter(paragraph => {
        return paragraph.length > 0
      })
      
      await API().put(`post/${this.user.id}`, {
        paragraphs
      }, {
        params: {
          id: this.post.id
        }
      })

      this.$emit('update', paragraphs)
      this.$emit('close')
      this.clearPostDialog()
    },

    setPersona(personas) {
      this.personas = personas
    },

    async submit() {
      this.loading = true

      const saverData = await this.editor.saver.save()
      if (!saverData.blocks.length) {
        return this.loading = false
      }
      const post = await API().post(`post/${this.user.id}`, {
        owner: this.myProfileId,
        type: this.postType,
        content: saverData,
        room: this.currentRoom,
        ...(this.$route.params.id && { community: this.$route.params.id }),
      })

      if(this.personas) {
        const chat = await API().post(`conversation/${this.user.id}`, {
          room: this.currentRoom,
          post: post.id,
          name: this.personas.name,
          personas: this.personas
        })

        await API().put(`post/${this.user.id}`, {
          chats: [chat]
        }, {
          params: {
            id: post.id
          }
        })
      }
      if (this.rerenderPostList) this.rerenderPostList()
      this.editor.clear()
      this.clearPostDialog()
      this.$emit('newPost', post)
      this.loading = false
      this.reset = !this.reset;
      this.closeDialog()
    },

    clearPostDialog() {
      // TODO
    },

    closeDialog() {
      this.newPostOn = false
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
/* Deprecated in Vue 3 */
::v-deep .v-dialog {
  border-radius: 24px !important;
}
.amber-background { 
  background-color: rgba(255, 170, 0, 0.225);
}
</style>

<style>
/* .hs-editorjs--post .ce-toolbar__actions {
  opacity: 1 !important;
}
.hs-editorjs--post .ce-toolbar {
  display: block !important;
} */

.hs-editorjs--post {
  min-width: 100%;
}
.editor-dark .ce-popover__item-icon {
  background-color: #212121;
}

.codex-editor__loader {
  display: none !important;
}

.editor-dark .ce-block--selected .ce-block__content,
.editor-dark .ce-inline-toolbar,
.editor-dark .codex-editor--narrow .ce-toolbox,
.editor-dark .ce-conversion-toolbar,
.editor-dark .ce-settings,
.editor-dark .ce-settings__button,
.editor-dark .ce-toolbar__settings-btn:hover,
.editor-dark .cdx-button,
.editor-dark .ce-popover,
.editor-dark .ce-toolbar__plus:hover.editor-dark .ce-block--selected .ce-block__content,
.editor-dark .ce-inline-toolbar,
.editor-dark .codex-editor--narrow .ce-toolbox,
.editor-dark .ce-conversion-toolbar,
.editor-dark .ce-settings,
.editor-dark .ce-settings__button,
.editor-dark .ce-toolbar__settings-btn:hover,
.editor-dark .cdx-button,
.editor-dark .ce-popover,
.editor-dark .ce-conversion-tool__icon,
.editor-dark .ce-toolbar__plus:hover {
  background: #424242;
  color: inherit;
}

.editor-dark .ce-toolbar__settings-btn {
  background: transparent;
  color: inherit;
}

.editor-dark .ce-inline-tool,
.editor-dark .ce-conversion-toolbar__label,
.editor-dark .ce-toolbox__button,
.editor-dark .cdx-settings-button,
.editor-dark .ce-toolbar__plus,
.editor-dark .ce-popover__item-secondary-label {
  color: inherit;
}

.editor-dark .cdx-settings-button:hover,
.editor-dark .ce-settings__button:hover,
.editor-dark .ce-toolbox__button--active,
.editor-dark .ce-toolbox__button:hover,
.editor-dark .cdx-button:hover,
.editor-dark .ce-inline-toolbar__dropdown:hover,
.editor-dark .ce-inline-tool:hover,
.editor-dark .ce-popover__item:hover,
.editor-dark .ce-toolbar__settings-btn:hover {
  background-color: #424242;
  color: inherit;
}
</style>