export default {
  namespaced: true,

  state: {
    membership: null,
    community: null,
    room: null,
    mainRoom: null
  },

  getters: {
    community: ({ community }) => community,
    membership: ({ membership }) => membership,
    room: ({ room }) => room,
    mainRoom: ({ mainRoom }) => mainRoom,
    isModeratorOrUp: ({ membership }) => membership && ['admin', 'owner', 'moderator'].includes(membership.role),
    isAdminOrUp: ({ membership }) => membership && ['admin', 'owner'].includes(membership.role),
    isOwner: ({ membership }) => membership && membership.role === 'owner',
    isInvited: ({ membership }) => membership && membership.status === 'invited',
    isActive: ({ membership }) => membership && membership.status === 'active',
  },
  
  mutations: {
    setCommunity: (state, payload) => {
      state.community = payload
    },
    setMainRoom: (state, payload) => {
      state.mainRoom = payload
    },
    setRoom: (state, payload) => {
      state.room = payload
    },
    setMembership: (state, payload) => {
      state.membership = payload
    },
    setMembershipStatus: (state, payload) => {
      state.membership = {
        ...state.membership,
        status: payload
      }
    }
  }
}