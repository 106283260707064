<template>
  <v-sheet
    class="clickable"
    v-ripple
    :style="`border-radius: 12px; width: 100%; ${style}`"
    @click="$emit('setPerson', person)"
  >
    <v-list-item>
      <v-list-item-avatar size="40">
        <v-img
          v-if="person.avatar"
          :src="getResourceUrl(person.avatar)"
        >
          <template v-slot:placeholder>
            <v-skeleton-loader
              class="mx-auto"
              height="40"
              width="40"
              type="avatar"
            />
          </template>
        </v-img>

        <v-icon 
          v-else 
          style="font-size: 40px;"
        >
          {{ mdiAccountCircle }}
        </v-icon>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>
          {{ person.name }}
        </v-list-item-title>
      </v-list-item-content>

      <v-list-item-action>
        <v-badge
          v-if="false"
          dot
          left
          inline
          color="deep-purple"
        >
          <span class="caption adjustY">
            studying
          </span>
        </v-badge>

        <v-badge
          v-else-if="online"
          dot
          left
          inline
          color="success"
        >
          <span class="caption adjustY">
            online
          </span>
        </v-badge>

        <v-badge
          v-else
          dot
          left
          inline
          color="grey darken-1"
        >
          <span class="caption adjustY">
            offline
          </span>
        </v-badge>
      </v-list-item-action>
    </v-list-item>
  </v-sheet>
</template>

<script>
import { mdiAccountCircle } from '@mdi/js'
import { getResourceUrl } from '@utils'

export default {
  props: {
    person: {
      type: Object,
      required: true
    },
    idx: {
      type: Number,
      required: true
    },
    outlined: {
      type: Boolean,
      default: true
    },
    style: {
      type: String,
      default: ''
    }
  },

  mounted() {
    this.onlinePooling = setInterval(() => {

      this.$socket.onlineSession.emit('isOnline', {
        profile: this.person.id,
        senderSocket: this.$socket.onlineSession.id
      })

    }, 2000)
  },

  beforeDestroy() {
    clearInterval(this.onlinePooling)
  },

  sockets: {
    onlineSession: {
      onlineStatus({ profile, online }) {
        if (profile === this.person.id) {
          this.online = online
        }
      }
    }
  },
  
  data() {
    return {
      mdiAccountCircle,
      
      online: false,
      onlinePooling: null
    }
  },

  methods: {
    getResourceUrl,

    emitNewSenderRequest(data) {
      this.$emit('newSenderRequest', data)
    },

    emitSnack(snackMsg) {
      this.$emit('snack', snackMsg)
    }
  }
}
</script>