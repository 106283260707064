<template>
  <v-card
    :class="`${$vuetify.theme.dark ? 'viewer-dark' : lighterViewerOn || !toolbar ? 'viewer-lighter' : 'viewer-light'}`"
    style="height: 40px; position: relative;"
    rounded="lg"
    :outlined="!toolbar"
  >
    <v-row
      align="center"
      class="px-6"
      style="padding-top: 8px;"
    >
      <v-hover v-slot="{ hover }">
        <v-icon
          :color="hover || sidebarOn ? 'tertiary' : 'white'"
          :class="`clickable ${ sidebarOn ? 'btn--active' : ''}`"
          @click="setSidebarOn"
        >
          {{ mdiMenu }}
        </v-icon>
      </v-hover>

      <v-divider
        vertical
        class="mx-3"
      />

      <v-expand-x-transition hide-on-leave>
        <input
          v-if="searchOn"
          v-model="search"
          ref="searchInput"
          type="text"
          style="width: 200px; color: white; caret-color: white;"
          spellcheck="false"
          :placeholder="$t(placeholder)"
          :class="`no-outline ${inputColorClass}`"
          @keydown="submit"
        />
      </v-expand-x-transition>

      <v-hover v-slot="{ hover }">
        <v-icon
          :color="hover ? 'tertiary' : 'white'"
          class="clickable"
          @click="searchOn = !searchOn"
        >
          {{ searchOn ? mdiClose : mdiMagnify }}
        </v-icon>
      </v-hover>
    </v-row>
  </v-card>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import { TextInput } from '@components/Document'
import { mdiMagnify, mdiClose, mdiMenu } from '@mdi/js'

export default {

  components: {
    TextInput
  },

  props: {
    setFocusOn: {
      type: Boolean,
      default: false
    },
    toolbar: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: 'search'
    },
  },

  data() {
    return {
      mdiMagnify,
      mdiClose,
      mdiMenu,

      searchOn: false,
      search: '',
      lighterViewerOn: false,

      text: '',
      focusOn: '',
      syncContentOn: false,
      syncSubmission: false
    }
  },

  watch: {
    setFocusOn(focus) {
      if (focus) {
        
        this.searchOn = true

        this.$nextTick(() => {
          this.$refs.searchInput.focus({
            preventScroll: true
          })
        })        

      }
    }
  },

  computed: {
    ...mapGetters({
      isOutlineEnabled: 'pdf/isOutlineEnabled',
      isSearchListEnabled: 'pdf/isSearchListEnabled',
      isPreviewEnabled: 'pdf/isPreviewEnabled',
      sidebarOn: 'pdf/sidebarOn'
    }),

    inputColorClass() {
      return this.$vuetify.theme.dark ? 'input-light' : 'input-dark'
    }
  },

  methods: {

    ...mapMutations({
      setIsPreviewEnabled: 'pdf/isPreviewEnabled',
      setIsSearchListEnabled: 'pdf/isSearchListEnabled',
      setIsOutlineEnabled: 'pdf/isOutlineEnabled',
      setSidebarOn: 'pdf/sidebarOn'
    }),

    setTextContent({ textContent }) {
      this.text = textContent
    },
    
    submit(e) {
      
      if (e.key === 'Enter') {
        
        this.$emit('submit', this.search)
    
      }

    }

  }
  
}
</script>

<style scoped>
.btn--active {
  transform: scale(0.85);
  box-shadow: 3px 2px 25px 1px rgba(0,0,0,0.25);
}

.input-dark {
 color: black;
}

.input-light {
  color: white;
}

.no-outline:focus {
  outline: none;
}

.viewer-dark {
  background-color: rgba(202, 199, 199, 0.12);
  -webkit-transition: background-color 0.1s linear;
  -ms-transition: background-color 0.1s linear;
  transition: background-color 0.1s linear;
}

.viewer-light {
  background-color: rgb(103, 58, 158);
  -webkit-transition: background-color 0.1s linear;
  -ms-transition: background-color 0.1s linear;
  transition: background-color 0.1s linear;
}

.viewer-light input::placeholder {
  color: rgb(204, 204, 204);
  opacity: 1; /* Firefox */
}

.viewer-light :-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: rgb(204, 204, 204);
}

.viewer-light input::-ms-input-placeholder { /* Microsoft Edge */
 color: rgb(204, 204, 204);
}

.viewer-lighter {
  background-color: rgb(255, 255, 255);
  -webkit-transition: background-color 0.1s linear;
  -ms-transition: background-color 0.1s linear;
  transition: background-color 0.1s linear;
}
</style>