<template>
  <v-dialog
    v-model="open"
    max-width="500"
    @click:outside="onClose"
  >
    <v-card rounded="xl">
      <v-form ref="form" @submit.prevent="submit">
        <v-container class="py-2 px-5">
          <v-row class="py-4 px-4">
            <v-col cols="4">
              <ImageUpload
                :onUpload="onUpload"
                :imagePlaceholderUrl="room.thumbnail"
                :size="{width: 120, height: 120}"
              />
            </v-col>
            <v-col cols="8">
              <v-row>
                <v-text-field
                  v-model="room.name"
                  outlined
                  dense
                  :class="`mb-4 hs-rounded-lg ${$vuetify.theme.dark ? 'icon-style-dark' : ''}`"
                  :label="$t('labels.name')"
                  :rules="[v => !!v || requiredFieldErrorMessage]"
                />
              </v-row>
              <v-row>
                <v-text-field
                  v-model="room.caption"
                  outlined
                  dense
                  :class="`hs-rounded-lg ${$vuetify.theme.dark ? 'icon-style-dark' : ''}`"
                  :label="$t('labels.caption')"
                />
              </v-row>
            </v-col>
          </v-row>
          <v-row class="px-5">
            <v-textarea
              v-model="room.about"
              outlined
              rows="3"
              auto-grow
              validate-on-blur
              class="hs-rounded-lg"
              :label="$t('labels.about')"
            />
          </v-row>
          <v-card-actions>
            <v-btn
              block
              large
              elevation="0"
              style="border-radius: 12px;"
              :loading="loading"
              @click="submit"
            >
              {{ room.id ? $t('save') : $t('create_room') }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ImageUpload from '@components/Image/ImageUpload'
  import RoomService from '@api/room'

  export default {
    components: {
      ImageUpload
    },

    created() {
      this.roomService = RoomService(this.user.id)
    },

    props: {
      open: {
        type: Boolean,
        default: false
      },
      onClose: {
        type: Function
      },
      communityId: String,
      onCreate: Function
    },

    data () {
      return {
        roomService: null,
        room: {},
        requiredFieldErrorMessage: this.$t('form.error.required'),
      }
    },

    computed: {
      ...mapGetters({
        user: 'user',
        profileId: 'profile/id'
      })
    },

    methods: {
      async onUpload(file) {
        this.room.coverImageFile = file
      },

      resetRoom() {
        this.room = {}
      },

      async submit() {
        if (!this.$refs.form.validate()) return this.$emit('err', 'incomplete')
        const formData = new FormData();
        formData.append('file', this.room.coverImageFile);
        formData.append('name', this.room.name);
        formData.append('caption', this.room.caption);
        formData.append('about', this.room.about);
        formData.append('ownerId', this.profileId);
        formData.append('community', this.communityId);
        let handler = this.roomService.createCommunityRoom
        if (this.room.id) {
          formData.append('id', this.room.id);
          handler = this.roomService.updateCommunityRoom
        }
        const { room } = await handler(formData)
        this.resetRoom()
        if (this.onCreate) this.onCreate()
        this.onClose(room)
      }
    }
  }
</script>