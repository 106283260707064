import { withLayout } from '@utils'
import Theater from '@layouts/Theater'

const Video = () => import('@views/Video')

export default {
  path: 'video',
  component: {
    render: h => h('router-view')
  },
  children: [
    {
      path: 'watch',
      name: 'video.watch',
      component: withLayout(Video, Theater),
      meta: {
        locked: true,
        app: 'video'
      }
    }
  ]
};
