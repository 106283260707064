<template>
  <v-app-bar
    app
    dark
    fixed
    height="60"
    elevation="1"
    clipped-left
    clipped-right
    :color="toolbarColor"
  >
    <div class="d-flex" style="width: 100%; min-width: 715px; justify-content: center;">
    <v-dialog
      v-model="showEditProfile"
      max-width="400"
    >
      <v-card>
        <v-container>
          <v-row align="center" justify="center">
            <v-col cols="12">
              <EditProfile
                :signup="false"
                @newProfile="submit"
                @close="showEditProfile = false"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-container class="mx-0" style="max-width: 1750px;">
    <v-row align="center">
      <v-col :xs="12" :sm="6">
        <v-row
          class="d-flex"
          justify="start"
          align="center"
        >
          <v-tooltip
            v-if="$vuetify.breakpoint.smAndDown"
            bottom
            :disabled="!tooltipsOn"
          >
            <template v-slot:activator="{ on }">
              <v-btn 
                icon
                large
                color="white"
                @click="LEFT_CARD_COLLAPSE"
                v-on="on"
              >
                <v-icon>
                  {{ mdiMenu }}
                </v-icon>
              </v-btn>
            </template>
            <span>Menu</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                @click="onHomeClick"
              >
                <v-avatar size="36">
                  <v-img src="@assets/images/hs-logo--white.png" />  
                </v-avatar>
              </v-btn>
            </template>
            <span>Personas</span>
          </v-tooltip>

          <SemanticSearch
            :avatarOn="false"
            :lighterViewerOn="true"
            :setFocusOn="setFocusOn"
          />
        </v-row>
      </v-col>
      <v-col :sm="6">
        <v-row
          align="center"
          justify="end"
        >
          <!-- <v-tooltip
            bottom
            :disabled="!tooltipsOn"
          >
            <template v-slot:activator="{ on }">
              <v-btn 
                icon
                large
                color="white"
                to="/personas"
                :disabled="isDisabled"
                v-on="on"
              >
                <v-icon>
                  {{ mdiDramaMasks }}
                </v-icon>
              </v-btn>
            </template>
            <span>AI Personas</span>
          </v-tooltip> -->
          <v-tooltip
            bottom
            :disabled="!tooltipsOn"
          >
            <template v-slot:activator="{ on }">
              <v-btn 
                icon
                large
                color="amber accent-4"
                to="/notebooks"
                :disabled="isDisabled"
                v-on="on"
              >
                <v-icon :color="studyColor">
                  {{ mdiLightbulb }}
                </v-icon>
              </v-btn>
            </template>
            <span v-text="$t('studySession')"></span>
          </v-tooltip>

          <v-tooltip
            bottom
            :disabled="!tooltipsOn"
          >
            <template v-slot:activator="{ on }">
              <v-btn 
                icon
                large
                color="amber accent-4"
                to="/rooms"
                :disabled="isDisabled"
                v-on="on"
              >
                <v-icon
                  v-if="!roomsOn"
                  color="white"
                >
                  {{ mdiFireplaceOff }}
                </v-icon>

                <v-icon v-else>
                  $fireplace
                </v-icon>
              </v-btn>
            </template>
            <span v-text="$tc('navigation.room', 2)"></span>
          </v-tooltip>

          <v-menu
            offset-y
            rounded="lg"
            v-model="bellOn"
            v-on="on"
          >
            <template v-slot:activator="{ on }">
              <v-btn 
                icon 
                text
                large
                v-on="on"
                :disabled="isDisabled"
              >
                <v-badge
                  overlap
                  color="red"
                  offset-x="15"
                  :value="Boolean(notificationsLen)"
                >
                  <span slot="badge">
                    {{ notificationsLen }}
                  </span>
                  <v-icon>
                    {{ $data[bellIcon] }}
                  </v-icon>
                </v-badge>
              </v-btn>
            </template>
            <Notifications />
          </v-menu>

          <v-menu
            offset-y
            rounded="lg"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                text
                v-on="on"
              >
                <v-avatar :size="avatarSize">
                  <v-img
                    v-if="avatar"
                    :src="getResourceUrl(avatar)"
                  >
                    <template v-slot:placeholder>
                      <v-skeleton-loader
                        class="mx-auto"
                        height="40"
                        width="40"
                        type="image"
                      />
                    </template>
                  </v-img>
                  <v-btn
                    v-else
                    icon
                    color="white"
                  >
                    <v-icon
                      dark
                      size="30"
                    >
                      {{ mdiAccount }}
                    </v-icon>
                  </v-btn>
                </v-avatar>
              </v-btn>
            </template>

            <v-list class="pa-0">
              <v-list-item
                v-for="(item, index) in menuItems" 
                :to="item.to"
                :key="index"
                @click="item.function"
              >
                <v-list-item-action v-if="item.icon">
                  <v-icon>
                    {{ $data[item.icon] }}
                  </v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t(`toolbar.${item.title}`) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-tooltip
            v-if="$vuetify.breakpoint.mdAndDown"
            bottom
            :disabled="!tooltipsOn"
          >
            <template v-slot:activator="{ on }">
              <v-btn 
                icon
                large
                color="white"
                @click="RIGHT_CARD_COLLAPSE"
                v-on="on"
              >
                <v-icon>
                  {{ mdiMenu }}
                </v-icon>
              </v-btn>
            </template>
            <span>Menu</span>
          </v-tooltip>
        </v-row>
      </v-col>
    </v-row>
    </v-container>
    </div>
  </v-app-bar>
</template>

<script>
import { signOut, getResourceUrl } from '@utils'
import { SemanticSearch } from '@components/Document'
import { EditProfile } from '@components/Profiles'
import { Notifications } from '@components/Notification'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import {
  mdiDramaMasks,
  mdiLightbulb,
  mdiAccount,
  mdiAccountCircle,
  mdiCog,
  mdiBrightness7,
  mdiBrightness2,
  mdiFireplaceOff,
  mdiEmailOpen,
  mdiEmailOutline,
  mdiBell,
  mdiBellOutline,
  mdiMagnify,
  mdiExitToApp,
  mdiMenu,
  mdiCash
} from '@mdi/js'

export default {
  components: {
    Notifications,
    SemanticSearch,
    EditProfile
  },

  created() {
    this.tooltipsOn = this.settings.tooltipsOn
  },

  data() {
    return {
      mdiDramaMasks,
      mdiLightbulb,
      mdiAccount,
      mdiAccountCircle,
      mdiCog,
      mdiCash,
      mdiBrightness7,
      mdiBrightness2,
      mdiFireplaceOff,
      mdiEmailOpen,
      mdiEmailOutline,
      mdiBell,
      mdiBellOutline,
      mdiMagnify,
      mdiExitToApp,
      mdiMenu,
      showEditProfile: false,
      setFocusOn: false,
      tooltipsOn: true,
      bellOn: false
    }
  },

  watch: {
    settings: {
      deep: true,
      handler: function(newSettings) {
        this.tooltipsOn = newSettings.tooltipsOn
      }
    }
  },

  computed: {
    ...mapGetters({
      user: 'user',
      profile: 'profile/id',
      avatar: 'profile/avatar',
      isValid: 'profile/isValid',
      settings: 'settings',
      notifications: 'notification/notifications',
      auth0Token: 'auth0Token',
      rightCardCollapse: 'rightCardCollapse',
    }),

    themeTxt() {
      return !this.$vuetify.theme.dark
        ? 'darkTheme'
        : 'lightTheme'
    },

    themeIcon() {
      return this.$vuetify.theme.dark
        ? 'mdiBrightness7'
        : 'mdiBrightness2'
    },
    
    menuItems() {
      return [
        {
          icon: 'mdiAccountCircle',
          to: null,
          function: () => {
            this.showEditProfile = true
          },
          title: 'myProfile',
        },
        {
            icon: this.themeIcon,
            to: null,
            function: this.changeTheme,
            title: this.themeTxt,
        },
        {
          icon: 'mdiCog',
          to: '/settings',
          function: () => {},
          title: 'settings',
        },
        {
          icon: 'mdiCash',
          to: '/subscriptions',
          function: () => {},
          title: 'subscriptions',
        },
        {
          icon: 'mdiExitToApp',
          to: '#',
          title: 'logout',
          function: () => {
            signOut(this)
          }
        }
      ];
    },
    
    toolbarColor() {
      return !this.$vuetify.theme.dark
        ? 'primary'
        : 'grey darken-4'
    },

    studyColor() {
      return this.$route.name === 'notebooks.list'
        ? 'amber accent-4'
        : 'white'
    },

    roomsIcon() {
      return this.roomsOn
        ? 'mdiFireplace'
        : 'mdiFireplaceOff'
    },

    bellIcon() {
      return this.bellOn
        ? 'mdiBell'
        : 'mdiBellOutline'
    },

    isDisabled() {
      return !this.isValid
    },

    notificationsLen() {
      return this.notifications.filter(({read}) => !read).length
    },

    roomsOn() {
      return this.$route.meta.app === 'rooms'
    },

    avatarSize() {
      return this.avatar ? '40px' : ''
    }
  },

  methods: {
    // Sets the logout action on context 'this' so that the 'signOut' function can use it
    ...mapActions({
      logout: 'logout',
    }),

    onHomeClick() {
      this.forceCloseAnnaChamber()
      this.$router.push('/home')
    },
    ...mapMutations({
      SET_DARK_THEME: 'setDarkTheme',
      RIGHT_CARD_COLLAPSE: 'RIGHT_CARD_COLLAPSE',
      LEFT_CARD_COLLAPSE: 'LEFT_CARD_COLLAPSE',
      forceCloseAnnaChamber: 'forceCloseAnnaChamber'
    }),

    getResourceUrl,
    signOut,

    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      this.SET_DARK_THEME(this.$vuetify.theme.dark)
    }
  }
}
</script>

<style scoped>
::v-deep .v-dialog {
  border-radius: 24px !important;
}
</style>