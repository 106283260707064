export default {
  namespaced: true,

  state: {
    currentRoom: {},
    openHandleGroupRoomDialog: false,
    groupRoomToHandle: null
  },

  getters: {
    currentRoom: ({ currentRoom }) => currentRoom,
    openHandleGroupRoomDialog: ({ openHandleGroupRoomDialog }) => openHandleGroupRoomDialog,
    groupRoomToHandle: ({ groupRoomToHandle }) => groupRoomToHandle
  },
  
  mutations: {
    setCurrentRoom: (state, payload) => {
      state.currentRoom = payload
    },
    setOpenHandleGroupRoomDialog: (state, payload) => {
      state.openHandleGroupRoomDialog = payload
    },
    setGroupRoomToHandle: (state, payload) => {
      state.groupRoomToHandle = payload
    }
  }
}