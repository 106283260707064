<template>
  <v-list color="transparent">
    <v-subheader class="subtitle-1 px-0">
      Privacy settings
    </v-subheader>

    <div
      v-if="customObs"
      :class="{
        'text-body-2 grey--text': true,
        'text--darken-2': !$vuetify.theme.dark
      }"
    >
      {{ customObs }}
    </div>

    <v-list-item
      v-for="(config, idx) in configs.slice(0, generative ? configs.length : configs.length - 1)"
      :key="`config_${idx}`"
      class="px-0"
    >
      <v-list-item-content>
        <v-list-item-title 
          :class="`text-h${textSize} font-weight-light`"
          v-text="config.title"
        />
      </v-list-item-content>
      <v-list-item-action>
        <v-switch 
          inset
          color="primary"
          v-model="config.value"
          @change="emitSettings"
        />
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    customObs: {
      type: String,
      default: ''
    },
    generative: {
      type: Boolean,
      default: false
    },
    textSize: {
      type: Number,
      default: 5
    },
    currentSettings: {
      type: Object,
      default: () => ({})
    }
  },

  created() {
    if (Object.keys(this.currentSettings).length > 0) {
      for (const key in this.currentSettings) {
        if (this.currentSettings.hasOwnProperty(key)) {
          const idx = this.configs.map(({ key }) => key).indexOf(key)
          this.configs[idx].value = this.currentSettings[key]
        }
      }
    }
  },

  data() {
    return {
      configs: [
        {
          key: 'isAnonymous',
          title: 'Anonymous',
          value: false
        },
        {
          key: 'isPublic',
          title: 'Public',
          value: true
        },
        {
          key: 'isUnlisted',
          title: 'Unlisted',
          value: false
        },
        {
          key: 'visiblePrompt',
          title: 'Prompt visibility',
          value: false
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      user: 'user'
    })
  },

  methods: {
    emitSettings() {
      const [isAnonymous, isPublic, isUnlisted, visiblePrompt] = this.configs.map(({value}) => value)
      this.$emit('settings', { isAnonymous, isPublic, isUnlisted, visiblePrompt })
    }
  }
}
</script>