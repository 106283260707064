<template>
  <div>
    <v-navigation-drawer
      absolute
      right
      temporary        
      width="600"
      :value="settingsOn"
      @input="close"
    >
      <v-container>
        <v-fade-transition hide-on-leave>
          <div v-if="isEditor">
            <v-row justify="center">
              <v-col
                cols="11"
                class="px-2"
              >
                <PrivacySettings
                  @settings="setPrivacySettings"
                />
              </v-col>
            </v-row>

            <v-divider />
          </div>
        </v-fade-transition>

        <v-list dense>
          <v-subheader class="body-2 mx-2">
            Settings
          </v-subheader>

          <v-list-item-group color="primary">
            <v-list-item
              v-for="item in optionItems"
              :key="item.title"
              link
            >
              <v-list-item-icon>
                <v-icon :color="item.color">
                  {{ $data[item.icon] }}
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-container>

      <template v-slot:append>
        <v-divider />
        <v-row justify="center">
          <v-col
            xl="11"
            lg="11"
          >
            <v-row justify="center">
              <v-btn
                text
              >
                edit
              </v-btn>

              <v-spacer />

              <v-btn
                text
                color="red darken-4"
              >
                delete
                <v-icon right>
                  {{ mdiDelete }}
                </v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { PrivacySettings } from '@components'
import API from '@api'

import {
  mdiDelete,
  mdiBookmarkOutline,
  mdiShieldLockOutline,
  mdiAlertOutline,
  mdiHistory
} from '@mdi/js'

export default {
  components: {
    PrivacySettings
  },

  data () {
    return {
      mdiDelete,
      mdiBookmarkOutline,
      mdiShieldLockOutline,
      mdiAlertOutline,
      mdiHistory,

      optionItems: [
        {
          title: 'Save',
          icon: 'mdiBookmarkOutline',
          color: ''
        },
        {
          title: 'Share',
          icon: 'mdiShieldLockOutline',
          color: ''
        },
        {
          title: 'Report',
          icon: 'mdiAlertOutline',
          color: ''
        },
        {
          title: 'History',
          icon: 'mdiHistory',
          color: ''
        }
      ]

    }
  },

  computed: {
    ...mapGetters({
      user: 'user',
      isUnlisted: 'privacy/isUnlisted',
      isPublic: 'privacy/isPublic',
      isAnonymous: 'privacy/isAnonymous',
      notebookId: 'pdf/pdfId',
      settingsOn: 'pdf/settingsOn',
      isEditor: 'pdf/isEditor',
      profile: 'profile/id'
    })
  },

  methods: {
    ...mapMutations({
      SET_PDF_SETTINGS: 'pdf/SET_PDF_SETTINGS',
    }),

    close(payload) {
      if (!payload) {
        this.SET_PDF_SETTINGS()
      }
    },

    setPrivacySettings({ isAnonymous, isUnlisted, isPublic }) {
      API().put(`/notebook/${this.user.id}`, {
        isAnonymous,
        isUnlisted,
        isPublic
      }, {
        params: {
          id: this.notebookId
        }
      })
    }
  }
}
</script>