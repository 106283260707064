<template>
  <v-card  
    outlined
    :style="`position: absolute; width: ${dense ? 85 : 92}%; z-index: 10;`"
  >
    <v-container>
      <v-list-item>
        <v-list-item-avatar size="40">
          <v-img :src="note.sender.avatar" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="note.sender.name" />
          <v-list-item-subtitle v-text="createdAt" />
        </v-list-item-content>
      </v-list-item>

      <v-container class="py-0">
        <v-row
          v-if="note.paragraphs"
          justify="center"
        >
          <v-col cols="12">
            <span
              class="body-2"
              v-katex:auto="{ options: latexOptions }"
              v-html="readMore ? note.paragraphs.join('<br />') : truncate(note.paragraphs.join('<br />'))"
            />
            <br />
            <span
              v-if="note.paragraphs.join(' ').length > 200"
              class="clickable caption primary--text"
              @click="readMore = !readMore"
            >
              {{ readMore ? 'hide' : 'read more' }}
            </span>
          </v-col>
        </v-row>

        <v-row
          v-if="audioSrc"
          justify="center"
        >
          <v-col cols="12">
            <AudioPlayer :src="audioSrc" />
          </v-col>
        </v-row>

        <v-row
          align="center"
          class="px-4"
        >
          <v-btn
            icon
            :color="starContent ? 'amber accent-2' : ''"
            @click="doStarContent"
          >
            <v-icon>
              {{ starContent ? mdiStarFourPoints : mdiStarFourPointsOutline }}
            </v-icon>
          </v-btn>

          <v-spacer />                    
        </v-row>
      </v-container>

    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { latexOptions, formatDate, truncateStr } from '@utils'
import { AudioPlayer } from '@components/Audio'
import API from '@api'

import {
  mdiStarFourPointsOutline,
  mdiStarFourPoints
} from '@mdi/js'

export default {

  components: {
    AudioPlayer
  },

  props: {
    dense: {
      type: Boolean,
      default: false
    },
    noteId: {
      type: String,
      default: ''
    },
    note: {
      type: Object,
      required: true
    }
  },

  created() {
    
    this.setEngagement()

    this.setAudio()

  },
  
  data() {
    return {
      latexOptions,

      mdiStarFourPointsOutline,
      mdiStarFourPoints,

      audioSrc: '',

      starContent: false,
      readMore: false,
    }
  },

  computed: {

    ...mapGetters({
      user: 'user',
      profile: 'profile/id'
    }),

    createdAt() {
      return formatDate(this.note.createdAt, this.$i18n.locale)
    }

  },

  watch: {
    noteId() {
      this.setAudio()
    },
  },

  methods: {
    
    truncate(str) {
      return truncateStr(str, 200)
    },

    async doStarContent() {
      
      // await API().put(`engagement/${this.user.id}`, {
      //   starContent: !this.starContent
      // },
      // {
      //   params: {
      //     query: {
      //       annotation: {
      //         $eq: this.note.id
      //       },
      //       profile: {
      //         $eq: this.profile
      //       }
      //     },
      //     options: {
      //       upsert: true
      //     }
      //   }
      // })

      // this.starContent = !this.starContent
      
    },

    async setEngagement() {

      const [engagement] = await API().get(`engagement/${this.user.id}`, {
        params: {
          annotation: this.note.id,
          profile: this.profile
        }
      })

      if (engagement) {

        this.starContent = engagement.starContent

      }

    },

    setAudio() {
      this.audioSrc = ''

      const [audio] = this.note.mediaContent
        ? this.note.mediaContent.audios
        : []

      if (audio) {

        this.audioSrc = audio.url

      }
    },
  }
  
}

</script>