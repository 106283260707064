<template>
  <div>
    <v-chip
      v-if="showDeclinedChip"
      label
      small
      outlined
      class="mx-2"
      color="red darken-4"
      style="border-radius: 8px !important;"
      v-text="$t('people.declined')"
    />

    <v-btn
      v-else-if="friendshipOn"
      small
      elevation="0"
      :class="{ 'success': true, 'darken-3': $vuetify.theme.dark, 'lighten-3': !$vuetify.theme.dark }"
      style="border-radius: 8px !important;"
    >
      <v-icon>
        {{  mdiAccountCheck }}
      </v-icon>
    </v-btn>

    <v-btn
      v-else-if="showConnect"
      small
      elevation="0"
      :class="{
        'primary': true,
        'darken-3': $vuetify.theme.dark,
        'lighten-3': !$vuetify.theme.dark
      }"
      :disabled="loading"
      style="border-radius: 8px !important;"
      @mouseenter="emitRipple(false)"
      @mouseleave="emitRipple(true)"
      @click="connect"
    >
      <v-icon>
        {{ mdiAccountPlus }}
      </v-icon>
    </v-btn>

    <div v-else-if="showAccept">
      <v-btn
        icon
        @mouseenter="emitRipple(false)"
        @mouseleave="emitRipple(true)"
        @click="accept"
      >
        <v-icon>
          {{ mdiCheck }}
        </v-icon>
      </v-btn>

      <v-btn
        v-if="false"
        icon
        :disabled="declined"
        @mouseenter="emitRipple(false)"
        @mouseleave="emitRipple(true)"
        @click="decline"
      >
        <v-icon>
          {{ mdiClose }}
        </v-icon>
      </v-btn>
    </div>

    <v-btn
      v-else
      small
      elevation="0"
      :class="{ 'warning': true, 'darken-3': $vuetify.theme.dark, 'lighten-3': !$vuetify.theme.dark }"
      style="border-radius: 8px !important; width: 104px;"
    >
      requested
    </v-btn>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import API from '@api'

import {
  mdiClose,
  mdiAccountPlus,
  mdiAccountCheck,
  mdiPlus,
  mdiCheck
} from '@mdi/js'

export default {
  props: {
    person: {
      type: Object,
      required: true
    },
    friendship: {
      type: String,
      default: '{}'
    },
    showDeclined: {
      type: Boolean,
      default: false
    }
  },
  
  data() {
    return {
      mdiClose,
      mdiPlus,
      mdiAccountPlus,
      mdiCheck,
      mdiAccountCheck,
      loading: false
    }
  },

  computed: {
    ...mapGetters({
      user: 'user',
      myProfile: 'profile/id',
      myName: 'profile/name',
      myAvatar: 'profile/avatar',
      friendshipCount: 'profile/friendshipCount'
    }),

    friendshipObj() {
      return JSON.parse(this.friendship)
    },

    connectBtnColor() {
      return !this.$vuetify.theme.dark
        ? 'primary'
        : ''
    },

    friendshipOn() {
      return this.friendshipObj.active && this.friendshipObj.request && this.friendshipObj.request.accepted
    },

    friendshipRequest() {
      return this.friendshipObj.sender != this.myProfile
    },
    
    senderRequest() {
      return this.friendshipObj.sender === this.myProfile
    },

    showConnect() {
      const friendshipObj = JSON.parse(this.friendship)
      return Object.keys(friendshipObj).length === 0
    },

    showAccept() {
      return this.friendshipRequest && this.friendshipObj.request && !this.friendshipObj.request.accepted
    },

    declined() {
      return this.friendshipObj.request && this.friendshipObj.request.declined && this.friendshipRequest
    },

    showDeclinedChip() {
      return this.declined && this.showDeclined
    }
  },

  methods: {
    ...mapMutations({
      SET_FRIENDSHIPS_COUNT: 'profile/friendshipCount'
    }),

    emitRipple(v) {
      this.$emit('setRipple', v)
    },

    async connect(e) {
      this.loading = true
      e.preventDefault()
      
      const sender = this.myProfile
      const recipient = this.person.id
      try {
        const friendship = await API().post(`friendship/${this.user.id}`, {
          sender,
          recipient
        })
        this.$emit('newSenderRequest', friendship)

        const notification = await API().post('notification', {
          profile: this.person.id,
          to: '/rooms',
          thumbnail: {
            profile: this.myProfile,
            url: this.myAvatar
          },
          type: 'friendshipRequest',
          names: [this.myName]
        })
        const profile = {
          _id: this.myProfile,
          id: this.myProfile,
          name: this.myName,
          avatar: this.myAvatar
        }
        this.$socket.notification.emit('friendshipRequest', { profile, notification, friendship })
        this.$emit('snack', 'friendshipRequestSent')

      } catch (err) {
        if (err.response.data.errMsg) {
          
          const { errMsg } = err.response.data
          this.$emit('snack', errMsg)
        
        } else {
        
          this.$emit('snack', 'errServer')
        
        }
      } finally {

        this.loading = false

      }
    },

    async accept(evt) {
      evt.preventDefault()

      const requestData = {
        request: {
          accepted: true,
          declined: false,
        },
        active: true
      }
      const params = { id: this.friendshipObj.id }
  
      try {
        const friendship = await API().put(`friendship/${this.user.id}`, requestData, { params });
        await API().put(`profile-friendship/add-count/${this.user.id}`, {
          friendshipCount: 1
        }, {
          params: {
            sender: this.person.id,
            recipient: this.myProfile
          }
        });

        this.SET_FRIENDSHIPS_COUNT(this.friendshipCount + 1);
        this.$emit('updateFriendship', friendship);

        this.snackMsg = 'friendshipRequestAccepted';
        this.snackOn = true;

        const notification = {
          profile: this.person.id,
          to: `/people/profile?id=${this.myProfile}`,
          thumbnail: {
            profile: this.myProfile,
            url: this.myAvatar
          },
          type: 'friendshipRequestAccepted',
          names: [this.myName]
        };

        await API().post('notification', notification);
        notification['createdAt'] = new Date();
        const profile = {
          _id: this.myProfile,
          id: this.myProfile,
          name: this.myName,
          avatar: this.myAvatar
        }
        this.$socket.notification.emit('acceptFriendshipRequest', { profile, notification, friendship });
      } catch (error) {
        console.error(error);
      }
    },

    decline(evt) {
      evt.preventDefault()

      API().put(`friendship/${this.user.id}`, {
        request: {
          accepted: false,
          declined:  true
        },
        active: false
      }, {
        params: {
          id: this.friendshipObj.id
        }
      })
      .then(friendship => {
        this.$emit('updateFriendship', friendship)
        this.snackMsg = 'friendshipRequestDeclined'
        this.snackOn = true
      })
    }
  }
}
</script>