<template>
  <div style="height: 100%;">
    <Scrollbar
      :thin="true"
      :flexible="true"
      :style="`height: ${rootHeight}px`"
    >
      <v-container class="py-0">
        <v-row class="px-3">
          <v-btn
            :small="$vuetify.breakpoint.mdAndDown"
            elevation="0"
            class="primary"
            :style="`flex-grow: 0;
              border-radius: ${borderRadius}px;`"
            @click="$emit('browse')"
          >
             {{ $t('favorites') }}
          </v-btn>
        </v-row>

        <div class="my-4"></div>

        <div class="text-body-1 px-1">
          Augmented AI
        </div>
        <div :class="{
          'text-body-2 grey--text px-1': true,
          'text--darken-3': !$vuetify.theme.dark
        }">
          Chat with YouTube or ArXiv
        </div>

        <ChatWeb class="my-2" />

        <div class="my-4"></div>

        <v-row
          align="center"
          justify="space-between"
          class="px-4"
        >
          <div class="text-body-1 px-1">
            AI Params
          </div>

          <v-btn
            small
            color="primary"
            elevation="0"
            class="mx-1"
            style="border-radius: 8px;"
            @click="resetParams"
          >
            reset
          </v-btn>
        </v-row>
        <div :class="{
          'text-body-2 grey--text px-1': true,
          'text--darken-3': !$vuetify.theme.dark
        }">
          {{ persona.id ? 'Customize the' : 'Choose an' }} AI Persona
        </div>

        <PersonaParams textBackground="" />
      </v-container>
    </Scrollbar>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { Scrollbar } from '@components/App'
import { mdiArrowLeft } from '@mdi/js';
import ChatParams from '@components/Conversation/ChatParams.vue'
import PersonaParams from '@components/Persona/PersonaParams.vue'
import ChatWeb from '@components/Persona/ChatWeb.vue'
import API from '@api'

export default {
  components: {
    Scrollbar,
    ChatWeb,
    ChatParams,
    PersonaParams
  },
  
  data: () => ({
    mdiArrowLeft
  }),

  computed: {
    ...mapGetters({
      innerHeight: 'innerHeight',
      user: 'user',
      persona: 'currentPersona',
      personaConnector: 'persona/connector',
      roles: 'persona/roles',
      tools: 'persona/tools'
    }),

    rootHeight() {
      return this.innerHeight * (1 - (100 / this.innerHeight)) - 150
    },

    borderRadius() {
      return this.$vuetify.breakpoint.mdAndDown ? 8 : 12
    },

    optionsColor() {
      return this.$vuetify.theme.dark ? 'primary' : '#9d51f958'
    },
  },

  methods: {
    ...mapMutations({
      RESET_PERSONA: 'RESET_PERSONA',
      SET_TOOLS: 'persona/SET_TOOLS',
      SET_CONNECTOR: 'persona/SET_CONNECTOR'
    }),

    async resetParams() {
      const persona = await API().get(`persona/${this.user.id}`, {
        params: {
          id: this.persona.id
        }
      })

      await API().put('connector', {
        tools: persona.tools
      }, {
        params: {
          id: this.personaConnector.id
        }
      })
      
      if (persona.tools) {
        let connector = { ...this.personaConnector }
        connector.tools = persona.tools

        this.SET_CONNECTOR(connector)

        this.SET_TOOLS([])
        setTimeout(() => {
          this.SET_TOOLS(persona.tools)
        }, 30)
      }
    }
  }
}
</script>

<style>
.unselectable {
  user-select: none;
  -moz-user-select: none; /* Firefox */
  -webkit-user-select: none; /* Safari, Chrome */
  -ms-user-select: none; /* IE/Edge */
}
</style>