<template>
  <v-form ref="form">
    <SnackBar
      :snackMsg="snackMsg"
      :snackOn="snackOn"
      :snackTop="true"
      @close="snackOn = false"
    />
    
    <v-card
      outlined
      class="hs-rounded-xl"
      style="width: 380px;"
    >
      <v-window v-model="step">
        <v-window-item :value="1">
          <v-container>
            <v-row justify="center">
              <v-card-title class="pb-0 text-center">
                {{  $t('signup_page.welcome') }}
              </v-card-title>
            </v-row>

            <v-row justify="center">
              <v-card-subtitle class="pt-0 grey--text text--darken-1">
                {{ $t('signup_page.already_signed') }}
                <span
                  class="clickable font-weight-bold"
                  @click="$router.push('/signin')"
                >
                  {{ $t('signin') }}
                </span>
              </v-card-subtitle>
            </v-row>

            <v-divider :class="{ 'transparent': loading }"/>

            <v-progress-linear
              :active="loading"
              indeterminate
              color="deep-purple accent-4"
              style="max-width: 95%; left: 50%; transform: translate(-50%, -50%);"
              absolute
            />

            <div class="my-4"></div>
            
            <v-row
              align="center"
              justify="center"
            >
              <OAuth
                type="signup"
                @setUser="oauthSubmit"
              />
            </v-row>

            <div class="text-h6 text-center my-3">
              {{ $t('or') }}
            </div>

            <div class="px-5">
              <v-text-field
                v-model="email"
                :rules="[v => /.+@.+\..+/.test(v) || $t('validation.email')]"
                validate-on-blur
                :append-icon="mdiEmail"
                name="email"
                label="e-mail"
                type="text"
                class="hs-rounded-lg"
                outlined
                @input="formatEmail"
                data-cy="email-input"
              />

              <v-text-field
                v-if="!safeSignup"
                v-model="password"
                :append-icon="showPassword ? mdiEyeOutline : mdiEyeOffOutline"
                @click:append="showPassword = !showPassword"
                :rules="[v => v && v.length >= 6 && v.indexOf(' ') === -1 || $t('validation.invalidPwd')]"
                :label="$t('verify.pwdLabel')"
                validate-on-blur
                name="password"
                :type="showPassword ? 'text' : 'password'"
                class="hs-rounded-lg"
                outlined
                data-cy="password-input"
              />

              <v-text-field
                v-if="!safeSignup"
                v-model="passwordConfirm"
                :append-icon="showPasswordConfirm ? mdiEyeOutline : mdiEyeOffOutline"
                @click:append="showPasswordConfirm = !showPasswordConfirm"
                :rules="[v => v === password || $t('validation.pwdsDontMatch')]"
                :label="$t('verify.pwdConfirmLabel')"
                validate-on-blur
                name="passwordConfirm"
                :type="showPasswordConfirm ? 'text' : 'password'"
                class="hs-rounded-lg"
                outlined
                data-cy="password-confirm-input"
              />

              <v-btn
                class="hs-rounded-lg"
                block
                large
                color="primary"
                @click="next"
                data-cy="continue-signup-button"
              >
                {{ $t('continue') }}
              </v-btn>

              <v-card-text>
                <v-row justify="center">
                  <div
                    class="text-center"
                    v-html="$t('signup_page.acceptTerms')"
                  ></div>
                </v-row>
              </v-card-text>
            </div>
          </v-container>
        </v-window-item>

        <v-window-item :value="2">
          <EditProfile
            :signup="true"
            @goBack="step--"
            @newProfile="submit"
          />
        </v-window-item>
      </v-window>
    </v-card>
  </v-form>
</template>

<script>
import { SnackBar } from '@components/App'
import { OAuth, Signup } from '@components/Auth'
import { nameRegex } from '@utils/regex'
import { mapActions, mapMutations } from 'vuex'
import { EditProfile } from '@components/Profiles'
import API from '@api'

import {
  mdiAccount,
  mdiEmail,
  mdiEyeOutline,
  mdiEyeOffOutline
} from '@mdi/js'

const { VUE_APP_SAFE_SIGNUP } = process.env

export default {
  components: {
    SnackBar,
    OAuth,
    EditProfile
  },

  created() {
    this.submitAccount = Signup.submitAccount.bind(this);
    this.oauthSignin = Signup.oauthSignin.bind(this);
  },

  data () {
    return {
      mdiAccount,
      mdiEmail,
      mdiEyeOutline,
      mdiEyeOffOutline,
      showPassword: false,
      showPasswordConfirm: false,
      step: 1,
      nameRegex,
      loading: false,
      snackMsg: '',
      snackOn: false,
      fullName: '',
      email: '',
      password: '',
      passwordConfirm: '',
      safeSignup: VUE_APP_SAFE_SIGNUP === 'true'
    }
  },

  methods: {
    ...mapMutations({
      setLocale: 'locale',
      SET_ANNA: 'anna',
      SET_AUTH0_TOKEN: 'auth0Token',

      setAvatar: 'profile/avatar',
    }),
    
    ...mapActions({
      setSession: 'setSession'
    }),

    next() {
      if (this.$refs.form.validate()) this.step++
    },

    formatEmail() {
      this.email = this.email.replace(/\s/g, "").toLowerCase()
    },

    oauthSubmit(authData) {
      if (!authData.exists)
        this.submitAccount(authData)
      else
        this.oauthSignin(authData)
    },

    submit({
      userId,
      name,
      username,
      avatar,
      avatarObj,
      birthDate,
      auth0Token
    }) {
      if (this.safeSignup)
        this.validateAccount() // TODO: email validation
      else
        this.submitAccount({
          userId,
          name,
          username,
          avatar,
          avatarObj,
          birthDate,
          auth0Token
        })
    },

    validateAccount() { // TODO: do it
      if (this.$refs.form.validate()) {
        this.loading = true
        API().post(`/user/validate/email/${this.$i18n.locale}`, {
          email: this.email.toLowerCase(),
          fullName: this.fullName,
          origin: 'STUDY_URL'
        }).then(() => {
          this.loading = false
          this.snackMsg = 'signupEmailSent'
          this.snackOn = true
        }).catch(err => {
          const responseData = err.response.data
          this.snackMsg = responseData.errMsg
            ? responseData.errMsg
            : 'signupErr'
          this.snackOn = true
          this.loading = false
        })
      }
    }
  }
}
</script>

<style scoped>
.title {
  font-size: 200% !important;
  font-weight: 300; 
  margin: 0;
}

a {
  text-decoration: none;
}
</style>