import store from '@store'
import * as Cookies from 'js-cookie'

export default async (to, from, next) => {
  const _hisolverLocale = Cookies.get('_hisolverLocale')

  const localeCookie = _hisolverLocale
    ? JSON.parse(_hisolverLocale)
    : {}

  const { locale } = localeCookie

  const navigatorLocale = navigator.language === 'pt-BR'
    ? 'pt-br'
    : 'en'

  if (locale)
    store.commit('locale', locale)

  else if (store.state.locale != navigatorLocale)
    store.commit('locale', navigatorLocale)

  next()
}