var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{staticStyle:{"position":"relative"}},[(_vm.signup)?_c('SnackBar',{attrs:{"snackMsg":_vm.snackMsg,"snackOn":_vm.snackOn},on:{"close":function($event){_vm.snackOn = false}}}):_vm._e(),_c('input',{ref:"avatarInput",attrs:{"type":"file","accept":".png,.jpg,.jpeg,.gif,.svg"},on:{"change":_vm.addAvatar}}),_c('ImageCropper',{attrs:{"stencilComponent":"CircleStencil","imageFile":_vm.imageFile,"mimeType":_vm.mimeType,"cropperOn":_vm.cropperOn},on:{"closeDialog":function($event){_vm.cropperOn = false},"save":_vm.saveAvatar}}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-card-title',{staticClass:"pb-0 text-center"},[_vm._v(" "+_vm._s(_vm.signup ? _vm.$t('almostThere') : (_vm.$t('hi')) + ", " + _vm.myName)+" ")])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-card-subtitle',{class:{
        'grey--text text-center pt-0': true,
        'text--darken-1': !_vm.$vuetify.theme.dark,
        'text--lighten-3': _vm.$vuetify.theme.dark
      }},[_vm._v(" "+_vm._s(_vm.$t('profile.fill_out'))+" ")])],1),_c('v-divider',{class:{ 'transparent': _vm.loading }}),_c('v-progress-linear',{staticStyle:{"max-width":"95%","left":"50%","transform":"translate(-50%, -50%)"},attrs:{"active":_vm.loading && !_vm.signup,"indeterminate":"","color":"deep-purple accent-4","absolute":""}}),_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hover = ref.hover;
return [_c('v-list-item-avatar',{staticClass:"py-0 text-center",attrs:{"size":"100","color":_vm.$vuetify.theme.dark ? 'grey darken-2' : 'grey lighten-3'},on:{"click":_vm.setAvatarInput}},[_c('v-img',{attrs:{"src":_vm.getResourceUrl(_vm.avatar)}},[(!_vm.avatar || hover)?_c('HoverLayer',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-icon',{attrs:{"dark":"","large":""}},[_vm._v(" "+_vm._s(_vm.mdiCamera)+" ")])],1)],1):_vm._e()],1)],1)]}}])})],1),_c('div',{staticClass:"px-5"},[_c('v-text-field',{staticClass:"hs-rounded-lg",attrs:{"outlined":"","label":_vm.$t('labels.name'),"rules":[_vm.rules.name],"data-cy":"name-input"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-text-field',{staticClass:"hs-rounded-lg",attrs:{"outlined":"","rules":[_vm.rules.isUniq, _vm.rules.charLen, _vm.rules.alphanumericOnly],"label":"username","autocapitalize":"off","autocorrect":"off","spellcheck":"false","validate-on-blur":"","prepend-inner-icon":_vm.mdiAt,"data-cy":"username-input"},on:{"input":_vm.verifyUniq},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('v-menu',{ref:"calendarOn",attrs:{"close-on-content-click":false,"return-value":_vm.birthDate,"transition":"scale-transition","min-width":"290px"},on:{"update:returnValue":function($event){_vm.birthDate=$event},"update:return-value":function($event){_vm.birthDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"hs-rounded-lg clickable",attrs:{"readonly":"","outlined":"","hide-details":"","label":_vm.$t('labels.dob'),"data-cy":"birthdate-input"},model:{value:(_vm.birthDate),callback:function ($$v) {_vm.birthDate=$$v},expression:"birthDate"}},on))]}}]),model:{value:(_vm.calendarOn),callback:function ($$v) {_vm.calendarOn=$$v},expression:"calendarOn"}},[_c('DatePicker',{attrs:{"locale":"en","calendarOn":_vm.calendarOn},on:{"setDate":_vm.setBirthDate,"saveDate":_vm.saveBirthDate,"cancel":function($event){_vm.calendarOn = false}}})],1),_c('div',{staticClass:"my-4"}),_c('v-fade-transition',{attrs:{"hide-on-leave":""}},[(!_vm.updateMsg)?_c('v-btn',{staticClass:"hs-rounded-lg",attrs:{"color":"primary","block":"","large":"","loading":_vm.loading,"data-cy":"signup-button"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.signup ? _vm.$t('signup') : _vm.$t('save'))+" ")]):_vm._e()],1),_c('v-fade-transition',{attrs:{"hide-on-leave":""}},[(_vm.updateMsg)?_c('v-btn',{staticClass:"hs-rounded-lg",attrs:{"color":"success","block":"","outlined":"","large":""}},[_vm._v(" "+_vm._s(_vm.$t('profile.updated'))+" "),_c('v-icon',[_vm._v(" "+_vm._s(_vm.mdiCheck)+" ")])],1):_vm._e()],1),_c('div',{staticClass:"my-4"}),(_vm.signup)?_c('span',{class:{
                  'clickable': !_vm.loading,
                  'grey--text': true,
                  'text--darken-1': !_vm.loading,
                  'text--lighten-1': _vm.loading
                },on:{"click":function($event){return _vm.$emit('goBack')}}},[_c('v-icon',{class:{
                    'grey--text': true,
                    'text--darken-1': !_vm.loading,
                    'text--lighten-1': _vm.loading
                  }},[_vm._v(" "+_vm._s(_vm.mdiArrowLeft)+" ")]),_vm._v(" "+_vm._s(_vm.$t('goBack'))+" ")],1):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }