import Vue from 'vue';
import Vuex from 'vuex';
import Cookies from 'js-cookie';
import createPersistedState from 'vuex-persistedstate';
import modules from './modules';

Vue.use(Vuex);

const storageGen = (expires = undefined) => {
  return {
    getItem: key => Cookies.get(key),
    setItem: (key, value) => Cookies.set(
      key,
      value, 
      {
        secure: process.env.NODE_ENV === 'production',
        sameSite: 'Strict',
        expires
      }
    ),
    removeItem: key => Cookies.remove(key)
  }
}

export default new Vuex.Store({
  modules,
  
  // should we have something like a "sessions" component?
  // (instead of all these variables handling settings)
  state: {
    innerWidth: 0,
    innerHeight: 0,
    loggingOut: false,
    currentPost: null,
    account: null,
    user: null,
    newUser: false,
    anna: null,
    from: '',
    settings: {},
    signup: false,
    scrollTop: 0,
    sessionExpired: false,
    auth0Token: '',
    authorizationToken: '',
    locale: '',
    emoji: '',
    annaChamber: false,
    landingScroll: false,
    darkTheme: false,
    studyTool: 'write',
    rightCardCollapse: false,
    leftCardCollapse: false,
    content: null,
    pillTab: 0,
    pendingOnBoarding: [],
    formState: {
      persona: null,
    },

    /*
    * Current Persona
    * We save not the full, persona, but rather
    * split the persona and the roles.
    * Whenever using the currentPersona, we check
    * whether we have the roles. If so we retrieve it from here,
    * if not we get it from the databse
    */
    currentPersona: {}
  },
  
  getters: {
    innerWidth: ({ innerWidth }) => innerWidth,
    innerHeight: ({ innerHeight }) => innerHeight,
    loggingOut: ({ loggingOut }) => loggingOut,
    currentPost: ({ currentPost }) => currentPost,
    account: ({ account }) => account,
    user: ({ user }) => user,
    newUser: ({ newUser }) => newUser,
    anna: ({ anna }) => anna,
    from: ({ from }) => from,
    signup: ({ signup }) => signup,
    settings: ({ settings }) => settings,
    scrollTop: ({ scrollTop }) => scrollTop,
    sessionExpired: ({ sessionExpired }) => sessionExpired,
    auth0Token: ({ auth0Token }) => auth0Token,
    authorizationToken: ({ authorizationToken }) => authorizationToken,
    locale: ({ locale }) => locale,
    emoji: ({ emoji }) => emoji,
    annaChamber: ({ annaChamber }) => annaChamber,
    landingScroll: ({ landingScroll }) => landingScroll,
    darkTheme: ({ darkTheme }) => darkTheme,
    studyTool: ({ studyTool }) => studyTool,
    currentPersona: ({ currentPersona }) => currentPersona,
    rightCardCollapse: ({ rightCardCollapse }) => rightCardCollapse,
    leftCardCollapse: ({ leftCardCollapse }) => leftCardCollapse,
    content: ({ content }) => content,
    pillTab: ({ pillTab }) => pillTab,
    pendingOnBoarding: ({ pendingOnBoarding }) => pendingOnBoarding,
    formState: ({ formState }) => formState
  },

  mutations: {
    SET_INNER_WIDTH: (state, payload) => {
      state.innerWidth = payload
    },
    SET_INNER_HEIGHT: (state, payload) => {
      state.innerHeight = payload
    },
    loggingOut: (state, payload) => {
      state.loggingOut = payload
    },
    currentPost: (state, payload) => {
      state.currentPost = payload
    },
    setState: (state, payload) => {
      for (let key in state) {
        state[key] = JSON.parse(payload)[key]
      }
    },
    account: (state, payload) => {
      state.account = payload
    },
    user: (state, payload) => {
      state.user = payload
    },
    newUser: (state, payload) => {
      state.newUser = payload
    },
    anna: (state, payload) => {
      payload.avatar = payload.avatar
      state.anna = payload
    },
    from: (state, payload) => {
      state.from = payload
    },
    setSignup: state => {
      state.signup = !state.signup
    },
    settings: (state, payload) => {
      const keys = Object.keys(payload)
      if (keys.length > 0) {
        keys.forEach(key => {
          state.settings[key] = payload[key]
        })
      } else {
        state.settings = {}
      }
    },
    scrollTop: (state, payload) => {
      state.scrollTop = payload
    },
    sessionExpired: (state, payload) => {
      state.sessionExpired = payload
    },
    auth0Token: (state, payload) => {
      state.auth0Token = payload
    },
    authorizationToken: (state, payload) => {
      state.authorizationToken = payload
    },
    locale: (state, payload)  => {
      state.locale = payload
    },
    emoji: (state, payload) => {
      state.emoji = payload
    },
    setAnnaChamber: state => {
      state.annaChamber = !state.annaChamber
    },
    forceCloseAnnaChamber: state => {
      state.annaChamber = false
    },
    setLandingScroll: state => {
      state.landingScroll = !state.landingScroll
    },
    setDarkTheme: (state, payload) => {
      state.darkTheme = payload
    },
    setStudyTool: (state, payload) => {
      state.studyTool = payload
    },
    pendingOnBoarding: (state, payload) => {
      state.pendingOnBoarding = payload
    },
    setFormState: (state, payload) => {
      state.formState = payload
    },
    RESET_PERSONA: state => {
      state.currentPersona = {}
    },
    SET_CURRENT_PERSONA: (state, payload) => {
      state.currentPersona = payload
    },
    RIGHT_CARD_COLLAPSE: state => {
      state.rightCardCollapse = !state.rightCardCollapse
    },
    LEFT_CARD_COLLAPSE: state => {
      state.leftCardCollapse = !state.leftCardCollapse
    },
    SET_CONTENT: (state, payload) => {
      state.content = payload
    },
    SET_PILL_TAB: (state, payload) => {
      state.pillTab = payload
    },
  },

  actions: {
    SET_PERSONA: ({ commit }, payload) => {
      const persona = { ...payload }

      const roles = persona['roles']
      delete persona['roles']
      commit('persona/SET_ROLES', roles)

      const tools = persona['tools']
      delete persona['tools']
      commit('persona/SET_TOOLS', tools)

      commit('SET_CURRENT_PERSONA', persona)
    },
    SET_PENDING_ONBOARDING: ({ commit }, payload) => {
      commit('pendingOnBoarding', payload)
    },
    
    setFormState: ({ commit }, payload) => {
      commit('setFormState', payload)
    },

    setSession: async ({ commit, dispatch }, {
      newUser,
      user,
      profile,
      authorizationToken,
      pendingOnBoarding,
      auth0Token
    }) => {
      if (newUser) commit('newUser', newUser)
      commit('user', user)
      commit('settings', profile.settings)
      commit('sessionExpired', false)
      commit('authorizationToken', authorizationToken)
      commit('pendingOnBoarding', pendingOnBoarding)
      commit('auth0Token', auth0Token)
      await dispatch('profile/set', profile)
    },

    logout: ({ commit, dispatch }, payload) => {
      commit('loggingOut', true)
      setTimeout(() => {
        commit('loggingOut', false)
      }, 2000)

      Cookies.remove('_hisolverSession')
      Cookies.remove('_hisolverAuthorizationToken')
      
      commit('authorizationToken', '')
      commit('sessionExpired', payload)
      // cleaning auth0Token avoids forcibly redirecting the users to signin
      commit('auth0Token', '')
      commit('notification/clear')
      commit('rooms/setCurrentRoom', {})
      commit('user', null)
      
      // setTimeout is currently used to avoid accessing
      // inexistent properties of null after removing anna
      setTimeout(() => {
        commit('anna', null)
        commit('SET_PERSONA', {})
      }, 100)
      commit('settings', {})
      dispatch('pendingOnBoarding/set', [])
      dispatch('profile/set', {
        id: '',
        name: '',
        avatar: '',
        username: '',
        portfolio: '',
        valid: '',
        bio: '',
        friendshipCount: 0,
        onBoardingDone: [],
        stripeSubscription: {}
      })
    }
  },

  /*
  * Watch out: can't have cookies > 4kb
  * otherwise you might have issues with the browser
  */
  plugins: [
    createPersistedState({
      key: '_hisolverSession',
      paths: ['user', 'profile', 'settings', 'application', 'pillTab', 'pendingOnBoarding'],
      storage: storageGen(365)
    }),
    createPersistedState({
      key: '_annaSession',
      paths: ['anna', 'anna-mind'],
      storage: storageGen(365)
    }),
    createPersistedState({
      key: '_annatarSession',
      paths: ['currentPersona'],
      storage: storageGen(365)
    }),
    createPersistedState({
      key: '_hisolverAuthorizationToken',
      paths: ['authorizationToken', 'auth0Token'],
      storage: storageGen(365)
    }),
    createPersistedState({
      key: '_hisolverLocale',
      paths: ['locale'],
      storage: storageGen(365)
    })
  ]
});
