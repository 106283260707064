<template>
  <div>
    <v-card class="px-2">
      <v-container v-if="image">
        <v-row
          justify="center"
          class="py-4"
        >
          <Cropper
            :stencilComponent="stencil"
            class="cropper"
            classname="upload-cropper"
            :src="image" 
            :stencil-props="{ aspectRatio: 1 }" 
            @change="change"
          />
        </v-row>

        <v-divider class="mb-2 mt-4" />

        <v-card-actions>
          <v-btn
            plain
            :color="$vuetify.theme.dark ? '' : 'primary'"
            @click="submit"
          >
            {{ $t('images.confirm') }}
          </v-btn>

          <v-spacer />

          <v-btn
            plain
            color="danger"
            @click="clear"
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-container>

      <v-container v-else>
        <v-row justify="center">
          <v-col cols="12">
            <input
              type="file"
              id="imageUpload"
              accept=".png,.jpg,.jpeg,.gif,.svg"
              @change="addDropImg"
            />
            <div v-if="!image">
              <label for="imageUpload">
                <v-card
                  outlined
                  :ripple="false"
                  :color="inputColor"
                  style="cursor: pointer;"
                  height="400"
                  @dragover.prevent
                  @drop.prevent="addDropImg"
                  @dragenter="dragging = true"
                  @dragleave="leaveTarget"
                  @mouseenter="mouseOver = true"
                  @mouseleave="mouseOver = false"
                >
                  <v-container fill-height>
                    <v-row align="center">
                      <v-col
                        xl="12"
                        lg="12"
                      >
                        <v-row 
                          justify="center"
                          align="center"
                        >
                          <v-icon x-large>
                            {{ mdiImage }}
                          </v-icon>
                        </v-row>
                        <v-row 
                          justify="center"
                          align="center"
                        >
                          <div
                            class="grey--text text--darken-1"
                            v-text="$t('images.uploadImg')"
                          />
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </label>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { 
  Cropper, 
  CircleStencil, 
  RectangleStencil 
} from 'vue-advanced-cropper'

import {
  mdiCheckboxMarkedOutline,
  mdiDelete,
  mdiImage
} from '@mdi/js'

export default {
  props: {
    stencilComponent: {
      type: String,
      default: 'CircleStencil'
    }
  },

  components: {
      Cropper,
      CircleStencil,
      RectangleStencil
  },

  data () {
    return {
      mdiCheckboxMarkedOutline,
      mdiDelete,
      mdiImage,

      image: null,
      dragging: false,
      mouseOver: false,
      processedImage: null,
      mouseOverImage: false
    }
  },

  computed: { 
    stencil () {
      return this.$options.components[this.stencilComponent]
    },
    inputColor () {
      return this.mouseOver || this.dragging
        ? this.$vuetify.theme.dark
        ? 'grey darken-4'
        : 'grey lighten-3'
        : ''
    }
  },

  methods: {
    addDropImg(e) {

      this.dragging = false

      const imageFile = e.type === 'change'
        ? e.target.files[0]
        : e.dataTransfer.files[0]

      this.image = URL.createObjectURL(imageFile)
    },
  
    leaveTarget(e) {
      if (e.target.className === 'container fill-height')
        this.dragging = false
    },

    change({canvas}) {
      this.processedImage = canvas.toDataURL()
    },

    submit() {
      this.$emit('save', this.processedImage)
    },

    clear() {
      this.processedImage = ''
      this.image = ''
    }
  }
}
</script>

<style scoped>
.upload-cropper {
	border: solid 1px #4A148C;
}
.cursor-pointer {
  cursor: pointer;
}
.cropper {
  width: 450px;
  height: 450px;
}
</style>