<template>
  <v-app-bar
    app
    dark
    fixed
    height="60"
    elevation="2"
    clipped-left
    :color="toolbarColor"
  >
    <v-row align="center">
      <v-col
        xl="3"
        lg="4"
      >
        <v-row
          justify="start"
          align="center"
        >
          <v-btn
            icon
            @click="$router.push('/main')"
          >
            <v-avatar size="40">
              <v-img src="@assets/images/HiSolver---DarkSymbol---512px.png" />  
            </v-avatar>
          </v-btn>

          <SearchBox
            class="mx-2"
            :avatarOn="false"
            :lighterViewerOn="true"
            :label="'Search'"
            :setFocusOn="setFocusOn"
          />
        </v-row>
      </v-col>

      <v-spacer />

      <v-avatar :size="avatarSize">
        <v-img
          v-if="avatar"
          :src="avatar"
        >
          <template v-slot:placeholder>
            <v-skeleton-loader
              class="mx-auto"
              height="40"
              width="40"
              type="image"
            />
          </template>           
        </v-img>
        <v-btn
          v-else
          icon
          color="white"
        >
          <v-icon
            dark
            size="30"
          >
            {{ mdiAccount }}
          </v-icon>
        </v-btn>
      </v-avatar>

      <v-divider
        vertical
        inset
        class="mx-3 my-4"
      />

      <v-btn
        disabled
        icon
      >
        <v-icon>
          {{ mdiLightbulb }}
        </v-icon>
      </v-btn>

      <v-tooltip
        bottom
        :disabled="!settings.tooltipsOn"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            icon
            disabled
          >
            <v-icon>
              {{ mdiPackage }}
            </v-icon>
          </v-btn>
        </template>
        <span>Coleções</span>
      </v-tooltip>

      <v-tooltip
        bottom
        :disabled="!settings.tooltipsOn"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            icon
            @click="SET_NOTES_ON(true)"
          >
            <v-icon>
              {{ mdiTextBox }}
            </v-icon>
          </v-btn>
        </template>
        <span>Annotations</span>
      </v-tooltip>

      <v-btn
        icon
        disabled
        @click="settingsOn(true)"
      >
        <v-icon>
          {{ mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-row>
  </v-app-bar>
</template>

<script>
import { SearchBox } from '@components/Document';
import { mapGetters, mapMutations } from 'vuex';

import {
  mdiLightbulb,
  mdiDotsVertical,
  mdiTextBox,
  mdiMagnify,
  mdiClose,
  mdiPackage,
  mdiAccount
} from '@mdi/js';

export default {

  components: {
    SearchBox
  },

  mounted() {
    document.addEventListener('keydown', this.onKeyDown)
  },

  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeyDown)
  },

  data() {
    return {
      mdiLightbulb,
      mdiDotsVertical,
      mdiTextBox,
      mdiMagnify,
      mdiClose,
      mdiPackage,
      mdiAccount,

      setFocusOn: false
    }
  },

  computed: {
    ...mapGetters({
      from: 'from',
      profile: 'profile/id',
      avatar: 'profile/avatar',
      settings: 'settings',
      user: 'user',
    }),

    avatarSize () {
      return this.avatar ? '40px' : ''
    },

    toolbarColor () {
      return !this.$vuetify.theme.dark
        ? 'primary'
        : 'grey darken-4'
    },

    appendIcon () {
      return this.wikiInput === ''
        ? 'mdiMagnify'
        : 'mdiClose'
    }

  },

  methods: {
  
    ...mapMutations({
      settingsOn: 'video/settingsOn',
      SET_NOTES_ON: 'note/SET_NOTES_ON'
    }),

    onKeyDown(e) {
      if (e.key === 'f' && e.ctrlKey) {
        e.preventDefault()
        this.setFocusOn = true
        setTimeout(() => {
          this.setFocusOn = false
        })
      }
    }

  }

}
</script>