<template>
    <v-list-item-avatar
      rounded="xl"
      :style="`width: ${size.width}px; height: ${size.height || size.width}px; border: 1px solid ${$vuetify.theme.dark ? 'white' : 'black'}`"
      :class="{
        'grey lighten-2': !$vuetify.theme.dark,
        'grey darken-4': $vuetify.theme.dark
      }"
      @click="openFilePicker"
      data-cy="persona-avatar-button"
    >
      <v-hover v-slot:default="{ hover }">
        <v-img :src="imageUrl || imagePlaceholderUrl">
          <input
            type="file"
            ref="fileInput"
            accept="image/*"
            style="display: none"
            @change="handleFileUpload"
          />

          <HoverLayer v-if="hover || (!imageUrl && !imagePlaceholderUrl)">
            <v-row
              justify="center"
              align="center"
            >
              <v-icon
                dark
                large
              >
                {{ mdiImage }}
              </v-icon>
            </v-row>
          </HoverLayer>
        </v-img>
      </v-hover>
    </v-list-item-avatar>


  <!-- <div
    class="image-placeholder"
    @click="openFilePicker"
    :style="`
      background-image: url(${imageUrl || imagePlaceholderUrl});
      ${
        size ?
        `width: ${size.width}px !important;
        height: ${size.height}px !important;` :
        ''
      }
      ${style}
    `"
  >
    <v-icon
      v-if="!imageUrl && !imagePlaceholderUrl"
      :size="size.width"
    >
      {{ mdiImage }}
    </v-icon>
    <input
      type="file"
      ref="fileInput"
      accept="image/*"
      style="display: none"
      @change="handleFileUpload"
    />
  </div> -->
</template>

<script>
import { mapGetters } from 'vuex'
import { mdiImage } from '@mdi/js'
import { HoverLayer } from '@components'

export default {
  components: {
    HoverLayer
  },
  
  props: {
    onUpload: Function,
    imagePlaceholderUrl: {
      type: String,
      default: ''
    },
    size: {
      type: Object,
      default: {
        width: 200,
        height: 200
      }
    },
    style: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      imageUrl: '',
      mdiImage,
    };
  },

  watch: {
    openHandleGroupRoomDialog: {
      handler(value) {
        if (!value) this.imageUrl = ''
      }
    }
  },

  computed: {
    ...mapGetters({
      openHandleGroupRoomDialog: 'rooms/openHandleGroupRoomDialog',
    })
  },

  methods: {
    openFilePicker() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.imageUrl = URL.createObjectURL(file);
        this.onUpload(file);
      }
    }
  }
};
</script>

<style>
  .image-placeholder {
    width: 200px;
    height: 200px;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    border-radius: 5%;
  }
</style>