<template>
  <v-dialog
    v-model="videoDialog"
    max-width="600"
    :max-height="`${ $vuetify.breakpoint.xl ? 800 : 600 }`"
    @click:outside="$emit('close')"
  >
    <v-card>
      <v-container>
        <v-row
          align="center"
          justify="center"
          class="px-4"
        >
          <v-card-title>Postar Vídeo</v-card-title>

          <v-spacer />

          <v-btn
            icon
            @click="$emit('close')"
          >
            <v-icon>
              {{ mdiClose }}
            </v-icon>
          </v-btn>
        </v-row>

        <v-divider />

        <Scrollbar style="height: 400px;">
          <v-form ref="form">
            <v-container class="pb-0">
              <v-row justify="center">
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <v-progress-linear
                    :active="loading"
                    indeterminate
                    color="primary"
                    absolute
                    top
                  />

                  <v-container class="pb-0">
                    <v-row>
                      <v-col
                        cols="5"
                        class="py-0"
                      >
                        <v-hover v-slot:default="{ hover }">
                          <v-container class="px-0 py-0">
                            <v-card
                              outlined
                              rounded="lg"
                            >
                              <v-img
                                :src="thumbnailObj.mediaSrc"
                                aspect-ratio="1.9"
                              >
                                <template v-slot:placeholder>
                                  <v-skeleton-loader
                                    class="mx-auto"
                                    height="100%"
                                    width="100%"
                                    type="image"
                                  />
                                </template>

                                <v-fade-transition>
                                  <div
                                    v-if="hover"
                                    class="d-flex purple darken-4 v-card--reveal display-3 white--text"
                                    style="height: 100%; cursor: pointer;"
                                  >
                                    <v-icon 
                                      x-large
                                      color="white"
                                    >
                                      {{ mdiImage }}
                                    </v-icon>
                                  </div>
                                </v-fade-transition>
                              </v-img>
                            </v-card>
                          </v-container>
                        </v-hover>
                      </v-col>

                      <v-col
                        cols="7"
                        class="py-0"
                      >
                        <v-text-field
                          dense
                          outlined
                          label="Título"
                          :rules="[v => v && v.length > 0 || 'Qual é o título deste vídeo?']"
                          v-model="title"
                        />

                        <v-text-field
                          dense
                          outlined
                          label="Autor"
                          :rules="[v => v && v.length > 0 || 'Quem é o autor deste vídeo?']"
                          v-model="author"
                        />
                      </v-col>
                    </v-row>

                    <v-row justify="center">
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-combobox
                          dense 
                          multiple
                          outlined
                          clearable
                          small-chips
                          validate-on-blur
                          label="Tags"
                          v-model="tags"
                        >
                          <template v-slot:selection="{ item, select, selected }">
                            <v-chip
                              label
                              outlined
                              small
                              color="secondary"
                              :key="JSON.stringify(item)"
                              :input-value="selected"
                              @click="select"
                            >
                              {{ item }}
                            </v-chip>
                          </template>
                        </v-combobox>
                      </v-col>
                    </v-row>

                    <v-row justify="center">
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-textarea
                          rows="3"
                          outlined
                          auto-grow
                          label="Descrição"
                          v-model="description"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>

              <v-row
                justify="center"
                class="px-6"
              >
                <v-col
                  cols="12"
                  class="pt-0"
                >
                  <PrivacySettings @settings="setPrivacySettings" />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </Scrollbar>

        <v-card-actions class="py-0">
          <v-container class="py-0">

            <v-divider class="mb-2" />

            <v-row align="center">
              <v-btn
                plain
                :color="btnColor"
                :loading="loading"
                :disabled="disabled"
                @click="submit"
              >
                enviar
              </v-btn>

              <v-spacer />

              <v-btn
                plain
                color="danger"
                :disabled="loading"
                @click="clear"
              >
                limpar
              </v-btn>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { Scrollbar } from '@components/App'
import { MediaURL, PrivacySettings } from '@components'
import API from '@api'

import {
  mdiClose,
  mdiImage
} from '@mdi/js'

export default {

  components: {
    Scrollbar,
    PrivacySettings
  },

  props: {
    videoDialog: {
      type: Boolean,
      default: false
    },
    videoFile: {
      type: Object,
      default: {}
    },
    redirect: {
      type: Boolean,
      default: true
    }
  },

  created() {
    this.getVideoThumbnail = MediaURL.getVideoThumbnail.bind(this);
    this.getVideoDuration = MediaURL.getVideoDuration.bind(this);
    this.getMediaUrl = MediaURL.getMediaUrl.bind(this);
  },

  data() {
    return {
      mdiClose,
      mdiImage,

      loading: false,

      thumbnailObj: {
        mediaSrc: '',
        filename: '',
        type: ''
      },

      filename: '',
      type: '',
      videoSrc: '',

      title: '',
      author: '',
      description: '',
      tags: [],
      url: '',

      isAnonymous: false,
      isUnlisted: false,
      isPublic: true
      
    }
  },

  computed: {

    ...mapGetters({
      user: 'user',
      profile: 'profile/id',
      name: 'profile/name',
      avatar: 'profile/avatar',
      bio: 'profile/bio'
    }),

    sender() {
      return {
        profile: this.profile,
        name: this.name,
        avatar: this.avatar,
        bio: this.bio
      }
    },

    disabled() {
      return !this.title || !this.author
    },

    btnColor() {
      return this.$vuetify.theme.dark
        ? 'white'
        : 'primary'
    },

  },

  watch: {
    async videoFile(video) {

      this.clear()

      if (video instanceof File) {

        const [videoName] = video.name.split('.');

        this.thumbnailObj = {
          mediaSrc: await this.getVideoThumbnail(video),
          filename: `${videoName}.jpeg`,
          type: 'image/jpeg'
        }

        const mediaSrc = URL.createObjectURL(video)

        this.duration = await this.getVideoDuration(mediaSrc)

        this.videoObj = {
          mediaSrc,
          filename: video.name,
          type: video.type
        }        

      }

    }
  },

  methods: {
    async submit() {
      
      this.loading = true

      try {

        const [thumbnail] = await this.getMediaUrl([this.thumbnailObj], 'images/thumbnails')
        const [url] = await this.getMediaUrl([this.videoObj], 'videos')

        const video = await API().post(`video/${this.user.id}`, {
          sender: this.sender,
          url,
          thumbnail,
          title: this.title,
          filename: this.videoObj.filename,
          author: this.author,
          description: this.description,
          tags: this.tags,
          duration: this.duration,
          isLibraryContent: true,
          isAnonymous: this.isAnonymous,
          isPublic: this.isPublic,
          isUnlisted: this.isUnlisted
        })

        if (this.$route.name === 'bundle.home') {

          const bundleId = this.$route.query.id

          await API().post(`bundleContent/${this.user.id}`, {
            video: video._id,
            bundle: bundleId,
            sender: this.sender,
            name: video.filename,
            caption: video.description,
            thumbnail: video.thumbnail,
            url: video.url,
            type: 'video'
          })

          this.$emit('close')

        } else if (this.redirect) {

          this.$router.push({
            name: 'video.watch',
            query: {
              id: video.id
            }
          })
        
        } else {

          this.$emit('videoData', video)

          this.$emit('close')

        }
      
      } catch(err) {

        this.$emit('setSnack', 'errServer')

      } finally {

        this.loading = false

      }

    },

    clear() {

      this.thumbnailObj = {
        mediaSrc: '',
        filename: '',
        type: ''
      }
      this.youtubeLink = ''
      this.author = ''
      this.title = ''
      this.tags = []
      this.description = '',
      this.isAnonymous = false
      this.isUnlisted = false,
      this.isPublic = true

    },

    setPrivacySettings({ isAnonymous, isUnlisted, isPublic }) {

      this.isAnonymous = isAnonymous
      this.isUnlisted = isUnlisted
      this.isPublic = isPublic

    }

  }

}

</script>

<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  border-radius: 4px;
  justify-content: center;
  opacity: .7;
  position: absolute;
  width: 100%;
}
</style>