<template>
  <v-card
    outlined
    rounded="xl"
    :color="navColor"
    :style="`height: 100%;
      width: 100%;
      border: ${borderStyle};`"
    ref="app-chamber"
  >
    <v-container
      v-if="!leftCardCollapse"
      :class="{
        'py-0 height100': true,
        'px-0': annaChamber,
        'px-1': !annaChamber
      }"
      style="margin: 0; max-width: 100%;"
    >
      <v-row :class="`height100`">
        <v-col
          :class="`py-0 height100 ${colPadding}`"
          :cols="col"
          :style="colBorder"
        >
          <v-fade-transition hide-on-leave>
            <v-container :class="{
              'py-2': annaChamber,
              'py-4': !annaChamber,
              'height100': true
            }">
              <v-container v-show="annaChamber">
                <v-row
                  justify="center"
                  :class="{ 'px-2': annaChamber }"
                >
                  <v-btn
                    block
                    x-large
                    elevation="0"
                    style="border-radius: 12px;"
                    @click="SET_ANNA_CHAMBER"
                  >
                    <v-icon
                      v-if="annaChamber"
                      left
                    >
                      {{ mdiKeyboardBackspace }}
                    </v-icon>
                    
                    {{ $t('close') }}
                  </v-btn>
                </v-row>
              </v-container>

              <v-fade-transition hide-on-leave>
                <div v-if="annaChamber">
                  <v-row class="px-6 py-2 pb-1">
                    <v-btn
                      block
                      large
                      elevation="0"
                      style="border-radius: 12px;"
                      :class="{
                        'primary': !$vuetify.theme.dark,
                        'amber-btn': $vuetify.theme.dark
                      }"
                      @click="addChat"
                    >
                      new chat
                    </v-btn>
                  </v-row>
                  
                  <Scrollbar
                    :thin="true"
                    autoHide="leave"
                    :style="`height: ${rootHeight}px`"
                  >
                    <v-list
                      :disabled="loading"
                      color="transparent"
                      class="px-4"
                    >
                      <ChatCard
                        v-for="(conversation, i) in conversations"
                        :key="`conversation_${i}`"
                        :conversation="conversation"
                        :chamber="true"
                        class="hs-rounded-lg"
                        :cardColor="chatCardColor(conversation)"
                        :itemColor="itemColor"
                        :active="conversation.id === currentConversation.id"
                        @setConversation="setConversation(conversation)"
                        @remove="conversations.splice(i, 1)"
                      />
                    </v-list>
                  </Scrollbar>
                </div>
              </v-fade-transition>

              <v-fade-transition hide-on-leave>
                <ChatTab
                  v-if="!annaChamber && $route.meta.app === 'rooms' ||
                    from.indexOf('/rooms') > -1 &&
                    ($route.meta.app === 'folders')"
                />
              </v-fade-transition>

              <v-fade-transition hide-on-leave>
                <div v-if="!annaChamber">
                  <StudyChamber
                    v-if="$route.meta.app != 'rooms'"
                    :notes="notes"
                    @callAnna="SET_ANNA_CHAMBER"
                  />
                </div>
              </v-fade-transition>
            </v-container>
          </v-fade-transition>
        </v-col>

        <v-fade-transition hide-on-leave>
          <v-col
            v-if="annaChamber"
            class="py-0"
            :cols="12 - col"
          >
            <v-container
              class="pt-2 pb-0"
              style="position: relative"
            >
              <v-fade-transition hide-on-leave>
                <ChatContainer
                  v-if="!loading && annaChamber"
                  containerClass="pt-4 pr-4"
                  :style="`background-color: ${backgroundColor} !important; border-radius: 20px;`"
                  :height="containerHeight + heightAdjustment"
                  :outerHeight="outerHeight"
                  :innerTop="containerHeight + 30"
                  menuClass="menu--top"
                  :fetchContext="true"
                  :autoCall="true"
                  :appChamber="true"
                  :room="currentRoom"
                  :conversation="currentConversation"
                  @heightChange="heightChange"
                />
              </v-fade-transition>
            </v-container>
          </v-col>
        </v-fade-transition>
      </v-row>
    </v-container>

    <!-- <v-btn
      v-if="!annaChamber"
      icon
      :class="{
        'chevron-btn': !leftCardCollapse,
        'chevron-left': leftCardCollapse
      }"
      @click="toggleCard"
    >
      <div class="chevron-bar"></div>
    </v-btn> -->
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { Scrollbar } from '@components/App'
import { Note } from '@components/Note'
import StudyChamber from './StudyChamber.vue'
import { ChatContainer } from '@components/Conversation'
import ChatCard from '@components/Conversation/Card.vue'
import { v4 as uuidv4 } from 'uuid'
import ChatTab from '@components/Chat/Tab.vue'
import PersonaFunctions from '@components/Persona/PersonaFunctions.vue'
import API from '@api'
import { mdiKeyboardBackspace } from '@mdi/js'

export default {
  components: {
    StudyChamber,
    Scrollbar,
    Note,
    ChatContainer,
    ChatCard,
    ChatTab
  },

  async created() {
    if (!this.user) return;
    document.addEventListener('keydown', this.onKeyDown);

    this.resetPersona = PersonaFunctions.resetPersona.bind(this);
  },

  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeyDown);
  },

  async mounted() {
    setInterval(() => {
      this.accMag < 4 ? this.accMag++ : this.accMag = 2;
    }, 500);

    // In case the route is changed, sets the chamber again
    if (this.user && this.user.id) this.setChamber()

    const noteText = this.locale === 'en' ? `Hi ${this.myName}, welcome to HiSolver! I'm Anna. Whether you need help to study something or just want to chat, I'm here to help you.` : `Olá ${this.myName}, bem-vindo à HiSolver! Eu sou Anna.`
    
    const roomText = this.locale === 'en' ? `Hi ${this.myName}! Quick update on the latest buzz in the chatrooms. The virtual meet-and-greet for new students is generating a lot of excitement, with discussions ranging from favorite study spots around campus to upcoming club events. The debate club is gearing up for a lively online tournament, and there's a thread discussing potential themes for the next student art showcase. Don't miss out on the vibrant conversations happening right now!` : `Olá ${this.myName}! Atualização rápida sobre as últimas novidades nos chats. O encontro virtual de apresentação para novos estudantes está gerando muita empolgação, com discussões que vão desde os lugares favoritos para estudar no campus até os próximos eventos dos clubes. O clube de debates está se preparando para um animado torneio online, e há um tópico discutindo temas potenciais para a próxima exposição de arte estudantil. Não perca as conversas vibrantes acontecendo agora mesmo!`

    if (!this.user) {
      // Used for the "preview page" when the user is not logged in
      const [anna] = await API().get('profile-anna', {
        params: {
          query: {
            'username': {
              $eq: 'anna'
            }
          }
        }
      })
      this.SET_ANNA(anna)
    }

    this.notes = [
      {
        sender: this.anna,
        id: uuidv4(),
        title: 'Welcome',
        paragraphs: [noteText]
      },
      {
        sender: this.anna,
        id: uuidv4(),
        title: 'Rooms',
        paragraphs: [roomText]
      }
    ]
  },

  data: () => ({
    mdiKeyboardBackspace,
    heightAdjustment: 10,
    friends: [],
    writing: false,
    accMag: 1,
    notes: [],
    loading: true,
    currentConversation: {},
    conversations: [],
    currentFriend: 0
  }),
  
  computed: {
    ...mapGetters({
      innerHeight: 'innerHeight',
      from: 'from',
      darkTheme: 'darkTheme',
      locale: 'locale',
      anna: 'anna',
      user: 'user',
      myProfileId: 'profile/id',
      myName: 'profile/name',
      myAvatar: 'profile/avatar',
      friends: 'social/friends',
      annaChamber: 'annaChamber',
      currentRoom: 'rooms/currentRoom',
      currentPersona: 'currentPersona',
      tools: 'persona/tools',
      leftCardCollapse: 'leftCardCollapse',
    }),

    baseHeight() {
      return this.innerHeight * (1 - (100 / this.innerHeight))
    },

    containerHeight() {
      return this.baseHeight  - 100
    },

    outerHeight() {
      return this.baseHeight - 1.6
    },

    rootHeight() {
      return this.innerHeight * (1 - (240 / this.innerHeight))
    },

    colPadding() {
      return this.annaChamber ? 'pr-0' : ''
    },

    backgroundColor() {
      return this.$vuetify.theme.dark ? '#1a1a1a' : '#f6f6f6'
    },

    colBorder() {
      return this.annaChamber ? `border-right: 1px solid ${this.dividerColor};` : ''
    },

    bottomWidth() {
      const xl = this.$vuetify.breakpoint.xl
      return this.annaChamber ? 24 + (xl ? 0.6 : 0.5) : 100 
    },

    col() {
      return this.annaChamber ? 3 : 12
    },

    roomMembers() {
      return this.currentRoom.members ? this.currentRoom.members : [] 
    },

    dividerColor() {
      return this.$vuetify.theme.dark ? '#2b2b2b' : '#EEEEEE'
    },

    compassColor() {
      return this.annaChamber ? 'amber accent-4' : ''
    },

    itemColor() {
      return this.$vuetify.theme.dark ? 'tertiary' : 'primary'
    },

    panelStyle() {
      return this.$vuetify.breakpoint.xl ? 'height: 70vh;' : 'height: 66vh;'
    },

    borderStyle() {
      return this.$vuetify.theme.dark
        ? ''
        : 'thin solid rgba(0, 0, 0, 0.12) !important;'
    },
    
    navColor () {
      return !this.$vuetify.theme.dark
        ? 'grey lighten-5'
        : 'grey darken-4'
    }
  },

  watch: {
    darkTheme() {
      this.loading = true
      setTimeout(() => {
        this.loading = false
      })
    },

    async annaChamber (v) {
      if (v) {
        this.setChamber()
      } else {
        this.SET_CURRENT_ROOM({})
        this.resetPersona()
      }
    }
  },

  methods: {
    ...mapMutations({
      SET_ANNA: 'anna',
      SET_ANNA_CHAMBER: 'setAnnaChamber',

      // TODO: put these mutations on PersonaFunctions
      RESET_PERSONA: 'RESET_PERSONA',
      SET_CONTENTS: 'folders/setContents',
      SET_CURRENT_FOLDER: 'folders/setCurrentFolder',
      SET_RAG: 'folders/setRag',
      SET_ROLES: 'persona/SET_ROLES',
      SET_TOOLS: 'persona/SET_TOOLS',

      SET_CURRENT_ROOM: 'rooms/setCurrentRoom',
      WRITER_COMMAND: 'anna-mind/writerCommand',
      LEFT_CARD_COLLAPSE: 'LEFT_CARD_COLLAPSE',
    }),

    heightChange(heightDifference) {
      /* HEIGHT ADJUSMENT LOGIC */
      if (Math.abs(heightDifference) > 2)
        this.heightAdjustment -= heightDifference
    },

    toggleCard() {
      this.LEFT_CARD_COLLAPSE()
    },

    setConversation(conversation) {
      this.currentConversation = conversation
      this.loading = true
      setTimeout(() => {
        this.loading = false
      })
    },

    async setChamber() {
      this.loading = true
      // This might turn undefined without the Boolean cast
      const isPersonaActive = Boolean(this.currentPersona && this.currentPersona.id)

      // If there is a persona selected, we set a room only for the persona
      let response = await API().get(`rooms/${this.user.id}`, {
        params: {
          query: {
            type: {
              $eq: 'CHAMBER'
            },
            owner: {
              $eq: this.myProfileId
            },
            ...(isPersonaActive ? { persona: { $eq: this.currentPersona.id } } : {})
          }
        }
      })

      if (!response) return

      let [chamber] = response

      if (!chamber) {
        chamber = await API().post(`room/${this.user.id}`, {
          owner: this.myProfileId,
          ...(isPersonaActive ? { persona: this.currentPersona.id } : {}),
          type: 'CHAMBER',
          name: isPersonaActive ?
            `Persona ${this.currentPersona.name} Chamber for ${this.myName}` :
            `Chamber for ${this.myName}`
        })
      }

      this.SET_CURRENT_ROOM(chamber)

      this.conversations = chamber && chamber.id ?
        await API().get(`conversations/${this.user.id}`, {
          params: {
            query: {
              room: {
                $eq: chamber.id
              },
              state: {
                $ne: 'DELETED'
              }
            },
            sort: {
              createdAt: -1
            }
          }
        }) : []

      if (this.conversations.length === 0) {
        this.conversations.unshift(await API().post(`conversation/${this.user.id}`, {
          room: chamber.id,
          name: `${this.myName} and Anna's convo 1`
        }))
      }

      this.currentConversation = this.conversations[0]
      
      setTimeout(() => {
        // Use the timeout to wait until the chamber is created
        // in case this is the first chat with a persona
        this.loading = false
      }, 200)
    },

    chatCardColor(conversation) {
      const dark = this.$vuetify.theme.dark
      return dark ? '#333333' : this.currentConversation.id === conversation.id ? 'white' : 'grey lighten-3'
    },

    onKeyDown(event) {
      // Shortcut for activating the chamber
      if ((event.ctrlKey) && event.key === 'n') {
        event.preventDefault()
        event.stopPropagation()
        if (this.annaChamber) {
          this.addChat()
        }
      }
    },

    async addChat() {
      this.conversations.unshift(await API().post(`conversation/${this.user.id}`, {
        room: this.currentRoom.id,
        name: `${this.myName} and Anna's convo ${this.conversations.length + 1}`,
        tools: this.tools
      }))
      this.currentConversation = this.conversations[0]

      this.loading = true
      setTimeout(() => {
        this.loading = false
      })
    },

    cardColor(idx) {
      if (idx === this.currentFriend) return 'transparent'
      return this.$vuetify.theme.dark ? '#1a1a1a' : ''
    }
  }
}
</script>

<style scoped>
.hs-star {
  border-radius: 50%;
  background: #ffaa002d;
}

.v-list-item--link::before {
  border-radius: 10px !important;
}
</style>

<style>
.amber-btn {
  color: #fbefd7 !important;
  background-color: rgba(255, 191, 16, 0.6) !important;
}

.chevron-btn, .chevron-left {
  position: absolute;
  top: 50%;
  right: -2%;
  transform: translateY(-50%);
  display: inline-block; /* To prevent full-width bar */
  cursor: pointer;
}

.chevron-bar {
  width: 4px;
  height: 20px;
  
  border-radius: 20px; /* Full rounded corners */
  transition: all 0.5s ease;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.theme--light .chevron-bar {
  background-color: #cacaca;
}

.theme--dark .chevron-bar {
  background-color: #595959d1;
}

.chevron-bar::before,
.chevron-bar::after {
  content: '';
  position: absolute;
  top: 50%; /* Align to the middle */
  left: 0;
  width: 100%;
  height: 4px; /* Same as bar width for uniform look */
  background-color: #adadad;
  transition: inherit;
  transform-origin: left;
  border-radius: 2px; /* Half of the width for rounded corners */
  transform: translateY(-50%) scaleY(0); /* Initial state not visible */
}

.chevron-btn:hover .chevron-bar, 
.chevron-left .chevron-bar {
  background-color: transparent;
}

.chevron-btn:hover .chevron-bar::before {
  transform: translateY(100%) translateX(-50%) rotateZ(-50deg) scaleY(3); /* Rotate for top part of chevron */
}

.chevron-btn:hover .chevron-bar::after {
  transform: translateY(-160%) translateX(-50%) rotateZ(50deg) scaleY(3); /* Rotate for bottom part of chevron */
}

.chevron-left .chevron-bar::before  {
  transform: translateY(-15%) translateX(30%) rotateZ(50deg) scaleY(3); /* Rotate for top part of chevron */
}

.chevron-left .chevron-bar::after {
  transform: translateY(-100%) translateX(30%) rotateZ(-50deg) scaleY(3); /* Rotate for bottom part of chevron */
}
</style>