<template>
  <v-main :class="background">
    <Socket />
    <Toolbar />

    <v-container
      class="mx-0 my-0 py-0 pr-0 fill-height"
      :style="`margin: auto !important; max-width: 100%; ${backgroundColor ? backgroundColor : ''}`"
    >
      <v-row class="fill-height">
        <v-col
          v-if="$vuetify.breakpoint.mdAndUp"
          class="fill-height"
          cols="3"
        >
          <Sidebar />
        </v-col>
        <v-col :xs="12" :md="9" class="fill-height">
          <slot></slot>
        </v-col>
      </v-row>
    </v-container>

    <v-navigation-drawer
      absolute
      left
      temporary
      width="50%"
      :value="leftCardCollapse && $vuetify.breakpoint.mdAndDown"
      @input="handleDrawerInput"
    >
      <Sidebar v-if="$vuetify.breakpoint.mdAndDown"/>
    </v-navigation-drawer>
  </v-main>
</template>

<script>
  import Toolbar from '@components/App/Toolbar'
  import { mapGetters, mapMutations } from 'vuex'
  import Sidebar from '@components/Community/Sidebar.vue'
  import RoomService from '@api/room'
  import ActionsHeader from '@components/Community/ActionsHeader'
  import MemberService from '@api/member'
  import CommunityService from '@api/community'
  import Socket from '@components/Socket.vue'
  import { getResourceUrl } from '@utils'

  export default {
    components: {
      Sidebar,
      ActionsHeader,
      Toolbar,
      Socket
    },

    data() {
      return {
        communityService: null,
        roomService: null,
        room: null,
      }
    },

    watch: {
      $route: {
        async handler (value) {
          if (value.params.roomId) {
            this.room = await this.roomService.getById(this.$route.params.roomId)
          } else this.resetRoom()
        }
      }
    },

    async created() {
      this.communityService = CommunityService(this.user.id)
      this.roomService = RoomService(this.user.id)
      this.memberService = MemberService(this.user.id)

      const operations = [
        this.communityService.getById(this.$route.params.id),
        this.memberService.getCommunityMembership(
          this.$route.params.id,
          this.profileId
        )
      ]

      if (this.$route.params.roomId) {
        operations.push(this.roomService.getById(this.$route.params.roomId))
      }

      const [community, membership, room] = await Promise.all(operations)

      this.setMembership(membership)
      this.setCommunity(community)
      if (room) this.setRoom(room)

      const mainRoom = await this.roomService.getById(community.mainRoom)
      this.setMainRoom(mainRoom)
    },

    computed: {
      ...mapGetters({
        user: 'user',
        profileId: 'profile/id',
        leftCardCollapse: 'leftCardCollapse',
        anna: 'anna',
      }),
      background () {
        return !this.$vuetify.theme.dark ? 'background' : ''
      },
      backgroundColor() {
        return this.$vuetify.theme.dark ? '' : 'background-color: #F5F5F5'
      },
    },

    methods: {
      ...mapMutations({
        setMembership: 'community/setMembership',
        setCommunity: 'community/setCommunity',
        setRoom: 'community/setRoom',
        setMainRoom: 'community/setMainRoom',
        LEFT_CARD_COLLAPSE: 'LEFT_CARD_COLLAPSE',
      }),
      resetRoom() {
        this.room = null
      },
      handleDrawerInput(value) {
        if (!value && this.leftCardCollapse) this.LEFT_CARD_COLLAPSE()
      },
      getResourceUrl
    }
  }
</script>
