export default {
  namespaced: true,

  state: {
    connector: {},
    roles: [],
    tools: [],
    favorites: [],
    profiling: {
      name: 'My Profile',
      description: `Let's have a chat and I will tell you what kind of learner you are.`,
      history: `We still haven't had a chance to chat.`,
      data: [0, 0, 0, 0, 0, 0]
    },
  },

  getters: {
    connector: ({ connector }) => connector,
    roles: ({ roles }) => roles,
    tools: ({ tools }) => tools,
    favorites: ({ favorites }) => favorites,
    profiling: ({ profiling }) => profiling
  },
  
  mutations: {
    SET_CONNECTOR: (state, payload) => {
      state.connector = payload
    },
    SET_ROLES: (state, payload) => {
      state.roles = payload
    },
    SET_TOOLS: (state, payload) => {
      state.tools = payload
    },
    SET_FAVORITES: (state, payload) => {
      state.favorites = payload
    },
    ADD_FAVORITE: (state, payload) => {
      state.favorites.unshift(payload)
    },
    REMOVE_FAVORITE: (state, index) => {
      state.favorites.splice(index, 1)
    },
    SET_PROFILING: (state, payload) => {
      state.profiling = payload
    },
    RESET_PROFILING: state => {
      state.profiling = {
        name: 'My Profile',
        description: `Let's have a chat and I will tell you what kind of learner you are.`,
        history: `We still haven't had a chance to chat.`,
        data: [0, 0, 0, 0, 0, 0]
      }
    }
  }
}