<template>
  <v-menu
    v-model="open"
    :position-x="position.left"
    :position-y="position.top - 320"
    offset-y
    absolute
    @input="onHide"
  >
    <Scrollbar
      :thin="true"
      class="mention-card-scroll"
      :dynamicScrolling="true"
    >
      <v-list class="mention-card-list px-3 py-3">
        <v-subheader>MENTION</v-subheader>
        <div class="d-flex" style="justify-content: center;" v-if="loading">
          <v-progress-circular
            indeterminate
            color="primary"
          />  
        </div>
        <div v-else>
          <v-list-item
            v-for="(item, index) in items"
            :key="item._id"
            :class="{
              'v-list-item--link': true,
              'v-list-item--active primary--text': selectedItem === index
            }"
            @click="() => onItemClick(item)"
          >
            <v-list-item-avatar>
              <img
                v-if="item.avatar"
                :src="getResourceUrl(item.avatar)"
              />
              <v-icon
                v-else
                size="45"
              >
                {{ mdiAccountCircle }}
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                {{ item.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ item.username }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </Scrollbar>
  </v-menu>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex'
  import { debounce } from 'lodash'
  import ProfileService from '@api/profile'
  import { Scrollbar } from '@components/App'
  import { getResourceUrl } from '@utils'
  import { mdiAt, mdiAccountCircle } from '@mdi/js'
  import { delay } from '@utils'

  export default {
    components: {
      Scrollbar
    },

    props: {
      getPosition: Object,
      editor: Object,
      setOpen: Function,
      getCaretIndex: Function,
      setMentions: Function,
      isSingleChat: {
        type: Boolean,
        default: true
      }
    },

    watch: {
      open: {
        async handler(value) {
          if (!value) {
            this.resetScroll()
          }
          if (value) {
            if (!this.initialItems.length) this.loadItems()
            this.position = this.getPosition()
          }
          //TODO: Find a way to execute parent component onKeyDown Enter key when the menu is open
          // This delay ensures the parent component onKeyDown Enter key code executes first
          await delay(30)
          this.setOpen(value)
        }
      },
      itemsMentioned: {
        deep: true,
        handler(value) {
          if (this.open) this.setMentions(value)
        }
      }
    },

    beforeDestroy() {
      document.removeEventListener('keyup', this.debounceOnSearch)
      document.removeEventListener('keyup', this.onKeyUp)
      document.removeEventListener('keydown', this.onKeyDown)
    },

    created() {
      this.profileService = ProfileService(this.user.id)
      this.debounceOnSearch = debounce((e) => this.onSearch(e), 1000)
      document.addEventListener('keyup', this.onKeyUp)
      document.addEventListener('keyup', this.debounceOnSearch)
      document.addEventListener('keydown', this.onKeyDown)
    },

    data() {
      return {
        open: false,
        items: [],
        initialItems: [],
        atTextPosition: null,
        atHtmlPosition: null,
        selectedItem: 0,
        mdiAt,
        mdiAccountCircle,
        position: { left: 0, top: 0 },
        headerHeight: 48,
        itemHeight: 62,
        lastSelectedItemOnArrowDown: 0,
        lastSelectedItemOnArrowUp: 0,
        lastUserNameSearch: '',
        mentionTagOpen: '<span class="mention clickable" contenteditable="false">',
        mentionTagClose: '</span>',
        itemsMentioned: [],
        loading: false
      }
    },

    computed: {
      ...mapGetters({
        user: 'user',
        profileId: 'profile/id',
        scrollTop: 'scrollTop'
      }),
      style() {
        const { left, top } = this.position
        return { top, left }
      }
    },

    methods: {
      ...mapMutations({
        setScrollTop: 'scrollTop'
      }),
      getResourceUrl,

      renderWithSpan(text) {
        return `${this.mentionTagOpen}${text}${this.mentionTagClose}`
      },

      getIndexOfNextOccurrenceFromIndex(text, index, occurrences = [' ', '']) {
        for (let i = index; i < text.length; i++) {
          if (occurrences.includes(text[i])) {
            return i
          }
        }
        return text.length
      },

      getInnerHTMLOfLastBlockAndDeleteIt() {
        const length = this.editor.blocks.getBlocksCount()
        const { holder } = this.editor.blocks.getBlockByIndex(length - 1)
        const { innerHTML } = holder.firstChild.firstChild
        this.editor.blocks.delete(length - 1)
        return innerHTML
      },

      async onItemClick(item, enterPressed = false) {
        let innerHTMLToDelete = enterPressed ? this.getInnerHTMLOfLastBlockAndDeleteIt() : null
        const { id, holder } = this.editor.blocks.getBlockByIndex(0)
        const { innerHTML: _innerHTML } = holder.firstChild.firstChild
        const innerHTML = _innerHTML.replace(/&nbsp;/g, ' ');
        const text = `${
          innerHTML.slice(0, this.atHtmlPosition)
        }${
          this.renderWithSpan(`@${item.username}`)
        }${
          innerHTMLToDelete ?
          innerHTMLToDelete :
          innerHTML.slice(
            this.getIndexOfNextOccurrenceFromIndex(innerHTML, this.atHtmlPosition),
            innerHTML.length
          )
        }&nbsp;`
        this.editor.blocks.update(id, { text })
        this.editor.focus('end')
        this.itemsMentioned = [...this.itemsMentioned, item]
        this.setMentions(this.itemsMentioned)
        this.open = false
      },

      countPatternOccurrencesInRange(str, index) {
        if (index > str.length) {
          index = str.length;
        }
        const substring = str.slice(0, index);
        const pattern = /(?<=\s|^)(@[\w\d]+)/g;
        const matches = substring.match(pattern);
        return matches ? matches.length : 0;
      },

      getHtmlCursorPosition(innerText, cursorPosition) {
        const mentionsLength = this.countPatternOccurrencesInRange(innerText, cursorPosition)
        return cursorPosition + mentionsLength * (this.mentionTagOpen.length + this.mentionTagClose.length)
      },

      getCursorsPosition() {
        try {
          let selection = window.getSelection();
          let el = selection.anchorNode.parentNode
          return this.getCaretIndex(el)
        } catch (e) {
          return 0
        }
      },

      onKeyUp(e) {
        const { innerText } = this.editor.blocks.getBlockByIndex(0).holder
        const cursorPosition = this.getCursorsPosition()
        if (this.shouldOpen(innerText, cursorPosition)) {
          this.atTextPosition = cursorPosition - 1
          this.atHtmlPosition = this.getHtmlCursorPosition(innerText, cursorPosition - 1)
          return this.open = true
        }

        if (this.shouldClose(e, innerText)) {
          this.atTextPosition = null
          this.atHtmlPosition = null
          return this.open = false
        }
      },

      extractUsernames(text) {
        const regex = /(?:^|\s)@(\w+)/g;
        const matches = [];
        let match;
        while ((match = regex.exec(text)) !== null) {
          matches.push(match[1]);
        }
        return matches;
      },

      async onKeyDown(e) {
        if (!this.open) return
        if (e.key === 'Backspace') {
          await delay(30)
          const { innerText } = this.editor.blocks.getBlockByIndex(0).holder
          const usernames = this.extractUsernames(innerText)
          if (usernames.length !== this.itemsMentioned.length) {
            this.itemsMentioned = this.initialItems.filter(item => usernames.includes(item.username))
          }
        }
        this.handleArrowKeys(e)
        if (e.key === 'Enter') {
          this.onItemClick(this.items[this.selectedItem], true)
        }
      },

      setItems(personas, profiles) {
        if (!this.isSingleChat) this.items = [...personas, ...profiles]
        else this.items = [...personas]
      },

      async loadByUsername(username) {
        this.loading = true;
        const { profiles, personas } = await this.profileService.listForMentions(this.profileId, username)
        this.setItems(personas, profiles)
        this.loading = false;
      },

      async loadItems() {
        this.loading = true;
        const { profiles, personas } = await this.profileService.listForMentions(this.profileId)
        this.setItems(personas, profiles)
        this.initialItems = this.items
        this.loading = false;
      },

      debounceOnSearch() {},

      async onSearch(e) {
        if (!this.open) return
        let value = this.editor.blocks.getBlockByIndex(0).holder.innerText || '';
        let valueAfterAt = value.slice(this.atTextPosition + 1).split(' ')[0].trim();
        valueAfterAt = valueAfterAt.replace(/\r/g, ''); // Remove any carriage returns
        
        if (
          !valueAfterAt ||
          this.lastUserNameSearch === valueAfterAt
        ) return

        this.lastUserNameSearch = valueAfterAt
        await this.loadByUsername(valueAfterAt)
        this.resetScroll()
      },

      handleArrowKeys(e) {
        if (e.key === 'ArrowDown') {
          e.preventDefault()
          if (this.selectedItem >= this.items.length - 1) return
          this.selectedItem += 1
          this.lastSelectedItemOnArrowDown = this.selectedItem
          let valueToSum = this.itemHeight
          if (this.selectedItem > this.lastSelectedItemOnArrowUp + 4) {
            this.setScrollTop(this.scrollTop + valueToSum)
          }
        }
        if (e.key === 'ArrowUp') {
          e.preventDefault()
          if (this.selectedItem === 0) return this.setScrollTop(0)
          this.selectedItem -= 1
          this.lastSelectedItemOnArrowUp = this.selectedItem
          let valueToSub = this.itemHeight
          if (this.selectedItem < this.lastSelectedItemOnArrowDown - 5) {
            this.setScrollTop(this.scrollTop - valueToSub)
          }
        }
      },

      shouldOpen(value, cursorPosition) {
        return (
          value[cursorPosition - 1] === '@' &&
          (
            [' ', '', null, undefined].includes(value[cursorPosition - 2]) ||
            /\s/.test(value[cursorPosition - 2])
          )
        )
      },

      shouldClose(e, value) {
        return (
          value[this.atTextPosition] !== '@' ||
          e.key === 'Escape' ||
          /\s/.test(value[value.length - 1])
        )
      },

      resetScroll() {
        this.selectedItem = 0
        this.setScrollTop(0)
      }
    }
  }
</script>

<style>
  .mention-card-scroll {
    width: 350px;
    height: 365px;
  }
  .mention-card-list {
    min-height: 365px;
  }
</style>