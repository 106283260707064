<template>
  <v-container>
    <v-divider v-if="index != 0 && showDivider" />

    <v-list-item>
      <v-list-item-avatar size="40">
        <v-img :src="getResourceUrl(note.sender.avatar)" />
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title v-text="note.sender.name" />
        <v-list-item-subtitle v-text="createdAt" />
      </v-list-item-content>
    </v-list-item>

    <v-container class="py-0">
      <v-row
        v-if="note.paragraphs"
        justify="center"
      >
        <v-col cols="12">
          <span
            class="body-2"
            v-katex:auto="{ options: latexOptions }"
            v-html="readMore ? note.paragraphs.join('<br />') : truncate(note.paragraphs.join('<br />'), 300)"
          ></span>

          <br />
          
          <span
            v-if="note.paragraphs.join(' ').length > 300"
            class="clickable caption primary--text"
            @click="readMore = !readMore"
          >
            {{ readMore ? $t('hide') : $t('read_more') }}
          </span>
        </v-col>
      </v-row>

      <v-row
        v-if="audioSrc"
        justify="center"
      >
        <v-col cols="12">
          <AudioPlayer :src="audioSrc" />
        </v-col>
      </v-row>

      <v-row
        align="center"
        class="px-4"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              :color="starContent ? 'amber accent-2' : ''"
              @click="doStarContent"
            >
              <v-icon>
                {{ starContent ? mdiStarFourPoints : mdiStarFourPointsOutline }}
              </v-icon>
            </v-btn>
          </template>
          <span>Mark as insightful</span>
        </v-tooltip>

        <v-spacer />

        <span
          class="overline clickable"
          @click="$emit('mention', note)"
        >
          {{ $t('daily_tip') }}
        </span>
      </v-row>
    </v-container>

  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { AudioPlayer } from '@components/Audio'
import API from '@api'
import {
  latexOptions,
  formatDate,
  truncateStr,
  getResourceUrl
} from '@utils'

import {
  mdiStarFourPointsOutline,
  mdiStarFourPoints
} from '@mdi/js'

export default {
  components: {
    AudioPlayer
  },

  props: {
    noteId: {
      type: String,
      default: ''
    },
    note: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      default: 0
    },
    showDivider: {
      type: Boolean,
      default: false
    }
  },
  
  data() {
    return {
      latexOptions,
      mdiStarFourPointsOutline,
      mdiStarFourPoints,

      audioSrc: '',
      starContent: false,
      readMore: false,
    }
  },

  computed: {
    ...mapGetters({
      user: 'user',
      profile: 'profile/id'
    }),

    createdAt() {
      return formatDate(this.note.createdAt, this.$i18n.locale)
    }
  },

  watch: {
    noteId() {
      this.setAudio()
    }
  },

  methods: {
    getResourceUrl,

    truncate(str = '', num = 200) {
      return truncateStr(str, num)
    },

    setAudio() {
      return

      this.audioSrc = ''
      if (this.note && this.note.mediaContent) {
        const [audio] = this.note.mediaContent
          ? this.note.mediaContent.audios
          : []

        if (audio) {
          this.audioSrc = audio.url
        }
      }
    },

    async doStarContent() {
      // await API().put(`engagement/${this.user.id}`, {
      //   starContent: !this.starContent
      // },
      // {
      //   params: {
      //     query: {
      //       annotation: {
      //         $eq: this.note.id
      //       },
      //       profile: {
      //         $eq: this.profile
      //       }
      //     },
      //     options: {
      //       upsert: true
      //     }
      //   }
      // })

      // this.starContent = !this.starContent
    },

    async setEngagement() {
      return
      const [engagement] = await API().get(`engagement/${this.user.id}`, {
        params: {
          annotation: this.note.id,
          profile: this.profile
        }
      })

      if (engagement) {
        this.starContent = engagement.starContent
      }
    }
  }
}

</script>