export default (context, sessionExpired = false) => {
  context.$socket.notification.disconnect()
  context.$socket.conversation.disconnect()
  context.$socket.onlineSession.disconnect()
  context.logout(sessionExpired)

  if (context.$auth.auth0Client) context.$auth.logout()
  else context.$router.replace('/signin')
  
  setTimeout(() => {
    context.$vuetify.theme.dark = false
  }, 300)
}