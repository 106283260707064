import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import { enUS, ptBR } from 'date-fns/locale'

/*
  Notice that the full date, in the format yyyy-mm-ddThh:mm:ss.ms,
  is necessary for correctly calculating the distance.
*/
export default (date = '', i18nLocale = 'en') => {

  const baseDate = date
    ? new Date(date)
    : new Date()

  const locale = i18nLocale === 'en'
    ? enUS
    : ptBR
   
  return formatDistanceToNow(baseDate, {
    addSuffix: true,
    locale
  })

}