<template>
  <v-container>
    <div class="text-overline">Materials</div>
    
    <v-container class="pt-8 pb-0">
      <FileInput />
    </v-container>

    <div class="text-overline">Topics and Context</div>

    <v-textarea
      v-model="topics"
      rows="3"
      :background-color="$vuetify.theme.dark ? '#1a1a1a' : 'grey lighten-4'"
      filled
      hide-details
      placeholder="What topics would you like to talk learn? Is it hobby, school, work related?"
      class="hs-rounded-text"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { FileInput } from '@components/File'
import API from '@api'

export default {
  components: {
    FileInput
  },

  props: {
    chat: {
      type: Object,
      default: () => ({})
    },
  },

  mounted() {
    if (this.chat.params) {
      this.complexity = this.chat.params.complexity ? this.chat.params.complexity : 1
      this.topics = this.chat.params.topics ? this.chat.params.topics : ''
    }
  },

  data: () => ({
    complexity: 1,
    topics: ''
  }),

  computed: {
    ...mapGetters({
      user: 'user',
      myProfileId: 'profile/id'
    })
  },

  methods: {

  }
}
</script>