import API from '@api';

export default (userId) => {
  const apiRequest = API()
  return {
    list () {
      return apiRequest.get(
        `tag/list/${userId}`,
      )
    }
  }
}
