var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"px-3",attrs:{"align":"center","justify":"start"}},[_c('v-menu',{staticClass:"my-2",attrs:{"close-on-content-click":true,"offset-y":"","max-width":"230","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{ 'grey': true, 'darken-3': _vm.$vuetify.theme.dark, 'lighten-3': !_vm.$vuetify.theme.dark },staticStyle:{"border-radius":"8px !important","height":"2.6em"},attrs:{"small":"","elevation":"0"}},on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" "+_vm._s(_vm.$vuetify.theme.dark ? _vm.mdiFolder : _vm.mdiFolderOutline)+" ")]),_c('span',{staticStyle:{"width":"60px"}},[_vm._v(_vm._s(_vm.slicedText))]),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v(" "+_vm._s(_vm.menuOn ? _vm.mdiChevronUp : _vm.mdiChevronDown)+" ")])],1)]}}]),model:{value:(_vm.menuOn),callback:function ($$v) {_vm.menuOn=$$v},expression:"menuOn"}},[_c('v-list',{class:{ 'px-2': true, 'grey darken-4': _vm.$vuetify.theme.dark }},[_c('v-list-item',{staticClass:"clickable rounded-list-item",on:{"click":function($event){return _vm.$router.push('/folders')}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('folderManager'))+" ")]),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.mdiFolderMultipleOutline)+" ")])],1)],1)],1),_c('div',{staticClass:"my-1"}),_c('span',{staticClass:"text-caption",staticStyle:{"display":"flex","flex-direction":"row","align-items":"center"}},[_c('span',{staticClass:"px-3"},[_vm._v(" "+_vm._s(_vm.$t('myFolders'))+" ")]),_c('v-divider')],1),(_vm.myFolders.length > 0)?_c('Scrollbar',{staticStyle:{"max-height":"200px"},attrs:{"basic":true}},_vm._l((_vm.myFolders),function(folder,i){return _c('v-list-item',{key:("menu_folder_" + i),staticClass:"clickable rounded",on:{"click":function($event){return _vm.setFolder(folder)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(folder.name)+" ")])],1)}),1):_vm._e()],1)],1),_c('div',{staticClass:"mx-1"}),_c('div',_vm._g({staticClass:"hs-rounded-lg",style:(_vm.iconContainerStyle)},_vm.on),[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{
            'red--text text--lighten-2': _vm.rag && _vm.$vuetify.theme.dark,
            'red--text text--darken-4': _vm.rag && !_vm.$vuetify.theme.dark
          },attrs:{"small":"","icon":""},on:{"click":_vm.SET_RAG}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.rag ? _vm.mdiClose : _vm.mdiLibraryOutline)+" ")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.rag ? 'Reset sources' : 'Chat with sources')+" ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }