<template>
  <v-card
    :outlined="!webNote"
    elevation="0"
    style="border-color: #9e9e9e; width: 85%; border-radius: 12px;"
    @click="login"
  >
    <div class="d-flex flex-no-wrap justify-space-between">
      <v-fade-transition hide-on-leave>
        <v-row class="px-3 pr-4 justify-space-between" v-if="!webNote">
          <div>
            <v-card-title class="text-body-1 font-weight-bold">
              {{ type != 'signin' ? $t('continue') : $t('signin') }} {{ $t('with_google') }}
            </v-card-title>
          </div>

          <v-avatar
            class="ma-3"
            size="35"
          >
            <v-img src="@assets/images/Google.png"></v-img>
          </v-avatar>
        </v-row>
      </v-fade-transition>

      <v-fade-transition hide-on-leave>
        <div v-if="webNote">
          To signup with google, open your phone browser and enter hisolver.com from there (we already copied the link for you, just paste the link on your borwser and and enjoy it)
        </div>
      </v-fade-transition>
    </div>
  </v-card>
</template>

<script>
import { MediaURL } from '@components'
import API from '@api'

export default {
  props: {
    type: {
      type: String,
      default: 'signup'
    }
  },

  created() {
    function isInWebview() {
      var ua = window.navigator.userAgent;
      var isAndroid = /Android/i.test(ua);
      var isIos = /iPhone|iPad|iPod/i.test(ua);
      var isWebview = false;

      if (isAndroid) {
        isWebview = /Version\/[\d\.]+/i.test(ua) && !/Chrome\/[\d\.]+/i.test(ua);
      }

      if (isIos) {
        isWebview = /AppleWebKit/i.test(ua) && !(/Chrome/i.test(ua) || /Safari/i.test(ua));
      }

      return isWebview;
    }

    // Usage
    if (isInWebview()) {
      this.isWebview = true
    }

    this.getMediaUrl = MediaURL.getMediaUrl.bind(this);
  },

  data() {
    return {
      isWebview: false,
      webNote: false
    }
  },

  methods: {
    async fetchSrc(imgSrc) {
      if (!imgSrc) return ''
      try {
        const response = await fetch(imgSrc);
        const blob = await response.blob();
        return URL.createObjectURL(blob);
      } catch (err) {
        console.error('Error loading image:', err);
      }
    },

    async fetchImg({objectUrl, name}) {
      if (!objectUrl) return ''

      let [avatar] = await this.getMediaUrl([{
        mediaSrc: objectUrl,
        filename: `${name}.jpg`,
        type: 'image/jpg'
      }], 'images/avatars')
      
      URL.revokeObjectURL(objectUrl);

      return avatar
    },

    async login() {
      // see https://developer.auth0.com/resources/code-samples/spa/vue/basic-authentication/v2-javascript
      if (this.type != 'mobile_signup') {
        await this.$auth.loginWithPopup()
        this.setUser()

      } else if (!this.isWebview) {
        await this.$auth.loginWithRedirect({
          appState: {
            targetPath: "/welcome",
          },
          authorizationParams: {
            prompt: "login",
          },
        })
      } else {
        function copyToClipboard(text) {
          const textArea = document.createElement("textarea");
          textArea.value = text;
          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();
          
          try {
            const successful = document.execCommand('copy');
            const msg = successful ? 'successful' : 'unsuccessful';
          } catch (err) {
            console.error('Unable to copy', err);
          }

          document.body.removeChild(textArea);
        }

        // Example usage:
        copyToClipboard('https://www.hisolver.com');
        this.webNote = true
      }
    },

    async setUser() {
      const { exists } = await API().get('user-exists', {
        params: {
          $or: [{
            email: {
              $eq: this.$auth.user.email
            }
          }, {
            'oauth.email': {
              $eq: this.$auth.user.email
            }
          }]
        }
      })

      this.$emit('setUser', {
        userId: this.$auth.user.sub,
        email: this.$auth.user.email,
        name: this.$auth.user.name,
        username: this.$auth.user.nickname.replace(/\./g, '_'),
        auth0Token: await this.$auth.getTokenSilently(),
        avatarObj: {
          mediaSrc: await this.fetchSrc(this.$auth.user.picture),
          type: 'image/jpg'
        },
        exists
      })
    }
  }
}
</script>