<template>
  <v-sheet
    :class="`clickable ${isCurrentConversation ? 'active--conversation' : ''}`"
    v-ripple
    rounded="lg"
    :color="sheetColor"
    @click="$emit('setCurrentConversation')"
  >
    <v-list-item>
      <v-list-item-avatar
        v-if="false"
        rounded="lg"
        size="50"
      >
        <v-img :src="conversation.thumbnail">
          <template v-slot:placeholder>
            <v-skeleton-loader
              class="mx-auto hs-rounded-lg"
              height="50"
              width="50"
              type="image"
              boilerplate
            />
          </template>
        </v-img>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>
          {{ conversation.name ? conversation.name : `Conversation ${index}` }}
        </v-list-item-title>

        <v-list-item-subtitle v-if="false">
          <v-badge
            v-if="online"
            dot
            left
            inline
            color="success"
          >
            <span class="`caption adjustY">
              active
            </span>
          </v-badge>

          <v-badge
            v-else
            dot
            left
            inline
            color="grey darken-1"
          >
            <span class="caption adjustY">
              inactive
            </span>
          </v-badge>
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-icon>
        <v-icon v-if="false">
          {{ mdiSeal }}
        </v-icon>
      </v-list-item-icon>
    </v-list-item>
  </v-sheet>
</template>

<script>
import { mdiAccountCircle, mdiSeal } from '@mdi/js'

export default {

  props: {
    isCurrentConversation: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 1
    },
    conversation: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  
  data() {
    return {
      mdiAccountCircle,
      mdiSeal,
      
      online: false,
      onlinePooling: null,
    }
  },

  computed: {
    sheetColor() {
      const darkTheme = this.$vuetify.theme.dark

      return this.isCurrentConversation
        ? darkTheme ? 'grey darken-4' : 'grey lighten-4'
        : darkTheme ? 'grey darken-3' : 'grey lighten-2'
    }
  },

  methods: {
    emitNewSenderRequest(data) {
      this.$emit('newSenderRequest', data)
    },

    emitSnack(snackMsg) {
      this.$emit('snack', snackMsg)
    }
  }
}
</script>

<style scoped>
.active--conversation {
  transition: .4s ease-in-out;
}
</style>