<template>
  <v-snackbar
    app
    :top="false"
    color="primary"
    v-model="snackOn"
  >
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn
        text
        color="white"
        v-bind="attrs"
        @click="$emit('close')"
      >
        OK
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    snackOn: {
      type: Boolean,
      required: true
    },
    snackMsg: {
      type: String,
      required: true
    },
    snackTop: {
      type: Boolean,
      default: true
    },
    message: {
      type: String,
      default: ''
    }
  }
}
</script>

<style>
.v-snack__wrapper.v-sheet {
  border-radius: 12px !important;
}
</style>