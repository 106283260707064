<template>
  <div style="width: 120px;">
    <v-col 
      xl="12"
      lg="12"
    >
      <v-text-field
        solo
        dense
        single-line
        solo-inverted
        :min="1"
        :max="pageCount"
        :suffix="suffix"
        :value="currentPage"
        @change="input"
      />
    </v-col>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'PDFPaginator',

  computed: {
    ...mapGetters({
      pageCount: 'pdf/pageCount',
      currentPage: 'pdf/currentPage'
    }),

    suffix () {
      return this.pageCount
        ? `/${this.pageCount}`
        : '...'
    }
  },

  methods: {
    ...mapMutations({
      SET_CURRENT_PAGE: 'pdf/currentPage',
      SET_PAGINATOR: 'pdf/paginatorOn'
    }),

    input(page) {
      
      if (typeof Number(page) === 'number' && Number(page) > 0) {

        this.SET_PAGINATOR(true)
      
        this.SET_CURRENT_PAGE(Number(page))
      
      }

    },
  },

  watch: {
    pageCount() {
      this.$emit('input', 1)
    }
  }
}
</script>
