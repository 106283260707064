<template>
  <v-container>
    <v-row justify="center">
      <div class="stopwatch">
        {{ hours }}:{{ minutes }}:{{ seconds }}
      </div>
    </v-row>
  </v-container>
</template>

<script>
  import { differenceInMilliseconds } from 'date-fns'
  export default {
    props: {
      finishDate: {
        type: Date,
      },
      onFinish: {
        type: Function
      }
    },

    created() {
      this.createTimer()
    },

    watch: {
      finishDate: {
        handler(value, oldValue) {
          if (value && !oldValue) {
            this.createTimer()
          }
        }
      }
    },

    methods: {
      createTimer() {
        this.timer = setInterval(() => {
          const now = new Date()
          const finishDate = new Date(this.finishDate)
          console.log('this.finishDate: ', this.finishDate);
          if (finishDate < now) {
            if (this.onFinish) this.onFinish(!!this.finishDate)
            clearInterval(this.timer)
            return this.timer = null;
          }
          const diffInMs = differenceInMilliseconds(finishDate, now);
          const totalSeconds = Math.floor(diffInMs / 1000);
          this.hours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
          this.minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0');
          this.seconds = String(totalSeconds % 60).padStart(2, '0');
        }, 1000)
      }
    },

    data() {
      return {
        timer: null,
        hours: '00',
        minutes: '00',
        seconds: '00',
      }
    }
  }
</script>