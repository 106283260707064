<template>
  <div>
    <v-list-item-avatar
      left
      :size="size"
      :class="{
        'hs-rounded-lg': roundedLg
      }"
    >
      <v-img
        v-if="avatar"
        :src="getResourceUrl(avatar)"
      >
        <template v-slot:placeholder>
          <v-skeleton-loader
            class="mx-auto"
            :height="size"
            :width="size"
            type="avatar"
          />
        </template>
      </v-img>
      <v-icon
        v-else
        :x-large="large"
      >
        {{ mdiAccountCircle }}
      </v-icon>
    </v-list-item-avatar>
  </div>
</template>

<script>
import { mdiAccountCircle } from '@mdi/js'
import { getResourceUrl } from '@utils'

export default {
  props: {
    avatar: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 40
    },
    large: {
      type: Boolean,
      default: false
    },
    roundedLg: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      mdiAccountCircle
    }
  },

  methods: {
    getResourceUrl
  }
}
</script>