<template>
  <div>
    <Toolbar />

    <v-main>
      <Scrollbar>
        <v-container class="hs-fill-height">
          <slot></slot>
        </v-container>
        
        <Footer></Footer>
      </Scrollbar>
    </v-main>
  </div>
</template>

<script>
import Toolbar from '@components/Open/web/Toolbar'
import Scrollbar from '@components/App/Scrollbar'
import Footer from '@components/Open/web/Footer'
import { mapGetters } from 'vuex'
import { mdiHelp } from '@mdi/js'

export default {
  components: {
    Toolbar,
    Footer,
    Scrollbar
  },

  // created() {
  //   const isSigninPage = this.$route.name === 'open.signup'
  //   const isSignupPage = this.$route.name === 'open.signin'

  //   if (this.user && (isSigninPage || isSignupPage)) {
  //     this.$router.replace({ name: 'main.home' })
  //   }
  // },

  data: () => ({
    mdiHelp,
    helpOn: false
  }),

  computed: {
    ...mapGetters({
      user: 'user'
    })
  }
}
</script>


<style scoped>
.hs-fill-height {
  min-height: 80vh;
}

/*
  Sets the height of the image skeleton loader to 100% of the parent component.
*/
::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading .v-skeleton-loader__image {
  height: 100% !important;
}

/*
  Sets the height and width of the avatar skeleton loader to 100% of the parent component.
*/
::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading .v-skeleton-loader__avatar {
  height: 100% !important;
  width: 100% !important;
}

/*
  Stops autocomplete and combobox icons from rotating.
  There might be an option to set this within Vuetify 3.
*/
::v-deep .v-select.v-select--is-menu-active
  .v-input__icon--append
  .v-icon {
  transform: rotate(0) !important;
}
</style>
