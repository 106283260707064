<template>
  <v-main :style="`max-width: 1750px; margin: auto; min-width: 800px;`">
    <OnBoardingModal />

    <Toolbar />

    <CustomOverlay :value="!user && !loggingOut">
      <v-row justify="center" align="center">
        <v-col cols="12">
          <SignupCard />
        </v-col>
      </v-row>
    </CustomOverlay>

    <v-container :style="`max-width: 100%;`" class="height100 py-0">
      <v-row class="height100">
        <v-col
          :cols="3"
          :sm="leftMenuSm"
          :style="`transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);`"
          :class="{
            'lateral-menu-collapse': leftCardCollapse,
            'height100': true,
            'pr-1': annaChamber
          }"
        >
          <AppChamber v-if="!loading" />
        </v-col>

        <v-col
          v-if="!annaChamber"
          :sm="contentSm"
          :style="`transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);`"
          :class="`height100 ${contentClass}`"
        >
          <slot></slot>
        </v-col>

        <v-col
          v-if="$vuetify.breakpoint.lgAndUp && annaChamber"
          :sm="rightCardCollapse ? 1 : 3"
          :style="`transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);`"
          :class="`height100 ${rightCardCollapse ? 'lateral-menu-collapse' : ''}`"
        >
          <PersonaPanel :room="currentRoom" />
        </v-col>
      </v-row>
    </v-container>

    <v-navigation-drawer
      absolute
      right
      temporary
      width="40%"
      :value="rightCardCollapse && $vuetify.breakpoint.mdAndDown"
      @input="close"
    >
      <PersonaPanel :room="currentRoom" />
    </v-navigation-drawer>
  </v-main>
</template>

<script>
import CustomOverlay from '@components/CustomOverlay'
import { AppChamber } from '@components/Anna'
import PersonaPanel from '@components/Persona/PersonaPanel'
import Toolbar from '@components/App/Toolbar'
import { SignupCard } from '@components/Auth'
import { mapGetters, mapMutations } from 'vuex'
import OnBoardingModal from '@components/Onboarding/Modal.vue';

export default {
  components: {
    CustomOverlay,
    AppChamber,
    PersonaPanel,
    SignupCard,
    Toolbar,
    OnBoardingModal
  },

  watch: {
    $route(to, from) {
      if (this.$route.name === 'search') {
        const value = to.query.q
        const oldValue = from.query.q
        if (!value || value === oldValue) return
        if (this.annaChamber) this.forceCloseAnnaChamber()
      }
    }
  },
  async created() {
    this.loading = true
    setTimeout(() => {
      this.loading = false
    }, 100)

    if (!this.user) return;

    if(!this.$socket.notification.id) {
      this.$socket.notification.connect()
    }
    
    if(this.$route.name != 'message.list') {
      this.$socket.conversation.connect()
    } else {
      this.$socket.conversation.disconnect()
    }
  },

  sockets: {
    notification: {
      connect() {
        this.$socket.notification.emit('saveSocket', {
          profile: this.profile
        })
        
        this.$socket.notification.emit('loadNotifications', {
          senderSocket: this.$socket.notification.id,
          profile: this.profile
        })
      },

      notifications(notifications) {
        this.SET_NOTIFICATIONS(notifications)
      },

      newNotification(notification) {
        this.ADD_NOTIFICATION(notification)
      }
    }
  },

  data: () => ({
    loading: false
  }),

  computed: {
    ...mapGetters({
      user: 'user',
      profile: 'profile/id',
      annaChamber: 'annaChamber',
      loggingOut: 'loggingOut',
      rightCardCollapse: 'rightCardCollapse',
      leftCardCollapse: 'leftCardCollapse',
    }),
  
    leftMenuSm() {
      if (this.annaChamber) {
        if (this.$vuetify.breakpoint.lgAndUp) {
          if (this.rightCardCollapse) return 11
          return 9
        }
        return 12
      }
      if (this.leftCardCollapse) return 1
      return 3
    },

    contentSm() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        if (this.leftCardCollapse) return 11
        return 9
      }

      if (this.leftCardCollapse || !this.annaChamber) {
        if (this.rightCardCollapse) return 12
        return 9
      }

      return 6
    },

    contentClass() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        if (this.leftCardCollapse) return 'expanded-with-one-collapse'
      }
      if (this.rightCardCollapse && this.leftCardCollapse) return "center-2-menu-collapse";
      if (this.rightCardCollapse || this.leftCardCollapse) return "center-1-menu-collapse";

      // TODO: there should be a more abstract way of doing this
      if (this.$route.name === 'main.home') return 'px-2 pl-0'
      return 'pr-6'
    },

    backgroundColor() {
      return this.$vuetify.theme.dark ? '' : 'background-color: #F5F5F5'
    },

    contextWidth() {
      const xl = this.$vuetify.breakpoint.xl
      const baseReduction = xl ? 20 : 22
      const base = xl ? 80 : 78
      const rightAdd = this.rightCardCollapse ? 18 : 0
      return baseReduction + (this.annaChamber ? (base - baseReduction + rightAdd) : 0)
    },

    rightWidth() {
      let breakpoint = 20
      if (this.rightCardCollapse) breakpoint = (breakpoint - 18)
      return this.$vuetify.breakpoint.xl ? breakpoint : breakpoint + 2
    },

    pdfPage() {
      return this.$route.name === 'pdf.read'
    },

    background() {
      return !this.$vuetify.theme.dark
        ? 'backgroundWhite'
        : ''
    },
    
    cardStyle() {
      return `transition: right 0.3s, width 0.3s cubic-bezier(0.4, 0, 0.2, 1); width: ${this.rightWidth}%;`;
    },
  },

  methods: {
    ...mapMutations({
      SET_FRIENDS: 'social/SET_FRIENDS',
      SET_MEMBERSHIPS: 'social/SET_MEMBERSHIPS',
      SET_NOTIFICATIONS: 'notification/setNotifications',
      ADD_NOTIFICATION: 'notification/addNotification',
      RIGHT_CARD_COLLAPSE: 'RIGHT_CARD_COLLAPSE',
      LEFT_CARD_COLLAPSE: 'LEFT_CARD_COLLAPSE',
      forceCloseAnnaChamber: 'forceCloseAnnaChamber'
    }),
  }
}
</script>

<style scoped>
/*
  Sets the height of the image skeleton loader to 100% of the parent component.
*/
::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading .v-skeleton-loader__image {
  height: 100% !important;
}

/*
  Sets the height and width of the avatar skeleton loader to 100% of the parent component.
*/
::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading .v-skeleton-loader__avatar {
  height: 100% !important;
  width: 100% !important;
}

/*
  Stops autocomplete and combobox icons from rotating.
  There might be an option to set this within Vuetify 3.
*/
::v-deep .v-select.v-select--is-menu-active
  .v-input__icon--append
  .v-icon {
  transform: rotate(0) !important;
}

.lateral-menu-collapse {
  flex: 0 0 4.333%;
  max-width: 4.333%;
}

.center-1-menu-collapse {
  flex: 0 0 70.333%;
  max-width: 70.333%;
}

.center-2-menu-collapse {
  flex: 0 0 90.666%;
  max-width: 90.666%;
}

.expanded-with-one-collapse {
  flex: 0 0 95.666%;
  max-width: 95.666%;
}
</style>