export default {
  namespaced: true,

  state: {
    message: '',
    severity: 'error'
  },

  getters: {
    message: ({ message }) => message,
    severity: ({ severity }) => severity,
  },
  
  mutations: {
    setSnackbarMessage(state, payload) {
      state.message = payload
    },
    setSnackbarSeverity(state, payload) {
      state.severity = payload
    }
  }
}