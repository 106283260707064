import { withLayout } from '@utils'

import StudyLayout from '@layouts/Study'

const Home = () => import('@views/Home')

export default {
  path: 'home',
  component: {
    render: h => h('router-view')
  },
  children: [
    {
      path: '',
      name: 'main.home',
      component: withLayout(Home, StudyLayout),
      meta: {
        locked: true,
        app: 'home'
      }
    }
  ]
}
