<template>
  <div>
    <PillTabs
      class="mx-2"
      :tabs="[{
        name: 'Friends',
        counter: 0
      }, {
        name: 'Rooms',
        counter: 0
      }]"
      :showSearch="false"
      @setActive="setTab"
    />

    <ChatFriends
      v-if="tab === 0"
    />

    <ChatGroups
      v-if="tab === 1"
    />
  </div>
</template>

<script>
  import PillTabs from '@components/PillTabs.vue'
  import ChatFriends from '@components/Chat/Friends.vue'
  import ChatGroups from '@components/Chat/Groups.vue'

  export default {
    components: {
      PillTabs,
      ChatFriends,
      ChatGroups
    },

    data() {
      return {
        tab: 0,
      }
    },

    methods: {
      setTab(index) {
        this.tab = index
      }
    }
  }
</script>