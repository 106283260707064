<template>
  <Scrollbar
    :darkBackground="true"
    @scrolling="updateScrollBounds"
  >    
    <PDFScrollingElement
      v-bind="{clientHeight, scrollTop, focusedPage, enablePageJump}"
      v-slot="{isPageFocused, isElementFocused}"
      @page-jump="onPageJump"
    >
      <div class="mb-4">
        <slot v-bind="{page, isPageFocused, isElementFocused}" />
      </div>
    </PDFScrollingElement>    
  </Scrollbar>
</template>

<script>
import visible from '@directives/visible'
import Scrollbar from '@components/App/Scrollbar'
import PDFScrollingElement from '@components/PDF/ScrollingElement'

export default {
  components: {
    PDFScrollingElement,
    Scrollbar
  },

  directives: {
    visible
  },

  props: {
    pages: {
      required: true,
    },
    enablePageJump: {
      type: Boolean,
      default: false,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    isParentVisible: {
      default: true,
    }
  },

  data() {
    return {
      focusedPage: undefined,
      scrollTop: 0,
      clientHeight: 0
    }
  },

  computed: {
    pagesLength() {
      return this.pages.length
    },
  },

  methods: {
    fetchPages(currentPage) {
      this.$emit('pages-fetch', currentPage)
    },

    onPageJump(scrollTop) {
      this.$emit('page-jump', scrollTop)
    },

    updateScrollBounds() {
      const {scrollTop, clientHeight} = this.$el
      this.scrollTop = scrollTop
      this.clientHeight = clientHeight
    }
  },

  watch: {
    isParentVisible: 'updateScrollBounds',

    pagesLength(count, oldCount) {
      if (oldCount === 0) this.$emit('pages-reset')

      // Set focusedPage after new pages are mounted
      this.$nextTick(() => {
        this.focusedPage = this.currentPage
      })
    },

    currentPage(currentPage) {
      if (currentPage > this.pages.length) {
        this.fetchPages(currentPage)
      } else {
        this.focusedPage = currentPage
      }
    }
  }
}
</script>
