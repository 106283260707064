/**
 * Splits a string containing code fence delimiters into an array of substrings.
 * Each substring is either contained within a pair of triple backticks "```" 
 * or is outside of such pairs.
 *
 * @param {string} str - The string containing code fence delimiters to be split.
 * @returns {string[]} - An array of split substrings, where each element
 * represents either a fenced code block or other text outside the fences.
 */

export default (str) => {
  const results = [];
  let i = 0;

  while (i < str.length) {
    if (str.slice(i, i + 3) === "```") {
      const start = i;
      i += 3;
      while (i < str.length && str.slice(i, i + 3) !== "```") {
        i++;
      }
      if (i < str.length) {
        results.push(str.slice(start, i + 3));
        i += 3;
      } else {
        results.push(str.slice(start));
        break;
      }
    } else {
      const start = i;
      while (i < str.length && str.slice(i, i + 3) !== "```") {
        i++;
      }
      results.push(str.slice(start, i));
    }
  }

  return results;
}