<template>
  <v-dialog
    v-model="open"
    :max-width="800"
    @click:outside="onClose"
  >
    <v-card>
      <v-container>
        <v-row class="px-4">
          <v-card-title>Upload Files</v-card-title>
          <v-spacer />
          <v-btn
            icon
            @click="onClose"
          >
            <v-icon>
              {{ mdiClose }}
            </v-icon>
          </v-btn>
        </v-row>
        <v-divider />
        <v-row
          v-for="(file, index) in files"
          :key="file.name"
          class="px-4 py-4"
        >
          <PdfFile
            v-if="file.type === 'application/pdf'"
            :file="file"
            :fileIndex="index"
            :setParentFile="(file) => filesByIndex[index] = file"
          />
        </v-row>
      </v-container>
      <v-card-actions class="py-0">
        <v-container class="py-0">
          <v-divider />
          <v-row
            align="center"
            justify="center"
          >
            <v-col cols="12">
              <v-btn
                block
                class="hs-rounded-lg"
                :color="btnColor"
                :loading="loading"
                @click="submit"
              >
                upload
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex'
  import PdfFile from '@components/Upload/PdfFile.vue'
  import { MediaURL } from '@components'
  import API from '@api';

  export default {
    components: {
      PdfFile
    },

    props: {
      files: Array,
      onClose: {
        type: Function,
        default: () => {}
      }
    },

    created() {
      this.getMediaUrl = MediaURL.getMediaUrl.bind(this);
    },

    data: () => ({
      open: false,
      filesByIndex: {},
      loading: false
    }),

    watch: {
      files: {
        deep: true,
        handler(value) {
          if (value.length) return this.open = true
          return this.open = false
        }    
      }
    },

    computed: {
      ...mapGetters({
        anna: 'anna',
        user: 'user',
        myName: 'profile/name',
        myAvatar: 'profile/avatar',
        myProfileId: 'profile/id',
        currentFolder: 'folders/currentFolder'
      }),
      btnColor() {
        return this.$vuetify.theme.dark
          ? 'secondary'
          : 'primary'
      }
    },

    methods: {
      ...mapMutations({
        ADD_CONTENT: 'folders/addContent',
        SET_CONTENT: 'SET_CONTENT'
      }),
      async submit() {
        await this.submitPdfs()
      },
      async submitPdfs() {
        this.loading = true
        const files = Object.values(this.filesByIndex)
        await Promise.all(
          files.map(async (file) => {
            try {
              const {
                thumbnailObj,
                pdfFile,
                title,
                about,
                config,
              } = file
              const [thumbnailUrl] = await this.getMediaUrl([thumbnailObj], 'images/thumbnails')
              const pdfObj = {
                mediaSrc: URL.createObjectURL(pdfFile),
                filename: pdfFile.name,
                type: 'application/pdf'
              }
              const [pdfUrl] = await this.getMediaUrl([pdfObj], 'pdfs')
              // Creates the pdf record
              const pdf = await API().post(`pdf/${this.user.id}`, {
                url: pdfUrl,
                thumbnail: thumbnailUrl,
                sender: {
                  profile: this.myProfileId,
                  name: this.myName,
                  avatar: this.myAvatar
                },
                title,
                about,
                ...config
              })
              // Check if the current user has permission to upload to the currentFolder
              const hasFolderPermission = (
                this.currentFolder && this.currentFolder.sender === this.myProfileId
              ) || Object.keys(
                this.currentFolder && this.currentFolder.permissions ?
                this.currentFolder.permissions : []
              ).indexOf(this.myProfileId) != -1
              // Creates the content associated with the pdf
              const content = await API().post(`content/${this.user.id}`, {
                sender: pdf.sender.profile,
                name: pdf.title,
                thumbnail: pdf.thumbnail,
                caption: pdf.caption,
                about: pdf.about,
                type: 'pdf',
                // folder reference
                ...(hasFolderPermission ? { folder: this.currentFolder.id } : {}),
                media: pdf._id,
                permissions: {
                  [pdf.sender.profile]: 'owner'
                }
              })
              this.SET_CONTENT(content)

              if (hasFolderPermission) {
                // Adds the content to the currentFolder
                this.ADD_CONTENT(content)
              }
              
              /*
              * Submits the pdf to the AI server
              */
              let formData = new FormData();

              // Rename the form data file to pdf._id
              let renamedPdfFile = new File([pdfFile], `${pdf._id}.pdf`, {
                type: 'application/pdf',
              });

              // Assuming `this.pdfFile` is the PDF file to upload
              // Append renamed pdf file to formDatas
              formData.append('file', renamedPdfFile);

              const response = await axios.post(`${process.env.VUE_APP_LANGCHAIN_API}/upload_pdf`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                },
                withCredentials: false
              })
              console.log('response: ', response);
              let embeddingStatus;
              if (this.embeddingStatus === 'UNDEFINED')
                embeddingStatus = 'UNDEFINED'
              else
                embeddingStatus = response.data === -1 ? 'MISSING' : 'CREATED'

              // resets the global embeddingStatus
              this.embeddingStatus = ''

              await API().put(`pdf/${this.user.id}`, {
                embeddingStatus
              }, {
                params: {
                  id: pdf._id
                }
              })

              if (this.redirect) {
                this.$router.push({
                  name: 'pdf.read',
                  query: {
                    id: content._id
                  }
                })
              }
            } catch (err) {
              this.$emit('err', 'errServer')
            }
          })
        )
        this.onClose()
        this.loading = false
      },
    }
  }
</script>