<template>
  <v-container>
    <div class="text-overline">Question Difficulty</div>
    
    <v-container class="pt-8 pb-0">
      <v-slider
        v-model="complexity"
        :thumb-size="24"
        max="5"
        min="1"
        step="1"
        thumb-label="always"
        @change="save"
      ></v-slider>
    </v-container>

    <div class="text-overline">Topics</div>

    <v-textarea
      v-model="topics"
      rows="3"
      :background-color="$vuetify.theme.dark ? '#1a1a1a' : 'grey lighten-4'"
      filled
      hide-details
      placeholder="What topics would you like to talk about"
      class="hs-rounded-text"
      @change="save"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import API from '@api'

export default {
  props: {
    chat: {
      type: Object,
      default: () => ({})
    },
  },

  mounted() {
    if (this.chat.params) {
      this.complexity = this.chat.params.complexity ? this.chat.params.complexity : 1
      this.topics = this.chat.params.topics ? this.chat.params.topics : ''
    }
  },

  data: () => ({
    complexity: 1,
    topics: ''
  }),

  computed: {
    ...mapGetters({
      user: 'user',
      myProfileId: 'profile/id'
    })
  },

  methods: {
    save() {
      this.$emit('setParams', {
        complexity: this.complexity,
        topics: this.topics
      })

      API().put(`/conversation/${this.user.id}`, {
        // don't get confused, these are chat params
        params: {
          complexity: this.complexity,
          topics: this.topics
        }
      }, {
        params: {
          id: this.chat.id
        }
      })
    }
  }
}
</script>