const requireModule = require.context('.', false, /\.js$/);
const modules = [];

requireModule.keys().forEach(filename => {
  if (filename === './index.js') return;
  const importedModule = requireModule(filename).default || requireModule(filename);

  if (Array.isArray(importedModule)) {
    modules.concat(importedModule);
  } else {
    modules.push(importedModule);
  }
});

export default modules;