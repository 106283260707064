<template>
  <v-form v-model="valid" ref="form">
    <v-container style="position: relative;">
      <SnackBar
        v-if="signup"
        :snackMsg="snackMsg"
        :snackOn="snackOn"
        @close="snackOn = false"
      />

      <input
        ref="avatarInput"
        type="file"
        accept=".png,.jpg,.jpeg,.gif,.svg"
        @change="addAvatar"
      />
      <ImageCropper
        stencilComponent="CircleStencil"
        :imageFile="imageFile"
        :mimeType="mimeType"
        :cropperOn="cropperOn"
        @closeDialog="cropperOn = false"
        @save="saveAvatar"
      />

      <v-row justify="center">
        <v-card-title class="pb-0 text-center">
          {{ signup ? $t('almostThere') : `${$t('hi')}, ` + myName }}
        </v-card-title>
      </v-row>

      <v-row justify="center">
        <v-card-subtitle :class="{
          'grey--text text-center pt-0': true,
          'text--darken-1': !$vuetify.theme.dark,
          'text--lighten-3': $vuetify.theme.dark
        }">
          {{ $t('profile.fill_out') }}
        </v-card-subtitle>
      </v-row>

      <v-divider :class="{ 'transparent': loading }"/>

      <v-progress-linear
        :active="loading && !signup"
        indeterminate
        color="deep-purple accent-4"
        style="max-width: 95%; left: 50%; transform: translate(-50%, -50%);"
        absolute
      />
        <v-container>
          <v-row justify="center">
            <v-col cols="12" class="py-0">
              <v-row justify="center">
                <v-hover v-slot:default="{ hover }">
                  <v-list-item-avatar
                    size="100"
                    class="py-0 text-center"
                    :color="$vuetify.theme.dark ? 'grey darken-2' : 'grey lighten-3'"
                    @click="setAvatarInput"
                  >
                    <v-img :src="getResourceUrl(avatar)">
                      <HoverLayer v-if="!avatar || hover">
                        <v-row
                          justify="center"
                          align="center"
                        >
                          <v-icon
                            dark
                            large
                          >
                            {{ mdiCamera }}
                          </v-icon>
                        </v-row>
                      </HoverLayer>
                    </v-img>
                  </v-list-item-avatar>
                </v-hover>
              </v-row>

              <div class="px-5">
                <v-text-field
                  v-model="name"
                  outlined
                  :label="$t('labels.name')"
                  class="hs-rounded-lg"
                  :rules="[rules.name]"
                  data-cy="name-input"
                />

                <v-text-field
                  outlined
                  :rules="[rules.isUniq, rules.charLen, rules.alphanumericOnly]"
                  label="username"
                  autocapitalize="off"
                  autocorrect="off"
                  spellcheck="false" 
                  validate-on-blur
                  class="hs-rounded-lg"
                  @input="verifyUniq"
                  :prepend-inner-icon="mdiAt"
                  v-model="username"
                  data-cy="username-input"
                />

                <v-menu
                  ref="calendarOn"
                  :close-on-content-click="false"
                  :return-value.sync="birthDate"
                  transition="scale-transition"
                  min-width="290px"
                  v-model="calendarOn"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      v-model="birthDate"
                      readonly
                      outlined
                      hide-details
                      class="hs-rounded-lg clickable"
                      :label="$t('labels.dob')"
                      data-cy="birthdate-input"
                    />
                  </template>
                  <DatePicker        
                    locale="en"
                    :calendarOn="calendarOn"
                    @setDate="setBirthDate"
                    @saveDate="saveBirthDate"
                    @cancel="calendarOn = false"
                  />
                </v-menu>
              

                <div class="my-4"></div>
                
                <v-fade-transition hide-on-leave>
                  <v-btn
                    v-if="!updateMsg"
                    class="hs-rounded-lg"
                    color="primary"
                    block
                    large
                    :loading="loading"
                    @click="submit"
                    data-cy="signup-button"
                  >
                    {{ signup ? $t('signup') : $t('save') }}
                  </v-btn>
                </v-fade-transition>

                <v-fade-transition hide-on-leave>
                  <v-btn
                    v-if="updateMsg"
                    class="hs-rounded-lg"
                    color="success"
                    block
                    outlined
                    large
                  >
                    {{ $t('profile.updated') }}
                    <v-icon>
                      {{ mdiCheck }}
                    </v-icon>
                  </v-btn>
                </v-fade-transition>

                <div class="my-4"></div>

                <span
                  v-if="signup"
                  :class="{
                    'clickable': !loading,
                    'grey--text': true,
                    'text--darken-1': !loading,
                    'text--lighten-1': loading
                  }"
                  @click="$emit('goBack')"
                >
                  <v-icon
                    :class="{
                      'grey--text': true,
                      'text--darken-1': !loading,
                      'text--lighten-1': loading
                    }"
                  >
                    {{ mdiArrowLeft }}
                  </v-icon>
                  {{ $t('goBack') }}
                </span>
              </div>
            </v-col>
          </v-row>
        </v-container>
    </v-container>
  </v-form>
</template>

<script>
import { getResourceUrl } from '@utils'
import { HoverLayer } from '@components'
import { SnackBar } from '@components/App'
import { MediaURL } from '@components'
import ImageCropper from '@components/Image/Cropper'
import DatePicker from '@components/DatePicker' 
import { mapGetters, mapMutations } from 'vuex'
import API from '@api'
import {
  mdiCamera,
  mdiAt,
  mdiCheckboxMarkedOutline,
  mdiClose,
  mdiDelete,
  mdiArrowLeft,
  mdiCheck
} from '@mdi/js'
import { debounce } from 'lodash'

const usernameReg = new RegExp(/^[a-zA-Z0-9]+([_]?[a-zA-Z0-9]+)*$/);

// Assigning debounce in methods can be trouble.
const verifyUniq = debounce( async function (username) {
  const usernameLower = username.toLowerCase();
  this.username = usernameLower

  try {
    const { exists } = await API().get('profile-exists', {
      params: {
        username: usernameLower,
        _id: this.signup ? undefined : { $ne: this.myProfileId }
      }
    })
    this.isUniq = !exists
  } catch (err) {
    this.snackMsg = 'errServer'
    this.snackOn = true
    this.snackTop = false
  }
}, 300)

export default {
  components: {
    SnackBar,
    HoverLayer,
    ImageCropper,
    DatePicker
  },

  props: {
    signup: {
      type: Boolean,
      default: false
    }
  },

  async created() {
    this.verifyUniq = verifyUniq.bind(this)
    this.getMimeType = MediaURL.getMimeType.bind(this);
    this.getMediaUrl = MediaURL.getMediaUrl.bind(this);

    try {
      if (this.user) {
        const profile = await API().get(`profile/${this.user.id}`, {
          params: {
            id: this.myProfileId
          }
        })

        this.name = profile.name
        this.username = profile.username
        this.avatar = profile.avatar
        // for keeping track of avatar versioning
        this.avatarCount = profile.avatarCount ?
          profile.avatarCount : 0

        if (profile.birthDate) {
          const [birthDate] = profile.birthDate.split('T')
          this.birthDate = birthDate
        }
      }
    } catch(err) {
      if (err.response && err.response.data.errMsg === 'unauthorized') {
        this.$socket.notification.disconnect()
        this.$socket.conversation.disconnect()

        this.$store.dispatch('logout', true)
        this.$router.replace('/signin')
      }
    } finally {
      this.loading = false
    }
  },

  data() {
    return {
      mdiCamera,
      mdiAt,
      mdiCheckboxMarkedOutline,
      mdiCheck,
      mdiClose,
      mdiDelete,
      mdiArrowLeft,

      snackMsg: '',
      snackOn: false,
      cropperOn: false,
      loading: true,
      updateMsg: false,

      hover: false,
      valid: false,
      rules: {
        name: v => !!v || 'What is your name?',
        isUniq: v => {
          return !!this.isUniq || 'Username unavailable'
        },
        charLen: v => !!v && v.length >= 3 && v.length <= 20 || 'Your username needs to have between 3 and 20 characters',
        alphanumericOnly: v => !!usernameReg.test(v) || 'For the username, please use only alphanumeric characters and underscore'
      },
      isValid: false,
      isUniq: true,
      calendarOn: false,

      avatarObj: {
        mediaSrc: '',
        filename: '',
        type: '',
        // s3Filename
      },
      imageFile: {},
      mimeType: '',

      avatar: '',
      name: '',
      username: '',
      birthDate: '',
      country: '',
      city: '',
      verifyUniq: () => {},
    }
  },

  computed: {
    ...mapGetters({
      myProfileId: 'profile/id',
      myName: 'profile/name',
      myAvatar: 'profile/avatar',
      myUsername: 'profile/username',
      user: 'user'
    }),

    btnColor() {
      return !this.$vuetify.theme.dark
        ? 'primary'
        : ''
    },

    hoverLayerColor() {
      return this.$vuetify.theme.dark
        ? 'grey darken-4'
        : 'grey darken-2'
    }
  },
  
  methods: {
    ...mapMutations({
      setIsValid: 'profile/isValid',
      setAvatar: 'profile/avatar',
      setName: 'profile/name'
    }),

    getResourceUrl,

    setAvatarInput() {
      this.$refs.avatarInput.click()
    },

    async addAvatar(e) {
      const [imageFile] = e.target.files;
      const mimeType = await this.getMimeType(imageFile);

      if (mimeType === 'image/gif') {
        /*
        * If the image is a gif, there is no cropping to do
        * so we set the avatarObj directly
        */
        this.avatarObj.mediaSrc = URL.createObjectURL(imageFile);
        this.avatarObj.filename = imageFile.name
        this.avatarObj.type = 'image/gif'

        this.avatarObj.s3Filename = this.myProfileId

        this.avatar = this.avatarObj.mediaSrc

      } else {
        /*
        * If the image is not a gif,
        * then we will first crop it,
        * and then after the image was cropped,
        * the saveAvatar function will set the avatarObj
        */
        this.mimeType = mimeType;
        this.imageFile = imageFile;
        this.cropperOn = true;
      }
    },

    saveAvatar(imageFile) {
      this.cropperOn = false
      this.avatarObj.mediaSrc = URL.createObjectURL(imageFile)
      this.avatarObj.filename = imageFile.name
      this.avatarObj.type = imageFile.type

      this.avatarObj.s3Filename = this.myProfileId
      
      this.avatar = this.avatarObj.mediaSrc
    },

    setBirthDate(date) {
      this.birthDate = date
    },

    saveBirthDate(date) {
      this.$refs.calendarOn.save(date)
    },

    avatarColor(hover) {
      return this.$vuetify.theme.dark
        ? hover
        ? 'grey darken-2'
        : 'grey darken-3'
        : hover
        ? 'grey'
        : 'grey lighten-2'
    },

    async submit() {
      if (this.$refs.form.validate()) {

        this.loading = true

        try {
          // saving a new avatar
          this.avatarObj.keep = true

          if (this.signup) {
            this.$emit('newProfile', {
              avatarObj: this.avatarObj,
              name: this.name,
              username: this.username,
              birthDate: this.birthDate
            })

            return;

          } else {
            /*
            * Saves this record of the avatar to the profile's Id.
            * Sets contentExtension to 'undefined' so that the avatar's
            * url will be unique, without varying with the extension of the image.
            */
            let [avatar] = await this.getMediaUrl([{
              ...this.avatarObj,
              filename: this.myProfileId,
              contentExtension: 'undefined'
            }], 'images/avatars')

            if (avatar) {
              /*
              * The avatars are versioned, allowing the user to reactivate
              * an avatar that was previously active. 
              */
              this.avatarCount += 1
              this.avatarObj.s3Filename = `${this.myProfileId}-v${this.avatarCount}`

              this.avatarObj.keep = false
              const [vAvatar] = await this.getMediaUrl([this.avatarObj], 'images/avatars')

              await API().post(`image/${this.user.id}`, {
                sender: this.myProfileId,
                contentType: this.avatarObj.type,
                url: vAvatar,
                featureType: 'avatar'
              }, {
                params: {
                  id: this.myProfileId
                }
              })

            } else avatar = this.myAvatar

            if (avatar) this.setAvatar(avatar)

            API().put(`profile/${this.user.id}`, {
              avatar,
              name: this.name,
              username: this.username,
              birthDate: this.birthDate,
              avatarCount: this.avatarCount,
              isValid: true // TODO: deprecate
            }, {
              params: {
                id: this.myProfileId
              }
            })
          }

          this.setIsValid(true)
          this.setName(this.name)

          this.updateMsg = true
          setTimeout(() => {
            this.updateMsg = false
            this.$emit('close')
          }, 800)
          
        } catch(err) {

          console.log("err: ", err)
          
          this.snackMsg = 'errServer'
          this.snackOn = true
          this.snackTop = false
        } finally {
          this.loading = false
        }
      } else {
        this.snackMsg = 'invalidFields'
        this.snackOn = true
      }
    }
  }
}
</script>

<style scoped>
.cursorText {
  cursor: text;
}
.mobile {
  background-color: white;
}
</style>