<template>
  <div>
    <v-btn 
      icon
      color="white"
      @click.prevent.stop="zoomIn"
      :disabled="isDisabled"
    >
      <v-icon 
        color="white"
        v-text="mdiMagnifyPlusOutline" 
      />
    </v-btn>

    <v-btn
      icon
      @click.prevent.stop="zoomOut"
      :disabled="isDisabled"
    >
      <v-icon 
        color="white" 
        v-text="mdiMagnifyMinusOutline"
      />
    </v-btn>

    <v-btn
      icon
      @click.prevent.stop="setFit"
      :disabled="isDisabled"
    >
      <v-icon 
        color="white" 
        v-text="fit === 'width' ? mdiFullscreenExit : mdiFullscreen"
      />
    </v-btn>

  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import {
  mdiMagnifyMinusOutline,
  mdiMagnifyPlusOutline,
  mdiFullscreen,
  mdiFullscreenExit
} from '@mdi/js'

export default {
  name: 'PDFZoom',

  created () {
    window.addEventListener('wheel', this.zoomWheel, { passive: false })
    window.addEventListener('keydown', this.zoomCtrl)
  },

  beforeDestroy () {
    window.removeEventListener('wheel', this.zoomWheel)
    window.removeEventListener('keydown', this.zoomCtrl)
  },

  data () {
    return {
      mdiMagnifyMinusOutline,
      mdiMagnifyPlusOutline,
      mdiFullscreen,
      mdiFullscreenExit,

      increment: 0.25
    }
  },

  computed: {
    ...mapGetters({
      scale: 'pdf/scale',
      fit: 'pdf/fit'
    }),

    isDisabled() {
      return !this.scale
    }
  },

  methods: {
    ...mapMutations({
      setScale: 'pdf/scale'
    }),

    setFit() {
     
      if (this.fit === 'width') {
      
        this.fitAuto()
      
      } else {

        this.fitWidth()
      
      }

    },

    zoomIn () {
      this.updateScale(this.scale + this.increment)
    },
    
    zoomOut () {
      if (this.scale <= this.increment) return
      this.updateScale(this.scale - this.increment)
    },

    updateScale (scale) {
      this.setScale(scale)
    },

    fitWidth () {
      
      this.$store.commit('pdf/fit', 'width')

    },

    fitAuto () {
      this.$store.commit('pdf/fit', 'auto')
    },

    zoomWheel (event) {
      if(event.ctrlKey === true) {
        event.preventDefault()
                
        if (event.deltaY > 0) this.zoomOut()
        else this.zoomIn()
      }
    },

    zoomCtrl (evt) {
      if(evt.ctrlKey) {
        if (evt.key === '=' || evt.key === '+') {
          evt.preventDefault()
          this.zoomIn()
        } else if (event.key === '-') {
          evt.preventDefault()
          this.zoomOut()
        }
      }
    }
  }
}
</script>