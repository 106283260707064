<template>
  <v-card
    style="border-radius: 18px;"
    :outlined="!$vuetify.theme.dark"
  >
    <v-list
      two-line
      class="py-0"
    >
      <v-list-item>
        <v-list-item-avatar
          class="hs-rounded-lg"
          size="50"
        >
          <v-img :src="getResourceUrl(room.thumbnail)">
            <template v-slot:placeholder>
              <v-skeleton-loader
                class="mx-auto"
                height="50"
                width="50"
                type="image"
              />
            </template>
          </v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ room.name }}
          </v-list-item-title>

          <v-list-item-subtitle>
            <v-badge
              dot
              left
              inline
              color="deep-purple"
            >
              <span
                class="caption adjustY"
                v-text="$t('onlineStatus.studying', { amount: 12 })"
              ></span>
            </v-badge>

            <v-badge
              dot
              left
              inline
              color="success"
              class="mx-1"
            >
              <span
                class="caption adjustY"
                v-text="$t('onlineStatus.online', { amount: 18 })"
              ></span>
            </v-badge>

            <v-badge
              dot
              left
              inline
              color="grey"
              v-if="false"
            >
              <span
                class="caption adjustY"
                v-text="$t('onlineStatus.members', { amount: 0 })"
              ></span>
            </v-badge>
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-btn
          v-if="currentPost"
          elevation="0"
          style="border-radius: 12px;"
          @click="$emit('reset')"
        >
          <v-icon>
            {{ mdiArrowLeft }}
          </v-icon>
          back
        </v-btn>

        <v-btn
          v-if="!currentPost"
          elevation="0"
          :outlined="(!tab && !$vuetify.theme.dark) || (tab && $vuetify.theme.dark)"
          :color="tab === 0 ? 'primary' : tab === 1 ? 'tertiary' : 'grey lighten-2'"
          style="border-radius: 12px; width: 143px;"
          @click="$emit(tab === 2 ? 'chat' : 'post')"
        >
          <!-- new {{ tab === 0 ? 'content' : tab === 1 ? 'thread' : 'chat' }} -->
          {{ $t('community.newPost') }}
        </v-btn>
        
        <!-- <v-list-item-action
          style="width: 30%;"
          class="px-4"
        >
          <PillTabs
            :tabs="[{
              name: 'Content',
              counter: 0
            }, {
              name: 'Threads',
              counter: 0
            }]"
            :showSearch="false"
            @setActive="$emit('setActive', $event)"
          />
        </v-list-item-action> -->
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { mdiArrowLeft } from '@mdi/js';
import PillTabs from '@components/PillTabs.vue'
import { getResourceUrl } from '@utils'

export default {
  components: {
    PillTabs
  },

  props: {
    currentPost: {
      type: Object,
      default: null // ew X(
    },
    room: {
      type: Object,
      required: true
    },
    tab: {
      type: Number,
      default: 0
    }
  },

  data: () => ({
    mdiArrowLeft
  }),

  methods: {
    getResourceUrl,

    emitSnack(snackMsg) {
      this.$emit('snack', snackMsg)
    }
  }
}
</script>