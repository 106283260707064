import { withLayout } from '@utils'
import Fluid from '@layouts/Fluid'
import HallLayout from '@layouts/Hall'

const List = () => import('@views/hall/list')
const Home = () => import('@views/hall/home')
const Room = () => import('@views/hall/room')

export default {
  path: 'halls',
  component: {
    render: h => h('router-view')
  },
  children: [
    {
      path: '',
      name: 'halls',
      component: withLayout(List, Fluid),
      meta: {
        app: 'halls',
        locked: true
      }
    },
    {
      path: 'hall',
      name: 'hall.home',
      component: withLayout(Home, HallLayout),
      meta: {
        app: 'halls',
        locked: true
      }
    },
    {
      path: 'hall/room',
      name: 'hall.room',
      component: withLayout(Room, HallLayout),
      meta: {
        app: 'halls',
        locked: true
      }
    }
  ]
};
