<template>
  <v-card
    class="mb-3 hs-rounded-lg"
    style="width: 100%;"
    elevation="0"
    :ripple="!edit"
    :color="cardColor"
    @click="routeConversation"
  >
    <v-fade-transition hide-on-leave>
      <v-hover
        v-show="!edit"
        v-slot:default="{ hover }"
      >
        <v-list-item
          :class="{
            'hs-rounded-item': true,
            'v-item--active v-list-item--active v-list-item--link': active,
            'theme--dark tertiary--text': $vuetify.theme.dark,
            'theme--light primary--text': !$vuetify.theme.dark
          }"
          :color="itemColor"
          style="min-height: 15px !important"
        >
          <v-list-item-content>
            <v-list-item-subtitle>
              {{ conversation.name }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-fade-transition hide-on-leave>
            <v-list-item-icon
              v-if="hover"
              class="my-0"
              style="padding: 6px 0px 6px 0px;"
            >
              <v-btn
                icon
                small
                @click.stop="edit = !edit"
              >
                <v-icon small>
                  {{ mdiPencil }}
                </v-icon>
              </v-btn>
            </v-list-item-icon>
          </v-fade-transition>
        </v-list-item>
      </v-hover>
    </v-fade-transition>

    <v-fade-transition hide-on-leave>
      <v-list-item
        v-if="edit && !confirmRemove"
        class="hs-rounded-item px-2"
      >
        <v-list-item-content class="py-0">
          <v-text-field
            v-model="conversation.name"
            class="hs-rounded-lg"
            :color="inputColor"
            :placeholder="placeholder"
            hide-details
            dense
            elevation="0"
            filled
          />
        </v-list-item-content>

        <v-list-item-icon class="pl-2" style="margin: 13px 0px 13px 0px;">
          <v-btn
            icon
            small
            @click.stop="submit"
          >
            <v-icon small>
              {{ mdiCheck }}
            </v-icon>
          </v-btn>

          <v-btn
            v-if="!onlyCreate"
            icon
            small
            color="danger"
            @click.stop="triggerRemove"
          >
            <v-icon small>
              {{ mdiDeleteOutline }}
            </v-icon>
          </v-btn>
        </v-list-item-icon>
      </v-list-item>
    </v-fade-transition>

    <v-fade-transition hide-on-leave>
      <v-list-item
        v-if="edit && confirmRemove"
        class="hs-rounded-item"
      >
        <v-list-item-content class="px-1">
          <span :class="{ 'grey--text text--darken-1': !$vuetify.theme.dark }">
            Are you sure? This can't be undone
          </span>
        </v-list-item-content>

        <v-list-item-icon>
          <v-btn
            icon
            small
            color="danger"
            @click.stop="remove"
          >
            <v-icon small>
              {{ mdiCheck }}
            </v-icon>
          </v-btn>
          
          <v-btn
            icon
            small
            @click.stop="confirmRemove = false"
          >
            <v-icon small>
              {{ mdiClose }}
            </v-icon>
          </v-btn>
        </v-list-item-icon>
      </v-list-item>
    </v-fade-transition>
  </v-card>
</template>

<script>
import API from '@api'
import { mapGetters } from 'vuex'
import {
  mdiPencil,
  mdiCheck,
  mdiClose,
  mdiDeleteOutline
} from '@mdi/js'

export default {
  props: {
    placeholder: {
      type: String,
      default: 'chat name'
    },
    cardColor: {
      type: String,
      default () {
        return this.$vuetify.theme.dark ? '#333333' : 'grey lighten-3'
      }
    },
    itemColor: {
      type: String,
      default: ''
    },
    chamber: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    conversation: {
      type: Object,
      default () {
        return {}
      }
    },
    onlyCreate: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    this.edit = !Boolean(this.conversation.id)
  },

  data () {
    return {
      mdiPencil,
      mdiCheck,
      mdiClose,
      mdiDeleteOutline,
      edit: false,
      confirmRemove: false
    }
  },

  computed: {
    ...mapGetters({
      user: 'user'
    }),

    inputColor() {
      return this.$vuetify.theme.dark ? 'white' : ''
    }
  },

  methods: {
    routeConversation() {
      if (!this.edit) {
        if (this.chamber) this.$emit('setConversation')
        else this.$router.push(`/rooms/conversation?id=${this.conversation._id}`)
      }
    },

    submit() {
      if (!this.conversation.name) return
      if (!this.conversation.id) this.$emit('addConversation', this.conversation.name)        
      else API().put(`conversation/${this.user.id}`, {
        name: this.conversation.name
      }, {
        params: {
          id: this.conversation._id
        }
      })

      if (this.onlyCreate) return
      this.edit = false
    },

    triggerRemove() {
      if (this.conversation.id)
        this.confirmRemove = true
      else this.remove()
    },

    remove() {
      API().put(`conversation/${this.user.id}`, {
        state: 'DELETED'
      }, {
        params: {
          query: {
            _id: {
              $eq: this.conversation._id
            }
          }
        }
      })
      this.$emit('remove')
      this.edit = false
      this.confirmRemove = false
    }
  }
}
</script>

<style>
/* Rounds the item inside the card */
.hs-rounded-item.v-list-item--active:hover::before, .hs-rounded-item.v-list-item--active::before, .hs-rounded-item.v-list-item:hover::before {
  border-radius: 10px;
}
</style>