<template>
  <v-menu
    transition="slide-y-transition"
    offset-y
    bottom
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-on="on"
        v-bind="attrs"
        icon
        @click="toolbarSignal = !toolbarSignal"
      >
        <v-tooltip
          bottom
          :disabled="!settings.tooltipsOn"
        >
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              v-text="mdiAppsBox"
            />
          </template>
          <span>{{ $t('actions') }}</span>
        </v-tooltip>
      </v-btn>
    </template>

    <v-card dark>
      <v-list dense>
        <v-list-item @click.prevent.stop="setFirstPage">
          <v-list-item-action>
            <v-btn 
              icon
              color="white"
            >
              <v-icon 
                color="white"
                style="transform: rotate(90deg)"
                v-text="mdiPageFirst" 
              />
            </v-btn>
          </v-list-item-action>
          <v-list-item-title>First page</v-list-item-title>
        </v-list-item>

        <v-list-item @click.prevent.stop="setLastPage">
          <v-list-item-action>
            <v-btn 
              icon
              color="white"
            >
              <v-icon 
                color="white"
                style="transform: rotate(90deg)"
                v-text="mdiPageLast" 
              />
            </v-btn>
          </v-list-item-action>
          <v-list-item-title>Last page</v-list-item-title>
        </v-list-item>

        <v-divider />

        <v-list-item @click.prevent.stop="zoomIn">
          <v-list-item-action>
            <v-btn 
              icon
              color="white"
            >
              <v-icon 
                color="white"
                v-text="mdiMagnifyPlusOutline" 
              />
            </v-btn>
          </v-list-item-action>
          <v-list-item-title>Zoom in</v-list-item-title>
        </v-list-item>

        <v-list-item @click.prevent.stop="zoomOut">
          <v-list-item-action>
            <v-btn
              icon
            >
              <v-icon 
                color="white" 
                v-text="mdiMagnifyMinusOutline"
              />
            </v-btn>
          </v-list-item-action>
          <v-list-item-title>Zoom out</v-list-item-title>
        </v-list-item>

        <v-divider />

        <v-list-item @click.prevent.stop="setFit('width')">
          <v-list-item-action>
            <v-btn
              icon
            >
              <v-icon 
                color="white"
                v-text="mdiFullscreenExit"
              />
            </v-btn>

          </v-list-item-action>
          <v-list-item-title>Original size</v-list-item-title>
        </v-list-item>

        <v-list-item @click.prevent.stop="setFit('auto')">
          <v-list-item-action>
            <v-btn
              icon
            >
              <v-icon 
                color="white" 
                v-text="mdiFullscreen"
              />
            </v-btn>

          </v-list-item-action>
          <v-list-item-title>Full screen</v-list-item-title>
        </v-list-item>

        <v-divider />

        <v-list-item @click.prevent.stop="rotateRight">
          <v-list-item-action>
            <v-btn
              icon
            >
              <v-icon 
                color="white" 
                v-text="mdiRotateRight"
              />
            </v-btn>
          </v-list-item-action>
          <v-list-item-title>Rotate to the right</v-list-item-title>
        </v-list-item>

        <v-list-item @click.prevent.stop="rotateLeft">
          <v-list-item-action>
            <v-btn
              icon
            >
              <v-icon 
                color="white" 
                v-text="mdiRotateLeft"
              />
            </v-btn>
          </v-list-item-action>
          <v-list-item-title>Rotate to the left</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import {
  mdiMenuUp,
  mdiMenuDown,
  mdiRotateLeft,
  mdiRotateRight,
  mdiMagnifyMinusOutline,
  mdiMagnifyPlusOutline,
  mdiFullscreen,
  mdiFullscreenExit,
  mdiPageFirst,
  mdiPageLast,
  mdiAppsBox
} from '@mdi/js'

const ZOOM_INCREMENT = 0.25
const ROTATION_INCREMENT = 90

export default {

  created () {
    window.addEventListener('wheel', this.zoomWheel, { passive: false })
    window.addEventListener('keydown', this.zoomCtrl)
  },

  beforeDestroy () {
    window.removeEventListener('wheel', this.zoomWheel)
    window.removeEventListener('keydown', this.zoomCtrl)
  },

  data () {
    return {
      mdiMenuUp,
      mdiMenuDown,
      mdiRotateLeft,
      mdiRotateRight,
      mdiMagnifyMinusOutline,
      mdiMagnifyPlusOutline,
      mdiFullscreen,
      mdiFullscreenExit,
      mdiPageFirst,
      mdiPageLast,
      mdiAppsBox
    }
  },

  computed: {
    ...mapGetters({
      isSearchListEnabled: 'pdf/isSearchListEnabled',
      isPreviewEnabled: 'pdf/isPreviewEnabled',
      querySwitch: 'pdf/querySwitch',
      textQuery: 'pdf/textQuery',
      matches: 'pdf/matches',
      from: 'from',
      settings: 'settings',
      avatar: 'profile/avatar',
      scale: 'pdf/scale',
      rotation: 'pdf/rotation',
      fit: 'pdf/fit',
      currentPage: 'pdf/currentPage',
      pageCount: 'pdf/pageCount'
    }),

    isDisabled() {
      return !this.scale
    }

  },

  methods: {
    ...mapMutations({
      SET_TEXT_QUERY: 'pdf/textQuery',
      SET_PDF_SETTINGS: 'pdf/SET_PDF_SETTINGS',
      setIsPreviewEnabled: 'pdf/isPreviewEnabled',
      setIsSearchListEnabled: 'pdf/isSearchListEnabled',
      setQuerySwitch: 'pdf/querySwitch',
      setIsSearchListQuery: 'pdf/isSearchListQuery' ,
      SET_NOTES_ON: 'note/SET_NOTES_ON',
      SET_CURRENT_PAGE: 'pdf/currentPage',
      SET_PAGINATOR: 'pdf/paginatorOn',

      setScale: 'pdf/scale',

      searchPrevious: 'pdf/searchPrevious',
      searchNext: 'pdf/searchNext',

      toggleOutline: 'pdf/isOutlineEnabled'
    }),

    setFirstPage() {

      this.SET_PAGINATOR(true)
    
      this.SET_CURRENT_PAGE(1)
    
    },

    setLastPage() {

      this.SET_PAGINATOR(true)
    
      this.SET_CURRENT_PAGE(this.pageCount)
    
    },

    setFit(fit) {
     
      if (fit === 'width') {
      
        this.fitAuto()
      
      } else {

        this.fitWidth()
      
      }

    },

    fitWidth () {
      this.$store.commit('pdf/fit', 'width')
    },

    fitAuto () {
      this.$store.commit('pdf/fit', 'auto')
    },

    zoomIn () {
      this.updateScale(this.scale + ZOOM_INCREMENT)
    },
    
    zoomOut () {

      if (this.scale <= this.increment) return;

      this.updateScale(this.scale - ZOOM_INCREMENT)

    },

    updateScale (scale) {
      this.setScale(scale)
    },

    zoomWheel (evt) {
      if(evt.ctrlKey === true || evt.metaKey) {
        evt.preventDefault()
                
        if (evt.deltaY > 0) {
          this.zoomOut()
        } else {
          this.zoomIn()
        }
      }
    },

    zoomCtrl (evt) {
      if(evt.ctrlKey || evt.metaKey) {
        if (evt.key === '=' || evt.key === '+') {
          evt.preventDefault()
          this.zoomIn()

        } else if (evt.key === '-') {
          evt.preventDefault()
          this.zoomOut()
        }
      }
    },

    rotateRight () {
      this.updateRotation(this.rotation + ROTATION_INCREMENT)
    },

    rotateLeft () {
      this.updateRotation(this.rotation - ROTATION_INCREMENT)
    },

    updateRotation (rotation) {
      this.$store.commit('pdf/rotation', rotation)
    }

  }

}

</script>
