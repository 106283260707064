import API from '@api';

export default (userId) => {
  const apiRequest = API()
  return {
    update (id, body) {
      return apiRequest.put(
        `notebook/${userId}`,
        body,
        {
          params: {
            query: {
              _id: {
                $eq: id
              }
            }
          }
        }
      )
    }
  }
}
