<template>
  <v-select
    v-model="inputValue"
    :items="items"
    item-text="text"
    item-value="id"
    v-on:change="onChange"
    :label="$t(`onboarding.role.type`)"
    :rules="rules"
    filled
    dense
    class="hs-rounded-12"
  />
</template>

<script>
  export default {
    props: {
      onChange: Function,
      value: String,
      rules: Array
    },

    computed: {
      inputValue: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      }
    },

    data() {
      return {
        items: [
          {
            id: 'teacher',
            text: this.$t('onboarding.role.teacher')
          },
          {
            id: 'student',
            text: this.$t('onboarding.role.student')
          }
        ]
      } 
    }
  }
</script>