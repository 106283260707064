<template>
  <v-container
    ref="textContainer"
    :class="`${block ? 'px-1' : ''} py-1`"
  >
    <v-dialog
      v-model="mentionCardOn"
      hide-overlay
      max-width="0"
      @click:outside="mentionCardOn = false"
    >
      <MentionCard
        :mentionCardOn="mentionCardOn"
        :mentionCardStyle="mentionCardStyle"
        :upperHalfOn="mentionCardUpperHalfOn"
        :dense="true"
        @hideMentionCard="mentionCardOn = false"
        @doMention="doMention"
      />
    </v-dialog>

    <v-row
      justify="center"
      align="start"
      class="px-2"
    >
      <v-col
        :cols="avatarOn ? 11 : 12"
        :class="`py-0 ${avatarOn ? '' : 'px-0'}`"
      >
        <v-container :class="`pr-0 pl-${dense ? 2 : 0} py-0`">
          <v-card
            :class="colorClass"
            style="min-height: 48px;"
            elevation="0"
            rounded="lg"
            :ripple="false"
          >
            <v-expand-transition hide-on-leave>
              <v-container
                v-if="noteFocus"
                class="py-0"
              >
                <v-row justify="center">
                  <v-list-item>
                    <v-list-item-avatar size="30">
                      <v-img :src="profileAvatar">
                        <template v-slot:placeholder>
                          <v-skeleton-loader
                            class="mx-auto"
                            height="40"
                            width="40"
                            type="image"
                          />
                        </template>             
                      </v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title
                        class="overline"
                        v-text="profileName"
                      />
                    </v-list-item-content>

                    <v-list-item-action>
                      <span class="caption">{{ charCount }}/{{ maxChars }}</span>
                    </v-list-item-action>
                  </v-list-item>
                </v-row>
              </v-container>
            </v-expand-transition>


            <div :style="`transition: all .5s linear; min-height: ${ !noteFocus ? 24 : 100 }px; `">
              <v-row
                v-for="(paragraph, i) in paragraphs"
                :key="`paragraph_${i}`"
                :ref="`paragraph_${i}`"
                :class="textContainerClass"
                align="center"
              >
                <TextInput
                  :style="`margin-bottom: 2px; width: ${textInputWidth}%;`"
                  :index="i"
                  :disabled="disabled"
                  :label="i === 0 ? label : ''"
                  :content="paragraph"
                  :latexOn="true"
                  :focusOn="currentParagraph === i"
                  :activeFocusOn="currentParagraph === i && focusOn"
                  :syncContentOn="syncContentOn"
                  :syncSwitch="syncSwitch"
                  :syncCarriageReturn="syncCarriageReturn"
                  :syncCarriageStart="syncCarriageStart"
                  :syncCarriageEnd="syncCarriageEnd"
                  :syncCarriageUp="syncCarriageUp"
                  :syncCarriageDown="syncCarriageDown"
                  :previousCaretLeft="previousCaretLeft"
                  :syncRangeContainer="syncRangeContainer"
                  :syncRangeOffset="syncRangeOffset"
                  :mentionCardOn="mentionCardOn"
                  :syncMention="syncMention"
                  :mentionData="mentionData"
                  :syncSubmission="syncSubmission"
                  :syncFocusOn="syncFocusOn"
                  @mention="setMentionCard"
                  @focus="setFocus(i)"
                  @blur="setBlur"
                  @textInput="setTextContent"
                  @overrideSyncContent="overrideSyncContent"
                  @setRangeState="setRangeState"
                  @enterKey="addParagraph"
                  @backspaceKey="carriageReturn"
                  @deleteKey="carriageMerge"
                  @click="currentParagraph = i"
                  @resetBold="resetBold"
                  @resetItalic="resetItalic"
                  @carriagePrevious="carriagePrevious"
                  @carriageNext="carriageNext"
                  @carriageUp="carriageUp"
                  @carriageDown="carriageDown"
                />
              </v-row>
            </div>

            <v-fade-transition hide-on-leave>
              <v-container v-if="noteMentions.length > 0">
                <v-row
                  class="px-4"
                  align="center"
                  v-for="(noteMention, i) in noteMentions"
                  :key="`noteMention_${i}`"
                >
                  <v-hover v-slot:default="{ hover }">
                    <div>
                      <v-fade-transition hide-on-leave>
                        <NoteMention
                          v-if="hover"
                          :note="noteMention"
                          :noteId="noteMention.id"
                        />
                      </v-fade-transition>
                      <span
                        class="clickable annotation"
                        v-html="`${truncate(noteMention.sender.name, 20)} @ note ${truncate(noteMention.id, 10)}`"
                      />
                    </div>
                  </v-hover>
                </v-row>
              </v-container>
            </v-fade-transition>

            <v-fade-transition hide-on-leave>
              <div
                v-if="audioSrc"
                style="height: 68px;"
                class="mt-4 px-3"
              >
                <AudioPlayer
                  :src="audioSrc"
                  @audioDuration="setAudioDuration"
                  @clear="audioSrc = ''"
                />
              </div>
            </v-fade-transition>

            <v-divider
              v-if="noteFocus"
              class="mt-4"
            />

            <v-expand-transition hide-on-leave>
              <v-container
                v-if="noteFocus"
                class="py-1"
              >
                <v-row
                  v-if="type === 'bulletin'"
                  justify="center"
                  class="px-4"
                >
                  <v-btn
                    plain
                    color="primary"
                    :loading="loading"
                    @click="submit"
                  >
                    save
                  </v-btn>

                  <v-spacer />

                  <v-btn
                    plain
                    @click="cancelNote"
                  >
                    cancelar
                  </v-btn>
                </v-row>

                <v-row
                  v-if="type != 'bulletin'"
                  justify="center"
                  class="px-4"
                >
                  <v-fade-transition hide-on-leave>
                    <AudioBtn
                      v-if="!audioSrc"
                      style="transform: translateY(-2px)"
                      color="primary"
                      @audioBlob="addAudioBlob"
                    />
                  </v-fade-transition>

                  <v-fade-transition hide-on-leave>
                    <v-btn
                      v-if="audioSrc"
                      color="danger"
                      icon
                      @click="removeAudio"
                    >
                      <v-icon> {{ mdiClose }} </v-icon>
                    </v-btn>
                  </v-fade-transition>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        icon
                        class="mx-1"
                        style="transform: translateY(-2px)"
                        @click="isPublic = !isPublic"
                      >
                        <v-icon>{{ isPublic ? mdiShieldLockOutline : mdiShieldLock }}</v-icon>
                      </v-btn>
                    </template>
                    <span> {{ isPublic ? 'Set private' : 'Set public' }}</span>
                  </v-tooltip>

                  <v-spacer />

                  <v-btn
                    plain
                    @click="cancelNote"
                  >
                    cancel
                  </v-btn>

                  <v-btn
                    plain
                    :loading="loading"
                    @click="submit"
                  >
                    submit
                  </v-btn>
                </v-row>
              </v-container>
            </v-expand-transition>
   
          </v-card>
        </v-container>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import NoteMention from '@components/Note/NoteMention'

import { mapGetters } from 'vuex'
import { MentionCard, MediaURL } from '@components'
import TextInput from './TextInput'
import { AudioPlayer } from '@components/Audio'
import AudioBtn from '@components/Post/AudioBtn'
import { some, uniq } from 'lodash'
import { truncateStr } from '@utils'
import API from '@api'

import {
  mdiEmoticonExcitedOutline,
  mdiAccount,
  mdiAttachment,
  mdiShieldLockOutline,
  mdiShieldLock,
  mdiClose
} from '@mdi/js'

export default {

  components: {
    TextInput,
    MentionCard,
    AudioBtn,
    AudioPlayer,

    NoteMention
  },

  props: {
    type: {
      type: String,
      default: 'annotation'
    },
    group: {
      type: String,
      default: ''
    },
    maxChars: {
      type: Number,
      default: 1000
    },
    lighterViewerOn: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Comentário...'
    },
    dense: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    syncNoteMention: {
      type: Boolean,
      default: false
    },
    noteMention: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  created() {
    this.getMediaUrl = MediaURL.getMediaUrl.bind(this);
  },

  data() {
    return {
      mdiClose,
      mdiAccount,
      mdiAttachment,
      mdiShieldLock,
      mdiShieldLockOutline,
      mdiEmoticonExcitedOutline,

      noteMentions:  [],

      charCount: 0,

      loading: false,

      isPublic: true,

      mentionCardUpperHalfOn: true,

      mentionCardOn: false,
      mentionCardLeft: null,
      mentionCardTop: null,
      syncMention: false,
      mentionData: {},
      mentions: [],

      rangeOn: false,
      latexOn: true,

      paragraphs: [''],
      currentParagraph: 0,
      htmlStr: '',
      emptyText: true,

      audioObj: {},
      audioSrc: '',

      focusOn: false,

      noteFocus: false,

      syncContentOn: false,
      syncSwitch: false,
      syncCarriageReturn: false,
      syncCarriageStart: false,
      syncCarriageEnd: false,
      syncCarriageUp: false,
      syncCarriageDown: false,
      previousCaretLeft: null,
      syncEmoji: false,
      syncLink: false,
      syncRangeContainer: {},
      syncRangeOffset: 0,
      syncSubmission: false
    }
  },

  computed: {

    ...mapGetters({

      user: 'user',
      profile: 'profile/id',
      profileName: 'profile/name',
      profileAvatar: 'profile/avatar',
      profileBio: 'profile/bio',
      profileUsername: 'profile/username',
      scrollTop: 'scrollTop',

      libraryType: 'note/libraryType',
      libraryId: 'note/libraryId'

    }),

    sender() {
      return {
        profile: this.profile,
        name: this.profileName,
        avatar: this.profileAvatar,
        bio: this.profileBio,
        username: this.profileUsername
      }
    },

    disabled() {
      return this.charCount >= this.maxChars
    },

    textContainerClass() {
      
      const { xl, width } = this.$vuetify.breakpoint

      return xl || width < 1300
        ? 'px-3'
        : 'pl-3'
        
    },

    colorClass() {
      return this.$vuetify.theme.dark
        ? 'viewer-dark'
        : this.lighterViewerOn
        ? 'viewer-lighter'
        : 'viewer-light'
    },

    textInputWidth() {
      return this.$vuetify.breakpoint.xl
        ? this.dense ? 90 : 92
        : this.dense ? 88 : 90

    },

    mentionCardStyle() {
      return `position: absolute;
        z-index: 100;
        left: ${this.mentionCardLeft}px;
        top: ${this.mentionCardTop}px;`
    }

  },

  watch: {
    charCount(count) {
      if (count > this.maxChars) {

        this.syncContentOn = true
      
        const paragraphLen = this.paragraphs.length
        const breakLine = '<br/>'
        const maxCharBr = this.maxChars + breakLine.length*(paragraphLen - 1)
        const paragraphStr = truncateStr(this.paragraphs.join(breakLine), maxCharBr)
        
        this.paragraphs = paragraphStr.split(breakLine)

        this.countChars()

        setTimeout(() => {
          this.syncContentOn = false
        })

      } 
    },

    focusOn(focus) {
      if (focus) {
        this.noteFocus = true
      }
    },

    syncNoteMention(sync) {

      if (sync && this.noteMentions.length <= 5) {

        if (!this.focusOn) {

          this.currentParagraph = 0
          this.focusOn = true

        }

        if (this.noteMentions.map(({id}) => id).indexOf(this.noteMention.id) === -1) {
          this.noteMentions.unshift(this.noteMention)
        }

      }

    }

  },

  methods: {

    truncate(str = '', num = 20) {

      return truncateStr(str, num)

    },

    async submit() {

      this.loading = true

      const mentionEls = this.$refs.textContainer.querySelectorAll('a')
      const mentionProfiles = []

      for (let el of mentionEls) {

        const { profile } = el.dataset

        if (mentionProfiles.indexOf(profile) === -1) {
          mentionProfiles.unshift(profile)
        }

      }

      const mentions = uniq(this.mentions.filter(({id}) => {
        return mentionProfiles.indexOf(id) != -1
      }))
      .map(profileData => {
        return {
          profile: profileData.id,

          ...profileData
        }
      })

      const contentMentions = {
        annotations: this.noteMentions.map(({ id }) => id)
      }

      const paragraphs = this.paragraphs.filter(paragraph => {
        return paragraph.length > 0
      })

      const mediaContent = {}

      if (this.audioSrc) {
        mediaContent.audios = await this.submitAudios()
      }

      if (this.type != 'bulletin') {

        const annotation = await API().post(`annotation/${this.user.id}`, {
          sender: this.sender,
          type: this.libraryType,

          contentMentions,
          mentions,
          
          [this.libraryType]: this.libraryId,

          paragraphs,
          mediaContent,
          isPublic: this.isPublic
        })

        this.$emit('annotation', annotation)

      } else {

        const bulletin = await API().post(`bulletin/${this.user.id}`, {
          sender: this.sender,
          mentions,
          group: this.group,
          paragraphs
        })

        this.$emit('bulletin', bulletin)

      }
      


      this.cancelNote()

      setTimeout(() => {
        this.loading = false
      })

    },

    async submitAudios() {
      
      if (Object.keys(this.audioObj).length === 0) return [];

      const [audioUrl] = await this.getMediaUrl([this.audioObj], 'audios')
      const audioData =  {
        sender: this.sender,
        filename: this.audioObj.filename,
        contentType: this.audioObj.type,
        duration: this.audioObj.duration,
        url: audioUrl,
        isAnnotationContent: this.type === 'annotation',
        isNoteContent: this.type === 'note'
      }
      
        
      return await API().post(`audio/${this.user.id}`, [audioData])
    },

    countChars() {
      this.charCount = this.paragraphs.join('').length
    },

    cancelNote() {
      this.syncContentOn = true
      this.noteFocus = false
      this.currentParagraph = 0
      this.paragraphs = ['']
      this.htmlStr = ''
      this.noteMentions = []

      this.countChars()

      this.removeAudio()

      setTimeout(() => {
        this.syncContentOn = false
      })
    },

    removeAudio() {
      this.audioSrc = ''
      this.audioObj = {}
    },

    addAudioBlob(audioBlob) {
    
      const filename = `${this.myProfileId}-${Date.now()}.ogg`;
      const type = audioBlob.type;
      const audioFile = new File([audioBlob], filename, { type })
      const audioSrc = URL.createObjectURL(audioFile);

      this.audioObj = { filename, mediaSrc: audioSrc, type }

      this.audioSrc = '';

      setTimeout(() => {
        this.audioSrc = audioSrc
      })
    
    },

    setAudioDuration(audioDuration) {

      this.audioObj.duration = this.audioObj.duration
        ? this.audioObj.duration
        : audioDuration

    },

    setFocus(i) {
      this.currentParagraph = i
      this.focusOn = true
    },

    setBlur() {
      this.focusOn = false
    },
    
    setTextContent({ textContent }) {

      if (!this.syncContentOn) {
      
        const i = this.currentParagraph

        this.paragraphs[i] = textContent
        this.emptyText = !some(this.paragraphs.map(paragraph => !paragraph.trim()), v => !v)

        this.countChars()
      
      }
    },

    carriageUp({ caretLeft }) {

      if (this.currentParagraph > 0) {

        this.currentParagraph--
        this.previousCaretLeft = caretLeft
  
        this.syncCarriageUp = true
        setTimeout(() => {
          this.syncCarriageUp = false
        })

      }

    },

    carriageDown({ caretLeft }) {

      if (this.currentParagraph < this.paragraphs.length - 1) {

        this.currentParagraph++
        this.previousCaretLeft = caretLeft
      
        this.syncCarriageDown = true
        setTimeout(() => {
          this.syncCarriageDown = false
        })

      }

    },

    carriagePrevious() {

      if (this.currentParagraph > 0) {

        this.currentParagraph--
        this.syncCarriageEnd = true
        setTimeout(() => {
          this.syncCarriageEnd = false
        })

      }

    },

    carriageNext() {

      if (this.currentParagraph < this.paragraphs.length - 1) {

        this.currentParagraph++
        this.syncCarriageStart = true

        setTimeout(() => {
          this.syncCarriageStart = false
        })

      }

    },
    
    overrideSyncContent({ index, textContent }) {
      this.paragraphs[index] = textContent
      
      this.countChars()
    },

    carriageReturn({ textContent }) {

      this.syncContentOn = true
      this.syncCarriageReturn = true

      this.paragraphs.splice(this.currentParagraph, 1)
      this.currentParagraph--

      const content = this.paragraphs[this.currentParagraph]

      const htmlStr = `${content}${textContent}`

      setTimeout(() => {

        this.paragraphs[this.currentParagraph] = htmlStr

        this.countChars()

        this.syncSwitch = !this.syncSwitch

        setTimeout(() => {
          this.syncContentOn = false
          this.syncCarriageReturn = false
        })

      })

    },

    carriageMerge() {

      if (this.currentParagraph < this.paragraphs.length - 1) {

        this.syncContentOn = true

        const mergeContent = this.paragraphs[this.currentParagraph + 1]

        this.paragraphs.splice(this.currentParagraph + 1, 1)

        const content = this.paragraphs[this.currentParagraph]

        const htmlStr = `${content}${mergeContent}`

        setTimeout(() => {

          this.paragraphs[this.currentParagraph] = htmlStr

          this.countChars()

          this.syncSwitch = !this.syncSwitch
          
          setTimeout(() => {
            this.syncContentOn = false
          })

        })

      }

    },

    addParagraph({ docFragment, textContent }) {
      
      this.syncContentOn = true

      const baseIdx = this.currentParagraph
      this.paragraphs[baseIdx] = textContent

      if (this.currentParagraph === this.paragraphs.length - 1) {

        this.paragraphs.push('')

      } else {

        this.paragraphs.splice(this.currentParagraph + 1, 0, '')

      }

      this.currentParagraph++

      let htmlStr = ''

      const textNodes = [ ... docFragment.childNodes ]

      textNodes.forEach(textNode => {
        htmlStr += textNode.nodeName === '#text'
          ? textNode.textContent
          : textNode.outerHTML
      })

      setTimeout(() => {

        const index = this.currentParagraph
        this.paragraphs[index] = htmlStr
        this.syncSwitch = !this.syncSwitch

        this.countChars()

        setTimeout(() => {
          this.syncContentOn = false
        })

      })
      
    },

    setRangeState(value) {
      this.rangeOn = value
    },

    doMention({ username, avatar, bio, name, id }) {

      this.mentions.unshift({ username, name, avatar, bio, id })

      this.mentionData = {
        username,
        id
      }
      
      this.syncMention = true

      setTimeout(() => {
        this.mentionCardOn = false
        this.syncMention = false
      })

    },

    setMentionCard({ x }) {

      const { textContainer } = this.$refs
      const { y } = textContainer.getBoundingClientRect()

      this.mentionCardLeft = x
      this.mentionCardTop = y
      
      this.mentionCardOn = true

    }

  }
}
</script>

<style scoped>
.viewer-dark {
 border: thin solid rgba(255, 255, 255, 0.12);
 background-color: rgb(39, 39, 39);
}

.viewer-light {
 border: thin solid rgba(0, 0, 0, 0.12);
 background-color: rgba(202, 199, 199, 0.12);
}

.viewer-lighter {
  border: thin solid rgba(0, 0, 0, 0.12);
  background-color: rgb(255, 255, 255);
}
</style>