<template>
  <v-form :ref="`pdf-form-${fileIndex}`" style="width: 100%;">
    <v-container>
      <v-row>
        <v-col xs="6" sm="6" md="4" class="d-flex" :style="`align-items: center; justify-content: center;`">
          <ImageUpload
            :onUpload="onUpload"
            :size="{
              width: 150,
              height: 250
            }"
            :imagePlaceholderUrl="thumbnailObj.mediaSrc"
            class="mx-2 my-2"
          />  
        </v-col>
        <v-col xs="6" sm="6" md="4" class="d-flex" :style="`align-items: center; justify-content: center;`">
          <div>
            <v-text-field
              v-model="title"
              filled
              :background-color="textBackgroundColor"
              placeholder="Title"
              class="hs-rounded-text"
              :rules="[v => !!v || 'The title is required.']"
            />
            <v-textarea
              v-model="about"
              :background-color="textBackgroundColor"
              filled
              hide-details
              placeholder="About"
              class="hs-rounded-text"
            />
          </div>
        </v-col>
        <v-col xs="12" sm="12" md="4" class="d-flex" :style="`width: 100%; align-items: center; justify-content: center;`">
          <div style="width: 100%;">
            <PrivacySettings @settings="setPrivacySettings" />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
  import ImageUpload from '@components/Image/ImageUpload.vue'
  import { MediaURL, PrivacySettings } from '@components'
  import { mapGetters, mapMutations } from 'vuex'
  import API from '@api'
  import axios from 'axios'

  export default {
    components: {
      ImageUpload,
      PrivacySettings
    },

    props: {
      file: {
        type: Object,
      },
      fileIndex: Number,
      setParentFile: Function
    },

    data: () => ({
      title: '',
      about: '',
      config: null,
      thumbnailFile: null,
      pdfDoc: null,
      thumbnailObj: {},
      embeddingStatus: ''
    }),

    watch: {
      $data: {
        handler(value) {
          this.setParentFile({
            ...value,
            pdfFile: this.file
          })
        },
        deep: true,
      }
    },
    created() {
      this.getPdfTranscription = MediaURL.getPdfTranscription.bind(this);
      this.getMediaUrl = MediaURL.getMediaUrl.bind(this);
      this.createPDFThumbnail = MediaURL.createPDFThumbnail.bind(this);
      this.fillFormWithFileData(this.file)
    },

    computed: {
      ...mapGetters({
        anna: 'anna',
        user: 'user',
        myProfileId: 'profile/id',
        currentFolder: 'folders/currentFolder'
      }),
      textBackgroundColor() {
        return this.$vuetify.theme.dark
          ? '#1a1a1a'
          : 'grey lighten-4'
      },
    },

    methods: {
      onUpload(file) {
        this.thumbnailFile = file
      },
      setPrivacySettings(config) {
        this.config = config
      },
      async fillFormWithFileData(pdf) {
        try {
          this.loading = true

          const {pdfDoc, transcription} = await this.getPdfTranscription(pdf)
          const transcriptionLen = transcription.map(({ textContent }) => textContent).join('').length
          this.pdfDoc = pdfDoc

          if (transcriptionLen === 0) {
            this.embeddingStatus = 'UNDEFINED'
          } else {
            this.summarize(transcription, 'title')
            this.summarize(transcription, 'about')
          }

          this.numPages = this.pdfDoc.numPages
          this.thumbnailObj = await this.createPDFThumbnail(this.file, this.pdfDoc, 1)
          this.loading = false

        } catch(err) {
          this.$emit('err', err)
          this.$emit('close')
        } finally {
          
        }
      },

      async summarize(transcription, type) {
        const response = await fetch(`${process.env.VUE_APP_LANGCHAIN_API}/hisolver_tasks`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: false,
          body: JSON.stringify({
            query: JSON.stringify(transcription.slice(0, 10)),
            model_number: 1,
            stream: true,
            task: `summarize_${type}`
          })
        })
        const reader = response.body.getReader();

        let loop = true;
        const decoder = new TextDecoder("utf-8");
        while(loop) {
          const {done, value} = await reader.read();
          if(done) {
            loop = false;
          } else {
            const chunkAsString = decoder.decode(value);

            if (chunkAsString != '\u0003')
              this.$data[type] += chunkAsString;
            else break;
          }
        }
      },

      
    }
  }
</script>