<template>
  <v-footer
    v-show="!loading"
    dark
    width="100%"
    padless
  >
    <v-card
      flat
      tile
      width="100%"
      color="#332058"
      class="white--text text-center"
    >
      <v-card-text>
        <v-btn
          v-for="media in socialMedia"
          :key="`socialMedia_${media.url}`"
          class="mx-4 white--text"
          target="_blank"
          :href="media.url"
          icon
        >
          <v-icon
            v-if="media.icon != 'tiktok' && media.icon != 'x'"
            size="24px"
            v-text="$data[media.icon]"
          />

          <v-icon v-else-if="media.icon === 'tiktok'" size="18px">
            $tiktok
          </v-icon>

          <v-icon
            v-else
            size="18px"
          >
            $x
          </v-icon>
        </v-btn>

        <v-menu
          v-model="menuOn"
          :close-on-content-click="true"
          offset-y
          class="my-2"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="white"
              text
              icon
              v-bind="attrs"
              v-on="on" 
            >
              <v-icon size="24px">
                {{ mdiTranslate }}
              </v-icon>
            </v-btn>
          </template>
          
          <v-list>
            <v-list-item
              v-for="(lang, idx) in langs"
              :key="idx"
              @click="setLang(idx)"
            >
              <v-list-item-title>
                {{ lang.title }}
              </v-list-item-title>

              <v-list-item-avatar size="30">
                <v-img
                  v-if="idx === 0"
                  src="@assets/svgs/united-states.svg"
                  />
                  <v-img
                  v-else
                  src="@assets/svgs/brazil.svg"
                />
              </v-list-item-avatar>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-text>

      <v-divider />

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} © <strong>HiSolver</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import {
  mdiTranslate,
  mdiLinkedin,
  mdiInstagram
} from '@mdi/js'

export default {
  created() {
    const search = window.location.search;
    if (
      (search.includes("code=") || search.includes("error=")) &&
      search.includes("state=")
    ) {
      this.loading = true
    }
  },
  
  data: () => ({
    mdiTranslate,
    mdiLinkedin,
    mdiInstagram,

    loading: false,
    menuOn: false,
    langs: [
      {
        title: 'English',
        img: '@assets/svgs/united-states.svg'
      },
      {
        title: 'Português',
        img: '@assets/svgs/brazil.svg'
      }
    ],
    socialMedia: [
      {
        icon: 'tiktok',
        url: 'https://www.tiktok.com/@hisolver'
      },
      {
        icon: 'x',
        url: 'https://twitter.com/hisolver'
      },
      {
        icon: 'mdiInstagram',
        url: 'https://www.instagram.com/hisolver/'
      }
    ],
  }),

  computed: {
    ...mapGetters({
      locale: 'locale'
    })
  },

  methods: {
    ...mapMutations({
      setLocale: 'locale'
    }),
    
    setLang(idx) {
      const locale = idx
        ? 'pt-br'
        : 'en'
      
      this.setLocale(locale)
    }
  }
}
</script>
