import { withLayout } from '@utils'
import Locked from '@layouts/Locked'

const Settings = () => import('@views/Settings')

export default {
  path: 'settings',
  component: {
    render: h => h('router-view')
  },
  children: [
    {
      path: '',
      name: 'settings.home',
      component: withLayout(Settings, Locked),
      meta: {
        locked: true,
        app: 'settings'
      }
    }
  ]
};
