import { withLayout } from '@utils'
import Study from '@layouts/Study'
const Folders = () => import('@views/Folders')

export default {
  path: 'folders',
  component: {
    render: h => h('router-view')
  },
  children: [
    {
      path: '',
      name: 'folders',
      component: withLayout(Folders, Study),
      meta: {
        app: 'folders',
        locked: true
      }
    }
  ]
};
