<template>
  <v-row
    class="align-center justify-center"
    :style="containerStyle"
  >
    <v-slide-x-transition hide-on-leave>
      <div
        class="hs-tabs"
        ref="hsTabs"
      >
        <a
          v-for="(tab, i) in tabs"
          :key="`tab_${i}`"
          :class="{'hs-tabs__button': true, 'active': activeIndex === i}"
          @click="setActive(i)"
        >
          <v-badge
            offset-y="8"
            offset-x="-1"
            color="red"
            :content="tab.counter"
            :value="tab.counter"
          >
            {{ $t(tab.name) }}
          </v-badge>
        </a>
      </div>
    </v-slide-x-transition>
  </v-row>
</template>

<script>
/*
* Works properly with 3 or 4 tabs only
*/

export default {
  props: {
    startIndex: {
      type: Number,
      default: 0
    },
    tabs: {
      type: Array,
      default() {
        return [{
          name: 'Friends',
          counter: 0
        }, {
          name: 'Rooms',
          counter: 0
        }]
      }
    }
  },

  data() {
    return {
      activeIndex: 0,
      outerPadding: "5px",
      borderRadius: "12px",
      backgroundOffset: 0,
    }
  },

  computed: {
    containerStyle() {
      return {
        "--outer-padding": this.outerPadding,
        "--border-radius": this.borderRadius,
        "--background-offset": this.backgroundOffset,
        "--tabs-count": this.tabs.length.toString()
      };
    }
  },

  mounted() {
    // We use this to start with references on the study page
    this.setActive(this.startIndex);
    window.addEventListener('resize', this.updatePosition);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.updatePosition);
  },

  methods: {
    setActive(index) {
      this.activeIndex = index;
      this.updatePosition();
      this.$emit('setActive', index);
    },

    updatePosition() {
      const el = this.$refs.hsTabs;
      const outerPadding = Number(this.outerPadding.replace("px", ""));
      const position = this.activeIndex * ((el.offsetWidth - 2 * outerPadding) / this.tabs.length) + "px";
      this.backgroundOffset = position;
    },
  }
}
</script>

<style lang="scss" scoped>
.input-dark {
 color: black;
}

.input-light {
  color: white;
}

.theme--dark .hs-tabs {
  background-color: #3b3b3bfa;

  &::before {
    background-color: #212121;
  }

  &__button {
    color: #cacaca;

    &:focus {
      box-shadow: inset 0 0 0 2px #212121;
    }

    &.active {
      color: #ffffff;
    }
  }
}

.hs-tabs {
  position: relative;
  display: flex;
  width: 100%; // adjust this for the desired width
  justify-content: space-between;
  background-color: #f2f2f2;
  border-radius: var(--border-radius);
  padding: var(--outer-padding);
  overflow: hidden;

  &::before {
    z-index: 0;
    content: "";
    position: absolute;
    transform: translateY(-50%) translateX(var(--background-offset));
    top: 50%;
    width: calc(100% / var(--tabs-count) - var(--outer-padding));
    height: calc(100% - var(--outer-padding) * 2);
    background-color: #2121211b;
    border-radius: var(--border-radius);
    transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &__button {
    text-align: center;
    flex: 1;
    font-weight: 500;
    padding: 0.5em; // reduce the padding to decrease the height
    font-size: 0.8rem; // adjust the font size as needed
    position: relative;
    border-radius: var(--border-radius);
    transition: color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
    user-select: none;
    color: #212121;

    &:focus {
      box-shadow: inset 0 0 0 2px #212121;
    }
  }
}
</style>
