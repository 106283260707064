import Vue from 'vue';
import App from '@/App';
import store from '@store';
import vuetify from '@plugins/vuetify';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@plugins/vue-fuse';
import '@plugins/vue-socket.io';
import '@plugins/vue-clipboard2';
import '@plugins/vue-overlayscrollbars';
import '@plugins/vue-katex';
import '@plugins/mask';
import i18n from '@plugins/vueI18n';
import { createRouter } from '@plugins/router';
import '@utils/hide';
import mitt from 'mitt';

import { Auth0Plugin } from '@plugins/auth0';

Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  redirectUri: process.env.VUE_APP_AUTH0_CALLBACK_URL,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetPath
        ? appState.targetPath
        : window.location.pathname
    );
  },
});

const router = createRouter()
Vue.config.productionTip = false

const app = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
})

// TODO: remove mitt, use vuex (pinia on the next version) instead
app.$emitter = mitt()

app.$mount('#app')