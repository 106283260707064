<template>
  <v-dialog
    v-model="open"
    width="auto "
    :fullscreen="$vuetify.breakpoint.xsOnly"
    persistent
  >
    <v-card>
      <v-container style="width: 500px;">
        <v-card-title>
          {{ $t('onboarding.title') }}
        </v-card-title>
        <v-card-subtitle>
          {{ $t('onboarding.subtitle') }}
        </v-card-subtitle>
        <Scrollbar thin="thin" style="max-height: 410px;">
          <div class="px-8 py-4 height100">
            <v-form ref="form" @submit.prevent="submit" class="height100" style="display: grid;">
              <!-- <v-row>
                <SelectRole v-model="form.roleName.value" />
              </v-row>

              <div v-if="form.roleName.value">
                <v-row>
                  <SelectEducationalLevel
                    v-model="form[getModelName('educationalLevel')].value"
                    :multiple="form.roleName.value === 'teacher'"
                  />
                </v-row>
                <v-row>
                  <SelectUniversity
                    v-model="form[getModelName('university')].value"
                    :multiple="form.roleName.value === 'teacher'"
                  />
                </v-row>
              </div>

              <div class="my-2"></div> -->

              <v-row>
                <v-chip
                  v-for="(tag, index) in tags"
                  :key="tag._id"
                  @click="handlePreferences(tag, index)"
                  :color="preferences.includes(tag.name) ? 'primary' : ''"
                  :style="'margin: 3px;'"
                >
                  {{ $t(`onboarding.preferences.tags.${tag.name.replace(/\ /g, '')}`) || tag.name }}
                </v-chip>   
              </v-row>

              <div class="my-6"></div>

              <v-row justify="end" style="margin-top: auto">
                <v-btn
                  :color="'primary'"
                  type="submit"
                  class="hs-rounded-12"
                  :disabled="preferences.length === 0"
                >
                  {{ $t('save') }} ☺️
                </v-btn>
              </v-row>
            </v-form>
          </div>
        </Scrollbar>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import SelectRole from '@components/Input/SelectRole'
  import SelectEducationalLevel from '@components/Input/SelectEducationalLevel'
  import SelectUniversity from '@components/Input/SelectUniversity'
  import OnBoarding from '@api/onboarding'
  import Tag from '@api/tag'
  import University from '@api/university'

  import { Scrollbar } from '@components/App'

  export default {
    components: {
      Scrollbar,
      SelectRole,
      SelectEducationalLevel,
      SelectUniversity
    },

    async created() {
      if (this.pendingOnBoarding.length && this.user) {
        this.onBoardingService = OnBoarding(this.user.id)
        this.tagService = Tag(this.user.id)
        this.universityService = University(this.user.id)
        this.tags = await this.tagService.list()
        this.universities = await this.universityService.list()
        this.open = true
      }
    },

    data() {
      return {
        onBoardingService: null,
        tagService: null,
        universityService: null,
        form: this.initialForm(),
        requiredFieldErrorMessage: this.$t('form.error.required'),
        tags:[],
        universities: [],
        preferences: [],
        open: false,
        submitting: false
      }
    },

    watch: {
      pendingOnBoarding: {
        deep: true,
        handler() {
          if (process.env.NODE_ENV === 'development') return;
          if (this.pendingOnBoarding.length) this.open = true
          else this.open = false
        }
      }
    },

    computed: {
      ...mapGetters({
        user: 'user',
        pendingOnBoarding: 'pendingOnBoarding',
        myProfileId: 'profile/id',
      }),
    },

    methods: {
      ...mapActions({
        SET_PENDING_ONBOARDING: 'SET_PENDING_ONBOARDING',
        setOnboardingDone: 'profile/SET_ONBOARDING_DONE',
      }),

      getModelName(model) {
        const role = this.form.roleName.value
        return {
          student: {
            educationalLevel: 'studentEducationalLevel',
            university: 'studentUniversity'
          },
          teacher: {
            educationalLevel: 'teacherEducationalLevels',
            university: 'teacherUniversities'
          }
        }[role][model]
      },

      getRules(model) {
        const role = this.form.roleName.value
        if (!role) return []
        return {
          student: {
            educationalLevel: this.form.studentEducationalLevel.rules,
            university: this.form.studentUniversity.rules
          },
          teacher: {
            educationalLevel: this.form.teacherEducationalLevels.rules,
            university: this.form.teacherUniversities.rules
          }
        }[role][model]
      },

      handlePreferences(tag) {
        if (this.preferences.includes(tag.name)) {
          this.preferences.splice(this.preferences.indexOf(tag.name), 1)
        } else {
          this.preferences.push(tag.name)
        }
      },

      bodyByRole() {
        const roleName = this.form.roleName.value;
        const body = {
          roleName,
          profileId: this.myProfileId
        };
        if (roleName === 'student') {
          body.educationalLevel = this.form.studentEducationalLevel.value
          body.universityId = this.form.studentUniversity.value
        } else {
          body.educationalLevels = this.form.teacherEducationalLevels.value
          body.universityIds = this.form.teacherUniversities.value
        }

        return body
      },

      async submit() {
        if (!this.$refs.form.validate())
          return;
        
        this.submitting = true

        try {
          const { handleOnBoardings } = this.onBoardingService
          const { onBoardingDone } = await handleOnBoardings({
            profileId: this.myProfileId,
            tagNames: this.preferences,
            ...this.bodyByRole()
          })

          this.setOnboardingDone(onBoardingDone)
          this.SET_PENDING_ONBOARDING([])

        } catch (err) {
          console.log(err)

        } finally {
          this.submitting = false
          this.open = false
        }        
      },

      setForm(field, value) {
        this.form[field] = value
      },

      initialForm() {
        return {
          roleName: {
            value: '',
            // rules: [
            //   v => !!v || this.requiredFieldErrorMessage
            // ]
          },
          studentEducationalLevel: {
            value: '',
            // rules: [
            //   v => {
            //     if (this.form.roleName.value !== 'student') return true
            //     return !!v || this.requiredFieldErrorMessage
            //   }
            // ]
          },
          teacherEducationalLevels: {
            value: [],
            // rules: [
            //   v => {
            //     if (this.form.roleName.value !== 'teacher') return true
            //     return !!v.length || this.requiredFieldErrorMessage
            //   }
            // ]
          },
          studentUniversity: {
            value: '',
            // rules: [
            //   v => {
            //     if (this.form.roleName.value !== 'student') return true
            //     return !!v || this.requiredFieldErrorMessage
            //   }
            // ]
          },
          teacherUniversities: {
            value: [],
            // rules: [
            //   v => {
            //     if (this.form.roleName.value !== 'teacher') return true
            //     return !!v.length || this.requiredFieldErrorMessage
            //   }
            // ]
          }
        }
      }
    }
  }
</script>

<style scoped>
/* Deprecated in Vue 3 */
::v-deep .v-dialog {
  border-radius: 24px !important;
}
</style>
