const langchainService = async (
  path,
  options,
  timeout = 2000,
) => {
  const {
    VUE_APP_LANGCHAIN_API,
    VUE_APP_LANGCHAIN_API2,
    VUE_APP_LANGCHAIN_API3
  } = process.env

  const API_URLS = [
    VUE_APP_LANGCHAIN_API,
    VUE_APP_LANGCHAIN_API2,
    VUE_APP_LANGCHAIN_API3
  ]

  const request = async (urls) => {
    for (let i = 0; i < urls.length; i++) {
      const url = urls[i];
      const controller = new AbortController()
      const timeoutId = setTimeout(() => controller.abort(), timeout)
      try {
        const response = await fetch(
          url,
          {
            ...options,
            signal: controller.signal
          }
        )
        // console.log(`Request ${i+1} succeeded.`, response)
        clearTimeout(timeoutId)
        return response;
      } catch (err) {
        clearTimeout(timeoutId)
        // console.log(`Request ${i+1} failed.`, err)
        if (i === urls.length - 1) throw err
      }
    }
  }

  return request(
    API_URLS.map(url => `${url}/${path}`)
  )
}

module.exports = langchainService
