var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:(("position: relative; width: 100%; height: " + _vm.outerHeight + "px;"))},[_c('SnackBar',{attrs:{"snackMsg":_vm.snackMsg,"snackOn":_vm.snackOn,"snackTop":true},on:{"close":function($event){_vm.snackOn = false}}}),_c('v-container',{class:_vm.containerClass,style:(("height: " + _vm.height + "px; position: absolute; z-index: 1;"))},[(_vm.showScrollDown)?_c('v-btn',{style:(("\n        position: absolute;\n        z-index: 3;\n        right: 30px;\n        bottom: " + (_vm.annaChamber ? -6 : 12) + "px;\n      ")),attrs:{"fab":"","dark":"","small":"","color":_vm.scrollDownColor},on:{"click":function($event){return _vm.scrollBottom(true)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(_vm.mdiArrowDown)+" ")])],1):_vm._e(),_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"},{name:"scroll_bottom",rawName:"v-scroll_bottom"}],ref:"chatContainer",class:{
        'hs-custom-scroll px-4 pr-8 pl-6': true,
        'fill-height': _vm.messages.length === 0
      },style:(("\n        opacity: " + _vm.chatOpacity + ";\n        transition: opacity 0.5s ease-in-out;\n        scroll-behavior: " + _vm.scrollBehavior + ";\n        max-height: " + (_vm.height - 2) + "px;\n        overflow-y: auto;\n        overflow-x: hidden;")),attrs:{"id":"chatContainer"},on:{"wheel":_vm.handleWheel,"scroll":_vm.handleScroll}},[_c('v-fade-transition',{attrs:{"hide-on-leave":""}},[(_vm.appChamber && _vm.messages.length === 0)?_c('v-row',{attrs:{"justify":"center"}},[(_vm.locale === 'en')?_c('v-img',{staticStyle:{"opacity":"0.5","height":"auto","max-width":"70%","transform":"translateX(13%)"},attrs:{"src":require("@assets/images/Anna_chamber_overlay_en.png")}}):_c('v-img',{staticStyle:{"opacity":"0.5","height":"auto","max-width":"70%","transform":"translateX(13%)"},attrs:{"src":require("@assets/images/Anna_chamber_overlay_pt-br.png")}})],1):_vm._e()],1),_vm._l((_vm.messages),function(message,i){return _c('div',{key:("message_" + (message.id) + "_room_" + (_vm.room.id)),ref:("message_" + (message.id) + "_room_" + (_vm.room.id)),refInFor:true},[(i > 0)?_c('div',{class:_vm.msgGap(i)}):_vm._e(),_c('v-row',{attrs:{"justify":"center"}},[_c('DateChip',{staticClass:"my-2",attrs:{"messageDate":message.createdAt,"beforeMessageDate":_vm.getPrevMsg(i) ? _vm.getPrevMsg(i).createdAt : null}})],1),_c('v-row',{attrs:{"align":"start","justify":message.sender._id === _vm.myProfileId ? 'end' : 'start'}},[_c('Message',{attrs:{"chat":_vm.conversation,"message":message,"prevMsg":_vm.getPrevMsg(i),"nextMsg":_vm.getNextMsg(i),"index":i,"streaming":_vm.annaStreaming && message.STREAMING,"singleSource":_vm.singleSource,"loadingSolution":_vm.loadingSolution && i === _vm.messages.length - 1,"messagesLength":_vm.messages.length},on:{"choose":function($event){return _vm.sendMsg({
              content: $event.choice,
              question: $event.question,
              solution: $event.solution,
              type: 'test_choice'
            })},"toggleLatex":_vm.toggleLatex,"setReference":function($event){return _vm.$emit('setReference', $event)},"setParams":_vm.setChatParams}})],1)],1)})],2)],1),_c('div',{style:(("position: sticky; z-index: 2; top: " + _vm.innerTop + "px;"))},[_c('v-row',{staticClass:"px-8",staticStyle:{"position":"relative"},attrs:{"justify":"start","align":"end"}},[_c('span',{class:{
        'grey--text text--darken-1 font-weight-medium body-2 mr-1': _vm.annaThinking,
        'transparent--text body-2': !_vm.annaThinking
      }},[_vm._v(" "+_vm._s(_vm.persona.name || 'Anna')+" is thinking ")]),(_vm.annaThinking)?_c('v-progress-linear',{staticStyle:{"width":"10%"},attrs:{"stream":"","color":"grey darken-1","buffer-value":"0"}}):_vm._e(),_c('v-spacer'),(_vm.showQuestionGen)?_c('div',{staticStyle:{"right":"5%","position":"absolute","margin-bottom":"7px","justify-self":"end","display":"flex","flex-direction":"row","align-items":"center","justify-content":"end"}},[_c('v-btn',{staticStyle:{"border-style":"solid","border-width":"1px","border-radius":"12px","backdrop-filter":"blur(5px)","background-color":"rgba(64, 64, 64, 0.1)"},attrs:{"elevation":"0","large":"","disabled":_vm.annaStreaming},on:{"click":function($event){_vm.sendMsg({
            content: _vm.$t('giveMeAQuestion'),
            tools: ['question_gen'],
            mentions: [],
            type: 'test_trigger'
          })}}},[_vm._v(" "+_vm._s(_vm.$t('giveMeAQuestion'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" "+_vm._s(_vm.mdiSend)+" ")])],1)],1):_vm._e()],1),_c('TextArea',{attrs:{"lighterViewerOn":true,"label":'Message...',"loading":_vm.loading,"autoCall":_vm.autoCall,"conversation":_vm.room.id,"menuClass":_vm.menuClass,"locked":_vm.annaStreaming,"streaming":_vm.annaStreaming},on:{"heightChange":function($event){return _vm.$emit('heightChange', $event)},"setStudy":function($event){return _vm.sendMsg({
        content: null,
        type: 'chat_study'
      })},"stop":function($event){_vm.stopStreaming = true},"setOptions":function($event){return _vm.sendMsg({
        content: null,
        type: 'chat_options'
      })},"submit":_vm.onSubmit}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }