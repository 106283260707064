export default (array) => {
  let markdownText = "";

  for (let item of array) {
    switch (item.type) {
      case "header":
        markdownText += "#".repeat(item.data.level) + " " + item.data.text + "\n\n";
        break;

      case "paragraph":
        markdownText += item.data.text + "\n\n";
        break;

      case "image":
        markdownText += `![${item.data.caption}](${item.data.url})` + "\n\n";
        break;

      default:
        console.warn("Unrecognized type:", item.type);
    }
  }
  
  // Replace HTML bold tags with markdown equivalent
  markdownText = markdownText.replace(/<b>(.*?)<\/b>/g, '**$1**');

  return markdownText;
}