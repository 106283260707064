<template>
  <v-list>
    <v-subheader
      class="subtitle-1 px-0"
      v-text="'Options'"
    />
    <v-list-item
      v-for="(config, idx) in configs"
      :key="`config_${idx}`"
      class="px-0"
    >
      <v-list-item-content>
        <v-list-item-title 
          :class="`text-h${textSize} font-weight-light`"
          v-text="config.title"
        />
        <v-list-item-subtitle
          class="grey--text text--darken-1" 
          v-text="config.description"
        />
      </v-list-item-content>
      <v-list-item-action>
        <v-switch 
          inset
          color="primary"
          v-model="config.value"
          @change="emitSettings"
        />
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    textSize: {
      type: Number,
      default: 5
    }
  },

  data() {
    return {
      configs: [
        {
          title: 'Bundle',
          text: 'Mark this to create a bundle. Unmark it to send separate content.',
          value: true
        },
        {
          title: 'Community Content',
          text: 'Mark this to choose Communities to add this content to.',
          value: false
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      user: 'user'
    })
  },

  watch: {
    configs: {
      handler(contentSettings) {
        this.$emit('setContentSettings', contentSettings)
      },
      deep: true
    }
  },

  methods: {
    emitSettings() {
      const [isAnonymous, isPublic, isUnlisted] = this.configs.map(({value}) => value)
      this.$emit('settings', { isAnonymous, isPublic, isUnlisted })
    }
  }
}
</script>