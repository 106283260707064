<template>
  <v-row align="center" justify="start" class="px-3">
    <v-menu
      v-model="menuOn"
      :close-on-content-click="true"
      offset-y
      class="my-2"
      max-width="230"
      bottom
    >
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on" 
          small
          elevation="0"
          :class="{ 'grey': true, 'darken-3': $vuetify.theme.dark, 'lighten-3': !$vuetify.theme.dark }"
          style="border-radius: 8px !important; height: 2.6em;"
        >
          <v-icon small left>
            {{ $vuetify.theme.dark ? mdiFolder : mdiFolderOutline }}
          </v-icon>
          <span style="width: 60px;">{{ slicedText }}</span>
          <v-icon small right>
            {{ menuOn ? mdiChevronUp : mdiChevronDown }}
          </v-icon>                      
        </v-btn>
      </template>

      <v-list :class="{ 'px-2': true, 'grey darken-4': $vuetify.theme.dark }">
        <v-list-item
          class="clickable rounded-list-item"
          @click="$router.push('/folders')"
        >
          <v-list-item-title>
            {{ $t('folderManager') }}
          </v-list-item-title>

          <v-list-item-action>
            <v-btn icon>
              <v-icon>
                {{ mdiFolderMultipleOutline }}
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <div class="my-1"></div>

        <span style="display: flex; flex-direction: row; align-items: center;" class="text-caption">
          <span class="px-3">
            {{ $t('myFolders') }}
          </span>
          <v-divider />
        </span>

        <Scrollbar v-if="myFolders.length > 0" :basic="true" style="max-height: 200px;">
          <v-list-item
            v-for="(folder, i) in myFolders"
            :key="`menu_folder_${i}`"
            class="clickable rounded"
            @click="setFolder(folder)"
          >
            <v-list-item-title>
              {{ folder.name }}
            </v-list-item-title>
          </v-list-item>
        </Scrollbar>
      </v-list>
    </v-menu>

    <div class="mx-1"></div>

    <div v-on="on" :style="iconContainerStyle" class="hs-rounded-lg">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            small
            icon
            :class="{
              'red--text text--lighten-2': rag && $vuetify.theme.dark,
              'red--text text--darken-4': rag && !$vuetify.theme.dark
            }"
            @click="SET_RAG"
          >
            <v-icon small>
              {{ rag ? mdiClose : mdiLibraryOutline }}
            </v-icon>
          </v-btn>
        </template>
        <span>
          {{ rag ? 'Reset sources' : 'Chat with sources' }}
        </span>
      </v-tooltip>
    </div>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { Scrollbar } from '@components/App'
import {
  mdiFolder,
  mdiFolderOutline,
  mdiChevronDown,
  mdiClose,
  mdiLibraryOutline,
  mdiChevronUp,
  mdiFolderMultipleOutline
} from '@mdi/js'
import API from '@api'

export default {
  components: {
    Scrollbar
  },

  async created() {
    if (!this.user) return;

    if (this.currentFolder) this.folderName = this.currentFolder.name
    else this.folderName = 'My Library'

    const myFolders = await API().get(`folders/${this.user.id}`, {
      params: {
        query: {
          sender: {
            $eq: this.myProfileId
          },
          folder: {
            $exists: false
          }
        },
        sort: {
          createdAt: -1
        }
      }
    })

    myFolders.unshift({
      id: null,
      name: 'My Library'
    })

    this.SET_MY_FOLDERS(myFolders)
  },

  data: () => ({
    mdiFolder,
    mdiFolderOutline,
    mdiChevronUp,
    mdiChevronDown,
    mdiClose,
    mdiLibraryOutline,
    mdiFolderMultipleOutline,
    folderName: 'My Library',
    menuOn: false,
    searchOn: false,
    search: ''
  }),

  watch: {
    currentFolder(folder) {
      if (folder) this.folderName = folder.name
      else this.folderName = 'My Library'
    }
  },

  computed: {
    ...mapGetters({
      user: 'user',
      myProfileId: 'profile/id',
      folders: 'folders/folders',
      myFolders: 'folders/myFolders',
      currentFolder: 'folders/currentFolder',
      contents: 'folders/contents',
      rag: 'folders/rag'
    }),

    slicedText() {
      return this.folderName.length > 7 ?
        `${this.folderName.slice(0, 6)}...` : this.folderName;
    },

    iconContainerStyle() {
      return `
        height: 2em;
        width: 2em;
        background-color: ${this.rag ? '#B71C1C40' : this.btnColor};
        display: flex;
        align-items: center;
        justify-content: center;
      `;
    },

    btnColor() {
      return this.$vuetify.theme.dark ? '#3b3b3bfa' : '#EEEEEE'
    }
  },

  methods: {
    ...mapMutations({
      SET_MY_FOLDERS: 'folders/setMyFolders',
      SET_CURRENT_FOLDER: 'folders/setCurrentFolder',
      SET_FOLDERS: 'folders/setFolders',
      SET_CONTENTS: 'folders/setContents',
      SET_RAG: 'folders/setRag'
    }),

    async setFolder(folder) {
      this.folderName = folder.name

      if (!folder.id) {
        // back to My Library
        this.SET_CURRENT_FOLDER(null)
        this.SET_FOLDERS(this.myFolders)

        // sets My Library contents
        const myContents = await API().get(`contents/${this.user.id}`, {
          params: {
            query: {
              sender: {
                $eq: this.myProfileId
              },
              folder: {
                $exists: false
              }
            },
            sort: {
              createdAt: -1
            }
          }
        })
        this.SET_CONTENTS(myContents)
      } else {
        this.SET_CURRENT_FOLDER(folder)
        const subFolders = await API().get(`folders/${this.user.id}`, {
          params: {
            query: {
              folder: {
                $eq: folder.id
              }
            },
            sort: {
              createdAt: -1
            }
          }
        })
        this.SET_FOLDERS(subFolders)

        const folderContents = await API().get(`contents/${this.user.id}`, {
          params: {
            query: {
              folder: {
                $eq: folder.id
              }
            },
            sort: {
              createdAt: -1
            }
          }
        })
        this.SET_CONTENTS(folderContents)
      }

      if (this.$route.name === 'folders' && folder.id) {
        this.$router.replace(`folders?id=${folder.id}`)
      } else if (this.$route.name === 'folders') {
        this.$router.replace('folders')
      }
    },

    // If the browser doesn't find the "handler" (function to which the keydown event is assigned)
    // it crashes the web app
    doSearch() {},

    toggleSearch() {
      this.searchOn = !this.searchOn;
      if (this.searchOn) {
        this.$nextTick(() => {
          this.$refs.textInput.focus();
        });
      } else {
        this.search = '';
        this.$emit('resetSearch');
      }
    },
  }
}
</script>

<style scoped>
.no-outline:focus {
  outline: none;
}

.rounded-list-item::before {
  border-radius: 12px;
}
</style>