<template>
  <v-container class="pt-0">
    <v-fade-transition hide-on-leave>
      <v-row justify="center">
        <v-col>
          <input
            type="file"
            id="fileUpload"
            multiple
            accept=".mp3,.wav,.mpeg,.ogg,.png,.jpg,.jpeg,.gif,.svg,.webm,.mp4,.pdf,.csv"
            @change="dropHandler"
          />
          <div>
            <label for="fileUpload">
              <v-card
                outlined
                rounded="xl"
                height="114"
                :ripple="false"
                :color="uploadColor"
                style="cursor: pointer;"
                @mouseenter="mouseOver = true"
                @mouseleave="mouseOver = false"
                @drop.prevent="dropHandler"
                @dragover.prevent
                @dragenter="dragging = true"
                @dragleave="leaveTarget"
              >
                <v-container fill-height>
                  <v-row 
                    justify="center"
                    align="center"
                  >
                    <v-icon x-large>
                      {{ mdiCloudUpload }}
                    </v-icon>
                  </v-row>

                  <v-row
                    justify="center"
                    align="center"
                  >
                    <span class="text-h6 grey--text text--darken-1 font-weight-regular">
                      Upload relevant data, homework or syllabus
                    </span>
                  </v-row>
                </v-container>
              </v-card>
            </label>
          </div> 
        </v-col>
      </v-row>
    </v-fade-transition>
  </v-container>
</template>

<script>
import { mdiCloudUpload } from '@mdi/js'
import { MediaURL } from '@components'
import { canvasToBlob } from '@utils'

const pdfjsLib = require('pdfjs-dist/legacy/build/pdf');
const worker = require('pdfjs-dist/legacy/build/pdf.worker.min')
window.pdfjsWorker = worker

export default {
  props: {
    src: {
      type: String,
      default: ''
    }
  },

  created() {
    this.getMimeType = MediaURL.getMimeType.bind(this);
    this.getVideoThumbnail = MediaURL.getVideoThumbnail.bind(this);
    this.getVideoDuration = MediaURL.getVideoDuration.bind(this);
    this.getMediaUrl = MediaURL.getMediaUrl.bind(this);
  },

  data() {
    return {
      mdiCloudUpload,
      
      dragging: false,
      mouseOver: false
    }
  },

  computed: {
    uploadColor() {
      return this.mouseOver || this.dragging
        ? this.$vuetify.theme.dark
        ? 'grey darken-4'
        : 'grey lighten-4'
        : ''
    }
  },

  methods: {
    async createPDFThumbnail(pdfFile, pdfDoc, pageNum) {
      
      const page = await pdfDoc.getPage(Number(pageNum))
      const canvas = document.createElement('canvas')
      const viewport = page.getViewport({ scale: 1.0 })
      const context = canvas.getContext('2d')
      
      canvas.height = viewport.height
      canvas.width = viewport.width
      
      const renderContext = {
        canvasContext: context,
        viewport: viewport
      }
  
      await page.render(renderContext).promise

      const blobData = await canvasToBlob(canvas);

      const [pdfName] = pdfFile.name.split('.');

      const thumbnailFile = new File([blobData], `${pdfName}.jpeg`, {
        type: 'image/jpeg'
      });

      const thumbnailObj = {
        mediaSrc: '',
        filename: '',
        type: ''
      }

      thumbnailObj.mediaSrc = URL.createObjectURL(thumbnailFile)
      thumbnailObj.filename = `${pdfName}.jpeg`
      thumbnailObj.type = blobData.type

      return thumbnailObj

    },

    async readPdfFile(pdf) {

      const pdfUrl = URL.createObjectURL(pdf)
      const pdfBlob = await fetch(pdfUrl).then(r => r.blob())
      const pdfBuffer = await pdfBlob.arrayBuffer()

      const pdfDoc = await pdfjsLib.getDocument(pdfBuffer).promise
      //this.numPages = this.pdfDoc.numPages

      return await this.createPDFThumbnail(pdf, pdfDoc, 1)

    },

    dragOverHandler(ev) {
      ev.preventDefault();
      
      if (this.user && this.$route.name != 'notebook.notepad') {
        this.overlayOn = true
      }
    },

    async dropHandler(ev) {

      // Prevent default behavior (Prevent file from being opened)
      ev.preventDefault();

      this.overlayOn = false

      const files = ev.type === 'change' ? ev.target.files : ev.dataTransfer.files

      let contentArray = []

      for (const file of files) {

        const mimeType = await this.getMimeType(file)
        let fileObj, thumbnailObj;

        switch(mimeType) {
          case 'image/png':
          case 'image/gif':
          case 'image/jpeg':
          case 'image/webp':

            fileObj = {
              mediaSrc: URL.createObjectURL(file),
              filename: file.name,
              type: mimeType
            }
            
            contentArray.push({
              name: '',
              caption: '',
              fileObj,
              type: 'image'
            })

            break;

          case 'video/mp4':
          case 'video/webm':

            const [videoName] = file.name.split('.');

            thumbnailObj = {
              mediaSrc: await this.getVideoThumbnail(file),
              filename: `${videoName}.jpeg`,
              type: 'image/jpeg'
            }

            const mediaSrc = URL.createObjectURL(file)

            fileObj = {
              mediaSrc,
              filename: file.name,
              type: mimeType,
              duration: await this.getVideoDuration(mediaSrc)
            }

            contentArray.push({
              name: '',
              caption: '',
              thumbnailObj,
              fileObj,
              type: 'video'
            })

            break;

          case 'audio/mpeg':
          case 'audio/wav':
          case 'audio/ogg':

            thumbnailObj = {
              mediaSrc: '',
              filename: '',
              type: ''
            }

            fileObj = {
              mediaSrc: URL.createObjectURL(file),
              filename: file.name,
              type: mimeType
            }
            
            contentArray.push({
              name: '',
              caption: '',
              fileObj,
              thumbnailObj,
              type: 'audio'
            })

            break;

          case 'application/pdf':

            thumbnailObj = await this.readPdfFile(file)

            fileObj = {
              mediaSrc: URL.createObjectURL(file),
              filename: file.name,
              type: 'application/pdf'
            }
            
            contentArray.push({
              name: '',
              caption: '',
              thumbnailObj,
              fileObj,
              type: 'pdf'
            })

            break;

          default:
            break;
        }

      }
      
      this.$emit('addMedia', contentArray)

    },

    leaveTarget (e) { 
      if (e.target.className === 'container fill-height')
        this.dragging = false
    }
  }
}
</script>