<template>
  <Scrollbar
    :thin="thin"
    :autoHide="autoHide"
    :flexible="true"
    :style="`height: ${rootHeight}; ${rootStyle}`"
    :class="rootClass"
    :onScrollDown="fetchMore"
  >
    <v-container :class="{
      'pagination-container': true,
      'pt-0': !paddingTop
    }" :style="`width: 100%;`">
      <v-row>
        <slot name="staticTitle"></slot>
      </v-row>

      <v-row>
        <slot name="staticSlot"></slot>
      </v-row>

      <v-row>
        <slot name="scrollTitle"></slot>
      </v-row>

      <v-row :style="rowClass">
        <slot
          v-for="(item, index) in items"
          name="child"
          :items="items"
          :item="item"
          :index="index"
        ></slot>
      </v-row>
    </v-container>
  </Scrollbar>
</template>

<script>
  import Scrollbar from './Scrollbar'
  import API from '@api'

  const defaultPagination = {
    limit: 10,
    skip: 0,
    pageSize: 10,
  }

  export default {
    components: {
      Scrollbar
    },

    props: {
      paddingTop: {
        type: Boolean,
        default: true
      },
      rowClass: {
        type: Object,
        default: () => ({})
      },
      requestData: {
        type: Object,
        default: () => ({
          url: '',
          params: {}
        })
      },
      countData: {
        type: Object,
        default: () => ({
          url: '',
          params: {}
        })
      },
      autoHide: {
        type: String,
        default: 'never'
      },
      thin: {
        type: Boolean,
        default: true
      },
      scrollStyle: {
        type: String,
        default: ''
      },
      rootHeight: {
        type: String,
        default: '80vh'
      },
      rootStyle: {
        type: String,
        default: ''
      },
      rootClass: {
        type: String,
        default: ''
      },
      setFatherItems: {
        type: Function,
        default: () => {}
      },
      customPagination: {
        type: Object,
        required: false,
      },
      entityName: String,
      forceUpdate: Object,
      itemToUnshift: Object,
      setLoading: {
        type: Function,
        default: () => {}
      }
    },

    data: () => ({
      items: [],
      fetching: false,
      count: 0,
      pagination: defaultPagination
    }),

    unmonted() {
      this.items = []
    },

    watch: {
      itemToUnshift: {
        deep: true,
        async handler(value) {
          this.items.unshift(value)
        }
      },
      forceUpdate: {
        deep: true,
        async handler(value, oldValue) {
          if (value !== oldValue) {
            this.items = await this.fetch(true)
            this.setCount()
          }
        }
      },
      items: {
        deep: true,
        handler(newItems) {
          if (this.setFatherItems && this.items && this.items.length)
            this.setFatherItems(newItems)            
        }
      },
      filter: {
        deep: true,
        handler() {
          this.items = this.items.filter(this.filter.handler)
        }
      },
      requestData: {
        deep: true,
        async handler(value, oldValue) {
          if (JSON.stringify(value) !== JSON.stringify(oldValue)) {
            this.items = await this.fetch(true)
            this.setCount()
          }
        }
      },

      countData: {
        deep: true,
        async handler(value, oldValue) {
          if (JSON.stringify(value) !== JSON.stringify(oldValue)) {
            this.setCount()
          }
        }
      },

      fetching: {
        handler(value) {
          this.setLoading(value)
        }
      }
    },

    async created() {
      if (this.customPagination) this.pagination = this.customPagination
      this.items = await this.fetch(true)
      this.setCount()
    },

    methods: {
      async setCount() {
        this.count = await API().get(this.countData.url, {
          params: this.countData.params || this.requestData.params
        })
      },

      async request() {
        const response = await API().get(this.requestData.url, {
          params: {
            ...this.requestData.params,
            pagination: {
              limit: this.pagination.limit,
              skip: this.pagination.skip
            },
          }
        })
        return response || []
      },

      async fetch(clearSkip = false) {
        if (this.fetching) return []

        this.fetching = true

        if (clearSkip) this.pagination.skip = 0
        
        let items = await this.request();
        if (items.length) {
          this.pagination.skip += this.pagination.pageSize
        }

        this.fetching = false
        return items
      },

      async fetchMore() {
        if (this.count === this.items.length) return
        let moreItems = await this.fetch()
        this.items = [...this.items, ...moreItems]
      },

      async fetchMoreOnScrollBottom(e) {
        const scrollTop = e.target.scrollTop
        const clientHeight = e.target.clientHeight
        const scrollHeight = e.target.scrollHeight
        if (scrollTop + clientHeight >= (scrollHeight - 2)) {
          this.fetchMore()
        }
      }
    }
  }
</script>

<style>
.pagination-container {
  /*
    Makes the Hall list design properly responsive
    by overriding a rule @media min width that sets
    the max-width to 900px  
  */
  max-width: 10000px;
}
</style>