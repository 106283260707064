var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('SnackBar',{attrs:{"snackMsg":_vm.snackMsg,"snackOn":_vm.snackOn,"snackTop":true},on:{"close":function($event){_vm.snackOn = false}}}),_c('ResetPwd',{attrs:{"dialog":_vm.dialog},on:{"snackMsg":function (msg) { _vm.snackMsg = msg; _vm.snackOn = true },"close":function($event){_vm.dialog = false}}}),_c('v-card',{staticClass:"hs-rounded-xl",attrs:{"outlined":""}},[_c('v-progress-linear',{attrs:{"active":_vm.loading,"indeterminate":"","color":"primary","absolute":"","top":""}}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-card-title',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t('signin_page.welcomeBack'))+" ")])],1),_c('v-divider'),_c('div',{staticClass:"my-4"}),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('OAuth',{attrs:{"type":"signin"},on:{"setUser":_vm.setUser}})],1),_c('div',{staticClass:"text-h6 text-center my-3"},[_vm._v("OR")]),_c('div',{staticClass:"px-5"},[_c('v-text-field',{staticClass:"hs-rounded-lg",attrs:{"rules":[function (v) { return /.+@.+\..+/.test(v) || _vm.$t('validation.email'); }],"validate-on-blur":"","append-icon":_vm.mdiEmail,"name":"email","label":"e-mail","type":"text","outlined":"","data-cy":"email-input"},on:{"input":_vm.formatEmail},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{staticClass:"hs-rounded-lg",attrs:{"required":"","append-icon":_vm.mdiLock,"name":"password","label":_vm.$t('signin_page.pwd'),"id":"password","type":"password","outlined":"","hide-details":"","data-cy":"password-input"},on:{"input":_vm.formatPassword},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('div',{class:{
          'px-1 py-3 text-subtitle-1 grey--text clickable': true,
          'text--darken-1': !_vm.$vuetify.theme.dark,
          'text--lighten-3': _vm.$vuetify.theme.dark
        },on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" "+_vm._s(_vm.$t('signin_page.forgotPwd'))+" ")]),_c('v-row',{staticClass:"px-3 pb-6",attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"hs-rounded-lg",attrs:{"large":"","block":"","color":"primary","type":"submit","data-cy":"login-button","loading":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t('signin_page.enter'))+" ")])],1),(false)?_c('v-row',{staticClass:"px-3 py-1",attrs:{"align":"center"}},[_c('span',{class:{
            'px-1 py-3 text-subtitle-1 grey--text': true,
            'text--darken-1': !_vm.$vuetify.theme.dark,
            'text--lighten-3': _vm.$vuetify.theme.dark
          },domProps:{"textContent":_vm._s(_vm.$t('signin_page.noAccount'))}}),_c('v-btn',{class:{ 'px-1 mx-2': true, 'primary--text': !_vm.$vuetify.theme.dark },attrs:{"text":"","to":"/signup"},domProps:{"textContent":_vm._s(_vm.$t('signup'))}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }