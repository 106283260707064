import { withLayout } from '@utils'
import Locked from '@layouts/Locked'

const Notifications = () => import('@views/Notifications')

export default {
  path: 'notifications',
  component: {
    render: h => h('router-view')
  },
  children: [
    {
      path: '',
      name: 'notification.list',
      component: withLayout(Notifications, Locked),
      meta: {
        locked: true,
        app: 'notification'
      }
    }
  ]
};
