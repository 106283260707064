<template>
  <v-card
    class="mx-auto"
    max-width="450"
    elevation="15"
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="overline mb-4">
          Inserir um link
        </div>

        <v-text-field
          v-model="linkText"
          validate-on-blur
          label="Texto do link"
          clearable
          outlined
          dense
        />

        <v-text-field
          v-model="linkUrl"
          validate-on-blur
          :prepend-inner-icon="mdiLink"
          label="Link (no formato https://link)"
          clearable
          outlined
          dense
        />
      </v-list-item-content>
    </v-list-item>

    <v-divider />

    <v-card-actions>
      <v-btn
        text
        :disabled="!linkUrl || !linkText"
        @click="submit"
      >
        ok
        <v-icon right>
          {{ mdiCheckboxMarkedOutline }}
        </v-icon>
      </v-btn>
      <v-spacer />
      <v-btn
        text
        @click="hideBox"
      >
        cancel
        <v-icon right>
          {{ mdiClose }}
        </v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { urlRegex } from '@utils/regex'
import { validateYoutubeUrl } from '@utils'

import {
  mdiLink,
  mdiCheckboxMarkedOutline,
  mdiClose
} from '@mdi/js'

export default {
  props: {
    linkCardOn: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      mdiLink,
      mdiCheckboxMarkedOutline,
      mdiClose,

      urlRegex,
      linkUrl: '',
      linkText: ''
    }
  },

  methods: {
    submit() {
      this.$emit('setLink', {
        linkUrl: this.linkUrl,
        linkText: this.linkText
      })

      if (validateYoutubeUrl(this.linkUrl)) {
        this.$emit('youtubeUrl', {
          youtubeUrl: this.linkUrl
        })
      }

      this.linkUrl = ''
      this.linkText = ''
    },
    hideBox() {
      this.link = ''
      if (this.linkCardOn) this.$emit('hideBox')
    }
  }
}
</script>