<template>
  <Scrollbar
    :darkBackground="true"
    :dynamicScrolling="dynamicScrolling"
    :scrollUpdateOn="false"
    class="pdf-menu__scrollbar"
    @scrolling="updateScrollBounds"
  >
    <div class="scrolling-document">
      <PDFScrollingPage
        v-for="page in pages"
        :key="page.pageNumber"
        v-bind="{page, clientHeight, scrollTop, focusedPage, enablePageJump}"
        v-slot="{isPageFocused, isElementFocused}"
        @page-jump="onPageJump"
      >
        <div class="mb-4">
          <slot v-bind="{page, isPageFocused, isElementFocused}" />
        </div>
      </PDFScrollingPage>

      <div
        v-visible="fetchPages"
        class="observer pb-1"
      />
    </div>
  </Scrollbar>
</template>

<script>
import PDFScrollingPage from '@components/PDF/ScrollingPage'
import Scrollbar from '@components/App/Scrollbar'
import visible from '@directives/visible'
import { mapMutations } from 'vuex'

export default {
  components: {
    Scrollbar,
    PDFScrollingPage
  },

  directives: {
    visible
  },

  props: {
    pages: {
      required: true
    },
    enablePageJump: {
      type: Boolean,
      default: false
    },
    currentPage: {
      type: Number,
      default: 1
    },
    isParentVisible: {
      default: true
    },
    dynamicScrolling: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      focusedPage: undefined,
      scrollTop: 0,
      clientHeight: 0
    }
  },

  computed: {
    pagesLength () {
      return this.pages.length
    }
  },

  methods: {
    ...mapMutations({
      setScrollTop: 'scrollTop'
    }),

    fetchPages (currentPage) {
      this.$emit('pages-fetch', currentPage)
    },

    onPageJump (scrollTop) {
      this.setScrollTop(scrollTop)
    },

    updateScrollBounds (scrollTop) {
      const {clientHeight} = this.$el
      this.scrollTop = scrollTop
      this.clientHeight = clientHeight
    }
  },

  watch: {
    isParentVisible: 'updateScrollBounds',

    pagesLength (count, oldCount) {

      if (oldCount === 0) {
        this.$emit('pages-reset')
      }

      // Set focusedPage after new pages are mounted
      this.$nextTick(() => {
        this.focusedPage = this.currentPage
      })
      
    },

    currentPage (currentPage) {
      
      if (currentPage > this.pages.length) {
        
        this.fetchPages(currentPage)

      } else {

        this.focusedPage = currentPage
      
      }

    }
  }
}
</script>