<template>

  <v-app-bar :class="barClass" :app="!invertScroll" dark fixed color="transparent" elevation="0">
    <v-row align="center" justify="center" :style="{ paddingLeft: '20vw', paddingRight: '21vw' }">
      
      
      <v-btn icon large @click="$router.push('/')" style="margin-right: 0;">
        <v-avatar size="40">
          <v-img src="@assets/images/hs-logo--white2.png" />
        </v-avatar>
      </v-btn>


      <v-btn @click="$router.push('/')" class="custom-font"
        style="font-size: 30px; margin-right: 20px; text-transform: none; letter-spacing: normal; padding-left: 0;" text>
        HiSolver
      </v-btn>

      <v-btn @click="$router.push('/about')" class="custom-font"
        style="font-size: 20px; margin-right: 20px; text-transform: none; letter-spacing: normal;" text>
        About
      </v-btn>

      <v-btn @click="$router.push('/')" class="custom-font"
        style="font-size: 20px; text-transform: none; letter-spacing: normal;" text>
        Blog
      </v-btn>







      <v-spacer />

      <v-btn large outlined style="border-radius: 12px;" class="mx-2" to="/signup">
        <span class="font-weight-bold" v-text="$t('signup')"></span>
      </v-btn>

      <v-btn large outlined style="border-radius: 12px;" class="mx-2" :to="enter">
        <span class="font-weight-bold" v-text="enterBtnText"></span>
      </v-btn>
    </v-row>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    scrollTop: {
      type: Number,
      default: 0
    },
    invertScroll: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      user: 'user'
    }),

    barClass() {
      return !this.invertScroll || this.scrollTop > 100 ? 'closedBar' : 'openBar'
    },

    enterBtnText() {
      return this.user
        ? this.$t('enterHiSolver')
        : this.$t('signin')
    },

    enter() {
      return this.user
        ? '/home'
        : '/signin'
    }
  }
}
</script>

<style scoped>
.closedBar {
  transform: translateY(-64px) !important;
  transition: transform .2s ease-in-out;
}

.openBar {
  transition: transform .2s ease-in-out;
}

.pointer {
  cursor: pointer;
}

.custom-font {
  font-family: 'Outfit', sans-serif;
  font-weight: 400;
}
</style>