export default {
  namespaced: true,

  state: {
    notesOn: false,
    libraryId: '',
    libraryType: ''
  },

  getters: {
    notesOn: ({ notesOn }) => notesOn,
    libraryId: ({ libraryId }) => libraryId,
    libraryType: ({ libraryType }) => libraryType
  },

  mutations: {
    SET_NOTES_ON: (state, notesOn) => state.notesOn = notesOn,
    SET_LIBRARY_ID: (state, libraryId) => {
      state.libraryId = ''

      setTimeout(() => {
        state.libraryId = libraryId
      })
    },
    SET_LIBRARY_TYPE: (state, libraryType) => state.libraryType = libraryType
  }
}