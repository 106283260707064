<template>
  <div>
    <v-footer
      dark
      padless
    >
      <v-card
        flat
        tile
        width="100%"
        class="darken-4 white--text text-center"
        color="#8729FE"
      >
        <v-card-text>
          <v-btn
            v-for="media in socialMedia"
            :key="`socialMedia_${media.url}`"
            class="mx-4 white--text"
            icon
            :href="media.url"
          >
            <v-icon
              v-if="media.icon != 'tiktok' && media.icon != 'x'"
              size="24px"
              v-text="$data[media.icon]"
            />

            <v-icon
              v-else-if="media.icon === 'tiktok'"
              size="18px"
            >
              $tiktok
            </v-icon>

            <v-icon
              v-else-if="media.icon === 'x'"
              size="18px"
            >
              $x
            </v-icon>
          </v-btn>
        </v-card-text>

        <v-card-text class="white--text pt-0">
          <v-row
            justify="center"
            align="center"
          >
            <v-btn
              v-for="link in links"
              :key="link"
              color="white"
              class="my-2"
              text
              :to="link.url"
              v-text="$t(`open.${link.text}`)"
            />

            <v-menu
              v-model="menuOn"
              :close-on-content-click="true"
              offset-y
              class="my-2"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon left>
                    {{ mdiTranslate }}
                  </v-icon>

                  {{ lang.title }}

                  <v-icon right>
                    {{ $data[langIcon] }}
                  </v-icon>
                </v-btn>
              </template>
              
              <v-list>
                <v-list-item
                  v-for="(lang, idx) in langs"
                  :key="idx"
                  @click="setLang(idx)"
                >
                  <v-list-item-title>{{ lang.title }}</v-list-item-title>

                  <v-list-item-avatar size="30">
                    <v-img
                      v-if="idx === 0"
                      src="@assets/svgs/united-states.svg"
                      />
                      <v-img
                      v-else
                      src="@assets/svgs/brazil.svg"
                    />
                  </v-list-item-avatar>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions class="white--text">
          <v-container>
            {{ new Date().getFullYear() }} © <strong>HiSolver</strong>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-footer>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import {
  mdiTranslate,
  mdiTwitter,
  mdiLinkedin,
  mdiInstagram,
  mdiChevronUp,
  mdiChevronDown
} from '@mdi/js'

export default {
  created() {
    const title = this.locale === 'pt-br'
      ? 'Português'
      : 'English'

    const img = this.locale === 'pt-br'
      ? '@assets/svgs/brazil.svg'
      : '@assets/svgs/united-states.svg'

    this.lang = { title, img }
  },

  data: () => ({
    mdiTranslate,
    mdiTwitter,
    mdiLinkedin,
    mdiInstagram,
    mdiChevronUp,
    mdiChevronDown,

    menuOn: false,
    lang: {},
    langs: [
      {
        title: 'English',
        img: '@assets/svgs/united-states.svg'
      },
      {
        title: 'Português',
        img: '@assets/svgs/brazil.svg'
      }

    ],
    socialMedia: [
      {
        icon: 'tiktok',
        url: 'https://www.tiktok.com/@hisolver'
      },
      {
        icon: 'x',
        url: 'https://twitter.com/hisolver'
      },
      {
        icon: 'mdiLinkedin',
        url: 'https://www.linkedin.com/company/hisolver/about/'
      },
      {
        icon: 'mdiInstagram',
        url: 'https://www.instagram.com/hisolver/'
      }
    ],
    links: [
      {
        text: 'aboutUs',
        url: '/about'
      }, 
      {
        text: 'terms',
        url: '/terms'
      },
      {
        text: 'contact',
        url: '/contact'
      }
    ]
  }),

  computed: {
    ...mapGetters({
      locale: 'locale'
    }),

    langIcon() {
      return this.menuOn
        ? 'mdiChevronDown'
        : 'mdiChevronUp'
    }
  },

  methods: {
    ...mapMutations({
      setLocale: 'locale'
    }),
    
    setLang(idx) {
      this.lang = this.langs[idx]

      const locale = idx
        ? 'pt-br'
        : 'en'
      
      this.setLocale(locale)
    }
  }
}
</script>