<template>
  <div>
    <template >
      <div
        v-for="(conversation, j) in conversations"
        :key="`conversation_${j}`"
      >
        <div class="my-4"></div>
        
        <ChatCard
          :conversation="conversation"
          :index="j + 1"
          @setCurrentConversation="setConversation(j)"
        />
      </div>
    </template>
  </div>
</template>

<script>
import ChatCard from '@components/Conversation/ChatCard'

export default {
  components: {
    ChatCard
  },

  props: {
    conversations: {
      type: Array,
      default: []
    },
    conversationIdx: {
      type: Number,
      default: -1
    }
  },

  methods: {
    setConversation(index) {
      this.$emit('setConversation', index)
    },
  }
}
</script>
