<template>
  <v-dialog
    v-model="audioDialog"
    :max-width="600"
    @click:outside="onClickOutside"
  >
    <v-card rounded="lg">
      <v-container>
        <v-row justify="center">
          <v-col>
            <v-list-item>
              <ThumbnailUploader @setThumbnail="setThumbnail" />

              <v-list-item-content>
                <AudioPlayer
                  v-if="audioSrc"
                  :src="audioSrc"
                />
              </v-list-item-content>
            </v-list-item>

            <Scrollbar
              style="max-height: 400px;"
              :thin="true"
            >
              <v-sheet
                :color="sheetColor"
                height="100%"
                width="100%"
              >
                <v-card-title>
                  <v-text-field
                    v-model="name"
                    outlined
                    dense
                    placeholder="Name..."
                  />
                </v-card-title>

                <v-card-text class="px-1">
                  <v-textarea
                    v-model="caption"
                    placeholder="Descrição"
                    :background-color="sheetColor"
                    :elevation="0"
                    :counter="2200"
                    rows="3"
                    flat
                    solo
                  />
                </v-card-text>

                <v-divider />

                <v-list nav>
                  <v-list-group
                    :value="privsOn"
                    :ripple="false"
                    :append-icon="mdiChevronDown"
                  >
                    <template v-slot:activator>
                      <v-list-item-title>Privacy</v-list-item-title>
                    </template>

                    <v-container>
                      <PrivacySettings
                        :textSize="6"
                        @settings="setPrivacySettings"
                      />
                    </v-container>
                  </v-list-group>

                  <v-list-group
                    :value="contentOn"
                    :ripple="false"
                    :append-icon="mdiChevronDown"
                  >
                    <template v-slot:activator>
                      <v-list-item-title>Content settings</v-list-item-title>
                    </template>

                    <v-container>
                      <ContentSettings
                        :textSize="6"
                        @settings="setContentSettings"
                      />
                    </v-container>
                  </v-list-group>
                </v-list>
                
              </v-sheet>
            </Scrollbar>            
          </v-col>
        </v-row>

        <v-card-actions>
          <v-btn
            text
            color="primary"
            :loading="loading"
            @click="submit"
          >
            save
          </v-btn>  
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { PrivacySettings, ContentSettings, MediaURL } from '@components'
import { ThumbnailUploader } from '@components/Image'
import { Scrollbar } from '@components/App'
import AudioPlayer from './AudioPlayer'
import { mdiChevronDown, mdiChevronUp } from '@mdi/js'
import API from '@api'

export default {
  components: {
    Scrollbar,
    ThumbnailUploader,
    AudioPlayer,
    PrivacySettings,
    ContentSettings
  },

  props: {
    audioDialog: {
      type: Boolean,
      default: false
    },
    audioFile: {
      type: Object,
      default: {}
    }
  },

  created() {
    this.getMimeType = MediaURL.getMimeType.bind(this);
    this.getVideoThumbnail = MediaURL.getVideoThumbnail.bind(this);
    this.getMediaUrl = MediaURL.getMediaUrl.bind(this);
  },

  data () {
    return {
      mdiChevronDown,
      mdiChevronUp,
      
      audioSrc: '',
      privsOn: false,
      loading: false,

      name: '',
      caption: '',

      thumbnailObj: {
        mediaSrc: '',
        filename: '',
        type: ''
      }
    }
  },

  computed: {
    ...mapGetters({
      user: 'user',
      profile: 'profile/id',
      name: 'profile/name',
      avatar: 'profile/avatar',
      bio: 'profile/bio'
    }),

    sender() {
      return {
        profile: this.profile,
        name: this.name,
        avatar: this.avatar,
        bio: this.bio
      }
    },

    sheetColor() {
      return this.$vuetify.theme.dark
        ? 'grey darken-4'
        : 'white'
    } 
  },

  watch: {
    audioFile: {
      handler (audio) {
        this.audioSrc = URL.createObjectURL(audio)    
      },
      deep: true
    }
  },

  methods: {
    async submit() {

      this.loading = true

      const fileObj = {
        mediaSrc: this.audioSrc,
        filename: this.audioFile.name,
        type: await this.getMimeType(this.audioFile)
      }
      
      const [audioThumbnail] = await this.getMediaUrl([this.thumbnailObj], 'images/thumbnails')
      const [audioUrl] = await this.getMediaUrl([fileObj], 'audios')

      const audioObj = await API().post(`audio/${this.user.id}`, {
        sender: this.sender,
        url: audioUrl,
        thumbnail: audioThumbnail,
        title: this.name,
        filename: fileObj.filename,
        caption: this.caption
      })

      if (this.$route.name === 'bundle.home') {

        const bundleId = this.$route.query.id

        await API().post(`bundleContent/${this.user.id}`, {
          audio: audioObj._id,
          bundle: bundleId,
          sender: this.sender,
          name: this.name,
          caption: this.caption,
          url: audioUrl,
          thumbnail: audioThumbnail,
          type: 'audio'
        })

      }
      
    },

    setThumbnail(thumbnailObj) {
      this.thumbnailObj = thumbnailObj
    },

    clearAudio() {
      this.audioSrc = ''
    },

    onClickOutside() {
      if (this.audioFile instanceof File) {
        this.$emit('close')
      }
    }
  }
}
</script>
