<template>
  <div>
    <Scrollbar :darkBackground="true">
      <v-container class="py-0">
        <v-list
          color="transparent"
          class="px-4"
        >
          <v-sheet
            v-for="({ title }, j) in pdfOutline"
            :key="`selection--${j}`"
            class="my-2 clickable hs-rounded-lg"
            :color="sheetColor(j)"
        
            @click="item = j"
          >
            <v-list-item
              dense
              style="border-radius: 10px"
            >
              <v-list-item-content>
                <v-list-item-title class="white--text">
                  {{ title }}  
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-sheet>
        </v-list>
      </v-container>
    </Scrollbar>
  </div>
</template>

<script>
import Scrollbar from '@components/App/Scrollbar'

export default {
  components: {
    Scrollbar
  },
  
  props: {
    pdfOutline: {
      default: [],
      required: true,
    }
  },

  data() {
    return {
      item: 0
    }
  },

  methods: {
    sheetColor(j) {
      return this.item === j
        ? 'rgba(255, 170, 0, 0.40)'
        : 'grey darken-3'
    }
  },

  watch: {
    item(index) {
      this.$emit('scrollToOutline', index)
    }
  }
}
</script>

<style scoped>
.scrolling-page {
  margin-bottom: 1em;
}

@media print {
  .pdf-preview {
    display: none;
  }
}
</style>
