<template>
  <div
    @click="focusPage"
    v-visible.once="drawPage"
    :class="{ focused: isPageFocused }"
    class="pdf-thumbnail"
  >
    <v-img
      :src="src"
      class="box-shadow"
      :width="width"
      :height="height"
    >
      <template v-slot:placeholder>
        <v-sheet
          height="100%"
          width="100%"
          color="grey lighten-3"
        >
          <v-skeleton-loader
            class="mx-auto"
            height="100%"
            width="100%"
            type="image"
          />
        </v-sheet>
      </template>
    </v-img>
  </div>
</template>

<script>
import debug from 'debug'
const log = debug('app:components/PDF/Thumbnail')

import visible from '@directives/visible'

export default {
  name: 'PDFThumbnail',

  props: {
    page: {
      type: Object, // instance of PDFPageProxy returned from pdf.getPage
      required: true,
    },
    scale: {
      required: true,
    },
    isPageFocused: {
      type: Boolean,
      default: false,
    },
  },

  directives: {
    visible
  },

  created() {
    const { width, height } = this.page.getViewport({ scale: 1 })
    const baseWidth = 12
    this.width = `${baseWidth}vh`
    this.height = `${(height/width)*baseWidth}vh`
  },

  data() {
    return {
      src: undefined,
      height: 0,
      width: 0
    }
  },

  computed: {
    viewport() {
      return this.page.getViewport({ scale: 1.0 })
    },

    pageNumber() {
      return this.page.pageNumber
    },
  },

  methods: {
    focusPage() {
      this.$emit('page-focus', this.pageNumber)
    },

    drawPage() {
      if (this.renderTask) return

      const {viewport} = this
      const canvas = document.createElement('canvas')
      const canvasContext = canvas.getContext('2d')
      const renderContext = { canvasContext, viewport }
      canvas.height = viewport.height
      canvas.width = viewport.width

      this.renderTask = this.page.render(renderContext).promise
      this.renderTask.then(() => {
        this.src = canvas.toDataURL()

        // Zeroing the width and height causes Firefox to release graphics
        // resources immediately, which can greatly reduce memory consumption.
        canvas.width = 0
        canvas.height = 0
        
      })
      .then(() => {
        this.$emit('thumbnail-rendered', {
          page: this.page,
          text: `Rendered thumbnail ${this.pageNumber}`,
        })
        })
      .catch(response => {
        this.destroyRenderTask()
        this.$emit('thumbnail-errored', {
          response,
          page: this.page,
          text: `Failed to render thumbnail ${this.pageNumber}`,
        })
      })
    },

    destroyPage(_newPage, page) {
      // PDFPageProxy#_destroy
      // https://mozilla.github.io/pdf.js/api/draft/PDFPageProxy.html
      if (page) page._destroy()

      this.destroyRenderTask()
    },

    destroyRenderTask() {
      if (!this.renderTask) return

      delete this.renderTask
    },

    updateVisibility() {
      this.$parent.$emit('update-visibility')
    },
  },

  watch: {
    page: 'destroyPage',
    src: 'updateVisibility',
    scale: 'updateVisibility',
  },

  mounted() {
    log(`Page ${this.pageNumber} mounted`)
  },

  beforeDestroy () {
    this.destroyPage(undefined, this.page)
  },
}
</script>


<style scoped>
.pdf-thumbnail {
  cursor: pointer;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.v-image, 
.placeholder {
  border: 5px solid transparent;
  border-radius: 2px;
  width: 65%;
}

.pdf-thumbnail.focused .v-image {
  box-shadow: 0 0 0 1px rgba(153, 134, 11, 0.5), 0 2px 8px rgba(173, 145, 32, 0.3);
  border-color: rgba(255, 203, 45, 0.493)
}

.page-number {
  color: white;
  font-weight: bold;
}
</style>