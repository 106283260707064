<template>
  <v-form ref="form" @submit.prevent="submit">
    <SnackBar
      :snackMsg="snackMsg"
      :snackOn="snackOn"
      :snackTop="true"
      @close="snackOn = false"
    />

    <ResetPwd
      :dialog="dialog"
      @snackMsg="(msg) => { snackMsg = msg; snackOn = true }"
      @close="dialog = false"
    />

    <v-card
      outlined
      class="hs-rounded-xl"
    >
      <v-progress-linear
        :active="loading"
        indeterminate
        color="primary"
        absolute
        top
      />

      <v-row justify="center">
        <v-card-title class="text-center">
          {{ $t('signin_page.welcomeBack') }}
        </v-card-title>
      </v-row>

      <v-divider />

      <div class="my-4"></div>

      <v-row
        align="center"
        justify="center"
      >
        <OAuth
          type="signin"
          @setUser="setUser"
        />
      </v-row>

      <div class="text-h6 text-center my-3">OR</div>

      <div class="px-5">
        <v-text-field
          v-model="email"
          :rules="[v => /.+@.+\..+/.test(v) || $t('validation.email')]"
          validate-on-blur
          :append-icon="mdiEmail"
          name="email"
          label="e-mail"
          type="text"
          outlined
          class="hs-rounded-lg"
          data-cy="email-input"
          @input="formatEmail"
        />

        <v-text-field
          v-model="password"
          required
          :append-icon="mdiLock"
          name="password"
          :label="$t('signin_page.pwd')"
          id="password"
          type="password"
          outlined
          hide-details
          class="hs-rounded-lg"
          data-cy="password-input"
          @input="formatPassword"
        />

        <div
          :class="{
            'px-1 py-3 text-subtitle-1 grey--text clickable': true,
            'text--darken-1': !$vuetify.theme.dark,
            'text--lighten-3': $vuetify.theme.dark
          }"
          @click="dialog = true"
        >
          {{ $t('signin_page.forgotPwd') }}
        </div>

        <v-row
          justify="center"
          class="px-3 pb-6"
        >
          <v-btn
            large
            block
            class="hs-rounded-lg"
            color="primary"
            type="submit"
            data-cy="login-button"
            :loading="loading"
          >
            {{ $t('signin_page.enter') }}
          </v-btn>
        </v-row>

        <v-row
          v-if="false"
          align="center"
          class="px-3 py-1"
        >
          <span
            :class="{
              'px-1 py-3 text-subtitle-1 grey--text': true,
              'text--darken-1': !$vuetify.theme.dark,
              'text--lighten-3': $vuetify.theme.dark
            }"
            v-text="$t('signin_page.noAccount')"
          ></span>
          
          <v-btn 
            text
            :class="{ 'px-1 mx-2': true, 'primary--text': !$vuetify.theme.dark }"
            v-text="$t('signup')"
            to="/signup"
          />
        </v-row>
      </div>
    </v-card>
  </v-form>
</template>

<script>
import { cleanCookies } from '@utils'
import { SnackBar } from '@components/App'
import { OAuth, ResetPwd } from '@components/Auth'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { mdiEmail, mdiLock } from '@mdi/js'
import API from '@api'

export default {
  components: {
    SnackBar,
    OAuth,
    ResetPwd
  },

  async created() {
    // If the session expired, we tell the user.
    // This is mostly useful for oauth.
    const noCookies = !navigator.cookieEnabled
    if (noCookies || this.sessionExpired) {
      this.RESET_SESSION_EXPIRED(false) // resets sesion
      setTimeout(() => {
        this.snackMsg = noCookies
          ? 'noCookies'
          : 'sessionExpired'
        this.snackOn = true
      }, 500)
    }
  },

  data() {
    return {
      mdiEmail,
      mdiLock,
      snackMsg: '',
      snackOn: false,
      email: '',
      password: '',
      loading: false,
      dialog: false,
      SIGNIN: true
    }
  },

  computed: {
    ...mapGetters({
      sessionExpired: 'sessionExpired',
      locale: 'locale'
    })
  },

  methods: {
    ...mapMutations({
      setLocale: 'locale',
      SET_AUTH0_TOKEN: 'auth0Token',
      SET_ANNA: 'anna',
      RESET_SESSION_EXPIRED: 'sessionExpired'
    }),

    ...mapActions({
      setSession: 'setSession'
    }),

    resetCookies() {
      cleanCookies()

      this.snackMsg = 'signinCleanCookies'
      this.snackOn = true
    },

    formatPassword() {
      this.password = this.password.replace(/\s/g, "")
    },

    formatEmail() {
      this.email = this.email.replace(/\s/g, "").toLowerCase()
    },

    async setUser({ email, auth0Token }) {
      this.SET_AUTH0_TOKEN(auth0Token)

      setTimeout(async () => {
        try {
          const { user, profile, pendingOnBoarding, authorizationToken } = await API().post('user/oauth-login', { email })
          await this.setSession({ user, profile, auth0Token, pendingOnBoarding, authorizationToken })
          const [anna] = await API().get(`profiles/${user.id}`, {
            params: {
              query: {
                  'username': {
                    $eq: 'anna'
                  }
                }
            }
          })
          this.SET_ANNA(anna)

          if (profile.settings) {
            if (this.$vuetify.theme.dark != profile.settings.darkThemeOn)
              this.$vuetify.theme.dark = profile.settings.darkThemeOn

            if (profile.settings.locale && profile.settings.locale != this.locale)
              this.setLocale(profile.settings.locale)
          }

          this.$router.push('/home')
        } catch(err) {
          this.$auth.logout({
            logoutParams: {
              returnTo: `${process.env.VUE_APP_AUTH0_CALLBACK_URL}/signup`,
            }
          })
        }
      })
    },

    async submit() {
      if (this.$refs.form.validate()) {
        setTimeout(() => {
          if (this.SIGNIN && this.loading)
            this.resetCookies()
        }, 8000);

        this.loading = true

        try {
          const { user, profile, authorizationToken, pendingOnBoarding } = await API().post('user/login', {
            email: this.email, 
            password: this.password
          })
          this.loading = false
          await this.setSession({ user, profile, authorizationToken, pendingOnBoarding })
          
          const [anna] = await API().get(`profiles/${user.id}`, {
            params: {
              query: {
                  'username': {
                    $eq: 'anna'
                  }
                }
            }
          })
          this.SET_ANNA(anna)


          this.$socket.onlineSession.connect()

          if (profile.settings) {
            if (this.$vuetify.theme.dark != profile.settings.darkThemeOn)
              this.$vuetify.theme.dark = profile.settings.darkThemeOn

            if (profile.settings.locale && profile.settings.locale != this.locale)
              this.setLocale(profile.settings.locale)
          }

          this.SIGNIN = false
          this.$router.push('/home')

        } catch(err) {
          this.loading = false

          if (err && err.response) {
            const responseData = err.response.data
            this.snackMsg = responseData.errMsg ? responseData.errMsg : 'errServer'
            this.snackOn = true
          }
        }
      }
    }
  } 
}
</script>

<style scoped>
.title {
  font-size: 200% !important; 
  font-weight: 300; 
  margin: 0;
}

/* Deprecated in Vue 3 */
::v-deep .v-dialog {
  border-radius: 24px !important;
}
</style>