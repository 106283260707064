<template>
  <v-dialog
    v-model="dialog"
    max-width="450"
    @click:outside="$emit('close')"
  >
    <v-card>
      <v-form ref="resetPwd">
        <v-container>
          <v-row justify="center">
            <v-card-title
              class="pb-0"
              v-text="$t('signin_page.resetPwd')"
            />
          </v-row>

          <v-row justify="center">
            <v-card-subtitle v-text="$t('signin_page.resetPwdSubtitle')" />
          </v-row>

          <v-text-field
            dense
            outlined
            label="E-mail"
            class="px-2"
            type="email"
            validate-on-blur
            style="border-radius: 12px;"
            :rules="[v => /.+@.+\..+/.test(v) || $t('validation.email')]"
            v-model="emailPwdReset"
          />

          <v-divider />

          <v-card-actions>
            <v-spacer />

            <v-btn
              elevation="0"
              :disabled="loadingResetPwd"
              style="border-radius: 12px;"
              @click="$emit('close')"
              v-text="$t('cancel')"
            />

            <v-btn
              elevation="0"
              color="primary"
              :loading="loadingResetPwd"
              style="border-radius: 12px;"
              @click="resetPwd"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import API from '@api'

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false 
    }
  },

  data: () => ({
    emailPwdReset: '',
    loadingResetPwd: false
  }),

  methods: {
    resetPwd() {
      if (this.$refs.resetPwd.validate()) {
        this.loadingResetPwd = true
        
        API().post(`user/validate/resetPwdEmail/${this.$i18n.locale}`, {
          email: this.emailPwdReset,
          origin: 'STUDY_URL'
        })
        .then(() => {
          this.loadingResetPwd = false
          this.$emit('snackMsg', 'pwdResetEmailSent')
        })
        .catch(err => {
          this.loadingResetPwd = false
          const responseData = err.response.data
          this.$emit('snackMsg', responseData.errMsg ? responseData.errMsg : 'errServer')
          this.loading = false
        })
        .finally(() => {
          this.$emit('close')
        })
      }
    }
  }
}
</script>

<style scoped>
/* Deprecated in Vue 3 */
::v-deep .v-dialog {
  border-radius: 24px !important;
}
</style>