
<template>
  <div :style="`position: relative; width: 100%; height: ${outerHeight}px;`">
    <SnackBar
      :snackMsg="snackMsg"
      :snackOn="snackOn"
      :snackTop="true"
      @close="snackOn = false"
    />
    <!--
      We separate the messages container from the editorjs textarea in different layers.
      The purpose is to have the textarea expand upwards when its input is multiple lines.

      The height here allows us to center the images of the container
    -->
    <v-container
      :class="containerClass"
      :style="`height: ${height}px; position: absolute; z-index: 1;`"
    >
      <v-btn
        v-if="showScrollDown"
        fab
        dark
        small
        :color="scrollDownColor"
        :style="`
          position: absolute;
          z-index: 3;
          right: 30px;
          bottom: ${annaChamber ? -6 : 12}px;
        `"
        @click="scrollBottom(true)"
      >
        <v-icon dark>
          {{ mdiArrowDown }}
        </v-icon>
      </v-btn>

      <v-container
        v-show="!loading"
        id="chatContainer"
        ref="chatContainer"
        v-scroll_bottom
        :class="{
          'hs-custom-scroll px-4 pr-8 pl-6': true,
          'fill-height': messages.length === 0
        }"
        :style="`
          opacity: ${chatOpacity};
          transition: opacity 0.5s ease-in-out;
          scroll-behavior: ${scrollBehavior};
          max-height: ${height - 2}px;
          overflow-y: auto;
          overflow-x: hidden;`"
        @wheel="handleWheel"
        @scroll="handleScroll"
      >
        <v-fade-transition hide-on-leave>
          <v-row
            v-if="appChamber && messages.length === 0"
            justify="center"
          >
            <v-img
              v-if="locale === 'en'"
              src="@assets/images/Anna_chamber_overlay_en.png"
              style="opacity: 0.5; height: auto; max-width: 70%; transform: translateX(13%)"
            />
            <v-img
              v-else
              src="@assets/images/Anna_chamber_overlay_pt-br.png"
              style="opacity: 0.5; height: auto; max-width: 70%; transform: translateX(13%)"
            />
          </v-row>
        </v-fade-transition>
        <!-- for each message -->
        <div
          v-for="(message, i) in messages"
          :key="`message_${message.id}_room_${room.id}`"
          :ref="`message_${message.id}_room_${room.id}`"
        >
          <div v-if="i > 0" :class="msgGap(i)"></div>

          <v-row justify="center">
            <DateChip
              class="my-2"
              :messageDate="message.createdAt"
              :beforeMessageDate="getPrevMsg(i) ? getPrevMsg(i).createdAt : null"
            />
          </v-row>

          <v-row
            align="start"
            :justify="message.sender._id === myProfileId ? 'end' : 'start'"
          >
            <Message
              :chat="conversation"
              :message="message"
              :prevMsg="getPrevMsg(i)"
              :nextMsg="getNextMsg(i)"
              :index="i"
              :streaming="annaStreaming && message.STREAMING"
              :singleSource="singleSource"
              :loadingSolution="loadingSolution && i === messages.length - 1"
              :messagesLength="messages.length"
              @choose="sendMsg({
                content: $event.choice,
                question: $event.question,
                solution: $event.solution,
                type: 'test_choice'
              })"
              @toggleLatex="toggleLatex"
              @setReference="$emit('setReference', $event)"
              @setParams="setChatParams"
            />
          </v-row>
        </div>
      </v-container>
    </v-container>
    
    <div :style="`position: sticky; z-index: 2; top: ${innerTop}px;`">
      <v-row 
        justify="start"
        align="end"
        class="px-8"
        style="position: relative;"
      >
        <span :class="{
          'grey--text text--darken-1 font-weight-medium body-2 mr-1': annaThinking,
          'transparent--text body-2': !annaThinking
        }">
          {{persona.name || 'Anna'}} is thinking
        </span>
        <v-progress-linear
          v-if="annaThinking"
          stream
          color="grey darken-1"
          buffer-value="0"
          style="width: 10%;"
        />

        <v-spacer />

        <div
          v-if="showQuestionGen"
          style="right: 5%;
            position: absolute;
            margin-bottom: 7px;
            justify-self: end;
            display: flex;  
            flex-direction: row;  
            align-items: center;  
            justify-content: end;"
        >
          <v-btn
            elevation="0"
            large
            :disabled="annaStreaming"
            style="
              border-style: solid;
              border-width: 1px;
              border-radius: 12px;
              backdrop-filter: blur(5px);
              background-color: rgba(64, 64, 64, 0.1);
            "
            @click="sendMsg({
              content: $t('giveMeAQuestion'),
              tools: ['question_gen'],
              mentions: [],
              type: 'test_trigger'
            })"
          >
            {{$t('giveMeAQuestion')}}
            <v-icon right>
              {{ mdiSend }}
            </v-icon>
          </v-btn>
        </div>
      </v-row>

      <TextArea
        :lighterViewerOn="true"
        :label="'Message...'"
        :loading="loading"
        :autoCall="autoCall"
        :conversation="room.id"
        :menuClass="menuClass"
        :locked="annaStreaming"
        :streaming="annaStreaming"
        @heightChange="$emit('heightChange', $event)"
        @setStudy="sendMsg({
          content: null,
          type: 'chat_study'
        })"
        @stop="stopStreaming = true"
        @setOptions="sendMsg({
          content: null,
          type: 'chat_options'
        })"
        @submit="onSubmit"
      ></TextArea>
    </div>
  </div>
</template>

<script>
import Message from './Message.vue'
import TextArea from './TextArea'
import { mapGetters, mapMutations } from 'vuex'
import { SnackBar } from '@components/App'
import { mdiArrowDown, mdiSend } from '@mdi/js'
import { replaceLatexDelimiters as formatLatex } from '@utils'
import scroll_bottom from '@directives/scroll-bottom'
import { MediaURL } from '@components'
import ChatFunctions from './ChatFunctions.vue'
import API from '@api'
import DateChip from '@components/Conversation/DateChip.vue'
import PersonaService from '@api/persona'

const { VUE_APP_LANGCHAIN_API } = process.env

export default {
  components: {
    SnackBar,
    TextArea,
    Message,
    DateChip
  },

  directives: {
    scroll_bottom
  },

  props: {
    appChamber: {
      type: Boolean,
      default: false
    },
    fetchContext: {
      type: Boolean,
      default: false
    },
    containerClass: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 500
    },
    maxWidth: {
      type: Number,
      default: 60
    },
    room: {
      type: Object,
      default() {
        return {}
      }
    },
    conversation: {
      type: Object,
      default: () => {
        return {}
      }
    },
    menuClass: {
      type: String,
      default: ''
    },
    outerHeight: {
      type: Number,
      default: 80
    },
    innerTop: {
      type: Number,
      default: 80
    },
    langChain: {
      type: Boolean,
      default: false
    },
    autoCall: {
      type: Boolean,
      default: false
    },
    filename: {
      type: String,
      default: ''
    },
  },

  async created() {
    try {
      this.personaService = PersonaService(this.user.id)
      // SETS GLOBAL CHATFUNCTIONS
      this.callPersona = ChatFunctions.callPersona.bind(this);
      this.CALL_ANNA = ChatFunctions.callAnna.bind(this);
      this.getMediaUrl = MediaURL.getMediaUrl.bind(this);
      this.singleSource = this.room.type === 'MEDIA'
      // SETS THE ANNA PROFILE
      const [anna] = await API().get(`profiles/${this.user.id}`, {
        params: {
          query: {
            'username': {
              $eq: 'anna'
            }
          }
        }
      })

      this.anna = anna
      this.$socket.conversation.connect()

      const messageCount = await API().get(`messages/${this.user.id}/count`, {
        params: {
          query: {
            conversation: {
              $eq: this.conversation.id
            }
          },
        }
      })
      this.pagination.count = messageCount
      const skip = messageCount - this.pagination.limit
      this.pagination.skip = skip < 0 ? 0 : skip
      this.messages = await this.requestMessages()

      this.joinConversation()

      setTimeout(() => {
        const conversation = this.conversation
        
        const convoLen = this.messages.length

        if (conversation.summaryHook && convoLen === 0) {
          if (this.annaChamber) this.summarize()
          else this.waitChamber = true
        }

        this.loading = false
        setTimeout(() => {
          this.scrollBottom()
          this.scrollBehavior = 'smooth'
        })
      }, 300)

    } catch (err) {
    
      console.error(err)
    
    }
  },

  beforeDestroy() {
    this.$socket.conversation.emit('leave', this.room.id)
    this.$socket.conversation.disconnect()
  },

  sockets: {
    conversation: {
      newMessage(msg) {
        if (msg && msg.conversation && msg.conversation === this.conversation.id) {
          this.messages.push(msg)

          if (msg.sender.username === 'anna') {
            this.annaThinking = false
          }

          if (!this.showScrollDown) {
            // if the scroll button is not active
            // it means that the scroll is sufficiently down
            // so that the user is reading the last messages
            // and thus the scroll should go to the bottom
            setTimeout(() => {
              this.scrollBottom(true)
            }, 100)
          }
        }
      },

      anna_thinking({ conversation }) {
        this.annaThinking = conversation === this.conversation.id
      }
    }
  },

  data: () => ({
    mdiArrowDown,
    mdiSend,
    chatOpacity: 1,
    loading: true,
    snackOn: false,
    snackMsg: '',
    annaThinking: false,
    annaStreaming: false,
    stopStreaming: false,
    scrollCounter: 0,
    scrollBehavior: 'auto',
    singleSource: false,
    messages: {},
    pagination: {
      limit: 20,
      pageSize: 20,
      skip: 0,
      count: 0,
    },
    viewport: {},
    host: {},
    anna: {},
    waitChamber: false,
    showScrollDown: false,
    stickyBottom: true,
    solution: {},
    loadingSolution: false,
    profilingCounter: 0,
    isOnScrollTop: false,
    personaService: null
  }),

  computed: {
    ...mapGetters({
      locale: 'locale',
      user: 'user',
      myProfileId: 'profile/id',
      myAvatar: 'profile/avatar',
      myName: 'profile/name',
      myUsername: 'profile/username',
      annaChamber: 'annaChamber',
      contents: 'folders/contents',
      rag: 'folders/rag',
      context: 'anna-mind/context',
      quality: 'anna-mind/quality',
      persona: 'currentPersona',
      tools: 'persona/tools',
      roles: 'persona/roles'
    }),

    showQuestionGen() {
      return this.tools &&
        this.tools.map(({ type }) => type)
                  .includes('question_gen')
    },

    scrollDownColor() {
      return this.$vuetify.theme.dark ? '' : 'primary'
    },

    sender() {
      return {
        _id: this.myProfileId,
        name: this.myName,
        username: this.myUsername,
        avatar: this.myAvatar
      }
    },

    annaSender() {
      return {
        _id: this.anna._id,
        name: this.anna.name,
        username: this.anna.username,
        avatar: this.anna.avatar
      }
    },

    personaSender() {
      return {
        _id: this.persona._id,
        name: this.persona.name,
        username: this.persona.username,
        avatar: this.persona.avatar
      }
    }
  },

  watch: {
    annaChamber(v) {
      if (v && this.waitChamber) {
        this.summarize()
        this.waitChamber = false
      }
    },

    conversation: {
      deep: true,
      handler: function(obj) {
        if (obj && obj.id) this.joinConversation()
      }
    },

    messages: {
      deep: true,
      handler () {
        if (!this.showScrollDown)
          this.scrollBottom()
      }
    }
  },

  methods: {
    ...mapMutations({
      INC_PROFILING_COUNTER: 'persona/INC_PROFILING_COUNTER',
      SET_QUALITY: 'anna-mind/quality',
      SET_PROFILING: 'persona/SET_PROFILING'
    }),

    requestMessages() {
      return API().get(`messages/${this.user.id}`, {
        params: {
          query: {
            conversation: {
              $eq: this.conversation.id
            }
          },
          pagination: {
            skip: this.pagination.skip,
            limit: this.pagination.limit,
          },
          sort: {
            createdAt: 1
          }
        }
      });
    },
    setChatParams(params) {
      this.conversation.params = params
    },

    getPrevMsg(i) {
      return i > 0 ? this.messages[i - 1] : null;
    },

    getNextMsg(i) {
      return i < this.messages.length - 1 ? this.messages[i + 1] : null;
    },

    msgGap(index) {
      if (index > 0) {
        if (this.messages[index-1].sender._id != this.messages[index].sender._id)
          return 'mt-4 mb-1'
        else
          return 'mb-1'
      }
    },

    async joinConversation() {
      this.loading = true

      this.$socket.conversation.emit('join', this.room.id)
      setTimeout(() => {
        this.loading = false
      }, 500)
    },

    async summarize() {
      this.annaThinking = true

      const queryStr = `index_name=pdf/${encodeURIComponent(this.filename)}`
      const response = await fetch(`${VUE_APP_LANGCHAIN_API}/cluster_summary?${queryStr}`);
      const reader = response.body.getReader();

      let conversation = this.conversation
      const annaMsg = {
        room: this.room.id,
        conversation: conversation.id,
        sender: this.persona.id ? this.personaSender : this.annaSender,
        ...( this.persona.id ? { persona: this.persona.id } : { profile: this.anna.id } ),
        content: '',
        createdAt: (new Date()).toISOString(),
        summaryHook: true
      }
      this.messages.push(annaMsg)
      const convoLen = this.messages.length

      while(true) {
        const {done, value} = await reader.read();

        if (done) break;

        /*
        * TODO: break this into two parts, the summary and the questions.
        * Ideally we break the summary and the suggested questions in separate calls.
        */
        const token = new TextDecoder().decode(value);
        if (token != '\u0003') {
          this.messages[convoLen - 1].content += token
        }
      }

      this.$socket.conversation.emit('sendMsg', this.messages[convoLen - 1])
      this.annaThinking = false
    },

    replaceLatexDelimiters(str) {
      return formatLatex(str)
    },

    handleWheel (evt) {
      if (evt.deltaY < 0 && this.annaStreaming)
        this.stickyBottom = false
    },

    async fetchMoreMessages() {
      const skip = this.pagination.skip - this.pagination.limit
      this.pagination.skip = skip < 0 ? 0 : skip;

      /*
      * PAGINATION LOGIC
      * We need to change the pagination limit to avoid duplicates
      * in the last step of the recursion. For example, if
      * count = 18 and skip = 10, the second fetch would retrieve
      * messages [9 - 0] if the limit is not reduced.
      */

      if (this.pagination.skip === 0)
        this.pagination.limit = this.pagination.count - this.messages.length

      const scrollMsg = this.messages[0]
      const [scrollEl] = this.$refs[`message_${scrollMsg.id}_room_${this.room.id}`]
      const chatContainer = this.$refs['chatContainer']

      if (this.messages.length < this.pagination.count) {

        this.scrollBehavior = 'auto'

        this.chatOpacity = 0

        const moreMessages = await this.requestMessages()
        this.messages = [...moreMessages, ...this.messages]
        
        requestAnimationFrame(() => {
          const scrollTop = scrollEl.offsetTop - chatContainer.offsetTop;
          // -45 balances the DateChip        
          chatContainer.scrollTop = scrollTop - 45;

          this.scrollBehavior = 'smooth'
          requestAnimationFrame(() => {
            this.chatOpacity = 1
          })
        })
      }
    },
  
    async onScrollTop(e) {
      const { scrollTop } = e.target
      if (scrollTop === 0) {
        this.isOnScrollTop = true
        this.fetchMoreMessages()
      } else {
        this.isOnScrollTop = false
      }
    },

    handleScroll(e) {
      const chatContainer = this.$refs.chatContainer
      const correctedScroll = chatContainer.scrollHeight - chatContainer.clientHeight
      // TODO: remove the persona from here. We use it only to avoid conflicts with the quesiton gen buton.
      const noShow = this.tools &&
        this.tools.map(({ type }) => type).includes('question_gen')
      this.showScrollDown = !noShow && !this.loading &&
        chatContainer.scrollTop < 0.98 * correctedScroll
      this.onScrollTop(e)
    },

    scrollBottom() {
      const container = this.$refs.chatContainer;
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    },

    removeAnnaLinks(inputString) {
      // Use regex to replace <a> tags with innerHTML "@anna" with an empty string
      return inputString.replace(/<span [^>]*>@anna<\/span>/g, '');
    },

    async profileChat() {
      const memory = this.messages.slice(-50).map(message => {
        const role = message.sender.username === 'anna' || message.persona ? 'assistant' : 'user';

        return {
          role,
          name: message.sender.name,
          content: message.content
        };
      });

      const langRes = await fetch(`${VUE_APP_LANGCHAIN_API}/student_profiler`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: false,
        body: JSON.stringify({
          chat_history: memory
        })
      })

      if (langRes.ok) {
        // Reads the stream and converts it to JSON
        const data = await langRes.json(); 
        
        this.SET_PROFILING({
          name: data.final_SLTI_name,
          history: data.new_regency,
          description: data.final_SLTI,
          data: [
            data.normalized_data['LA+'],
            data.normalized_data['PSS+'],
            data.normalized_data['EL+'],
            data.normalized_data['LA-'],
            data.normalized_data['PSS-'],
            data.normalized_data['EL-']
          ]
        })
      } else {
        console.error('Network response was not ok.');
      }
    },

    async onSubmit({content, persona}) {
      if (!content) return
      const messageType = 'chat_msg'
      const contentFormatted = this.replaceLatexDelimiters(
        content.replace(/\u2005/g, " ")
      )

      const message = {
        type: messageType,
        room: this.room.id,
        conversation: this.conversation.id,
        sender: this.sender,
        profile: this.myProfileId,
        // TODO: review why the fuck do we have this replaceLatexDelimiters here and in the message.vue component?
        content: contentFormatted,
        createdAt: (new Date()).toISOString(),
        ...(
          this.messages.length > 0 ?
          { previousMsg: this.messages[this.messages.length - 1].id } :
          {}
        )
      }

      this.$socket.conversation.emit('sendMsg', message)
      this.messages.push(message)
      if (!persona && !this.annaChamber) return

      const personaData = this.annaChamber ?
        await this.personaService.getByUsername('anna') :
        await this.personaService.getById(persona._id)

      const memory = this.buildMemory()
      
      try {
        await this.callPersona({
          persona: personaData,
          memory,
          type: messageType,
          message,
        })

      } catch (error) {
        this.snackOn = true
        this.snackMsg = 'annaTimeout'
        this.annaThinking = false;
      }
      this.stickyBottom = false
      this.annaStreaming = false
      this.scrollBottom(true)
    },

    buildMemory() {
      return this.messages.slice(-10).map(message => {
        const role = (message.sender || message.persona) ? 'assistant' : 'user';
        return {
          role,
          name: message.sender.name,
          content: message.content
        };
      });
    },

    async sendMsg({
      content,
      mentions = [],
      question = '',
      solution = {},
      tools = [],
      type = 'chat_msg'
    }) {
      // Handles the profiling
      let doProfiling = (new Set([0, 9])).has(this.profilingCounter) &&
        !(new Set(['chat_options', 'chat_study'])).has(type) &&
        this.persona.id

      if (doProfiling) {
        this.profileChat()
        if (this.profilingCounter === 9)
          this.profilingCounter = 0
      }
      this.profilingCounter = this.profilingCounter + 1

      // Sends the message to the room
      let conversation = this.conversation

      if (type === 'chat_options' || type === 'chat_study') {
        let optionsMsg = {
          type,
          room: this.room.id,
          conversation: conversation.id,
          sender: this.persona.id ? this.personaSender : this.annaSender,
          ...(
            this.persona.id ?
            { persona: this.persona.id } :
            { profile: this.anna.id }
          ),
          content: `Let's set the ${
            type === 'chat_options' ?
              'chat options. This options are only valid for this chat' :
              'study session'
          }!`,
        }

        this.messages.push({
          ...optionsMsg,
          createdAt: (new Date()).toISOString()
        })
        this.$socket.conversation.emit('sendMsg', optionsMsg)
        setTimeout(() => {
          this.scrollBottom(true)
        })
        return;
      }

      const msgContent = this.replaceLatexDelimiters(
        content.replace(/\u2005/g, " ")
      )

      const message = {
        type,
        room: this.room.id,
        conversation: conversation.id,
        sender: this.sender,
        profile: this.myProfileId,
        // TODO: review why the fuck do we have this replaceLatexDelimiters here and in the message.vue component?
        content: msgContent,
        createdAt: (new Date()).toISOString(),
        ...(
          this.messages.length > 0 ?
          { previousMsg: this.messages[this.messages.length - 1].id } :
          {}
        )
      }

      // sends msg to the socket
      this.$socket.conversation.emit('sendMsg', message)
      
      // Either by type or direct call
      // TODO: remove tools definition here
      // For now, the decision to call AI should be solely based on call type
      const callAnna = type === 'test_choice' || tools.length > 0 ||
        (content && this.annaChamber)

      const memory = this.messages.slice(-10).map(message => {
        const role = message.sender.username === 'anna' || message.persona ? 'assistant' : 'user';

        return {
          role,
          name: message.sender.name,
          content: message.content
        };
      }); // TODO: name users FOR ROOM CHATS AND CHATS WITH MULTIPLE PERSONAS

      this.messages.push(message)

      setTimeout(() => {
        // keep the scroll to the bottom (if it's already there)
        this.scrollBottom(!this.showScrollDown)
      }, 10)

      const assistant_name = this.persona.id ? this.persona.name : 'Anna'
      // Ask Anna
      if (callAnna) {
        try {

          await this.CALL_ANNA(
            conversation,
            memory,
            assistant_name,
            tools,
            type,
            message,
            question,
            solution,
            this.scrollBottom
          )

        } catch (error) {
          this.snackOn = true
          this.snackMsg = 'annaTimeout'
          this.annaThinking = false;
        }
      }
      
      setTimeout(() => {
        this.stickyBottom = false
        this.annaStreaming = false
      })
    }
  }
}
</script>