export default (str) => {
  const delimiters = [
    {left: "\\begin{CD}", right: "\\end{CD}", display: true},
    {left: "\\begin{gather}", right: "\\end{gather}", display: true},
    {left: "\\begin{alignat}", right: "\\end{alignat}", display: true},
    {left: "\\begin{align}", right: "\\end{align}", display: true},
    {left: "\\begin{equation}", right: "\\end{equation}", display: true},
    {left: "\\begin{gather*}", right: "\\end{gather*}", display: true},
    {left: "\\begin{alignat*}", right: "\\end{alignat*}", display: true},
    {left: "\\begin{align*}", right: "\\end{align*}", display: true},
    {left: "\\begin{equation*}", right: "\\end{equation*}", display: true},
    {left: "\\[", right: "\\]", display: true},
    {left: "\\(", right: "\\)", display: false}
  ];

  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  // Special condition for \begin{aligned} without encompassing $$
  const alignedRegex = /(?<!\$)(\\begin{aligned}[\s\S]*?\\end{aligned})(?!\$)/g;
  str = str.replace(alignedRegex, "$$$1$$");

  for (const delimiter of delimiters) {
    if (["\\begin{alignat}", "\\begin{align}", "\\begin{alignat*}", "\\begin{align*}"].includes(delimiter.left)) {
      const regex = new RegExp(escapeRegExp(delimiter.left) +
        '(?:{\\d+})?' + '\\s*([^]+?)\\s*' + escapeRegExp(delimiter.right), 'g');
      str = str.replace(regex, function(_, content) {
        return "$$\\begin{aligned}" + content.trim() + "\\end{aligned}$$";
      });
    } else {
      const replacement = delimiter.display ? "$$" : "$";
      const regex = new RegExp(escapeRegExp(delimiter.left) + '\\s*([^]+?)\\s*' + escapeRegExp(delimiter.right), 'g');
      str = str.replace(regex, function(_, content) {
        return replacement + content.trim() + replacement;
      });
    }
  }

  // This one-liner regex finds all LaTeX math enclosed by $ or $$, and trims any whitespace around the content inside the delimiters.
  str = str.replace(/(\$+\s*)(.*?)(\s*\$+)/g,
    (match, startDelim, content, endDelim) => startDelim.trim() + content.trim() + endDelim.trim());

  return str.replace(/<br\s*\/?>/gi, '  \n').replace(/\${4}/g, '$$'); // replaces <br> tags
}
