<template>
  <v-dialog
    v-model="pdfDialog"
    :max-width="`${ $vuetify.breakpoint.xl ? 700 : 600 }`"
    @click:outside="close"
  >
    <v-dialog
      v-model="thumbnailDialog"
      max-width="400"
      @click:outside="thumbnailDialog = false"
    >
      <v-card>
        <v-container>
          <v-col class="pb-0">
            <v-text-field
              outlined
              label="Pdf thumbnail page"
              :rules="[v => v >= 1 && v <= numPages || 'Type the page number']"
              v-model="thumbnailPage"
            />
          </v-col>        
        </v-container>

        <v-divider />

        <v-card-actions>
          <v-btn
            text
            block
            class="hs-rounded-lg"
            :disabled="!(thumbnailPage >= 1 && thumbnailPage <= numPages)"
            @click="setThumbnailPage"
          >
            ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card>
      <v-container>
        <v-row
          align="center"
          class="px-4"
        >
          <v-card-title>Upload Pdf</v-card-title>

          <v-spacer />

          <v-btn
            icon
            @click="close"
          >
            <v-icon>
              {{ mdiClose }}
            </v-icon>
          </v-btn>
        </v-row>

        <v-divider />

        <Scrollbar
          :thin="true"
          autoHide="leave"  
          :style="`max-height: ${$vuetify.breakpoint.xl ? 600 : 450}px;`"
        >
          <v-form ref="form">
            <v-container
              v-if="!reloading"
              class="py-0"
            >
              <v-row justify="center">
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <v-progress-linear
                    :active="loading"
                    indeterminate
                    color="primary"
                    absolute
                    top
                  />

                  <v-container class="pb-0">
                    <v-row justify="center">
                      <v-col
                        cols="5"
                        class="pb-0"
                      >
                        <v-card
                          outlined
                          class="hs-rounded-lg"
                        >
                          <v-hover v-slot:default="{ hover }">
                            <v-img
                              :src="thumbnailObj.mediaSrc"
                              max-height="320"
                              class="grey lighten-4"
                              @click="thumbnailDialog = true"
                            >
                              <v-fade-transition>
                                <div
                                  v-if="hover"
                                  style="height: 100%; cursor: pointer;"
                                  class="d-flex primary display-3 white--text v-card--reveal"
                                >
                                  <v-icon
                                    dark
                                    large
                                  >
                                    {{ mdiImage }}
                                  </v-icon>
                                </div>
                              </v-fade-transition>
                            </v-img>
                          </v-hover>
                        </v-card>
                      </v-col>

                      <v-col cols="7">
                        <v-text-field
                          v-model="title"
                          filled
                          :background-color="textBackgroundColor"
                          placeholder="Title"
                          class="hs-rounded-text"
                          :rules="[v => !!v || 'The title is required.']"
                        />

                        <v-textarea
                          v-model="about"
                          rows="8"
                          :background-color="textBackgroundColor"
                          filled
                          hide-details
                          placeholder="About"
                          class="hs-rounded-text"
                        />
                      </v-col>
                    </v-row>

                    <v-expand-transition hide-on-leave>
                      <v-row
                        v-if="embeddingStatus === 'UNDEFINED'"
                        justify="start"
                      >
                        <v-col cols="12">
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-img :src="anna.avatar"></v-img>
                            </v-list-item-avatar>                            
                            <v-list-item-content>
                              <div class="grey--text text--darken-1">
                                I can't read this pdf, but you can still upload it :)
                              </div>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                      </v-row>
                    </v-expand-transition>
                  </v-container>
                </v-col>
              </v-row>

              <v-row justify="center">
                <v-col cols="11" class="pt-0">
                  <PrivacySettings @settings="setPrivacySettings" />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </Scrollbar>
   
        <v-card-actions class="py-0">
          <v-container class="py-0">
            <v-divider />
            <v-row
              align="center"
              justify="center"
            >
              <v-col cols="12">
                <v-btn
                  block
                  class="hs-rounded-lg"
                  :color="btnColor"
                  :loading="loading"
                  @click="submit"
                >
                  upload
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { MediaURL } from '@components'
import { mapGetters, mapMutations } from 'vuex'
import { HoverLayer, PrivacySettings } from '@components'
import { Scrollbar } from '@components/App'
import { mdiImage, mdiClose } from '@mdi/js'
import API from '@api'
import axios from 'axios'

export default {
  components: {
    HoverLayer,
    Scrollbar,
    PrivacySettings
  },

  props: {
    pdfDialog: {
      type: Boolean,
      default: false
    },
    pdfFile: {
      type: Object,
      default: {}
    },
    redirect: {
      type: Boolean,
      default: true
    }
  },

  created() {
    this.getPdfTranscription = MediaURL.getPdfTranscription.bind(this);
    this.getMediaUrl = MediaURL.getMediaUrl.bind(this);
    this.createPDFThumbnail = MediaURL.createPDFThumbnail.bind(this);
  },

  data: () => ({
    mdiImage,
    mdiClose,

    reloading: false,
    thumbnailDialog: false,
    loading: false,
    thumbnailPage: 1,
    dragging: false,
    mouseOver: false,

    pdf: null,
    pdfDoc: null,
    numPages: 0,
    dialog: false,

    embeddingStatus: '',
    file: [],
    title: '',
    about: '',
    thumbnailObj: {
      mediaSrc: '',
      filename: '',
      type: ''
    },
    isAnonymous: false,
    isPublic: false,
    isUnlisted: false
  }),

  computed: {
    ...mapGetters({
      anna: 'anna',
      user: 'user',
      myProfileId: 'profile/id',
      myName: 'profile/name',
      myAvatar: 'profile/avatar',
      settings: 'settings',
      currentFolder: 'folders/currentFolder'
    }),

    sender() {
      return {
        profile: this.myProfileId,
        name: this.myName,
        avatar: this.myAvatar
      }
    },

    textBackgroundColor() {
      return this.$vuetify.theme.dark
        ? '#1a1a1a'
        : 'grey lighten-4'
    },
    
    btnColor() {
      return this.$vuetify.theme.dark
        ? 'secondary'
        : 'primary'
    }
  },

  watch: {
    pdfFile(file) {
      if (file) {
        this.readFile(file)
      }
    }
  },

  methods: {
    ...mapMutations({
      ADD_CONTENT: 'folders/addContent',
      SET_CONTENT: 'SET_CONTENT'
    }),

    close() {
      this.clearAll()
      this.reloading = true
      setTimeout(() => {
        this.reloading = false
      })
      this.$emit('close')
    },
    
    async summarize(transcription, type) {
      const response = await fetch(`${process.env.VUE_APP_LANGCHAIN_API}/hisolver_tasks`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: false,
        body: JSON.stringify({
          query: JSON.stringify(transcription.slice(0, 10)),
          model_number: 1,
          stream: true,
          task: `summarize_${type}`
        })
      })

      const reader = response.body.getReader();

      let loop = true;
      const decoder = new TextDecoder("utf-8");
      while(loop) {
        const {done, value} = await reader.read();
        if(done) {
          loop = false;
        } else {
          const chunkAsString = decoder.decode(value);

          if (chunkAsString != '\u0003')
            this.$data[type] += chunkAsString;
          else break;
        }
      }
    },

    async readFile(pdf) {
      try {
        this.loading = true

        const {pdfDoc, transcription} = await this.getPdfTranscription(pdf)
        const transcriptionLen = transcription.map(({ textContent }) => textContent).join('').length
        this.pdfDoc = pdfDoc

        if (transcriptionLen === 0) {
          this.embeddingStatus = 'UNDEFINED'
        } else {
          this.summarize(transcription, 'title')
          this.summarize(transcription, 'about')
        }

        this.numPages = this.pdfDoc.numPages
        this.thumbnailObj = await this.createPDFThumbnail(this.pdfFile, this.pdfDoc, 1)
        this.loading = false

      } catch(err) {
        this.$emit('err', err)
        this.$emit('close')
      }
    },

    async setThumbnailPage() {
      this.thumbnailDialog = false
      this.thumbnailObj = await this.createPDFThumbnail(this.pdfFile, this.pdfDoc, this.thumbnailPage)
    },

    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true

        try {
          const [thumbnailUrl] = await this.getMediaUrl([this.thumbnailObj], 'images/thumbnails')
          const pdfObj = {
            mediaSrc: URL.createObjectURL(this.pdfFile),
            filename: this.pdfFile.name,
            type: 'application/pdf'
          }
          const [pdfUrl] = await this.getMediaUrl([pdfObj], 'pdfs')
          // Creates the pdf record
          const pdf = await API().post(`pdf/${this.user.id}`, {
            url: pdfUrl,
            thumbnail: thumbnailUrl,
            sender: this.sender,
            title: this.title,
            about: this.about,
            isAnonymous: this.isAnonymous,
            isPublic: this.isPublic,
            isUnlisted: this.isUnlisted
          })

          // Check if the current user has permission to upload to the currentFolder
          const hasFolderPermission = (
            this.currentFolder && this.currentFolder.sender === this.myProfileId
          ) || Object.keys(
            this.currentFolder && this.currentFolder.permissions ?
            this.currentFolder.permissions : []
          ).indexOf(this.myProfileId) != -1
          // Creates the content associated with the pdf
          const content = await API().post(`content/${this.user.id}`, {
            sender: pdf.sender.profile,
            name: pdf.title,
            thumbnail: pdf.thumbnail,
            caption: pdf.caption,
            about: pdf.about,
            type: 'pdf',
            // folder reference
            ...(hasFolderPermission ? { folder: this.currentFolder.id } : {}),
            media: pdf._id,
            permissions: {
              [pdf.sender.profile]: 'owner'
            }
          })

          this.SET_CONTENT(content)

          if (hasFolderPermission) {
            // Adds the content to the currentFolder
            this.ADD_CONTENT(content)
          }
          
          /*
          * Submits the pdf to the AI server
          */
          let formData = new FormData();

          // Rename the form data file to pdf._id
          let renamedPdfFile = new File([this.pdfFile], `${pdf._id}.pdf`, {
            type: 'application/pdf',
          });

          // Assuming `this.pdfFile` is the PDF file to upload
          // Append renamed pdf file to formDatas
          formData.append('file', renamedPdfFile);

          const response = await axios.post(`${process.env.VUE_APP_LANGCHAIN_API}/upload_pdf`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            withCredentials: false
          })

          let embeddingStatus;
          if (this.embeddingStatus === 'UNDEFINED')
            embeddingStatus = 'UNDEFINED'
          else
            embeddingStatus = response.data === -1 ? 'MISSING' : 'CREATED'

          // resets the global embeddingStatus
          this.embeddingStatus = ''

          await API().put(`pdf/${this.user.id}`, {
            embeddingStatus
          }, {
            params: {
              id: pdf._id
            }
          })

          if (this.redirect) {
            this.$router.push({
              name: 'pdf.read',
              query: {
                id: content._id
              }
            })
            this.$emit('close')
          } else {
            this.$emit('pdfData', pdf)
            this.$emit('close')
          }
        } catch (err) {
          this.$emit('err', 'errServer')

        } finally {
          this.loading = false
        }
      }
    },

    clearAll() {
      this.title = ''
      this.description = ''
      this.thumbnailObj = {
        mediaSrc: '',
        filename: '',
        type: ''
      }
      this.isAnonymous = false
      this.isUnlisted = false
      this.isPublic = true
    },

    clearTags() {
      this.tags = this.tags.filter(tag => tag)
    },

    setPrivacySettings({ isAnonymous, isUnlisted, isPublic }) {
      this.isAnonymous = isAnonymous
      this.isUnlisted = isUnlisted
      this.isPublic = isPublic
    }
  }
}
</script>

<style scoped>
/* Styling for firefox */
.theme--light .hs-rounded-text {
  scrollbar-color: #9e9e9e #a50a0a00;
  scrollbar-width: thin;
}
.theme--dark .hs-rounded-text {
  scrollbar-color: #6b6b6b #a50a0a00;
  scrollbar-width: thin;
}

img {
  cursor: pointer;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  border-radius: 4px;
  justify-content: center;
  opacity: .7;
  position: absolute;
  width: 100%;
}

/* Deprecated in Vue 3 */
::v-deep .v-dialog {
  border-radius: 24px !important;
}
</style>