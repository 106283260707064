<template>
  <v-snackbar
    v-model="open"
    :top="true"
    :timeout="5000"
    :color="severity"
  >
    {{ message }}
  </v-snackbar>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    data() {
      return {
        open: false
      }
    },

    watch: {
      message: {
        deep: true,
        immediate: true,
        handler(message) {
          if (message) {
            this.open = true
          }
        }
      },
    },

    computed: {
      ...mapGetters({
        message: 'snackbar/message',
        severity: 'snackbar/severity'
      })
    }
  }
</script>