import { withLayout } from '@utils'
import StudyLayout from '@layouts/Study'

const Personas = () => import('@views/personas/Personas')
const CreatePersona = () => import('@views/personas/Create')

export default {
  path: 'personas',
  component: {
    render: h => h('router-view')
  },
  children: [
    {
      path: '',
      name: 'persona.home',
      component: withLayout(Personas, StudyLayout),
      meta: {
        locked: true,
        app: 'personas'
      }
    },
    {
      path: 'create',
      name: 'persona.create',
      component: withLayout(CreatePersona, StudyLayout),
      meta: {
        locked: true,
        app: 'personas'
      }
    },
    {
      path: 'edit',
      name: 'persona.edit',
      component: withLayout(CreatePersona, StudyLayout),
      meta: {
        locked: true,
        app: 'personas'
      }
    }
  ]
}
