<template>
  <v-dialog
    v-model="uploadOn"
    :max-width="$vuetify.breakpoint.xl ? 1200 : 900"
    @click:outside="$emit('close')"
  >
    <v-card
      rounded="xl"
      :height="$vuetify.breakpoint.xl ? 600 : 500"
    >
      <v-container>
        <v-row justify="center">
          <v-col>
            <Scrollbar :style="`height: ${ $vuetify.breakpoint.xl ? '550px' : '450px;' }`">
              <v-container>
                <v-list-item class="px-2">
                  <ThumbnailUploader
                    :active="bundleOn"
                    @setThumbnail="setThumbnail($event, -1)"
                  />

                  <v-list-item-content>
                    <v-list-item-title
                      :contenteditable="bundleOn"
                      data-placeholder="Bundle name"
                      :spellcheck="false"
                      :style="`width: 100px; color: ${ bundleOn ? '' : 'grey' };`"
                      class="no-outline text-h5"
                      @input="processText($event, -1, 'name')"
                    />
                    
                    <v-list-item-subtitle
                      :contenteditable="bundleOn"
                      data-placeholder="Legenda..."
                      :spellcheck="false"
                      :style="`width: 100px; color: ${ bundleOn ? '' : 'grey' };`"
                      class="no-outline"
                      @input="processText($event, -1, 'caption')"
                    />
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          icon
                          :color="bundleOn ? 'primary' : ''"
                          @click="bundleOn = !bundleOn"
                        >
                          <v-icon>
                            {{ mdiPackage }}
                          </v-icon>
                        </v-btn>
                      </template>
                    </v-tooltip>
                  </v-list-item-action>
                </v-list-item>                

                <v-timeline dense>
                  <template>
                    <draggable
                      tag="div"
                      :list="contentArray"
                      @change="updateEl"
                    >
                      <v-timeline-item
                        v-for="(content, idx) in contentArray"
                        :key="`content_${idx}`"
                        :color="bundleOn ? 'primary' : 'grey'"
                        small
                      >
                        <v-list-item>
                          <v-badge
                            bottom
                            overlap
                            color="secondary"
                            :icon="contentIcon[content.type]"
                          >
                            <ThumbnailUploader
                              v-if="content.type === 'audio'"
                              @setThumbnail="setThumbnail($event, idx)"
                            />

                            <v-list-item-avatar
                              v-else
                              size="80"
                              rounded="xl"
                              style="border: thin solid rgba(0, 0, 0, 0.12); cursor: grab;"
                            >
                              <v-img
                                v-if="content.type === 'pdf' || content.type === 'video'"
                                :src="content.thumbnailObj.mediaSrc"
                              />                    
                              <v-img
                                v-if="content.type === 'image'"
                                :src="content.fileObj.mediaSrc"
                              />
                            </v-list-item-avatar>
                          </v-badge>
                          
                          <v-list-item-content>
                            <v-list-item-title
                              :ref="`title_${idx}`"
                              contenteditable="true"
                              data-placeholder="Content name"
                              :spellcheck="false"
                              style="width: 100px"
                              class="no-outline"
                              @input="processText($event, idx, 'title')"
                            />
                            
                            <v-list-item-subtitle
                              :ref="`caption_${idx}`"
                              contenteditable="true"
                              data-placeholder="Caption..."
                              :spellcheck="false"
                              style="width: 100px"
                              class="no-outline"
                              @input="processText($event, idx, 'caption')"
                            />
                          </v-list-item-content>
                        </v-list-item>
                      </v-timeline-item>
                    </draggable>
                  </template>
                </v-timeline>

                <v-progress-circular
                  v-if="loading"
                  class="ml-8 mt-2"
                  indeterminate
                  color="primary"
                />        
              </v-container>
            </Scrollbar>
          </v-col>

          <v-col>
            <Scrollbar :style="`height: ${ $vuetify.breakpoint.xl ? 550 : 450}px;`">
              <v-sheet
                :color="sheetColor"
                height="100%"
                width="100%"
              >
                <v-card-title>
                  Content bundle
                </v-card-title>

                <v-card-text class="px-1">
                  <v-textarea
                    placeholder="Descrição"
                    :background-color="sheetColor"
                    :elevation="0"
                    :counter="2200"
                    :rows="imgHeight > imgWidth ? 5 : 3"
                    flat
                    solo
                  />
                </v-card-text>

                <v-divider />

                <v-list nav>
                  <v-list-group
                    :value="privsOn"
                    :ripple="false"
                    :append-icon="mdiChevronDown"
                  >
                    <template v-slot:activator>
                      <v-list-item-title>Privacy</v-list-item-title>
                    </template>

                    <v-container>
                      <PrivacySettings
                        :textSize="6"
                        @settings="setPrivacySettings"
                      />
                    </v-container>
                  </v-list-group>

                  <v-list-group
                    :value="contentOn"
                    :ripple="false"
                    :append-icon="mdiChevronDown"
                  >
                    <template v-slot:activator>
                      <v-list-item-title>Content settings</v-list-item-title>
                    </template>

                    <v-container>
                      <ContentSettings
                        :textSize="6"
                        @setContentSettings="setContentSettings"
                      />
                    </v-container>
                  </v-list-group>
                </v-list>

                <v-divider />

                <v-card-actions>
                  <v-btn
                    text
                    color="primary"
                    @click="submit"
                  >
                    save
                  </v-btn>
                </v-card-actions>
              </v-sheet>
            </Scrollbar>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { canvasToBlob } from '@utils'
import { mapGetters } from 'vuex'
import { Scrollbar } from '@components/App'
import { ThumbnailUploader } from '@components/Image'
import { MediaURL, PrivacySettings, ContentSettings } from '@components'
import API from '@api'

import draggable from 'vuedraggable';

const pdfjsLib = require('pdfjs-dist/legacy/build/pdf');
const worker = require('pdfjs-dist/legacy/build/pdf.worker.min')
window.pdfjsWorker = worker

import {
  mdiClose,
  mdiChevronDown,
  mdiImage,
  mdiFilePdfBox,
  mdiMotionPlayOutline,
  mdiMicrophone,
  mdiCamera,
  mdiPackage
} from '@mdi/js'

export default {
  components: {
    Scrollbar,
    ContentSettings,
    PrivacySettings,
    ThumbnailUploader,
    draggable
  },

  props: {
    uploadOn: {
      type: Boolean,
      default: false
    },
    files: {
      type: Array,
      default: []
    }
  },

  created() {
    this.getMimeType = MediaURL.getMimeType.bind(this);
    this.getVideoThumbnail = MediaURL.getVideoThumbnail.bind(this);
    this.getVideoDuration = MediaURL.getVideoDuration.bind(this);
    this.getMediaUrl = MediaURL.getMediaUrl.bind(this);
  },

  data() {
    return {
      mdiClose,
      mdiImage,
      mdiChevronDown,
      mdiCamera,
      mdiPackage,

      contentIcon: {
        'image': mdiImage,
        'pdf': mdiFilePdfBox,
        'video': mdiMotionPlayOutline,
        'audio': mdiMicrophone
      },

      bundleOn: true,
      bundleName: '',
      bundleCaption: '',
        
      mimeType: '',
      imageFile: '',
      cropperOn: false,

      loading: true,

      editIdx: -1,
      contentArray: [],

      thumbnailObj: {
        mediaSrc: '',
        filename: '',
        type: ''
      },

      filename: '',
      type: '',
      videoSrc: '',

      title: '',
      author: '',
      description: '',
      tags: [],
      url: '',

      isAnonymous: false,
      isUnlisted: false,
      isPublic: true
    }
  },

  computed: {
    ...mapGetters({
      user: 'user',
      profile: 'profile/id',
      name: 'profile/name',
      avatar: 'profile/avatar',
      bio: 'profile/bio'
    }),

    sender() {
      return {
        profile: this.profile,
        name: this.name,
        avatar: this.avatar,
        bio: this.bio
      }
    },

    disabled() {
      return !this.title || !this.author
    },

    btnColor() {
      return this.$vuetify.theme.dark
        ? 'white'
        : 'primary'
    },

  },

  watch: {
    files: {
      async handler(fileArray) {
        this.loading = true
        
        let contentArray = []

        for (const file of fileArray) {

          const mimeType = await this.getMimeType(file)
          let fileObj, thumbnailObj;

          switch(mimeType) {
            case 'image/png':
            case 'image/gif':
            case 'image/jpeg':
            case 'image/webp':

              fileObj = {
                mediaSrc: URL.createObjectURL(file),
                filename: file.name,
                type: mimeType
              }
              
              contentArray.push({
                title: '',
                caption: '',
                fileObj,
                type: 'image'
              })

              break;

            case 'video/mp4':
            case 'video/webm':

              const [videoName] = file.name.split('.');

              thumbnailObj = {
                mediaSrc: await this.getVideoThumbnail(file),
                filename: `${videoName}.jpeg`,
                type: 'image/jpeg'
              }

              const mediaSrc = URL.createObjectURL(file)

              fileObj = {
                mediaSrc,
                filename: file.name,
                type: mimeType,
                duration: await this.getVideoDuration(mediaSrc)
              }

              contentArray.push({
                title: '',
                caption: '',
                thumbnailObj,
                fileObj,
                type: 'video'
              })

              break;

            case 'audio/mpeg':
            case 'audio/wav':
            case 'audio/ogg':

              thumbnailObj = {
                mediaSrc: '',
                filename: '',
                type: ''
              }

              fileObj = {
                mediaSrc: URL.createObjectURL(file),
                filename: file.name,
                type: mimeType
              }
              
              contentArray.push({
                title: '',
                caption: '',
                fileObj,
                thumbnailObj,
                type: 'audio'
              })

              break;

            case 'application/pdf':

              thumbnailObj = await this.readPdfFile(file)

              fileObj = {
                mediaSrc: URL.createObjectURL(file),
                filename: file.name,
                type: 'application/pdf'
              }
              
              contentArray.push({
                title: '',
                caption: '',
                thumbnailObj,
                fileObj,
                type: 'pdf'
              })

              break;

            default:
              break;
          }

        }

        this.contentArray = contentArray
        this.loading = false

      },
      deep: true
    }
  },

  methods: {
    setContentSettings(contentSettings) {},

    updateEl() {
      this.contentArray.forEach((content, idx) => {
        const [titleEl] = this.$refs[`title_${idx}`]
        const [captionEl] = this.$refs[`caption_${idx}`]

        titleEl.innerHTML = content.title
        captionEl.innerHTML = content.caption
      })
    },

    async submit() {

      const bundleContents = []
      
      for (const content of this.contentArray) {

        switch(content.type) {
          case 'pdf':

            const [pdfThumbnail] = await this.getMediaUrl([content.thumbnailObj], 'images/thumbnails')
            const [pdfUrl] = await this.getMediaUrl([content.fileObj], 'pdfs')
          
            const pdfObj = await API().post(`pdf/${this.user.id}`, {
              url: pdfUrl,
              thumbnail: pdfThumbnail,
              sender: this.sender,
              title: content.title,
              description: content.description
            })

            bundleContents.push({
              pdf: pdfObj._id,
              url: pdfUrl,
              name: content.title,
              caption: content.caption,
              thumbnail: pdfThumbnail,
              type: 'pdf'
            })
            
            break;

          case 'video':

            const [videoThumbnail] = await this.getMediaUrl([content.thumbnailObj], 'images/thumbnails')
            const [videoUrl] = await this.getMediaUrl([content.fileObj], 'videos')

            const videoObj = await API().post(`video/${this.user.id}`, {
              sender: this.sender,
              url: videoUrl,
              thumbnail: videoThumbnail,
              title: content.title,
              filename: content.fileObj.filename,
              caption: content.caption,
              duration: content.fileObj.duration
            })

            bundleContents.push({
              video: videoObj._id,
              url: videoUrl,
              name: content.title,
              caption: content.caption,
              thumbnail: videoThumbnail,
              type: 'video'
            })

            break;

          case 'image':

            const [imgUrl] = await this.getMediaUrl([content.fileObj], 'images')

            const imgObj = await API().post(`image/${this.user.id}`, {
              sender: this.sender,
              url: imgUrl,
              title: content.title,
              filename: content.fileObj.filename,
              caption: content.caption
            })

            bundleContents.push({
              image: imgObj._id,
              url: imgUrl,
              name: content.title ? content.title : content.fileObj.filename,
              caption: content.caption,
              type: 'image'
            })

            break;

          case 'audio':

            const [audioThumbnail] = await this.getMediaUrl([content.thumbnailObj], 'images/thumbnails')
            const [audioUrl] = await this.getMediaUrl([content.fileObj], 'audios')

            const audioObj = await API().post(`audio/${this.user.id}`, {
              sender: this.sender,
              url: audioUrl,
              thumbnail: audioThumbnail,
              title: content.title,
              filename: content.fileObj.filename,
              caption: content.caption
            })

            bundleContents.push({
              audio: audioObj._id,
              url: audioUrl,
              name: content.title ? content.title : content.fileObj.filename,
              thumbnail: audioThumbnail,
              caption: content.caption,
              type: 'audio'
            })

            break;

          default:
            break;
        }

      }

      if (this.bundleOn) {

        const [bundleThumbnail] = await this.getMediaUrl([this.thumbnailObj], 'images/thumbnails')

        const bundleObj = await API().post(`bundle/${this.user.id}`, {
          sender: this.sender,
          name: this.bundleName,
          caption: this.bundleCaption,
          description: this.bundleDescription,
          thumbnail: bundleThumbnail
        })

        for (const bundleContent of bundleContents) {

          const { type } = bundleContent

          await API().post(`bundleContent/${this.user.id}`, {
            [type]: bundleContent[type],
            bundle: bundleObj._id,
            sender: this.sender,
            name: bundleContent.name,
            caption: bundleContent.caption,
            url: bundleContent.url,
            thumbnail: bundleContent.thumbnail,
            type: bundleContent.type
          })

        }

      }
      
    },

    processText({ target }, idx, textType) {

      if (target.innerHTML === '<br>') {
        target.innerHTML = ''
      }

      if (idx > -1) {

        this.contentArray[idx][textType] = target.innerText
        
      } else {
        
        if (textType === 'name') {

          this.bundleName = target.innerText

        } else {

          this.bundleCaption = target.innerText

        }

      }

    },

    setThumbnail(thumbnailObj, idx) {

      if (idx > -1) {
  
        this.contentArray[idx].thumbnailObj = thumbnailObj
      
      } else {

        this.thumbnailObj = thumbnailObj

      }

    },

    async addThumbnail(e) {

      const [imageFile] = e.target.files;
      const mimeType = await this.getMimeType(imageFile);

      if (mimeType === 'image/gif') {

        this.thumbnailObj.mediaSrc = URL.createObjectURL(imageFile);
        this.thumbnailObj.filename = imageFile.name
        this.thumbnailObj.type = 'image/gif'

      } else {
        
        this.mimeType = mimeType;
        this.imageFile = imageFile;
        this.cropperOn = true;

      }

    },

    setPrivacySettings({ isAnonymous, isUnlisted, isPublic }) {

      this.isAnonymous = isAnonymous
      this.isUnlisted = isUnlisted
      this.isPublic = isPublic

    },

    async readPdfFile(pdf) {

      const pdfUrl = URL.createObjectURL(pdf)
      const pdfBlob = await fetch(pdfUrl).then(r => r.blob())
      const pdfBuffer = await pdfBlob.arrayBuffer()

      const pdfDoc = await pdfjsLib.getDocument(pdfBuffer).promise
      //this.numPages = this.pdfDoc.numPages

      return await this.createPDFThumbnail(pdf, pdfDoc, 1)

    },

    async createPDFThumbnail(pdfFile, pdfDoc, pageNum) {
      
      const page = await pdfDoc.getPage(Number(pageNum))
      const canvas = document.createElement('canvas')
      const viewport = page.getViewport({ scale: 1.0 })
      const context = canvas.getContext('2d')
      
      canvas.height = viewport.height
      canvas.width = viewport.width
      
      const renderContext = {
        canvasContext: context,
        viewport: viewport
      }
  
      await page.render(renderContext).promise

      const blobData = await canvasToBlob(canvas);

      const [pdfName] = pdfFile.name.split('.');

      const thumbnailFile = new File([blobData], `${pdfName}.jpeg`, {
        type: 'image/jpeg'
      });

      const thumbnailObj = {
        mediaSrc: '',
        filename: '',
        type: ''
      }

      thumbnailObj.mediaSrc = URL.createObjectURL(thumbnailFile)
      thumbnailObj.filename = `${pdfName}.jpeg`
      thumbnailObj.type = blobData.type

      return thumbnailObj

    }
  }
}
</script>

<style scoped>
[contenteditable] {
  cursor: text;
}

[data-placeholder]:empty:before{
  content: attr(data-placeholder);
  color: #888;
  font-style: italic;
}

.no-outline:focus {
  outline: none;
}

/* Deprecated in Vue 3 */
::v-deep .v-dialog {
  border-radius: 24px !important;
}
</style>  