<template>
  <div class="sidebar">
    <div class="sidebar-menu py-4">
      <v-btn-toggle
        v-model="toggle_exclusive"
        dense
        dark
        color="amber darken-1"
        background-color="#3a3a3a"
      >
        <v-btn icon>
          <v-icon> {{ mdiPageLayoutBody }} </v-icon>
        </v-btn>

        <v-btn icon>
          <v-icon> {{ mdiFormatListBulleted }} </v-icon>
        </v-btn>

        <v-btn icon>
          <v-icon> {{ mdiCardSearch }} </v-icon>
        </v-btn>
      </v-btn-toggle>
    </div>

    <v-progress-linear
      :active="loading"
      indeterminate
      color="amber darken-1"
      absolute
    />

    <PDFPreview
      v-show="!loading && toggle_exclusive === 0"
      style="display: block; width: 100%;"
      v-bind="{pages, scale, currentPage, pageCount, isPreviewEnabled: true}"
      @page-focus="pageNum => $emit('page-focus', pageNum)"
    />

    <PDFOutline
      v-show="!loading && toggle_exclusive === 1"
      v-bind="{pdfOutline}"
      style="display: block; width: 100%; position: absolute; z-index: 2;"
      @scrollToOutline="scrollToOutline"
    />

    <MatchList
      v-show="!loading && toggle_exclusive === 2"
      v-bind="{matches}"
      style="display: block; width: 100%; position: absolute; z-index: 2;"
      @scrollToMatch="$emit('scrollToMatch', $event)"
    />
  </div>  
</template>

<script>
import MatchList from './MatchList.vue'
import PDFPreview from './Preview.vue'
import PDFOutline from './Outline.vue'

import { mapGetters } from 'vuex'

import {
  mdiFormatListBulleted,
  mdiPageLayoutBody,
  mdiCardSearch
} from '@mdi/js'

export default {
  props: {
    pages: Array,
    scale: Number,
    currentPage: Number,
    pageCount: Number,
    isOutlineEnabled: Boolean,
    isPreviewEnabled: Boolean,
    isSearchListEnabled: Boolean,
    pdfOutline: Array
  },

  components: {
    MatchList,
    PDFPreview,
    PDFOutline
  },

  data() {
    return {
      mdiFormatListBulleted,
      mdiPageLayoutBody,
      mdiCardSearch,

      toggle_exclusive: 0,
      loading: true
    }
  },

  watch: {
    isOutlineEnabled(v) {
      if (v) setTimeout(() => { this.loading = false }, 400)
      else this.loading = true
    }
  },

  computed: {
    ...mapGetters({
      matches: 'pdf/matches'
    })
  }
}
</script>

<style scoped>
.sidebar {
  position: absolute;
  z-index: 2;
  width: 15%;
  height: 100vh;
  outline: solid 1px;
  outline-color: #616161;
  background-color: rgba(42, 42, 46, 1);
}

.sidebar-menu {
  outline-color: #616161;
  justify-content: center;
  display: flex;
  width: 100%;
}
</style>