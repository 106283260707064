<template>
  <v-select
    v-model="inputValue"
    :items="items"
    item-text="text"
    item-value="id"
    v-on:change="onChange"
    :label="$t(`onboarding.role.educationalLevel`)"
    :multiple="multiple"
    :rules="rules"
    dense
    class="scollbar-layer px-1 hs-rounded-12"
  />
</template>

<script>
  export default {
    props: {
      onChange: Function,
      multiple: {
        type: Boolean,
        default: false
      },
      value: String,
      rules: Array
    },

    computed: {
      inputValue: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      }
    },

    data() {
      return {
        items: [
          {
            id: 'elementarySchool',
            text: this.$t('onboarding.role.elementarySchool')
          },
          {
            id: 'highSchool',
            text: this.$t('onboarding.role.highSchool')
          },
          {
            id: 'college',
            text: this.$t('onboarding.role.college')
          },
          {
            id: 'postGraduate',
            text: this.$t('onboarding.role.postGraduate')
          },
          {
            id: 'master',
            text: this.$t('onboarding.role.master')
          },
          {
            id: 'doctor',
            text: this.$t('onboarding.role.doctor')
          }
        ]
      } 
    }
  }
</script>