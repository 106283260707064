var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{ref:"app-chamber",style:(("height: 100%;\n    width: 100%;\n    border: " + _vm.borderStyle + ";")),attrs:{"outlined":"","rounded":"xl","color":_vm.navColor}},[(!_vm.leftCardCollapse)?_c('v-container',{class:{
      'py-0 height100': true,
      'px-0': _vm.annaChamber,
      'px-1': !_vm.annaChamber
    },staticStyle:{"margin":"0","max-width":"100%"}},[_c('v-row',{class:"height100"},[_c('v-col',{class:("py-0 height100 " + _vm.colPadding),style:(_vm.colBorder),attrs:{"cols":_vm.col}},[_c('v-fade-transition',{attrs:{"hide-on-leave":""}},[_c('v-container',{class:{
            'py-2': _vm.annaChamber,
            'py-4': !_vm.annaChamber,
            'height100': true
          }},[_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(_vm.annaChamber),expression:"annaChamber"}]},[_c('v-row',{class:{ 'px-2': _vm.annaChamber },attrs:{"justify":"center"}},[_c('v-btn',{staticStyle:{"border-radius":"12px"},attrs:{"block":"","x-large":"","elevation":"0"},on:{"click":_vm.SET_ANNA_CHAMBER}},[(_vm.annaChamber)?_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.mdiKeyboardBackspace)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('close'))+" ")],1)],1)],1),_c('v-fade-transition',{attrs:{"hide-on-leave":""}},[(_vm.annaChamber)?_c('div',[_c('v-row',{staticClass:"px-6 py-2 pb-1"},[_c('v-btn',{class:{
                      'primary': !_vm.$vuetify.theme.dark,
                      'amber-btn': _vm.$vuetify.theme.dark
                    },staticStyle:{"border-radius":"12px"},attrs:{"block":"","large":"","elevation":"0"},on:{"click":_vm.addChat}},[_vm._v(" new chat ")])],1),_c('Scrollbar',{style:(("height: " + _vm.rootHeight + "px")),attrs:{"thin":true,"autoHide":"leave"}},[_c('v-list',{staticClass:"px-4",attrs:{"disabled":_vm.loading,"color":"transparent"}},_vm._l((_vm.conversations),function(conversation,i){return _c('ChatCard',{key:("conversation_" + i),staticClass:"hs-rounded-lg",attrs:{"conversation":conversation,"chamber":true,"cardColor":_vm.chatCardColor(conversation),"itemColor":_vm.itemColor,"active":conversation.id === _vm.currentConversation.id},on:{"setConversation":function($event){return _vm.setConversation(conversation)},"remove":function($event){return _vm.conversations.splice(i, 1)}}})}),1)],1)],1):_vm._e()]),_c('v-fade-transition',{attrs:{"hide-on-leave":""}},[(!_vm.annaChamber && _vm.$route.meta.app === 'rooms' ||
                  _vm.from.indexOf('/rooms') > -1 &&
                  (_vm.$route.meta.app === 'folders'))?_c('ChatTab'):_vm._e()],1),_c('v-fade-transition',{attrs:{"hide-on-leave":""}},[(!_vm.annaChamber)?_c('div',[(_vm.$route.meta.app != 'rooms')?_c('StudyChamber',{attrs:{"notes":_vm.notes},on:{"callAnna":_vm.SET_ANNA_CHAMBER}}):_vm._e()],1):_vm._e()])],1)],1)],1),_c('v-fade-transition',{attrs:{"hide-on-leave":""}},[(_vm.annaChamber)?_c('v-col',{staticClass:"py-0",attrs:{"cols":12 - _vm.col}},[_c('v-container',{staticClass:"pt-2 pb-0",staticStyle:{"position":"relative"}},[_c('v-fade-transition',{attrs:{"hide-on-leave":""}},[(!_vm.loading && _vm.annaChamber)?_c('ChatContainer',{style:(("background-color: " + _vm.backgroundColor + " !important; border-radius: 20px;")),attrs:{"containerClass":"pt-4 pr-4","height":_vm.containerHeight + _vm.heightAdjustment,"outerHeight":_vm.outerHeight,"innerTop":_vm.containerHeight + 30,"menuClass":"menu--top","fetchContext":true,"autoCall":true,"appChamber":true,"room":_vm.currentRoom,"conversation":_vm.currentConversation},on:{"heightChange":_vm.heightChange}}):_vm._e()],1)],1)],1):_vm._e()],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }