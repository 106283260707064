import { find } from 'lodash'
import API from '@api'

export default {
  namespaced: true,

  state: {
    notifications: []
  },

  getters: {
    notifications: state => {
      return state.notifications
    }
  },

  mutations: {
    setNotifications: (state, payload) => {
      state.notifications = payload
    },
    addNotification: (state, payload) => {
      state.notifications.unshift(payload)
    },
    toggleRead: (state, {id}) => {
      let notification = find(state.notifications, notification => notification.id == id)
      notification.read = !notification.read
    },
    setRead: (state, {id, read}) => {
      let notification = find(state.notifications, notification => notification.id == id)
      notification.read = read
    },
    allRead: state => {
      state.notifications.forEach(nt => nt.read = true)
    },
    clear: state => {
      state.notifications = []
    }
  },

  actions: {
    post: (context, payload) => {
      return new Promise((resolve, reject) => {
        API().post('notification', payload).then(() => resolve(true)).catch(reject)
      })
    }
  }
}