<template>
  <div @click="$emit('focus')">
    <v-expand-transition>
      <v-row
        v-if="focusOn && editorOn && !captionOff"
        justify="center"
      >
        <v-sheet
          width="100%"
          class="mx-2"
          :color="sheetColor"
        >
          <v-slider
            v-model="cols"
            step="1"
            min="0"
            max="6"
            :color="sliderColor"
            :track-color="sliderColor"
            :track-fill-color="sliderColor"
            hide-details
          />
        </v-sheet>
      </v-row>
    </v-expand-transition>

    <v-row justify="center">
      <v-col :cols="6 + cols">
        <v-hover v-slot:default="{ hover }">
          <v-img
            :src="src"
            :class="classClicking"
            @click="selected = !selected"
            @mousedown="mouseClicking = true"
            @mouseup="mouseClicking = false"
          >
            <v-fade-transition>
              <div
                v-if="editorOn && (hover || selected)"
                :class="`d-flex v-card--reveal ${borderColor} ${clickingClass}`"
                style="height: 100%; border-style: solid;"
              >
                <v-btn
                  v-if="removeOn"
                  icon
                  color="primary"
                  @click.prevent="removeImage"
                >
                  <v-icon>
                    {{ mdiClose }}
                  </v-icon>
                </v-btn>
              </div>
            </v-fade-transition>
          </v-img>
        </v-hover>
      </v-col>
    </v-row>

    <v-row
      v-if="!captionOff"
      justify="center"
    >
      <v-col
        :cols="6 + cols"
        class="py-0"
      >
        <v-fade-transition hide-on-leave>
          <p
            v-if="(caption && !editCaption)"
            v-text="caption"
            :class="`${textColorClasses} subtitle-1 text-center clickable mb-3`"
            @click="editCaption = true"
          />
        </v-fade-transition>
        <v-fade-transition hide-on-leave>
          <div v-if="!caption || editCaption">
            <v-expand-transition hide-on-leave>
              <v-text-field
                v-if="focusOn"
                v-model="caption"
                dense
                outlined
                hide-details
                label="Legenda"       
                @focus="$emit('setPartInput', true)"
                @blur="$emit('setPartInput', false)"
                @keydown="onKeyDown"
              />
            </v-expand-transition>
          </div>
        </v-fade-transition>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { BACKGROUND_DARK_COLOR } from '@utils/constants'
import { mapGetters } from 'vuex'
import { mdiClose } from '@mdi/js'

export default {
  props: {
    removeOn: {
      type: Boolean,
      default: true
    },
    focusOn: {
      type: Boolean,
      required: true
    },
    partIdx: {
      type: Number,
      required: true
    },
    src: {
      type: String,
      required: true
    },
    meta: {
      type: Object,
      default() {
        return {}
      }
    },
    captionOff: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    
    if (this.meta.cols) {
      
      this.cols = this.meta.cols
    
    }

    if (this.meta.caption) {
    
      this.caption = this.meta.caption
    
    } else {
    
      this.editCaption = true
    
    }

  },

  data() {
    return {
      mdiClose,
      
      cols: 6,
      dragging: false,
      mouseOver: false,
      mouseClicking: false,
      caption: '',
      editCaption: false,
      image: '',
      selected: false,
      writingCaption: false
    }
  },

  watch: {

    cols(v) {
      
      this.$emit('setMeta', {
        cols: v,
        caption: this.caption
      })

    },

    focusOn(v) {

      if (!v) {
      
        this.selected = false
      
      }

    }
    
  },

  computed: {
    ...mapGetters({
      editorOn: 'notebook/editorOn'
    }),
    sheetColor() {
      return this.$vuetify.theme.dark
        ? BACKGROUND_DARK_COLOR
        : ''
    },
    textColorClasses() {
      return !!this.caption
        ? this.$vuetify.theme.dark
        ? 'grey--text text--lighten-2'
        : 'grey--text text--darken-2'
        : 'transparent--text'
    },
    sliderColor() {
      return this.focusOn
        ? 'primary'
        : 'transparent'
    },
    borderColor() {
      return this.$vuetify.theme.dark
        ? 'border-secondary'
        : 'border-primary'
    },
    classClicking() {
      return this.mouseClicking && this.editorOn
        ? 'mouse-cursor'
        : ''
    },
    uploadColor() {
      return this.mouseOver || this.dragging
        ? this.$vuetify.theme.dark
        ? 'grey darken-4'
        : 'grey lighten-4'
        : ''
    }
  },

  methods: {
    onKeyDown(e) {
      if (e.key === 'Enter') {
        this.editCaption = false
        this.$emit('setMeta', { cols: this.cols, caption: this.caption })
        setTimeout(() => {
          this.$emit('setPartInput', false)
        }, 0)
      }
    },
    addDropImage (e) {
      this.dragging = false
      const imageFile = e.type === 'change' ?
        e.target.files[0] : e.dataTransfer.files[0]
      const { type } = imageFile
      if (type.split('/')[0] === 'image') {
        this.image = URL.createObjectURL(imageFile)
        this.$emit('content', this.image)
      } else {
        this.$emit('notImage', 'NOT_IMAGE')
      }
    },
    leaveTarget (e) { 
      if (e.target.className === 'container fill-height')
        this.dragging = false
    },
    removeImage () {
      this.$emit('removeImage', this.partIdx)
    }
  }
}
</script>

<style scoped>
.border-primary {
  border-color: #4A148C;
}
.border-secondary {
  border-color: #6A1B9A;
}
.mouse-cursor {
  cursor: grabbing;
}
</style>