<template>
  <div style="width: 100%">
    <SnackBar
      :snackMsg="snackMsg"
      :snackOn="snackOn"
      :snackTop="true"
      @close="snackOn = false"
    />

    <input
      ref="pdfInput"
      type="file"
      accept=".pdf"
      @change="addPdf"
    />

    <NewPdf
      :pdfDialog="pdfDialog"
      :pdfFile="pdfFile"
      @close="pdfDialog = false"
      @err="setSnack"
    />

    <v-btn
      block
      large
      depressed
      style="border-radius: 12px"
      @click="clickPdfInput"
    >
      {{ $t('content_upload') }}
    </v-btn>
  </div>
</template>

<script>
import { SnackBar } from '@components/App'
import { NewPdf } from '@components/PDF'

export default {
  components: {
    SnackBar,
    NewPdf
  },

  data: () => ({
    snackMsg: '',
    snackOn: false,

    pdfDialog: false,
    pdfFile: {},
    pdfs: []
  }),

  methods: {
    clickPdfInput() {
      this.$refs.pdfInput.click()
    },
    
    addPdf({ target }) {
      [this.pdfFile] = target.files
      this.pdfDialog = true
    },

    setSnack(snackMsg) {
      this.snackMsg = snackMsg
      this.snackOn = true
    }
  }
}
</script>