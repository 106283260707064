<template>
  <v-card
    :class="$vuetify.theme.dark ? 'viewer-dark' : lighterViewerOn ||
      !toolbar ? 'viewer-lighter' : 'viewer-light'"
    :style="`border-radius: 20px; position: relative;`"
    :outlined="!toolbar"
    dense
  >
    <v-container class="py-2 px-6">
      <v-row align="center">
        <input
          v-model="searchValue"
          ref="input"
          type="text"
          spellcheck="false"
          :placeholder="placeholder"
          :class="`no-outline ${inputColorClass}`"
          @focus="lighterViewerOn = true"
          @blur="lighterViewerOn = false"
          @keydown="debounceOnKeyDown"
        />
        <v-progress-circular
          v-if="isLoading"
          :size="15"
          :width="2"
          :indeterminate="true"
        />
        <v-icon
          v-else
          :color="(!lighterViewerOn && toolbar) || $vuetify.theme.dark ? 'white' : 'primary'"
          >
          {{ mdiShimmer }}
        </v-icon>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex'
  import { mdiShimmer } from '@mdi/js'
  import { debounce } from 'lodash'

  export default {

    props: {
      setFocusOn: {
        type: Boolean,
        default: false
      },
      toolbar: {
        type: Boolean,
        default: true
      },
      debounceDelay: {
        type: Number,
        default: 1000
      }
    },

    created() {
      this.debounceOnKeyDown = debounce((e) => this.onSearch(e), this.debounceDelay)
      if (this.$route.query.q) {
        this.$refs.input.focus({
          preventScroll: true
        }) 
      }
    },

    data() {
      return {
        searchOn: true,
        mdiShimmer,
        searchValue: this.$route.query.q || '',
        lighterViewerOn: false
      }
    },

    watch: {
      setFocusOn(focus) {
        if (focus) {
          this.$refs.input.focus({
            preventScroll: true
          }) 
        }
      }
    },

    computed: {
      ...mapGetters({
        persona: 'semantic-search/persona',
      }),

      inputPadding() {
        const xl = this.$vuetify.breakpoint.xl
        return xl ? 0 : 2
      },

      inputColorClass() {
        return this.$vuetify.theme.dark ? 'input-light' : 'input-dark'
      },

      placeholder() {
        switch (this.$route.name) {
          case 'persona.home': return this.$t('search')
          case 'main.home': return this.$t('search')
          default: return this.$t('semantic_search')
        }
      },

      tooltipLabel() {
        return this.disabled ? this.$t('coming_soon') : this.placeholder
      },

      isLoading() {
        return this.persona.loading
      }
    },

    methods: {
      ...mapMutations({
        setPersonaFilter: 'semantic-search/setPersonaValue',
      }),

      onSearch(e) {
        const value = e.target.value
        const oldValue = this.$route.query.q
        if (!value || value === oldValue) return
        if (this.$route.name === 'search') {
          return this.$router.replace({
            query: {q: value}
          })
        }
        this.$router.push(`/search?q=${value}`)
      },

      onClear() {
        this.searchValue = ''
        this.$emit('clear')
      },

      debounceOnKeyDown() {},

      getLabel() {
        return this.$t('search')
      }
    }
  }
</script>

<style scoped>
.input-dark {
 color: black;
}

.input-light {
  color: white;
}

.no-outline:focus {
  outline: none;
}

.viewer-dark {
  background-color: rgba(202, 199, 199, 0.12);
  -webkit-transition: background-color 0.1s linear;
  -ms-transition: background-color 0.1s linear;
  transition: background-color 0.1s linear;
}

.viewer-light {
  background-color: rgb(103, 58, 158);
  -webkit-transition: background-color 0.1s linear;
  -ms-transition: background-color 0.1s linear;
  transition: background-color 0.1s linear;
}

.viewer-light input::placeholder {
  color: rgb(204, 204, 204);
  opacity: 1; /* Firefox */
}

.viewer-light :-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: rgb(204, 204, 204);
}

.viewer-light input::-ms-input-placeholder { /* Microsoft Edge */
 color: rgb(204, 204, 204);
}

.viewer-lighter {
  background-color: rgb(255, 255, 255);
  -webkit-transition: background-color 0.1s linear;
  -ms-transition: background-color 0.1s linear;
  transition: background-color 0.1s linear;
}
</style>