<template>
  <div>
    <Scrollbar
      class="pdf-menu__scrollbar"
      :darkBackground="true"
    >
      <v-container>
        <v-list-item-group
          dark
          color="amber accent-4"
          v-model="item" 
        >
          <div
            v-for="({ matchStr }, j) in matches"
            :key="`selection--${j}`"
          >
            <v-divider v-if="j != 0" />
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ matchStr }}  
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-container>
    </Scrollbar>
  </div>
</template>

<script>
import Scrollbar from '@components/App/Scrollbar'

export default {
  components: {
    Scrollbar
  },
  
  props: {
    matches: {
      default: [],
      required: true,
    }
  },

  data() {
    return {
      item: 0
    }
  },

  watch: {
    item(index) {
      this.$emit('scrollToMatch', index)
    }
  }
}
</script>

<style scoped>
.scrolling-page {
  margin-bottom: 1em;
}

@media print {
  .pdf-preview {
    display: none;
  }
}
</style>
