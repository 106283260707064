<template>
  <v-container class="py-0">
    <v-alert
      v-if="rooms.length === 0"
      text
      outlined
      prominent
      :color="$vuetify.theme.dark ? 'tertiary' : 'primary'"
      class="hs-rounded-lg mt-4"
    >
      <v-row>
        <v-col
          class="grow"
          v-html="$t('community.emptyRoom')"
        ></v-col>
      </v-row>
    </v-alert>

    <ScrollPagination
      v-if="community._id"
      :requestData="{
        'url': `rooms/${this.user.id}`,
        'params': {
          community: community._id
        }
      }"
      :countData="{
        'url': `rooms/${this.user.id}/count`,
      }"
      :rootHeight="`${rootHeight}px`"
      :setFatherItems="setRooms"
      :style="rooms.length === 0 ? 'display: none;' : ''"
    >
      <template v-slot:child="props">
        <v-container class="hs-room-list px-0 py-0">
          <v-list-item
            dense
            :color="itemColor"
            v-if="props.item._id !== community.mainRoom"
            :to="`/community/${props.item.community}/room/${props.item._id}`"
            @click="$emit('enter')"
          >
            <v-list-item-avatar size="30" class="hs-rounded-lg">
              <v-img :src="getResourceUrl(props.item.thumbnail)" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title  v-text="props.item.name" />
            </v-list-item-content>
          </v-list-item>
        </v-container>
      </template>
    </ScrollPagination>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ScrollPagination from '@components/App/ScrollPagination.vue'
  import { getResourceUrl } from '@utils'

  export default {
    components: {
      ScrollPagination
    },

    props: {
      community: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        roomService: null,
        rendersLength: 0,
        rooms: []
      }
    },

    computed: {
      ...mapGetters({
        innerHeight: 'innerHeight',
        user: 'user',
      }),
      rootHeight() {
        return this.innerHeight - 420
      },
      itemColor() {
        return this.$vuetify.theme.dark ? 'grey lighten-4' : '1'
      }
    },

    methods: {
      getResourceUrl,

      setRooms(rooms = []) {
        this.rooms = rooms.filter(room => room._id !== this.community.mainRoom)
      }
    }
  }
</script>

<style>
.hs-room-list .v-ripple__container {
  border-radius: 12px;
}

.hs-room-list .v-list-item--active:hover::before,
.hs-room-list .v-list-item--active::before,
.hs-room-list .v-list-item::before {
  border-radius: 12px;
}
</style>