import API from '@api';

export default (userId) => {
  const apiRequest = API()
  return {
    listChatRooms (profileId) {
      return apiRequest.get(
        `rooms/chat/${userId}`,
        {
          params: { profileId }
        }
      )
    },
    getFriendshipRoom (profileId, friendProfileId) {
      return apiRequest.get(
        `room/friendship/${userId}`,
        {
          params: { profileId, friendProfileId }
        }
      )
    },
    createChatRoom (body) {
      return apiRequest.post(
        `room/chat/create/${userId}`,
        body
      )
    },
    updateChatRoom (body) {
      return apiRequest.post(
        `room/chat/update/${userId}`,
        body
      )
    },

    createCommunityRoom (body) {
      return apiRequest.post(
        `room/community/${userId}`,
        body
      )
    },

    updateCommunityRoom (body) {
      return apiRequest.put(
        `room/community/${userId}`,
        body
      )
    },

    listByCommunity (communityId) {
      return apiRequest.get(
        `rooms/${userId}`,
        {
          params: { community: communityId }
        }
      )
    },
    getById (id) {
      return apiRequest.get(
        `room/${userId}`,
        {
          params: { id }
        }
      )
    }
  }
}
