export default function(imageAddress) {
  const filename = imageAddress.substring(imageAddress.lastIndexOf('/') + 1);
  const type = filename.substring(filename.lastIndexOf('.') + 1);
  
  const coverObj = {
    mediaSrc: imageAddress,
    filename: filename,
    type: `image/${type}`
  };
  
  return coverObj;
}
