export default {
  namespaced: true,
  
  state: {
    isEditor: false,
    settingsOn: false,
    nav: false,
    fit: 'width',
    scale: undefined,
    rotation: 0,
    optimalScale: undefined,
    paginatorOn: false,
    currentPage: 1,
    pageCount: undefined,
    isPreviewEnabled: false,
    isSearchListEnabled: false,
    textQuery: undefined,
    matches: [],
    querySwitch: false,
    isSearchListQuery: false,
    highlightSelectedSwitch: false,
    previousMatchIndex: undefined,
    currentMatchIndex: undefined,
    currentMatchPage: undefined,
    previousMatchPage: undefined,
    currentPageMatchCount: undefined,

    isOutlineEnabled: false,

    searchNext: false,
    searchPrevious: false,

    sidebarOn: false,
    
    pdfDocument: {},
    sources: []
  },

  getters: {
    sidebarOn: ({ sidebarOn }) => sidebarOn,
    isOutlineEnabled: ({ isOutlineEnabled }) => isOutlineEnabled,
    searchPrevious: ({ searchPrevious }) => searchPrevious,
    searchNext: ({ searchNext }) => searchNext,
    isEditor: ({ isEditor }) => isEditor,
    settingsOn: ({ settingsOn }) => settingsOn,
    nav: ({ nav}) => nav,
    fit: ({ fit }) => fit,
    scale: ({ scale }) => scale,
    rotation: ({ rotation }) => rotation,
    optimalScale: ({ optimalScale }) =>  optimalScale,
    paginatorOn: ({ paginatorOn }) => paginatorOn,
    currentPage: ({ currentPage }) => currentPage,
    pageCount: ({ pageCount }) => pageCount,
    isPreviewEnabled: ({ isPreviewEnabled }) => isPreviewEnabled,
    isSearchListEnabled: ({ isSearchListEnabled }) => isSearchListEnabled,
    textQuery: ({ textQuery }) => textQuery,
    isSearchListQuery: ({ isSearchListQuery }) => isSearchListQuery,
    querySwitch: ({ querySwitch }) => querySwitch,
    highlightSelectedSwitch: ({ highlightSelectedSwitch }) => highlightSelectedSwitch,
    matches: ({ matches }) => matches,
    previousMatchIndex: ({ previousMatchIndex }) => previousMatchIndex,
    currentMatchIndex: ({ currentMatchIndex }) => currentMatchIndex,
    previousMatchPage: ({ previousMatchPage }) => previousMatchPage,
    currentMatchPage: ({ currentMatchPage }) => currentMatchPage,
    currentPageMatchCount: ({ currentPageMatchCount }) => currentPageMatchCount,
    pdfDocument: ({ pdfDocument }) => pdfDocument,
    sources: ({ sources }) => sources
  },
  
  mutations: {
    sidebarOn: (state) => {
      state.sidebarOn = !state.sidebarOn
    },
    isOutlineEnabled: (state) => {
      state.isOutlineEnabled = !state.isOutlineEnabled
    },
    searchNext: (state) => {
      state.searchNext = !state.searchNext
    },
    searchPrevious: (state) => {
      state.searchPrevious = !state.searchPrevious
    },
    SET_EDITOR: (state, isEditor) => {
      state.isEditor = isEditor
    },
    SET_PDF_SETTINGS: (state) => {
      state.settingsOn = !state.settingsOn
    },
    nav: (state, payload) => {
      state.nav = payload
    },
    fit: (state, payload) => {
      state.fit = payload
    },
    scale: (state, payload) => {
      state.scale = payload
    },
    rotation: (state, payload) => {
      state.rotation = payload
    },
    optimalScale: (state, payload) => {
      state.optimalScale = payload
    },
    paginatorOn: (state, payload) => {
      state.paginatorOn = payload
    },
    currentPage: (state, payload) => {
      state.currentPage = payload
    },
    pageCount: (state, payload) => {
      state.pageCount = payload
    },
    isPreviewEnabled: (state, payload) => {
      state.isPreviewEnabled = payload
    },
    isSearchListEnabled: (state, payload) => {
      state.isSearchListEnabled = payload
    },
    textQuery: (state, payload) => {
      state.textQuery = payload
    },
    isSearchListQuery: (state, payload) => {
      state.isSearchListQuery = payload
    },
    querySwitch: (state, payload) => {
      state.querySwitch = payload
    },
    highlightSelectedSwitch: (state, payload) => {
      state.highlightSelectedSwitch = payload
    },
    matches: (state, payload) => {
      state.matches = payload
    },
    previousMatchIndex: (state, payload) => {
      state.previousMatchIndex = payload
    },
    currentMatchIndex: (state, payload) => {
      state.currentMatchIndex = payload
    },
    currentMatchPage: (state, payload) => {
      state.currentMatchPage = payload
    },
    previousMatchPage: (state, payload) => {
      state.previousMatchPage = payload
    },
    currentPageMatchCount: (state, payload) => {
      state.currentPageMatchCount = payload
    },
    setPdfDocument: (state, payload) => {
      state.pdfDocument = payload
    },
    setSources: (state, payload) => {
      state.sources = payload
    }
  },

  actions: {
    resetQuery: ({ commit }) => {
      commit('matches', [])
      commit('textQuery', '')
      commit('isSearchListEnabled', false)
      commit('currentMatchIndex', undefined)
      commit('currentMatchPage', undefined)
      commit('previousMatchPage', undefined)
      commit('currentPageMatchCount', undefined)
      commit('setSources', [])
    },

    reset: ({ commit }) => {
      commit('currentPage', 1)
      commit('highlightSelectedSwitch', false)
      commit('isSearchListQuery', false)
      commit('pageCount', undefined)
      commit ('setSources', [])
    }
  }
}