<template>
  <div @keydown="emitKeydown">
    <v-row justify="center">
      <v-col cols="12">
        <input
          type="file"
          id="imageUpload"
          accept="image/jpg,image/png"
          @change="addDropImage"
        />
        <div v-if="!$data._src">
          <label for="imageUpload">
            <v-card 
              outlined 
              height="246"
              :ripple="false"
              :color="uploadColor"
              style="cursor: pointer;"
              @dragover.prevent
              @drop.prevent="addDropImage"
              @dragenter="dragging = true"
              @dragleave="leaveTarget"
              @mouseenter="mouseOver = true"
              @mouseleave="mouseOver = false"
            >
              <v-container fill-height>
                <v-row align="center">
                  <v-col cols="12">
                    <v-row
                      justify="center"
                      align="center"
                    >
                      <v-icon x-large>
                        {{ mdiImage }}
                      </v-icon>
                    </v-row>
                    <v-row 
                      justify="center"
                      align="center"
                    >
                      <span class="grey--text text--darken-1">
                        {{ uploaderCaption ? uploaderCaption : 'Upload image' }}
                      </span>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </label>
        </div>
        <div v-else>
          <v-row justify="center">
            <v-col cols="12">
              <v-hover v-slot:default="{ hover }">
                <v-img
                  :src="$data._src"
                  :class="classClicking"
                  @click="selected = !selected"
                  @mousedown="mouseClicking = true"
                  @mouseup="mouseClicking = false"
                >
                  <v-fade-transition>
                    <div
                      v-if="editorOn && focusOn && (hover || selected)"
                      :class="`d-flex v-card--reveal ${borderColor} ${clickingClass}`"
                      style="height: 100%; border-style: solid;"
                    >
                      <v-btn
                        icon
                        color="primary"
                        @click="removeImage"
                      >
                        <v-icon>
                          {{ mdiClose }}
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-fade-transition>
                </v-img>
              </v-hover>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <v-expand-transition hide-on-leave>
      <v-row
        v-if="editorOn || caption"
        justify="center"
      >
        <v-col
          :cols="6 + cols"
          class="py-0"
        >
          <v-fade-transition hide-on-leave>
            <p
              v-if="caption && !editCaption"
              v-text="caption"
              :class="`${textColorClasses} clickable subtitle-1 text-center  mb-3`"
              @click="editCaption = true"
            />
          </v-fade-transition>
          <v-fade-transition hide-on-leave>
            <div v-if="!caption || editCaption">
              <v-expand-transition hide-on-leave>
                <v-text-field
                  v-if="focusOn"
                  v-model="$data._caption"
                  dense
                  outlined
                  hide-details
                  label="Legenda"       
                  @focus="emitLocalFocus(true)"
                  @blur="emitLocalFocus(false)"
                  @keydown="onKeyDown"
                />
              </v-expand-transition>
            </div>
          </v-fade-transition>
        </v-col>
      </v-row>
    </v-expand-transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  mdiImage,
  mdiClose
} from '@mdi/js'

export default {
  props: {
    focusOn: {
      type: Boolean,
      default: false
    },
    partIdx: {
      type: Number,
      default: -1
    },
    src: {
      type: String,
      default: ''
    },
    caption: {
      type: String,
      default: ''
    },
    uploaderCaption: {
      type: String,
      default : null
    }
  },

  mounted() {
    
    if (this.src) {
    
      this.$data._src = this.src
    
    }
    
    if (this.caption) {
    
      this.$data._caption = this.caption
    
    } else {
    
      this.editCaption = true
    
    }
    
  },

  data() {
    return {
      mdiImage,
      mdiClose,

      _src: '',
      _caption: '',
      dragging: false,
      mouseOver: false,
      mouseClicking: false,
      imageCaption: '',
      selected: false,
      writingCaption: false,
      editCaption: false
    }
  },

  watch: {
    editCaption(_editCaption) {
      if (!_editCaption) {
        setTimeout(() => {
          this.emitLocalFocus(false)
        })
      }
    },
  },

  computed: {
    ...mapGetters({
      editorOn: 'notebook/editorOn'
    }),
    textColorClasses() {
      return !!this.caption
        ? this.$vuetify.theme.dark
        ? 'grey--text text--lighten-2'
        : 'grey--text text--darken-2'
        : 'transparent--text'
    },
    borderColor() {
      return this.$vuetify.theme.dark
        ? 'border-secondary'
        : 'border-primary'
    },
    classClicking() {
      return this.mouseClicking
        ? 'mouse-cursor'
        : ''
    },
    uploadColor() {
      return this.mouseOver || this.dragging
        ? this.$vuetify.theme.dark
        ? 'grey darken-4'
        : 'grey lighten-4'
        : ''
    }
  },

  methods: {
    emitLocalFocus(v) {
      this.$emit('setLocalFocus', v)
      this.$emit('setPartInput', v)

      if(!v) this.editCaption = false
    },

    save() {
      this.editCaption = false
      this.$emit('setMeta', { src: this.$data._src, caption: this.$data._caption })
      setTimeout(() => {
        this.$emit('setPartInput', false)
      })
    },

    onKeyDown(e) {
      if (e.key === 'Enter') {
        this.save()
      }
    },

    focus() {
      this.$emit('focus')
    },

    emitKeydown(e) {
      this.$emit('keydown', e)
    },

    addDropImage (e) {

      this.dragging = false

      const imageFile = e.type === 'change'
        ? e.target.files[0]
        : e.dataTransfer.files[0]

      const { type } = imageFile

      if (type.split('/')[0] === 'image') {

        this.$data._src = URL.createObjectURL(imageFile)
        this.save()

      } else {

        this.$emit('notImage', 'NOT_IMAGE')

      }

    },
    leaveTarget (e) { 
      if (e.target.className === 'container fill-height') {
        this.dragging = false
      }
    },
    removeImage () {
      this.$data._src = ''
    }
  }
}
</script>

<style scoped>
.border-primary {
  border-color: #4A148C;
}
.border-secondary {
  border-color: #6A1B9A;
}
.mouse-cursor {
  cursor: grabbing;
}
</style>