<template>
  <v-list-item
    :ripple="ripple"
    :to="`/people/profile?id=${profile.id}`"
  >
    <v-list-item-avatar>
      <v-img
        :src="profile.avatar"
        v-if="profile.avatar"
      >
        <template v-slot:placeholder>
          <v-skeleton-loade
            class="mx-auto"
            height="40"
            width="40"
            type="avatar"
          />
        </template>
      </v-img>

      <v-icon 
        v-else 
        style="font-size: 50px;"
      >
        {{ mdiAccountCircle }}
      </v-icon>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title v-html="profile.name" />
      <v-list-item-subtitle v-html="profile.bio" />
    </v-list-item-content>

    <v-list-item-action>
      <v-list-item-action-text>
        <div v-if="showPermission">
          <div
            v-if="isAdmin"
            class="amber--text text--accent-4 font-weight-bold"
          >
            Admin
          </div>

          <div 
            class="primary--text font-weight-bold"
            v-else-if="isModerator"
          >
            {{ moderatorTxt }}
          </div>
        </div>

        <div v-else-if="request">
          <v-btn
            text
            color="primary"
            :loading="loadingAccept"
            :disabled="loadingDeclined"
            @click="acceptRequest"
            @mouseenter="ripple = false"
            @mouseleave="ripple = true"
          >
            {{ $t('accept') }}
            <v-icon>
              {{ mdiCheckboxMarkedOutline }}
            </v-icon>
          </v-btn>
          
          <v-btn
            text
            color="red darken-4"
            :loading="loadingDeclined"
            :disabled="loadingAccept"
            @click="declineRequest"
            @mouseenter="ripple = false"
            @mouseleave="ripple = true"
          >
            {{ $t('decline') }}
            <v-icon>
              {{ mdiClose }}
            </v-icon>
          </v-btn>
        </div>
      </v-list-item-action-text>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { capitalize } from 'lodash'
import {
  mdiCheckboxMarkedOutline,
  mdiAccountCircle,
  mdiClose
} from '@mdi/js'

export default {
  props: {
    profile: {
      type: Object,
      required: true 
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    isModerator: {
      type: Boolean,
      default: false
    },
    showPermission: {
      type: Boolean,
      default: false
    },
    request: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      mdiCheckboxMarkedOutline,
      mdiAccountCircle,
      mdiClose,

      ripple: true,
      loadingAccept: false,
      loadingDeclined: false
    }
  },

  computed: {
    moderatorTxt() {
      return capitalize(this.$t('groups.permissions.moderator'))
    }
  },

  methods: {
    acceptRequest (event) {
      event.preventDefault()
      this.loadingAccept = true
      this.$emit('acceptRequest')
      setTimeout(() => {
        this.loadingAccept = false
      }, 2000)
    },

    declineRequest (event) {
      event.preventDefault()
      this.loadingDecline = true
      this.$emit('declineRequest')
      setTimeout(() => {
        this.loadingDecline = false
      }, 2000)
    }
  }
}
</script>
