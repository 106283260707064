export default {
  namespaced: true,

  state: {
    persona: {
      value: '',
      loading: false
    },
  },

  getters: {
    persona: ({ persona }) => persona
  },

  mutations: {
    setPersonaValue: (state, payload) => {
      state.persona = {
        ...state.persona,
        value: payload
      }
    },

    setPersonaLoading: (state, payload) => {
      state.persona = {
        ...state.persona,
        loading: payload
      }
    }
  }
}