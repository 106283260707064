/* eslint-disable */

import Vue from 'vue'

/*
 * Ignores a calendar error caused by an incompatibility between .native usage in vuetify and vuejs
 * See https://github.com/vuejs/vue/issues/10939 
 */
const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <div>.'
Vue.config.warnHandler = function (msg, vm, trace) {
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    trace = null;
  }
}