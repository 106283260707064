<template>
  <v-card
    class="mx-auto hs-rounded-lg"
    max-width="400"
    min-width="400"
  >
    <v-toolbar flat>
      <v-row justify="center" class="px-6">
        <v-btn
          block
          large
          elevation="0"
          style="border-radius: 12px;"
          to="/notifications"
        >
          {{ $t('notifications.allNotifications') }}
          <v-icon right>
            {{ mdiBellOutline }}
          </v-icon>
        </v-btn>
      </v-row>
    </v-toolbar>

    <v-divider />

    <v-list
      three-line
      class="py-0"
    >
      <v-list-item-group
        multiple
        v-model="notRead"
        active-class="text-darken-1 deep-purple--text"
      >
        <div
          v-for="(notification, idx) in notifications"  
          :key="`notification_${idx}`"
        >
          <v-divider v-if="idx != 0" />

          <v-list-item @click="read(notification)">
            <v-list-item-avatar style="align-list">
              <v-img
                v-if="notification.thumbnail.url"
                :src="getResourceUrl(notification.thumbnail.url)"
              />
              <v-icon
                v-else
                x-large
              >
                {{ mdiAccountCircle }}
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-subtitle
                :class="`grey--text ${txtDark}`"
                v-html="formatTitle(notification)"
              />
            </v-list-item-content>

            <v-list-item-action class="align-list">
              <div :class="`caption grey--text ${txtDark}`">
                {{ notification.date }}
              </div>
            </v-list-item-action>
          </v-list-item>
        </div>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { formatDistanceToNowStrict } from 'date-fns'
import { enUS, ptBR } from 'date-fns/locale'
import { formatNotification, getResourceUrl } from '@utils'
import {
  mdiBellOutline,
  mdiAccountCircle
} from '@mdi/js'

export default {
  data() {
    return {
      mdiBellOutline,
      mdiAccountCircle
    }
  },
  computed: {
    ...mapGetters({
      loadedNotifications: 'notification/notifications'
    }),

    txtDark() {
      return !this.$vuetify.theme.dark
        ? 'text--darken-3'
        : 'text--lighten-1'
    },

    notRead: {
      get () {
        let notRead = []
        this.notifications.forEach((notification, idx) => {
          if (!notification.read) notRead.push(idx)
        })
        return notRead
      },
      set () {}
    },

    notifications () {
      let notifications = []
      
      const locale = this.$i18n.locale === 'en'
        ? enUS
        : ptBR

      if (this.loadedNotifications.length > 0) {
        this.loadedNotifications.filter((n, idx) => idx < 6)
          .forEach(notification => {
            const date = notification.createdAt ? new Date(notification.createdAt) : new Date()
            notifications.push({
              date: formatDistanceToNowStrict(
                date,
                { locale }
              ),
              ...notification
            })
          })

      } else {
        notifications.push({
          thumbnail: {
            url: ''
          },
          type: 'default',
          names: [],
          date: formatDistanceToNowStrict(new Date(), { locale })
        })
      }

      return notifications
    },

    notReadLen() {
      return this.notifications.filter(notification => !notification.read).length
    }
  },

  methods: {
    ...mapMutations({
      setRead: 'notification/toggleRead'
    }),

    getResourceUrl,

    formatTitle(notification) {
      return formatNotification(notification, this, true)
    },

    read(notification) {
      if (!notification.read) {
        this.$socket.notification
          .emit(
            'setRead',
            {
              notificationId: notification.id,
              read: true
            }
          )
        this.setRead({id: notification.id})
      }

      this.$router.push(notification.to)
    }
  }
}
</script>

<style scoped>
.align-list {
  transform: translateY(7px);
}
</style>
