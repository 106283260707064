<template>
  <v-card
    outlined
    rounded="lg"
    @click="$emit('imgClick')"
  >
    <v-hover v-slot:default="{ hover }">
      <v-img
        :src="src"
        :class="classClicking"
        :height="height"
        @mousedown="mouseClicking = true"
        @mouseup="mouseClicking = false"
      >
        <v-overlay
          v-if="overlay"
          color="#6A1B9A"
          absolute
        >
          <span class="text-h3">{{ overlayMsg }} </span>
        </v-overlay>

        <v-fade-transition>
          <div
            v-if="hover && removeOn"
            class="d-flex v-card--reveal"
          >
            <v-spacer />
            
            <v-btn
              fab
              x-small
              :color="removeBtnColor"
              class="mx-2 my-2"
              @click.prevent="removeImage"
            >
              <v-icon>
                {{ mdiClose }}
              </v-icon>
            </v-btn>
          </div>
        </v-fade-transition>
      </v-img>
    </v-hover>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { mdiClose } from '@mdi/js'

export default {
  props: {
    imgIdx: {
      type: Number,
      required: true
    },
    src: {
      type: String,
      required: true
    },
    height: {
      type: Number,
      default: null
    },
    removeOn: {
      type: Boolean,
      default: false
    },
    overlay: {
      type: Boolean,
      default: false
    },
    overlayMsg: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      mdiClose,
      
      dragging: false,
      mouseOver: false,
      mouseClicking: false,
      image: ''
    }
  },

  computed: {
    ...mapGetters({
      editorOn: 'notebook/editorOn'
    }),

    removeBtnColor() {
      return this.$vuetify.theme.dark ? 'primary' : ''
    },

    classClicking() {
      return this.mouseClicking
        ? 'mouse-cursor'
        : ''
    }
  },

  methods: {
    removeImage () {
      this.$emit('removeImage', this.imgIdx)
    }
  }
}
</script>

<style scoped>
.border-primary {
  border-color: #4A148C;
}
.border-secondary {
  border-color: #6A1B9A;
}
.mouse-cursor {
  cursor: grabbing;
}
</style>