<template>
  <div :class="background">
    <NoteDrawer />

    <Toolbar v-if="$route.name != 'video.watch'" />
    <VideoToolbar v-else />
    
    <v-main>
      <slot />      
    </v-main>
  </div>
</template>

<script>
import Toolbar from '@components/App/Toolbar'
import VideoToolbar from '@components/Video/Toolbar'
import { NoteDrawer } from '@components/Note'
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    Toolbar,
    VideoToolbar,
    NoteDrawer
  },

  created() {
    if(!this.$socket.notification.id) {
    
      this.$socket.notification.connect()
    
    }

    if(this.$route.name != 'message.list') {
      
      this.$socket.conversation.connect()
    
    } else {
    
      this.$socket.conversation.disconnect()
    
    }
  },

  sockets: {
    notification: {
      connect () {
        this.$socket.notification.emit('saveSocket', {
          profile: this.profile
        })

        this.$socket.notification.emit('loadNotifications', { 
          senderSocket: this.$socket.notification.id,
          profile: this.profile
        })
      },

      notifications (notifications) {
        this.setNotifications(notifications)
      },

      newNotification (notification) {
        this.addNotification(notification)
      }
    }
  },

  computed: {
    ...mapGetters({
      navOn: 'application/navOn',
      navTempOn: 'application/navTempOn',
      profile: 'profile/id'
    }),

    background () {
      return !this.$vuetify.theme.dark
        ? 'background'
        : ''
    }
  },

  methods: {
    ...mapMutations({
      setNotifications: 'notification/setNotifications',
      addNotification: 'notification/addNotification'
    })
  }
}
</script>

<style scoped>
.background {
  background-color: #FAFAFA;
}

/*
  Sets the height of the image skeleton loader to 100% of the parent component.
*/
::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading .v-skeleton-loader__image {
  height: 100% !important;
}

/*
  Sets the height and width of the avatar skeleton loader to 100% of the parent component.
*/
::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading .v-skeleton-loader__avatar {
  height: 100% !important;
  width: 100% !important;
}

/*
  Stops autocomplete and combobox icons from rotating.
  There might be an option to set this within Vuetify 3.
*/
::v-deep .v-select.v-select--is-menu-active
  .v-input__icon--append
  .v-icon {
  transform: rotate(0) !important;
}
</style>