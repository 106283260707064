import { withLayout } from '@utils'
import StudyLayout from '@layouts/Study'

const Study = () => import('@views/Study')

export default {
  path: 'study',
  component: {
    render: h => h('router-view')
  },
  children: [
    {
      path: '',
      name: 'study.home',
      component: withLayout(Study, StudyLayout),
      meta: {
        locked: true,
        app: 'study'
      }
    }
  ]
}
