import { withLayout } from '@utils'
import StudyLayout from '@layouts/Study'

const Animations = () => import('@views/Animations')

export default {
  path: 'animations',
  component: {
    render: h => h('router-view')
  },
  children: [
    {
      path: '',
      name: 'animations.list',
      component: withLayout(Animations, StudyLayout),
      meta: {
        locked: true,
        app: 'animations'
      }
    }
  ]
}
