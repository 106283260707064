export default {
  namespaced: true,

  state: {
    friends: [],
    memberships: []
  },

  getters: {
    friends: ({ friends }) => friends, 
    memberships: ({ memberships }) => memberships
  },

  mutations: {
    SET_FRIENDS: (state, payload) => {
      state.friends = payload
    },
    SET_MEMBERSHIPS: (state, payload) => {
      state.memberships = payload
    }
  }
}