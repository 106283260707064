<template>
  <div
    tabIndex="1"
    style="position: relative; min-height: 20px;"
    :class="`${inputClass} ${paddingClass}`"
  >
    <v-fade-transition hide-on-leave>
      <span
        ref="caret"
        :class="`caret unselectable ${caretFontStyle} ${caretColorClass}`.trim()"
        :style="{
          top: `${caretTop}px`,
          left: `${caretLeft}px`
        }"
      ></span>
    </v-fade-transition>

    <span
      v-if="!edit && textContent.length === 0"
      v-text="label"
      style="position: absolute;"
      :class="`grey--text unselectable ${placeholderClass}`"
    ></span>

    <v-container :class="latexCardClass">
      <v-row
        ref="spanRow"
        class="custom-scroll px-3"
        :style="`position: relative; ${latexAlignment}; min-height: ${lineHeight}px;
          max-width: 100%; overflow-y: hidden; overflow-x: auto;`"
      >
        <div
          ref="textBox"
          :class="`text-box-span ${ latexDivOn ? 'transparent-span' : '' }`"
          :style="`${positionStyle}; min-width: 90%; min-height: 100%; ${colorStyle}`"
          :contenteditable="!syncSubmission && !disabled"
          :autocorrect="false"
          :spellcheck="false"
          @focus="$emit('focus')"
          @blur="$emit('blur', $event, index)"
          @input="$emit('input', $event, index)"
          @paste="$emit('paste', $event, index)"
          v-click-outside="delayEmitRangeState"
        ></div>

        <div
          ref="latexBox"
          :class="`text-box-span unselectable ${ latexDivOn ? ''  : 'transparent-span' }`"
          :style="`position: ${ latexDivOn ? 'relative' : 'absolute' };
            ${ !latexDivOn ? 'color: transparent;' : '' }
            z-index: -10; min-width: 90%;`"
          v-katex:auto="{ options: latexOptions }"
          v-html="contentProxy"
        ></div>
      </v-row>
    </v-container>
    
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { some, map } from 'lodash';
import { latexOptions } from '@utils';

let BASE_LEFT_OFFSET = 12
let CARET_TOP_OFFSET = 6
const EMOJI_OFFSET = 16

function removeSingleParent(formatCaretOn) {
  
  if (formatCaretOn) {
    
    const { parentElement } = document.getSelection().anchorNode
    
    if (parentElement.innerHTML.length === 1) {
      parentElement.parentElement.removeChild(parentElement)
    }

  }

}

function getSelectionData($refs) {
  
  const { anchorNode, anchorOffset } = document.getSelection()

  if (anchorNode) {

    const { tagName } = anchorNode.parentNode
    const textBoxLastChild = $refs.textBox.lastChild

    const formatEOT = ( textBoxLastChild === anchorNode.parentNode ) || ( tagName != 'B' && tagName != 'I' )
    
    return {
      endOfText: formatEOT && anchorNode.nodeName === '#text' && anchorNode.length === anchorOffset,
      endOfInnerHtml: anchorNode.innerHTML && anchorNode.innerHTML.length === anchorOffset,
      lastChildOn: anchorNode.parentElement.lastChild === anchorNode,
      emptyNode: $refs.textBox.innerHTML.length === 0
    }

  } else {

    return {
      endOfText: false,
      endOfInnerHtml: false,
      lastChildOn: false,
      emptyNode: true
    }

  }

}

function unwrap(node) {
  node.replaceWith(...node.childNodes)
}

export default {

  props: {
    index: {
      type: Number,
      default: 0
    },
    paddingClass: {
      type: String,
      default: 'px-3 pt-2'
    },
    edit: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    activeFocusOn: {
      type: Boolean,
      default: false
    },
    focusOn: {
      type: Boolean,
      default: false
    },
    topOffset: {
      type: Number,
      default: 9
    },
    lineHeight: {
      type: Number,
      default: 24
    },
    breaklineOffset: {
      type: Number,
      default: 33
    },
    label: {
      type: String,
      default: ''
    },
    boldSwitch: {
      type: Boolean,
      default: false
    },
    boldOn: {
      type: Boolean,
      default: false
    },
    italicOn: {
      type: Boolean,
      default: false
    },
    italicSwitch: {
      type: Boolean,
      default: false
    },
    latexOn: {
      type: Boolean,
      default: false
    },
    notebookOn: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'paragraph'
    },
    content: {
      type: String,
      default: ''
    },
    syncContentOn: {
      type: Boolean,
      default: false
    },
    syncCarriageReturn: {
      type: Boolean,
      default: false
    },
    syncSwitch: {
      type: Boolean,
      default: false
    },
    syncCarriageStart: {
      type: Boolean,
      default: false
    },
    syncCarriageEnd: {
      type: Boolean,
      default: false
    },
    syncCarriageUp: {
      type: Boolean,
      default: false
    },
    syncCarriageDown: {
      type: Boolean,
      default: false
    },
    previousCaretLeft: {
      type: Number,
      default: null
    },
    syncEmoji: {
      type: Boolean,
      default: false
    },
    linkText: {
      type: String,
      default: ''
    },
    linkUrl: {
      type: String,
      default: ''
    },
    syncLink: {
      type: Boolean,
      default: false
    },
    syncRangeContainer: {
      type: Object,
      default: () => {
        return {}
      }
    },
    syncRangeOffset: {
      type: Number,
      default: 0
    },
    mentionCardOn: {
      type: Boolean,
      default: false
    },
    syncMention: {
      type: Boolean,
      default: false
    },
    mentionData: {
      type: Object,
      default() {
        return {
          username: '',
          id: ''
        }
      }
    },
    syncSubmission: {
      type: Boolean,
      default: false
    },
    syncFocusOn: {
      type: Boolean,
      default: false
    }
  },

  created() {

    if (!this.paddingClass) {
    
      BASE_LEFT_OFFSET = 0
      CARET_TOP_OFFSET = 0

    }

    this.caretOn = this.delayCaretOn = this.index > 0

  },

  mounted() {

    if (this.$refs.textBox) {
      
      this.$refs.textBox.innerHTML = this.content
      this.textContent = this.content

    }

    window.addEventListener('resize', this.setCaretOff)
    
    this.$refs.textBox.addEventListener('paste', this.doPaste)
    this.$refs.textBox.addEventListener('copy', this.doCopy)
    this.$refs.textBox.addEventListener('keydown', this.onKeyDown)
    this.$refs.textBox.addEventListener('input', this.onInput)
    this.$refs.textBox.addEventListener('click', this.onClick)

    this.$refs.textBox.addEventListener('mousedown', this.setCaretOff)
    this.$refs.textBox.addEventListener('mouseup', this.setCaretOn)

  },

  beforeDestroy() {

    window.removeEventListener('resize', this.setCaretOff)
  
    this.$refs.textBox.removeEventListener('paste', this.doPaste)
    this.$refs.textBox.removeEventListener('copy', this.doCopy)
    this.$refs.textBox.removeEventListener('keydown', this.onKeyDown)
    this.$refs.textBox.removeEventListener('input', this.onInput)
    this.$refs.textBox.removeEventListener('click', this.onClick)

    this.$refs.textBox.removeEventListener('mousedown', this.setCaretOff)
    this.$refs.textBox.removeEventListener('mouseup', this.setCaretOn)
    
  },
  
  data() {
    return {

      latexOptions,

      latexCardOn: false,

      textContent: '',

      caretTop: this.topOffset,
      caretLeft: BASE_LEFT_OFFSET,

      caretOn: true,
      delayCaretOn: true,

      preserveSelection: false,

      mentionRange: {}

    }
  },

  computed: {
    
    ...mapGetters({
      emoji: 'emoji'
    }),

    contentProxy() {
      return !this.focusOn ? this.content: ''
    },

    latexCardClass() {
      return this.latexCardOn ? 'latexCardClass' : 'textBoxClass'
    },

    colorStyle() {
      return this.latexDivOn ? 'color: transparent;' : '' 
    },

    positionStyle() {
      return `position: ${this.latexDivOn ? 'absolute' : 'relative'}` 
    },

    inputClass() {

      const type = this.type === 'title'
        ? 'title'
        : this.type === 'section'
        ? 'section'
        : this.type === 'textQuote'
        ? 'text-quote'
        : this.notebookOn
        ? 'notebook-paragraph'
        : 'paragraph'

      return `${type}-line`

    },

    placeholderClass() {
      return this.$vuetify.theme.dark
        ? 'text--darken-2'
        : 'text--lighten-1'
    },

    latexDivOn() {
      return this.latexDelimiterOn && this.latexOn && !this.focusOn
    },
    
    caretColorClass() {
      return this.activeFocusOn && this.delayCaretOn && !this.disabled
        ? 'active-caret'
        : 'transparent-caret'
    },

    caretFontStyle() {

      if (this.type === 'paragraph') {

        const italicClass = this.italicOn ? 'italic-caret' : ''
        const boldClass = this.boldOn ? 'bold-caret' : ''

        return `${italicClass} ${boldClass}`
      
      } else {

        return ''

      }

    },

    latexDelimiterOn() {

      const rightDelimiters = map(this.latexOptions.delimiters, 'right')

      return some(rightDelimiters, delimiter => {

        return this.textContent.indexOf(delimiter) != -1

      })

    },

    latexAlignment() {

      const leftDelimiters = this.latexOptions.delimiters.filter(({left}) => {
        return left != '$' && left != '\\('
      })
      .map(({left}) => left)

      const hasAlignDelimiter = leftDelimiters.some(delimiter => {
        return this.textContent.indexOf(delimiter) === 0
      })

      return hasAlignDelimiter ? 'justify-center' : ''
      
    }

  },

  watch: {

    syncSubmission(sync) {

      if (sync && this.focusOn) {

        this.emitTextInput()
      
      }

    },

    latexDivOn() {

      if (this.focusOn) {

        this.latexCardOn = false

      }

      setTimeout(() => {
        this.latexCardOn = !this.focusOn && this.$refs.latexBox.scrollWidth > this.$refs.spanRow.clientWidth
      })

    },

    syncFocusOn(focus) {

      if (focus && this.index === 0) {

        this.$refs.textBox.focus()

      }

    },

    syncCarriageEnd(sync) {
      
      if (sync && this.focusOn) {

        const { htmlLen, childIndex } = this.lastChildProps()

        setTimeout(() => {

          this.resetSelection(htmlLen, childIndex)

        })
      
      }

    },

    syncCarriageStart(sync) {

      if (sync && this.focusOn) {

        setTimeout(() => {

          this.resetSelection(0, 0)

        })

      }
      
    },

    syncCarriageReturn(sync) {

      if (sync) {

        const { htmlLen, childIndex } = this.lastChildProps()
        
        setTimeout(() => {

          this.resetSelection(htmlLen, childIndex)

          this.calcCaretPosition()
          
        })

      }

    },
    
    syncCarriageUp(sync) {

      if (sync && this.focusOn) {

        const { htmlLen, childIndex } = this.lastChildProps()

        setTimeout(() => {

          this.resetSelection(htmlLen, childIndex)

          let anchorOffset = -1
          let endOfLine = false

          document.getSelection().modify('move', 'backward', 'character')
          document.getSelection().modify('move', 'forward', 'character')

          const textBoxRectX = this.getTextBoxRect().x
          let caretLeft = this.getSelectionCoords().x - textBoxRectX
          
          while(caretLeft >= this.previousCaretLeft && !endOfLine) {         

            caretLeft = this.getSelectionCoords().x - textBoxRectX

            document.getSelection().modify('move', 'backward', 'character')
            
            endOfLine = anchorOffset === document.getSelection().anchorOffset
            anchorOffset = document.getSelection().anchorOffset

            this.calcCaretPosition()
            
          }

          this.calcCaretPosition()

        })

      }

    },

    syncCarriageDown(sync) {

      if (sync && this.focusOn) {   

        setTimeout(() => {

          this.resetSelection(0, 0)

          let anchorOffset = -1
          let endOfLine = false

          while(this.calcCurrentRow()  == 1 && this.caretLeft < this.previousCaretLeft && !endOfLine) {

            document.getSelection().modify('move', 'forward', 'character')

            endOfLine = anchorOffset === document.getSelection().anchorOffset
            anchorOffset = document.getSelection().anchorOffset

            this.calcCaretPosition()

          }

          if (this.calcCurrentRow() > 1) {

            document.getSelection().modify('move', 'backward', 'character')
            this.calcCaretPosition()

          }

        })

      }

    },

    syncEmoji(sync) {

      if (sync && this.focusOn) {

        this.$refs.textBox.focus()

        const numRows = this.calcNumRows()

        setTimeout(() => {

          const range = document.getSelection().getRangeAt(0)

          const { commonAncestorContainer } = range
          const classList = commonAncestorContainer.classList
            ? commonAncestorContainer.classList
          : null

          const parentClassList = commonAncestorContainer.parentElement.classList
            ? commonAncestorContainer.parentElement.classList
            : null

          const emojiValidEl = classList && classList.contains('text-box-span')
          const emojiValidParent = parentClassList && parentClassList.contains('text-box-span')
          
          if (emojiValidEl || emojiValidParent) {

            const emojiTextNode = document.createTextNode(this.emoji)

            range.insertNode(emojiTextNode)
            this.textContent = this.$refs.textBox.textContent.length > 0
              ? this.$refs.textBox.innerHTML
              : ''

            document.getSelection().modify('move', 'forward', 'character')

            if (this.calcNumRows() > numRows) {

              this.caretLeft = BASE_LEFT_OFFSET + EMOJI_OFFSET
              this.caretTop += this.lineHeight

            } else {

              this.caretLeft += EMOJI_OFFSET

            }

          }
          
        })
      }
    },

    syncLink(sync) {

      if (sync && this.focusOn) {

        this.$refs.textBox.focus()

        const webAnchorEl = document.createElement('a')

        webAnchorEl.href = this.linkUrl
        webAnchorEl.target = '_blank'
        webAnchorEl.innerHTML = this.linkText

        const range = document.getSelection().getRangeAt(0)

        let containerIdx = -1

        if (this.$refs.textBox.innerHTML.length > 0) {
          
          const childNodes = [ ...this.$refs.textBox.childNodes ]
          containerIdx = childNodes.indexOf(this.syncRangeContainer)
          const containerNode = this.$refs.textBox.childNodes[containerIdx]

          if (containerIdx > -1) {
            range.setStart(containerNode, this.syncRangeOffset)
            range.setEnd(containerNode, this.syncRangeOffset)
          }
          
        }

        if (containerIdx > -1 || this.$refs.textBox.innerHTML.length === 0) {

          range.insertNode(webAnchorEl)
          range.collapse()

          setTimeout(() => {

            const webAnchorRect = webAnchorEl.getBoundingClientRect()
            const textBoxRect = this.$refs.textBox.getBoundingClientRect()

            this.caretLeft = webAnchorRect.x > 0
              ? webAnchorRect.x - textBoxRect.x + BASE_LEFT_OFFSET + webAnchorEl.offsetWidth
              : BASE_LEFT_OFFSET

            this.caretTop = webAnchorRect.y > 0
              ? webAnchorRect.y - textBoxRect.y + CARET_TOP_OFFSET
              : this.topOffset

          })

          this.textContent = this.$refs.textBox.innerHTML

        }

      }
    },

    focusOn(focus) {
      
      if (!focus) {

        this.setCaretOff()
        
      } else if (this.syncContentOn) {
        
        setTimeout(() => {

          this.calcCaretPosition()
          this.setCaretOn()

        })
      
      } else {

        setTimeout(() => {

          this.delayCaretOn = this.caretOn

        }, 50)

      }

    },

    caretOn(caret) {

      setTimeout(() => {

        this.delayCaretOn = caret

      }, 50)

    },

    syncMention(sync) {
      if (sync && this.focusOn) this.doMention()
    },

    syncSwitch() {
      if (this.focusOn) this.$refs.textBox.focus()
    },

    content(newContent, oldContent) {

      if (this.syncContentOn) {

        const { htmlLen, childIndex } = this.lastChildProps()
        
        this.$refs.textBox.innerHTML = newContent
        this.textContent = newContent

        if (newContent.indexOf(oldContent) === -1) {

          // runs if content change wasn't caused by deleteKey
          this.calcCaretPosition()

        }

        if (this.preserveSelection) {

          this.preserveSelection = false

          setTimeout(() => {

            this.resetSelection(htmlLen, childIndex)
            
          })

        }

      }

    },

    textContent(str) {
      
      if (!this.syncContentOn) {

        this.emitTextInput()

      }

    },

    italicSwitch() {
      
      if (this.focusOn) {
        
        this.doFontFormat('i')        

      }

    },

    boldSwitch() {
      
      if (this.focusOn) {

        this.doFontFormat('b')   

      }

    }

  },

  methods: {

    getTextBoxRect() {
      return this.$refs.textBox.getBoundingClientRect()
    },

    getSelectionCoords() {

      let selection = document.selection, range, rect;
      let x = 0, y = 0;

      if (selection) {

        if (selection.type != "Control") {

          range = selection.createRange()
          range.collapse(true)
          x = range.boundingLeft
          y = range.boundingTop

        }

      } else if (window.getSelection) {

        selection = window.getSelection()

        if (selection.rangeCount) {

          range = selection.getRangeAt(0).cloneRange()

          if (range.getClientRects) {

            range.collapse(true)

            if (range.getClientRects().length > 0){

              rect = range.getClientRects()[0]
              x = rect.left
              y = rect.top
            
            }

          }

          // Fall back to inserting a temporary element
          if (x == 0 && y == 0) {

            const span = document.createElement('span');

            if (span.getClientRects) {

              // Ensure span has dimensions and position by
              // adding a zero-width space character
              span.appendChild( document.createTextNode('\u200b') )
              range.insertNode(span)

              rect = span.getClientRects()[0]

              x = rect.left
              y = rect.top

              const spanParent = span.parentNode
              spanParent.removeChild(span)

              // Glue any broken text nodes back together
              spanParent.normalize()

            }

          }

        }

      }

      return { x, y }

    },

    emitTextInput() {

      this.$emit('textInput', {
        textContent: this.$refs.textBox.innerHTML
      })

    },

    lastChildProps() {
      
      const { lastChild } = this.$refs.textBox
      const htmlLen = lastChild ? lastChild.length : null
      const childNodes = [ ...this.$refs.textBox.childNodes ]
      const childIndex = childNodes.indexOf(lastChild)

      return { htmlLen, childIndex }
    
    },

    resetSelection(htmlLen = 0, childIndex) {

      const childNodes = [ ...this.$refs.textBox.childNodes ]

      if (this.focusOn && childIndex > -1 && childNodes.length > childIndex) {

        const range = document.createRange()

        const childNode = childNodes[childIndex]

        if (childNode.tagName === 'BR') {

          const previousSibling = childNodes[childIndex].previousSibling

          let textContent;

          if (previousSibling.nodeName === '#text') {

            textContent = previousSibling.textContent 

          } else {

            textContent = previousSibling.innerText

          }


          range.setStart(previousSibling, textContent.length)
          range.setEnd(previousSibling, textContent.length)

        } else {

          range.setStart(childNodes[childIndex], htmlLen)
          range.setEnd(childNodes[childIndex], htmlLen)

        }

        document.getSelection().removeAllRanges()
        document.getSelection().addRange(range)

      }

      if (this.focusOn) {
        this.$refs.textBox.focus()
        this.calcCaretPosition()
        this.setCaretOn()      
      }

    },

    insertTextAtCaret(text) {

      const range = document.getSelection().getRangeAt(0)
      range.deleteContents()

      const node = document.createTextNode(text)
      range.insertNode(node)

      document.getSelection().collapse(node, text.length)

    },

    setCaretOn() {

      const range = document.getSelection().getRangeAt(0)

      if ( range ) {
      
        const { collapsed } = range
        this.caretOn = collapsed && this.focusOn
      
      }      

    },

    setCaretOff() {
      this.caretOn = false
      this.delayCaretOn = false
    },

    formatCaretOn() {

      const docSel = document.getSelection()

      if (docSel.anchorNode) {

        const parentEl = docSel.anchorNode.parentElement
        const parentElClassList = [...parentEl.classList]
        const formatClasses = ['font-weight-bold', 'font-italic']

        const filterFormat = formatClasses.map(formatClass => {
          return parentElClassList.indexOf(formatClass) != -1
        })
        .indexOf(true) != -1

        return filterFormat

      } else {

        return false
      
      }

    },

    emitLinkCaretStatus() {

      const docSel = document.getSelection()

      if (docSel.anchorNode) {

        const parentEl = docSel.anchorNode.parentElement
        const linkCaretOn = parentEl.tagName === 'A'

        this.$emit('linkCaretOn', linkCaretOn)

      }
      
    },

    cleanEmptyChildren() {

      const docSelection = document.getSelection()
      const { anchorNode } = docSelection

      if (anchorNode) {

        const childNodes = anchorNode
          ? [ ...anchorNode.parentElement.childNodes ]
          : []

        childNodes.forEach(childNode => {
          
          if (childNode.nodeName === '#text' && childNode.nodeValue === '') {
          
            anchorNode.parentElement.removeChild(childNode)
          
          }

        })

        this.$refs.textBox.focus()

      }

    },

    doMention() {

      this.$refs.textBox.focus()

      setTimeout(() => {

        const newParent = document.createElement('a')

        newParent.href = `/people/profile?id=${this.mentionData.id}`
        newParent.target = '_blank'
        newParent.dataset.profile = this.mentionData.id

        const docSelection = document.getSelection()
        
        docSelection.removeAllRanges()
        docSelection.addRange(this.mentionRange)

        this.mentionRange.surroundContents(newParent)

        newParent.classList.add('mention')
        newParent.innerText = `@${this.mentionData.username}`

        this.mentionRange.collapse()
        this.insertTextAtCaret(' ')
        this.calcCaretPosition()
        this.textContent = this.$refs.textBox.innerHTML

        setTimeout(() => {
          this.cleanEmptyChildren()
        })

      })
      
    },

    setMentionCard() {
      this.mentionRange = document.getSelection().getRangeAt(0)
      const { x } = this.$refs.textBox.getBoundingClientRect()
      this.$emit('mention', { x })
    },

    emitRangeState() {
      this.$emit('setRangeState', !document.getSelection().isCollapsed)
    },

    delayEmitRangeState() {
      setTimeout(this.emitRangeState)
    },

    resetFontFormatting() {

      const selection = document.getSelection()

      if (selection.anchorNode) {

        if (selection.isCollapsed && selection.anchorNode.parentNode.tagName === 'B') {

          this.$emit('setBoldOn')

        } else if (selection.isCollapsed) {

          this.$emit('resetBold')

        }

        if (selection.isCollapsed && selection.anchorNode.parentNode.tagName === 'I') {

          this.$emit('setItalicOn')

        } else if (selection.isCollapsed) {

          this.$emit('resetItalic')

        }

      }

    },

    calcNumRows() {
      const { clientHeight } = this.$refs.textBox
      return  Math.floor(clientHeight / this.lineHeight)
    },

    calcCurrentRow() {

      const selectionRect = this.getSelectionCoords()
      const textBox = this.getTextBoxRect()
      const caretTop = selectionRect.y - textBox.y + CARET_TOP_OFFSET + this.lineHeight

      return Math.max(Math.floor(caretTop / this.lineHeight), 1)    

    },

    calcCaretPosition(shiftEnter = false) {

      const { x, y } = this.getSelectionCoords()
      const textBoxRect = this.getTextBoxRect()

      const preCaretTop = this.caretTop

      this.caretTop = y - textBoxRect.y + CARET_TOP_OFFSET
      this.caretLeft = x - textBoxRect.x + BASE_LEFT_OFFSET

      setTimeout(() => {
        
        if(this.caretTop === preCaretTop && shiftEnter) {

          this.caretTop += this.lineHeight
          this.caretLeft = BASE_LEFT_OFFSET

        }

      })      

    },

    updateInput(shiftEnter) {

      this.emitRangeState()

      if (this.$refs.textBox) {

        this.textContent = this.$refs.textBox.innerText.split('<br>')
          .filter(s => s)
          .join('<br>')

      }

      this.calcCaretPosition(shiftEnter)

      if (!shiftEnter) {

        setTimeout(this.calcCaretPosition)

      }

    },

    doPaste(e) {

      e.preventDefault();

      const pasteTxt = e.clipboardData.getData('text/plain')

      if (pasteTxt != '' && !this.disabled) {

        this.insertTextAtCaret(pasteTxt)
        this.updateInput()
      
      }
      
    },

    doCopy(evt) {

      const selection = document.getSelection();

      evt.clipboardData.setData('text/plain', selection.toString());
      evt.preventDefault();

    },

    onInput(e) {
      
      if (e.inputType != 'insertLineBreak') {
      
        this.updateInput()
      
      }

    },

    onClick() {

      setTimeout(this.emitLinkCaretStatus)
      
      this.resetFontFormatting()

      const { lastChild } = this.$refs.textBox

      if (lastChild && lastChild.nodeName === '#text' && !lastChild.textContent) { // deals with bold and italic fonts

        lastChild.nodeValue = ' '

        document.getSelection().modify('move', 'forward', 'character')

      }

      setTimeout(this.setCaretOn)
      
      this.updateInput()
      this.emitRangeState()

    },

    removeBreakLine() {

      if (this.$refs.textBox && this.$refs.textBox.innerHTML.split('<br>').every(val => val === '')) {
        this.$refs.textBox.innerHTML = ''
      }

      if (this.$refs.textBox) {
        this.textContent = this.$refs.textBox.innerHTML
      }

    },

    onKeyDown(e) {

      setTimeout(this.removeBreakLine, 10)
      
      setTimeout(this.emitLinkCaretStatus, 10)

      this.prepareMention(e)

      setTimeout(this.resetFontFormatting, 10)

      if (e.key === 'a' && e.ctrlKey) {

        setTimeout(this.emitRangeState)
        this.setCaretOff()
      
      } else {
      
        setTimeout(this.setCaretOn)
      
      }
      
      const backspaceKey = e.key === 'Backspace'
      const deleteKey = e.key === 'Delete'

      if (backspaceKey) {

        this.doBackspace(e)

        removeSingleParent(this.formatCaretOn())
      
      } else if (deleteKey) {

        this.doDelete()

        removeSingleParent(this.formatCaretOn())

      } else {

        this.doKey(e)

      }

    },

    prepareMention(e) {

      const { anchorNode, anchorOffset } = document.getSelection()

      if (anchorNode) {

        const prevCharIdx = anchorOffset > 0
          ? anchorOffset - 1
          : anchorOffset

        const nextCharIdx = anchorOffset < anchorNode.textContent.length - 1
          ? anchorOffset + 1
          : anchorOffset

        const prevChar = anchorNode.textContent[prevCharIdx]
        const nextChar = anchorNode.textContent[nextCharIdx]
        const currentChar = anchorNode.textContent[anchorOffset]

        const nextCharSpace = !nextChar || currentChar === ' '
        const freeSorroundings = nextCharSpace && prevChar === ' '
        const allowMention = !this.content || freeSorroundings

        if (e.key === '@' && !this.formatCaretOn() && allowMention) {

          this.setMentionCard()

        }

        const caretMention = anchorNode
          && e.key.indexOf('Arrow') === -1
          && anchorNode.parentElement.classList.contains('mention')

        const anchorSiblings = [ ...anchorNode.parentElement.childNodes ]
        const anchorIdx = anchorSiblings.indexOf(anchorNode)
        const prevAnchor = anchorIdx > 0
          ? anchorSiblings[anchorIdx - 1]
          : anchorSiblings[anchorIdx]

        const prevAnchorContainsMentions = prevAnchor.classList
          && prevAnchor.classList.contains('mention')

        const backspaceKey = e.key === 'Backspace'
          
        if (caretMention && !backspaceKey) {
          
          e.preventDefault()
        
        } else if (caretMention) {

          anchorNode.parentElement.parentElement.removeChild(anchorNode.parentElement)

        } else if (backspaceKey && prevAnchorContainsMentions) {

          prevAnchor.parentElement.removeChild(prevAnchor)
              
        }

      }

    },

    doKey(e) {

      const currentRow = this.calcCurrentRow()

      const startRowCaret = this.caretLeft === BASE_LEFT_OFFSET
      const startRow = currentRow === 1
      const lastRow = currentRow === this.calcNumRows()

      const { endOfText, endOfInnerHtml, lastChildOn, emptyNode } = getSelectionData(this.$refs)
      const shiftEnter = e.shiftKey && e.key === 'Enter'

      if (e.key === 'Enter' && !e.shiftKey) {

        e.preventDefault()

        if (this.caretOn && !this.formatCaretOn()) {

          const range = new Range()
          const { anchorNode, anchorOffset } = document.getSelection()

          if (anchorNode != this.$refs.textBox && this.$refs.textBox.firstChild) {

            const lastChild = anchorNode.parentElement.lastChild

            range.setStart(anchorNode, anchorOffset)
            range.setEnd(lastChild, lastChild.length)
            
            document.getSelection().removeAllRanges()
            document.getSelection().addRange(range)

          } else if (this.$refs.textBox.firstChild) {

            const lastChild = anchorNode.lastChild

            range.setStart(anchorNode.firstChild, 0)
            range.setEnd(lastChild, lastChild.length)
            
            document.getSelection().removeAllRanges()
            document.getSelection().addRange(range)

          }

          const docFragment = this.$refs.textBox.firstChild
            ? range.extractContents()
            : { childNodes: [] }

          this.$emit('enterKey', {
            docFragment,
            textContent: this.$refs.textBox.innerHTML
          })
          this.textContent = this.$refs.textBox.innerHTML

          this.$emit('overrideSyncContent', {
            textContent: this.textContent,
            index: this.index
          })
          
        }

      } else if (shiftEnter) {

        if (this.formatCaretOn() || this.type === 'title' || this.type === 'section') {
          
          e.preventDefault()
        
        } else {
        
          this.updateInput(true)
        
        }

      } else if (e.key.indexOf('Arrow') != -1 && !e.shiftKey) {

        setTimeout(this.calcCaretPosition, 10)

        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {

          if (e.key === 'ArrowUp') {

            if (startRow) {
              
              e.preventDefault()

              this.$emit('carriageUp', { caretLeft: this.caretLeft })

            } else {

              setTimeout(this.calcCaretPosition, 10)

            }

          } else if (e.key === 'ArrowDown') {

            if (lastRow) {

              e.preventDefault()

              this.$emit('carriageDown', { caretLeft: this.caretLeft })

            } else {

              setTimeout(this.calcCaretPosition, 10)

            }

          } 

        } else if (e.key === 'ArrowLeft' && startRowCaret && startRow && this.index > 0) {
          
          this.$emit('carriagePrevious')

        } else if (e.key === 'ArrowRight' && lastChildOn && endOfText || endOfInnerHtml || emptyNode) {
        
          this.$emit('carriageNext')
        
        }

      }

    },

    doDelete() {

      const { endOfText, endOfInnerHtml, lastChildOn, emptyNode } = getSelectionData(this.$refs)

      const lastEl = lastChildOn || emptyNode
      const endOfEl = endOfText || endOfInnerHtml || this.textContent === ''

      if (lastEl && endOfEl) {
       
        this.preserveSelection = true
        
        this.$emit('deleteKey')

      } else {
        
        setTimeout(() => {

          this.calcCaretPosition()
          this.setCaretOn()
        
        }, 10)
      
      }

    },

    doBackspace() {

      const currentRow = this.calcCurrentRow()
      const startRowCaret = this.caretLeft === BASE_LEFT_OFFSET
      const startRow = currentRow === 1

      if (startRowCaret && startRow && this.index > 0) {

        this.$emit('backspaceKey', {
          textContent: this.$refs.textBox.innerHTML
        })

      } else {

        const brOffsetArr = []

        this.$refs.textBox.childNodes.forEach(childNode => {
          
          if (childNode.tagName === 'BR') {
          
            const rect = childNode.getBoundingClientRect()
            const textBox = this.$refs.textBox.getBoundingClientRect()
            
            brOffsetArr.push(rect.x - textBox.x + BASE_LEFT_OFFSET)
          
          }

        })

        const brIdx = Math.max(currentRow - 2, 0)
        const brOffset = brOffsetArr[brIdx]

        if (startRowCaret && startRow) {
          
          this.caretLeft = brOffset
        
        } else {          
          
          setTimeout(() => {
            
            this.calcCaretPosition()
            this.setCaretOn()
        
          }, 10)
        
        }
          
      }

    },

    cleanChildren(formatEl, format) {

      const childNodes = [ ...formatEl.childNodes ]

      childNodes.forEach(childNode => {

        if ( childNode.parentElement.parentElement.tagName === format.toUpperCase() ) {
        
          unwrap(childNode.parentElement)
        
        } else if ( childNode.tagName === format.toUpperCase() ) {

          unwrap(childNode)

        }

      })

    },

    formatSelection(format) {

      const selection = document.getSelection()
      const range = selection.getRangeAt(0)
      const collapsedRange = range.collapsed
      const fragment = range.extractContents()
      const formatEl = document.createElement(format)
      
      formatEl.appendChild(fragment)
      range.insertNode(formatEl)

      if (collapsedRange) {

        formatEl.appendChild( document.createTextNode('\u200b') )
      
        selection.setBaseAndExtent(formatEl, 0, formatEl, 0)

        document.getSelection().modify('move', 'forward', 'character')

      } else {

        this.cleanChildren(formatEl, format)
        selection.selectAllChildren(formatEl)

      }

    },

    doFontFormat(format) {

      const docSelection = document.getSelection()
      const { anchorNode } = docSelection
      const formatTagName = format.toUpperCase()
      const isCollapsed = docSelection.isCollapsed

      if( anchorNode.parentNode.tagName === formatTagName && !isCollapsed) {

        unwrap(docSelection.anchorNode.parentNode)
        
      } else if(!isCollapsed)  {

        this.formatSelection(format)

      }

      setTimeout(this.cleanEmptyChildren)

    }

  }

}
</script>

<style scoped>

.textBoxClass {
  padding: 0;
}

.latexCardClass {
  border: thin solid rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  padding: 0.5em 0.1em 0.5em 1.1em;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.text-box-span {
  caret-color: transparent;
  border-width: 0px;
  border: none;
  word-break: break-word;
  white-space: pre-wrap !important;
  white-space: -moz-pre-wrap !important;
  white-space: -pre-wrap !important;
  white-space: -o-pre-wrap !important;
  word-wrap: break-word !important;
  outline: none !important;
}

.theme--light .textBox ::selection {
  background: rgba(133, 69, 212, 0.2);
}

.theme--light .selected {
  background: #ece0fc;
}

.theme--dark .selected {
  background: white;
  color: #8348ca;
}

.italic-caret {
  transform: rotate(10deg) !important;
}

.bold-caret {
  min-width: 3px !important;
}

.transparent-caret {
  background: transparent !important;
}

.active-caret {
  background: #8348ca !important;
}

.caret {
  position: absolute;
  z-index: 3;
  display: inline-block;
  text-decoration: blink;
  animation: blinker 1s linear infinite;
}

.caret.selection {
  background:#8348ca;
  color:black;
}

.title-line .caret {
  min-width: 2px;
  height: 3rem;
}

.title-line {
  line-height: 3.187rem;
  font-size: 2.5rem;
  cursor: text;
}

.section-line {
  line-height: 2.625rem;
  font-size: 1.8rem;
  cursor: text;
}

.section-line .caret {
  min-width: 2px;
  height: 2.2rem;
}

.paragraph-line {
  font-size: 1rem;
  line-height: 1.50rem;
  min-height: 3rem;
  cursor: text;
}

.paragraph-line .caret {
  min-width: 2px;
  height: 1.3rem;
}

.notebook-paragraph-line {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
  min-height: 3rem;
}

.notebook-paragraph-line .caret {
  min-width: 2px;
  height: 1.5rem;
  transform: translateY(2px);
}

@keyframes blinker {  
  50% { opacity: 0.0; }
}

</style>