var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"outlined":"","elevation":"0","rounded":"xl","clickable":""},on:{"click":function($event){return _vm.$emit('callAnna')}}},[(_vm.notes.length > 0)?_c('AnnaCard',{attrs:{"index":0,"note":_vm.notes[0],"noteId":_vm.notes[0].id}}):_vm._e()],1),_c('div',{staticClass:"my-4"}),_c('v-card',{staticStyle:{"border-radius":"18px"},attrs:{"elevation":"0"}},[_c('v-container',{staticClass:"hs-rounded-tabs"},[_c('v-row',{staticClass:"hs-rounded-tabs px-3"},[_c('v-btn',{staticClass:"tab-btn text-body-2 font-weight-medium hs-rounded-12",class:{
            'v-tab--active': _vm.activeTab === 0,
            'primary--text': _vm.activeTab === 0,
            'grey--text': _vm.activeTab != 0,
            'text--lighten-1': _vm.$vuetify.theme.dark && _vm.activeTab != 0
          },attrs:{"elevation":"0","text":""},on:{"click":function($event){return _vm.setTab(0)}}},[_c('span',[_vm._v("Notes")]),(_vm.$vuetify.breakpoint.lgAndUp)?_c('v-icon',{attrs:{"right":_vm.$vuetify.breakpoint.lgAndUp,"dense":""}},[_vm._v(" "+_vm._s(_vm.mdiText)+" ")]):_vm._e()],1),_c('v-btn',{staticClass:"tab-btn text-body-2 font-weight-medium hs-rounded-12",class:{
            'v-tab--active': _vm.activeTab === 1,
            'primary--text': _vm.activeTab === 1,
            'grey--text': _vm.activeTab != 1,
            'text--lighten-1': _vm.$vuetify.theme.dark && _vm.activeTab != 1
          },attrs:{"elevation":"0","text":""},on:{"click":function($event){return _vm.setTab(1)}}},[_c('span',[_vm._v("Folders")]),(_vm.$vuetify.breakpoint.lgAndUp)?_c('v-icon',{attrs:{"right":_vm.$vuetify.breakpoint.lgAndUp,"dense":""}},[_vm._v(" "+_vm._s(_vm.mdiFolder)+" ")]):_vm._e()],1)],1)],1)],1),_c('div',{staticClass:"my-4"}),_c('Scrollbar',{style:(("height: " + (_vm.innerHeight * 0.9 * (1 - ((_vm.isSubscriptionActive ? 250 : 300) / _vm.innerHeight))) + "px")),attrs:{"thin":true,"autoHide":"leave"}},[_c('div',{staticClass:"px-4"},[_c('span',{staticClass:"text-h6"},[_vm._v("Recent Personas")]),_vm._l((_vm.personas),function(persona,i){return _c('div',{key:("persona_" + i),on:{"click":function($event){return _vm.$router.push(("/personas?id=" + (persona._id)))}}},[_c('PersonaCard',{key:("persona_" + i),attrs:{"name":persona.name,"avatar":persona.avatar,"caption":persona.caption},on:{"persona":function($event){return _vm.$emit('setPersona', persona)}}})],1)})],2)]),(!_vm.isSubscriptionActive)?_c('div',{staticClass:"my-4",staticStyle:{"text-align":"center"}},[_c('v-btn',{attrs:{"small":"","rounded":"","depressed":"","color":"primary","loading":_vm.loadingPaymentLink},on:{"click":_vm.getSubscriptionPaymentLink}},[_vm._v(" "+_vm._s(_vm.$t('subscriptions.try'))+" ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }