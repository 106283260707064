<template>
  <div class="absolute-div">
    <div :id="`page-container--${pageNumber}`">
      <canvas
        v-visible.once="drawPage"
        v-bind="canvasAttrs"
        :id="`canvas--${pageNumber}`"
      ></canvas>

      <div
        class="textLayer"
        :id="`text-layer--${pageNumber}`"
      ></div>
    </div>
  </div>
</template>

<script>
const PDFJSViewer = require('pdfjs-dist/legacy/web/pdf_viewer')
const { TextLayerBuilder } = PDFJSViewer

import debug from 'debug'
const log = debug('app:components/PDFPage')

import { PIXEL_RATIO } from '@utils/constants'
import visible from '@directives/visible'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'PDFPage',

  props: {
    page: {
      type: Object, // instance of PDFPageProxy returned from pdf.getPage
      required: true,
    },
    scale: {
      type: Number,
      required: true,
    },
    rotation: {
      type: Number,
      required: true
    },
    optimalScale: {
      type: Number,
      required: true,
    },
    isPageFocused: {
      type: Boolean,
      default: false,
    },
    isElementFocused: {
      type: Boolean,
      default: false,
    },
  },

  directives: {
    visible,
  },

  created () {
    // PDFPageProxy#getViewport
    // https://mozilla.github.io/pdf.js/api/draft/PDFPageProxy.html
    this.viewport = this.page.getViewport({ scale: this.optimalScale })
  },

  mounted () {
    log(`Page ${this.pageNumber} mounted`)
  },

  beforeDestroy () {
    this.destroyPage(this.page)
    this.setScale(undefined)
    this.setRotation(0)
  },

  data () {
    return {
      rendered: false,
      newMatches: []
    }
  },

  computed: {
    ...mapGetters({
      matches: 'pdf/matches',
      textQuery: 'pdf/textQuery',
      isSearchListQuery: 'pdf/isSearchListQuery',
      querySwitch: 'pdf/querySwitch',
      currentMatchPage: 'pdf/currentMatchPage',
      previousMatchPage: 'pdf/previousMatchPage',
      currentMatchIndex: 'pdf/currentMatchIndex',
      previousMatchIndex: 'pdf/previousMatchIndex',
      highlightSelectedSwitch: 'pdf/highlightSelectedSwitch'
    }),

    actualSizeViewport () {
      return this.viewport.clone({ scale: this.scale });
    },

    canvasStyle() {

      const {width: actualSizeWidth, height: actualSizeHeight} = this.actualSizeViewport;
      const [pixelWidth, pixelHeight] = [actualSizeWidth, actualSizeHeight].map(dim => Math.ceil(dim / PIXEL_RATIO));

      return  (this.rotation / 90) % 2 === 0
        ? `width: ${pixelWidth}px; height: ${pixelHeight}px;`
        : `width: ${pixelHeight}px; height: ${pixelWidth}px;`

    },

    canvasAttrs() {

      let {width, height} = this.viewport; // here we must use the semicolon ";"
      [width, height] = (this.rotation / 90) % 2 === 0
        ? [width, height].map(dim => Math.ceil(dim))
        : [height, width].map(dim => Math.ceil(dim))
      const style = this.canvasStyle

      return {
        width,
        height,
        style,
        class: 'pdf-page box-shadow',
      }

    },

    pageNumber() {
      return this.page.pageNumber
    }
  },

  watch: {
    matches(newMatches) {
      
      this.newMatches = newMatches
      
      if (newMatches.length > 0) {

        this.highlight(newMatches)

      }
        
    },

    currentMatchIndex(newMatchIndex, oldMatchIndex) {
      if (
        this.rendered
        && oldMatchIndex != undefined
        && newMatchIndex != undefined
        && this.pageNumber === this.currentMatchPage
      )
        this.highlightSelected(newMatchIndex, oldMatchIndex, this.isSearchListQuery)
      else if (newMatchIndex === undefined)
        this.clearHighlight()
    },

    highlightSelectedSwitch() {
      if (this.pageNumber === this.currentMatchPage && this.rendered)
        this.highlightSelected(this.currentMatchIndex, undefined, true)
    },

    scale() {
      this.updateVisibility()

      if (this.rendered) setTimeout(this.renderTextLayer, 30)
    },

    rotation() {
      this.drawPage()
    },

    page(_newPage, oldPage) {
      this.destroyPage(oldPage)
    },

    isElementFocused (isElementFocused) {
      if (isElementFocused) this.focusPage()
    }
  },

  methods: {
    ...mapMutations({
      setScale: 'pdf/scale',
      setRotation: 'pdf/rotation'
    }),

    clearHighlight() {
      const selected = document.getElementsByClassName('selected')

      if (selected.length > 0) {
      
        const span = selected[0].parentNode
        
        span.innerHTML = span.innerHTML.replace("<span class=\"highlight selected\">", "")
        span.innerHTML = span.innerHTML.replace("</span>", "")
      
      }

      const highlights = document.getElementsByClassName('highlight')
      
      for (let element of highlights) {
      
        const span = element.parentNode
        
        span.innerHTML = span.innerHTML.replace("</span>", "")
        span.innerHTML = span.innerHTML.replace("<span class=\"highlight\">", "")
      
      }
      
    },

    isElementInViewport(el) {
      const rect = el.getBoundingClientRect()

      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      )

    },

    highlight(newMatches) {
      if (newMatches.length > 0 && this.rendered) {
        if (this.previousMatchPage) {
          const oldChildNodes = document.getElementById(`text-layer--${this.previousMatchPage}`).childNodes 
          const oldSpanIndex = this.matches[this.previousMatchPage - 1][this.matches[this.previousMatchPage - 1].length - 1]['index']
          const oldSpan = oldChildNodes[oldSpanIndex]
          oldSpan.innerHTML = oldSpan.innerHTML.replace("<span class=\"highlight selected\">", "<span class=\"highlight\">")
        }

        newMatches[this.pageNumber - 1].forEach((match, idx) => {
          const childNodes = document.getElementById(`text-layer--${this.pageNumber}`).childNodes
          const span = childNodes[match.index]

          if (span) {
            const highlightClass = idx === this.currentMatchIndex
              && this.pageNumber === this.currentMatchPage
                ? "<span class='highlight selected'>"
                : "<span class='highlight'>"

            const matchStart = match.position
            const matchEnd = match.position + this.textQuery.length
            span.innerHTML = span.innerHTML.slice(0, matchStart) + highlightClass +
              span.innerHTML.slice(matchStart, matchEnd) +
              "</span>" + span.innerHTML.slice(matchEnd, span.innerHTML.length)

            if (
              idx === 0 &&
              this.currentMatchIndex === 0 &&
              !this.isElementInViewport(span) &&
              this.currentMatchPage === this.pageNumber
            )
              span.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
          }
        })
      }
    },

    highlightSelected(newMatchIndex, oldMatchIndex, isSearchListQuery) {
      if (newMatchIndex != 0) {
        const oldSpanPageIndex = isSearchListQuery
          ? this.previousMatchPage - 1
          : this.pageNumber - 1

        const newChildNodes = document.getElementById(`text-layer--${this.pageNumber}`).childNodes
        const oldChildNodes = document.getElementById(`text-layer--${oldSpanPageIndex + 1}`).childNodes
        const oldSpanIndex = this.matches[oldSpanPageIndex][oldMatchIndex]['index']
        const newSpanIndex = this.matches[this.pageNumber - 1][newMatchIndex]['index']

        const oldSpan = oldChildNodes[oldSpanIndex]
        const newSpan = newChildNodes[newSpanIndex]

        if (oldSpan && newSpan) {
          oldSpan.innerHTML = oldSpan.innerHTML.replace("<span class=\"highlight selected\">", "<span class=\"highlight\">")
          newSpan.innerHTML = newSpan.innerHTML.replace("<span class=\"highlight\">", "<span class=\"highlight selected\">")

          if (
            this.rotation % 180 === 0
            && !this.isElementInViewport(newSpan)
            && this.currentMatchPage === this.pageNumber
          )
            newSpan.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
        }
      } else if (newMatchIndex === 0) {
        const oldChildNodes = document.getElementById(`text-layer--${this.previousMatchPage}`).childNodes 
        const newChildNodes = document.getElementById(`text-layer--${this.pageNumber}`).childNodes

        // this solves the problem of undefined oldMatchIndex when going grom the last to the first match paages
        const oldIdx = this.matches[this.previousMatchPage - 1].length - 1
        
        const oldSpanIndex = this.matches[this.previousMatchPage - 1][oldIdx]['index']
        const newSpanIndex = this.matches[this.pageNumber - 1][newMatchIndex]['index']
        const oldSpan = oldChildNodes[oldSpanIndex]
        const newSpan = newChildNodes[newSpanIndex]
        oldSpan.innerHTML = oldSpan.innerHTML.replace("<span class=\"highlight selected\">", "<span class=\"highlight\">")
        newSpan.innerHTML = newSpan.innerHTML.replace("<span class=\"highlight\">", "<span class=\"highlight selected\">")

        if (
          this.rotation % 180 === 0
          && !this.isElementInViewport(newSpan)
        )
          newSpan.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
      }
    },

    renderTextLayer() {

      const eventBus = new PDFJSViewer.EventBus()

      const viewport = this.page.getViewport({ scale: this.scale, rotation: this.rotation })
      const div = document.getElementById(`page-container--${this.pageNumber}`)

      div.removeChild(document.getElementById(`text-layer--${this.pageNumber}`))

      const textLayerDiv = document.createElement("div")

      textLayerDiv.style.width = document.getElementById(`canvas--${this.pageNumber}`).style.width
      textLayerDiv.id = `text-layer--${this.pageNumber}`
      textLayerDiv.className = "textLayer"
      div.appendChild(textLayerDiv)

      const pageIndex = this.pageNumber - 1

      const textLayer = new TextLayerBuilder({ textLayerDiv, eventBus, pageIndex, viewport })

      this.page.getTextContent().then(textContent => {

        textLayer.setTextContent(textContent)
        textLayer.render()

        setTimeout(() => {
          this.highlight(this.matches)
        }, 20)

      })

    },

    focusPage() {

      if (this.isPageFocused) return;

      this.$emit('page-focus', this.pageNumber)

    },

    drawPage() {

      this.rendered = true

      const viewport = this.page.getViewport({ scale: this.optimalScale, rotation: this.rotation })
      const canvasContext = document.getElementById(`canvas--${this.pageNumber}`).getContext('2d')
      const renderContext = {canvasContext, viewport}

      // PDFPageProxy#render
      // https://mozilla.github.io/pdf.js/api/draft/PDFPageProxy.html
      this.renderTask = this.page.render(renderContext)

      this.renderTask.promise.then(() => {

        this.renderTextLayer()

        this.$emit('page-rendered', {
          page: this.page,
          text: `Rendered page ${this.pageNumber}`,
        })

      })
      .catch(response => {

        this.destroyRenderTask()

        this.$emit('page-errored', {
          response,
          page: this.page,
          text: `Failed to render page ${this.pageNumber}`,
        })

      })

    },

    updateVisibility() {
      this.$parent.$emit('update-visibility')
    },

    destroyPage(page) {
      // PDFPageProxy#_destroy
      // https://mozilla.github.io/pdf.js/api/draft/PDFPageProxy.html
      if (page) {
        
        page._destroy()
      
      }

      this.destroyRenderTask()
    },

    destroyRenderTask() {
      if (!this.renderTask) return;

      delete this.renderTask
    }
    
  }
}
</script>

<style>
.absolute-div {
  position: relative;
}

.pdf-page {
  display: block;
  margin: 0 auto;
}
  
.textLayer {
  margin: 0 auto;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 0.2;
  line-height: 1.0;
}

.textLayer > span {
  color: transparent;
  position: absolute;
  white-space: pre;
  cursor: text;
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
}

.textLayer .highlight {
  margin: -1px;
  padding: 1px;

  background-color: blueviolet;
  border-radius: 4px;
}

.textLayer .highlight.begin {
  border-radius: 4px 0px 0px 4px;
}

.textLayer .highlight.end {
  border-radius: 0px 4px 4px 0px;
}

.textLayer .highlight.middle {
  border-radius: 0px;
}

.textLayer .highlight.selected {
  background-color: #BF360C;
}

.textLayer ::-moz-selection {
  background: rgba(81, 45, 168, 1);
}

.textLayer ::selection {
  background: rgba(81, 45, 168, 1);
}

.textLayer .endOfContent {
  display: block;
  position: absolute;
  left: 0px;
  top: 100%;
  right: 0px;
  bottom: 0px;
  z-index: -1;
  cursor: default;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.textLayer .endOfContent.active {
  top: 0px;
}
</style>
