<template>
  <div>
    <v-textarea
      rows="1"
      :background-color="$vuetify.theme.dark ? '' : 'grey lighten-4'"
      filled
      hide-details
      placeholder="Arxiv or YouTube link"
      class="hs-rounded-text"
    />
  </div> 
</template>

<script>
export default {}
</script>