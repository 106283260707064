<template>
  <v-container>
    Choose your persona templates to interact with the thread chat
    <v-list
      two-line
      rounded
    >
      <v-subheader>AI Personas</v-subheader>
      <v-list-item-group
        v-model="persona"
        :color="$vuetify.theme.dark ? 'tertiary' : 'primary'"
      >
        <v-list-item
          v-for="(duo, i) in personas"
          :key="`item_${i}`"
        >
          <v-list-item-avatar>
            <img :src="duo.thumbnail" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              {{ duo.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-container>
</template>

<script>
/*
* Name
* Avatar
* Greetin
* Short description (how would the AI describe themselves)
* Long description
* Categories (keywords)
* Voice
* Image generation
* Visibility
* Open Source
* Example conversations

Tips on Defining a Character

Getting started defining a character is easy, but there are some hints as you try to refine the experience.
You can use the placeholders {{user}} and {{char}} as placeholders
{{user}}: What's your favorite game? 
{{char}}: Hi {{user}}! I've been playing a lot of Zelda: Breath of the Wild.
You can also introduce additional people in your examples
narrator: You two will be playing a game together
{{char}}: Ok, what are the rules?
narrator: First, one of you will think of a word
You can include image generation in your characters.
{{char}}: *dark underground cave* You look around the cave.
{{user}}: I run away!
{{char}}: *large bear with big teeth* A bear runs after you!
Keep in mind words said by {{user}} in examples will be as if the user has said those things, even though they won't appear in the chat. You can also use something like {{random_user_1}} or actual names in definitions to provide examples.
Bob: I'm from Maine, tell me about my state
{{char}}: Maine is great place in the fall!
Importantly, keep experimenting by cycling between definition and talking to your character!
*/

export default {
  props: {
    reset: String
  },

  data: () => ({
    persona: null,
    personas: [
      {
        name: 'GPT-4 vs GPT-3 Rap battle',
        thumbnail: 'https://upload.wikimedia.org/wikipedia/commons/a/a4/GPT-4.png',
        code: 'rap_battle',
        instructions: ''
      },
      {
        name: 'Einstein vs Newton',
        thumbnail: 'https://sm.mashable.com/t/mashable_in/photo/default/newtons-law-of-gravity-was-wrong-claim-scientists-target-ein_haxa.1248.jpg',
        code: 'einsten_newton',
        instructions: ''
      },
      {
        name: 'Elon vs Zuck',
        thumbnail: 'https://i.ytimg.com/vi/hh_-1jHEUU0/maxresdefault.jpg',
        code: 'elon_zuck',
        instructions: ''
      }
    ]
  }),

  watch: {
    reset() {
      this.persona = null
    },
    
    persona(i) {
      this.$emit('newPersona', this.personas[i])
    }
  }
}
</script>