var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"py-0"},[(_vm.rooms.length === 0)?_c('v-alert',{staticClass:"hs-rounded-lg mt-4",attrs:{"text":"","outlined":"","prominent":"","color":_vm.$vuetify.theme.dark ? 'tertiary' : 'primary'}},[_c('v-row',[_c('v-col',{staticClass:"grow",domProps:{"innerHTML":_vm._s(_vm.$t('community.emptyRoom'))}})],1)],1):_vm._e(),(_vm.community._id)?_c('ScrollPagination',{style:(_vm.rooms.length === 0 ? 'display: none;' : ''),attrs:{"requestData":{
      'url': ("rooms/" + (this.user.id)),
      'params': {
        community: _vm.community._id
      }
    },"countData":{
      'url': ("rooms/" + (this.user.id) + "/count"),
    },"rootHeight":(_vm.rootHeight + "px"),"setFatherItems":_vm.setRooms},scopedSlots:_vm._u([{key:"child",fn:function(props){return [_c('v-container',{staticClass:"hs-room-list px-0 py-0"},[(props.item._id !== _vm.community.mainRoom)?_c('v-list-item',{attrs:{"dense":"","color":_vm.itemColor,"to":("/community/" + (props.item.community) + "/room/" + (props.item._id))},on:{"click":function($event){return _vm.$emit('enter')}}},[_c('v-list-item-avatar',{staticClass:"hs-rounded-lg",attrs:{"size":"30"}},[_c('v-img',{attrs:{"src":_vm.getResourceUrl(props.item.thumbnail)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(props.item.name)}})],1)],1):_vm._e()],1)]}}],null,false,933542852)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }