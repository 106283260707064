export default {
  namespaced: true,

  state: {
    context: {},
    quality: false,
    writerCommand: ''
  },

  getters: {
    context: ({ context }) => context,
    quality: ({ quality }) => quality,
    writerCommand: ({ writerCommand }) => writerCommand
  },
  
  mutations: {
    addContext: (state, { id, type }) => {
      state.context[id] = type
    },
    removeContext: (state, id) => {
      delete state.context[id]
    },
    resetContext: state => {
      state.context = {}
    },
    quality: (state, payload) => {
      state.quality = payload
    },
    writerCommand: (state, payload) => {
      state.writerCommand = payload
    }
  }
}