export default {
  namespaced: true,

  state: {
    media: {},
    file: {},
    fileType: '',
    context: []
  },

  getters: {
    media: ({ media }) => media,
    file: ({ file }) => file,
    fileType: ({ fileType }) => fileType,
    context: ({ context }) => context
  },

  mutations: {
    setMedia: (state, payload) => {
      state.media = payload
    },
    setFile: (state, payload) => {
      state.file = payload
    },
    setFileType: (state, payload) => {
      state.fileType = payload
    },
    setContext: (state, payload) => {
      state.context = payload
    },
  }
}