import Open from '@layouts/Open'
import LandingLayout from '@layouts/Landing'
import MobileLayout from '@layouts/Mobile'
import { isMobile, deviceDetect } from 'mobile-device-detect'
import { withLayout } from '@utils'

const { os, osName } = deviceDetect()
const deviceOs = osName ? osName : os

/* We test the deviceOs to make sure that the device detection will work for higher privacy browsers  */
const mobile = isMobile || deviceOs === 'Android' || deviceOs === 'iOS'
const Layout = mobile ? MobileLayout : LandingLayout
const deviceType = mobile ? 'mobile' : 'web'

const LandingPage = () => import(`@views/open/${deviceType}/landing`)
const Privacy = () => import(`@views/open/web/privacy`)
const Terms = () => import(`@views/open/web/terms`)
const Signup = () => import('@views/open/web/signup')
const Signin = () => import('@views/open/web/signin')
const About = () => import('@views/open/web/about')
const Contact = () => import('@views/open/web/contact')
const Verify = () => import('@views/open/web/verify')
const VerifyEmailChange = () => import('@views/open/web/verifyEmailChange')
const VerifyPasswordChange = () => import('@views/open/web/verifyPasswordChange')
const Welcome = () => import('@views/open/mobile/welcome')


export default {
  path: '/',
  component: {
    name: 'hisolver-open',
    render: h => h('router-view')
  },
  children: [
    {
      path: '',
      name: 'open.landing',
      component: withLayout(LandingPage, Layout),
      meta: {
        app: 'open'
      }
    },
    {
      path: 'home',
      name: 'open.home',
      component: withLayout(LandingPage, Layout),
      meta: {
        app: 'open'
      }
    },
    {
      path: 'welcome',
      name: 'open.welcome',
      component: withLayout(Welcome, Layout),
      meta: {
        app: 'open'
      }
    },
    {
      path: 'terms',
      name: 'open.terms',
      component: withLayout(Terms, mobile ? Layout : Open),
      meta: {
        app: 'open'
      }
    },
    {
      path: 'privacy',
      name: 'open.privacy',
      component: withLayout(Privacy, mobile ? Layout : Open),
      meta: {
        app: 'open'
      }
    },
    {
      path: 'signup',
      name: 'open.signup',
      component: withLayout(Signup, Open),
      meta: {
        app: 'open'
      }
    },
    {
      path: 'verify',
      name: 'open.verify',
      component: withLayout(Verify, Open),
      meta: {
        app: 'open'
      }
    },
    {
      path: 'verify/emailChange',
      name: 'open.verify.emailChange',
      component: withLayout(VerifyEmailChange, Open),
      meta: {
        app: 'open'
      }
    },
    {
      path: 'verify/passwordChange',
      name: 'open.verify.passwordChange',
      component: withLayout(VerifyPasswordChange, Open),
      meta: {
        app: 'open'
      }
    },
    {
      path: 'signin',
      name: 'open.signin',
      component: withLayout(Signin, Open),
      meta: {
        app: 'open'
      }
    },
    {
      path: 'about',
      name: 'open.about',
      component: withLayout(About, Layout),
      meta: {
        app: 'open'
      }
    },
    {
      path: 'contact',
      name: 'open.contact',
      component: withLayout(Contact, Open),
      meta: {
        app: 'open'
      }
    }
  ]
}
