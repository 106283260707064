<template>
  <v-container fluid>
    <v-card
      flat
      shaped
      outlined
      to="/trail"
      :loading="loading"
      class="mx-auto"
      max-width="400"
    >
      <v-img
        height="250"
        src="https://online-learning.harvard.edu/sites/default/files/styles/social_share/public/course/asset-v1_HarvardX%2BCalcAPL1x%2B2T2017%2Btype%40asset%2Bblock%40TITLE-Calculus-Applied-2120x1192-NO-SPOTLIGHT%202.png?itok=PiTdsK8k"
      />

      <v-card-title>Cálculo I</v-card-title>

      <v-card-text>
        <div>Indução matemática. Propriedades básicas dos números reais. Limite de uma sequência. Séries de números reais. Convergência absoluta e condicional. Principais testes de convergência de séries. Noções de topologia na reta. Funções contínuas; operações.</div>
      </v-card-text>

      <v-divider class="mx-4"></v-divider>

      <v-card-title>Tópicos Principais</v-card-title>

      <v-card-text>
        <v-chip-group column>
          <v-chip 
            label
            outlined
            color="primary"
          >
            Cálculo
          </v-chip>

          <v-chip 
            label
            outlined
            color="primary"
          >
            Matemática
          </v-chip>

          <v-chip 
            label
            outlined
            color="primary"
          >
            Análise
          </v-chip>
        </v-chip-group>
      </v-card-text>

      <v-card-actions>
        <v-list-item>
          <v-list-item-avatar color="grey">
              <v-img src="https://hisolverlab.s3.amazonaws.com/avatars/46b56d2f-1ac6-4343-ab60-72901f33c80c_YanKaledBarbosa.jpg" />
          </v-list-item-avatar>
          <v-list-item-content>
              <v-list-item-title class="subtitle-1">
                Yan Kaled
              </v-list-item-title>
              <v-list-item-subtitle>
                IME-USP
              </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card-actions>
    </v-card>
  </v-container>
</template>


<script>
  export default {
    data: () => ({
      loading: false,
      selection: 1,
    }),

    methods: {
      reserve () {
        this.loading = true

        setTimeout(() => (this.loading = false), 2000)
      },
    },
  }
</script>
