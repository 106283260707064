<template>
  <v-container>
    <v-file-input
      v-model="filesArr"
      :prepend-inner-icon="mdiPaperclip"
      class="clickable-input"
      label="File input"
      prepend-icon=""
      color="primary"
      clearable
      counter
      multiple
      outlined
      :dense="dense"
      :disabled="disabled"
      :show-size="1000"
      @change="emitFiles"
      @click:clear="$emit('clearContent')"
    >
      <template v-slot:selection="{ index, text }">
        <v-chip
          v-if="index < 2"
          color="primary"
          outlined
          label
          small
        >
          {{ truncateStr(text) }}
        </v-chip>

        <span
          v-else-if="index === 2"
          class="overline grey--text text--darken-3 mx-2"
        >
          +{{ filesArr.length - 2 }} File(s)
        </span>
      </template>
    </v-file-input>

    <v-row
      v-if="disabled"
      justify="start"
    >
      <v-btn
        plain
        color="primary"
        @click="download"
      >
        download files
        <v-icon right>
          {{ mdiDownload }}
        </v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { mdiPaperclip, mdiDownload } from '@mdi/js'
import { truncateStr } from '@utils'
import FileCard from './FileCard'
import { saveAs } from 'file-saver';
import JSZip from 'jszip';

export default {
  components: {
    FileCard
  },

  props: {
    partIdx: {
      type: Number,
      default: 0
    },
    sender: {
      type: Object,
      default() {
        return {}
      }
    },
    focusOn: {
      type: Boolean,
      required: true
    },
    files: {
      type: Array,
      required: true
    },
    dense: {
      type: Boolean,
      default: false
    },
    syncFiles: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    if (this.files.length > 0)
      this.filesArr = this.files
  },

  data() {
    return {
      mdiDownload,
      mdiPaperclip,
      
      filesArr: []
    }
  },

  watch: {
    syncFiles(sync) {
      if (sync) {
        this.filesArr = []
        setTimeout(() => {
          this.filesArr = this.files
        })
      }
    }
  },

  methods: {
    truncateStr,

    download() {

      const zip = new JSZip();

      this.files.forEach(file => {
        zip.file(file.name, file);
      });

      zip.generateAsync({ type: 'blob' }).then(blob => {
        saveAs(blob, `${this.sender.name}-post-${Date.now()}.zip`);
      });

    },

    emitFiles() {
      if (!this.syncFiles && this.filesArr != this.files) {
        this.$emit('content', this.filesArr)
      }
    }    
  }
}
</script>