<template>
  <v-dialog
    v-model="searchDialogOn"
    hide-overlay
    max-width="0"
    max-height="0"
    @click:outside="$emit('closeDialog')"
  >
    <v-card
      rounded="lg"
      elevation="10"
      :color="$vuetify.theme.dark ? 'grey darken-3' : ''"
      :style="`position: absolute;  height: 400px; width: 400px;
        left: ${searchDialogLeft}px; top: ${searchDialogTop}px;`"
    >
      <v-container style="position: relative; height: 350px;">
        <v-tabs
          v-model="searchTab"
          centered
          background-color="transparent"
        >
          <v-tab>Pdfs</v-tab>
          <v-tab>Notebooks</v-tab>
          <v-tab>Videos</v-tab>
        </v-tabs>

        <v-card-text
          style="overflow-y: scroll; height: 95%;"
          class="emoji-mart-scroll"
        >
          <v-window v-model="window">
            <v-window-item :value="1">
              <v-list
                v-if="pdfs.length > 0"
                color="transparent"
              >
                <v-subheader>PDFS</v-subheader>
                <template v-for="(pdf, i) in pdfs.filter((pdf, i) => i < 50)">

                  <v-divider
                    v-if="i != 0"
                    :key="`pdfDivider_${i}`"
                    inset
                  />

                  <v-list-item
                    :key="`pdfTag_${i}`"
                    @click="selectPdf(pdf)"
                  >
                    <template>
                      <v-card
                        outlined
                        class="mr-4"
                      >
                        <v-img
                          :src="pdf.thumbnail"
                          height="50"
                          width="40"
                        />
                      </v-card>

                      <v-list-item-content>
                        <v-list-item-title
                          v-text="pdf.title"
                          class="subtitle-1"
                        />
                        <v-listitem-subtitle
                          v-text="pdf.author"
                          class="subtitle-2"
                        />
                      </v-list-item-content>
                    </template>
                  </v-list-item>

                </template>
              </v-list>
            </v-window-item>

            <v-window-item :value="2">
              <v-list
                v-if="notebooks.length > 0"
                color="transparent"
              >
                <v-subheader>Notebooks</v-subheader>
                <template v-for="(notebook, i) in notebooks">

                  <v-divider 
                    v-if="i != 0"
                    :key="`notebook_${i}`"
                    class="small-inset"
                  />

                  <v-list-item
                    :key="`notebookTag_${i}`"
                    @click="selectNotebook(notebook)"
                  >
                    <template>
                      <v-card
                        outlined
                        class="mr-4"
                      >
                        <v-img
                          :src="notebook.coverImage"
                          height="40"
                          width="60"
                        />
                      </v-card>

                      <v-list-item-content>
                        <v-list-item-title
                          v-text="notebook.title"
                          class="subtitle-1"
                        />
                        <v-listitem-subtitle
                          v-text="notebook.sender.name"
                          class="subtitle-2"
                        />
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </template>
              </v-list>

            </v-window-item>

            <v-window-item :value="3">
              <v-list
                v-if="videos.length"
                color="transparent"
              >
                <v-subheader>Videos</v-subheader>
                <template v-for="(video, i) in videos">

                  <v-divider
                    v-if="i != 0"
                    :key="`video_${i}`"
                    class="small-inset"
                  />

                  <v-list-item
                    :key="`videoTag_${i}`"
                    @click="selectVideo(video)"
                  >
                    <template>
                      <v-card
                        outlined
                        class="mr-4"
                      >
                        <v-img
                          :src="video.thumbnail"
                          height="40"
                          width="60"
                        />
                      </v-card>

                      <v-list-item-content>
                        <v-list-item-title
                          v-text="video.title"
                          class="subtitle-1"
                        />
                        <v-listitem-subtitle
                          v-text="video.author"
                          class="subtitle-2"
                        />
                      </v-list-item-content>
                    </template>
                  </v-list-item>

                </template>
              </v-list>
            </v-window-item>
          </v-window>

        </v-card-text>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import API from '@api'

export default {
  props: {
    searchDialogOn: {
      type: Boolean,
      default: false
    },
    searchDialogLeft: {
      type: Number,
      default: 0
    },
    searchDialogTop: {
      type: Number,
      default: 0
    },
    searchQuery: {
      type: String,
      default: ''
    },
    searchFilter: {
      type: Array,
      default: []
    }
  },

  data() {
    return {
      window: 1,
      searchTab: 0,

      pdfs: [],
      notebooks: [],
      videos: []
    }
  },

  computed: {
    ...mapGetters({
      user: 'user'
    })
  },

  watch: {
    searchTab(tab) {
      this.window = tab + 1
    },
    async searchQuery(searchQuery) {

      if (searchQuery) {

        const query = {
          _id: {
            $nin: this.searchFilter
          }
        }

        this.pdfs = await API().get(`pdfSearch/${this.user.id}`, {
          params: {
            searchQuery,
            query
          }
        })

        this.notebooks = await API().get(`notebookSearch/${this.user.id}`, {
          params: {
            searchQuery,
            query
          }
        })

        this.videos = await API().get(`videoSearch/${this.user.id}`, {
          params: {
            searchQuery,
            query: {
              ...query,
              isLibraryContent: true
            }
          }
        })
      }
    }
  },

  methods: {
    selectVideo(video) {
      this.$emit('selectVideo', video)
    },

    selectNotebook(notebook) {
      this.$emit('selectNotebook', notebook)
    },
    
    selectPdf(pdf) {
      this.$emit('selectPdf', pdf)
    }
  }
}
</script>

<style scoped>
.small-inset {
  margin-left: 90px;
}
</style>