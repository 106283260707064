<template>
  <v-dialog
    :value="open"
    max-width="500"
    @click:outside="onClose"
  >
    <v-card>
      <v-container>
        <v-row justify="center">
          <v-chip color="primary">
            <Stopwatch
              :finishDate="subscriptionRequiredTill"
              :onFinish="onFinish"
            />
          </v-chip>
        </v-row>
        <v-row>
          <v-card-title>
            {{ $t('subscribe.title') }}
          </v-card-title>
        </v-row>
        <v-row class="mx-4">
          {{ $t('subscribe.item1') }}
        </v-row>
        <v-row class="mx-4 mt-4">
          <v-icon color="success">
            {{mdiCheckboxMarkedCircle}}
          </v-icon>
          {{ $t('subscribe.item2') }}
        </v-row>
        <v-row class="mx-4">
          <v-icon color="success">
            {{mdiCheckboxMarkedCircle}}
          </v-icon>
          {{ $t('subscribe.item3') }}
        </v-row>
        <v-row class="mx-4">
          <v-icon color="success">
            {{mdiCheckboxMarkedCircle}}
          </v-icon>
          {{ $t('subscribe.item4') }}
        </v-row>
        <v-row justify="center" class="mt-4">
          <v-btn
            small
            rounded
            depressed
            color="primary"
            @click="getSubscriptionPaymentLink"
            :loading="loading"
          >
            <v-icon>
              {{ mdiCash }}
            </v-icon>
            {{ $t('community.subscribe') }}
          </v-btn>
        </v-row>
        <v-row justify="center" class="mt-4">
          <v-btn
            small
            rounded
            depressed
            @click="onClose"
          >
            {{ $t('subscribe.notNow') }}
          </v-btn>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex'
  import { mdiCash, mdiCheckboxMarkedCircle } from '@mdi/js'
  import StripeService from '@api/stripe'
  import Stopwatch from '@components/Stopwatch/Stopwatch'

  export default {
    components: {
      Stopwatch
    },

    data() {
      return {
        stripeService: null,
        loading: false,
        mdiCash,
        mdiCheckboxMarkedCircle
      }
    },

    created() {
      this.stripeService = StripeService(this.user.id)
    },

    computed: {
      ...mapGetters({
        user: 'user',
        profileId: 'profile/id',
        subscriptionRequiredTill: 'application/subscriptionRequiredTill'
      }),
      open() {
        return (
          !this.$route.query.stripeCheckoutSessionId &&
          !!this.subscriptionRequiredTill &&
          this.$route.name !== 'open.signin'
        )
      }
    },

    methods: {
      ...mapMutations({
        setSubscriptionRequiredTill: 'application/setSubscriptionRequiredTill'
      }),

      onClose() {
        this.setSubscriptionRequiredTill(null)
      },

      onFinish(reload) {
        this.setSubscriptionRequiredTill(null)
        if (reload) window.location.reload()
      },

      async getSubscriptionPaymentLink() {
        this.loading = true
        try {
          const response = await this.stripeService.getSubscriptionPaymentLink(
            this.profileId
          )
          if (!response || !response.url) return
          window.open(response.url, '_blank')
        } catch (err) {
          console.log('err: ', err);
        }
        this.loading = false
      },
    }  
  }
</script>