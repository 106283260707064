<template>
  <v-select
    v-model="inputValue"
    :items="items"
    item-text="text"
    item-value="id"
    v-on:change="onChange"
    :label="$t(`onboarding.role.university`)"
    :multiple="multiple"
    :rules="rules"
    dense
    class="px-1 hs-rounded-12"
  />
</template>

<script>
  import University from '@api/university'
  import { mapGetters } from 'vuex'

  export default {
    props: {
      onChange: Function,
      multiple: {
        type: Boolean,
        default: false
      },
      value: String,
      rules: Array
    },

    async created() {
      this.universityService = University(this.user.id)
      const universities = await this.universityService.list()
      this.items = this.formatItems(universities)
    },

    computed: {
      ...mapGetters({
        user: 'user',
      }),

      inputValue: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      }
    },

    methods: {
      formatItems(items) {
        return items.map(item => ({
          id: item.id,
          text: item.name
        }))
      }
    },

    data: () => ({
      items: [],
    })
  }
</script>