var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{style:(("\n    height: 100%;\n    position: relative;\n    border: " + _vm.borderStyle + ";\n  ")),attrs:{"outlined":_vm.showPanel,"elevation":"0","color":_vm.navColor,"rounded":"xl"}},[(_vm.showPanel)?_c('v-container',{staticStyle:{"height":"100%","width":"100%"}},[_c('div',{staticClass:"hs-col"},[(!_vm.loading)?_c('PersonaCard',{attrs:{"create":_vm.create,"name":_vm.persona.name,"about":_vm.persona.about,"caption":_vm.persona.caption,"avatar":_vm.persona.avatar,"currentPersona":_vm.currentPersona}}):_vm._e()],1),_c('v-divider',{staticClass:"my-2"}),_c('PillTabs',{staticClass:"mx-2",staticStyle:{"max-width":"100%"},attrs:{"tabs":[{
        name: 'Persona',
        counter: 0
      }, {
        name: 'sources',
        counter: 0
      }],"startIndex":_vm.pillTab,"showSearch":false},on:{"setActive":_vm.SET_PILL_TAB}}),_c('div',{staticClass:"my-2"}),_c('v-fade-transition',{attrs:{"hide-on-leave":""}},[(_vm.pillTab === 0)?_c('div',{staticStyle:{"height":"100%"}},[(!_vm.library)?_c('Persona',{attrs:{"persona":_vm.persona},on:{"edit":function($event){_vm.edit = true},"browse":function($event){_vm.library = true}}}):_c('PersonaLibrary',{on:{"back":function($event){_vm.library = false},"setPersona":_vm.setPersona}})],1):_vm._e()]),_c('v-fade-transition',{attrs:{"hide-on-leave":""}},[(_vm.pillTab === 1)?_c('div',{staticStyle:{"height":"100%"}},[_c('Scrollbar',{style:(("height: " + _vm.rootHeight + "px;")),attrs:{"thin":true,"flexible":true}},[_c('v-container',{staticClass:"py-0"},[_c('div',{staticClass:"my-2"}),_c('SearchSources'),_c('div',{staticClass:"my-4"}),_c('v-fade-transition',{attrs:{"hide-on-leave":""}},[(_vm.contents.length === 0)?_c('div',[_c('v-container',{style:(("height: " + (_vm.$vuetify.breakpoint.xl ?
                    _vm.annaChamber ? 92 : 88  : _vm.annaChamber ? 90 : 85) + "%;"))},[_c('v-row',{attrs:{"justify":"center"}},[(_vm.locale === 'en')?_c('v-img',{style:("opacity: 0.7; height: auto; max-width: 80%;"),attrs:{"src":require("@assets/images/pdf_upload_overlay_en.png")}}):_c('v-img',{style:("opacity: 0.7; height: auto; max-width: 80%;"),attrs:{"src":require("@assets/images/pdf_upload_overlay_pt-br.png")}})],1)],1)],1):_vm._e()]),(_vm.contents.length > 0)?_c('Folder',{attrs:{"contentArray":_vm.contents}}):_vm._e()],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-container',{staticClass:"bottom-menu"},[_c('MediaUploader')],1)],1)],1):_vm._e()])],1):_vm._e(),_c('v-btn',{class:{
      'chevron-btn': !_vm.rightCardCollapse,
      'chevron-left': _vm.rightCardCollapse
    },attrs:{"icon":""},on:{"click":_vm.toggleCard}},[_c('div',{staticClass:"chevron-bar"})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }