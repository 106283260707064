export default async function(pdf) {
  const maxPages = pdf.numPages; 
  const pageTextPromises = [];
  let charCount = 0;
  
  // Loop over each page and get the text content 
  for (let i = 1; i <= maxPages; i++) {
    const page = await pdf.getPage(i);
    const pageText = await page.getTextContent();
    let textContent = pageText.items.map(function(item) {
      return item.str;
    }).join('');
    
    // Truncate to 16e3 - 1 chars
    if (charCount + textContent.length > 16e3 - 1) {
      textContent = textContent.slice(0, 16e3 - 1 - charCount);
    }
    
    charCount += textContent.length;
    
    const pageObj = {
      pageNumber: i,
      textContent: textContent 
    };
    pageTextPromises.push(pageObj);
    
    // Break out of loop if we reach the max char count
    if (charCount >= 16e3 - 1) {
      break; 
    }
  }
  
  return pageTextPromises;
}