<template>
  <v-app :style="backgroundColor">
    <Snackbar />
    <Subscribe
      v-if="user"
      :open="openSubscribeDialog"
    />
    <div
      @drop="dropHandler"
      @dragover="dragOverHandler"
      class="v-app-son"
    >
      <v-overlay
        :value="overlayOn"
        @click="overlayOn = false"
      >
        <v-row justify="center">
          <v-icon x-large>
            {{ mdiUpload }}
          </v-icon>
        </v-row>

        <p class="text-h5">Drag and drop files here</p>
      </v-overlay>

      <AudioBottomPlayer />

      <BundleUploader
        :uploadOn="uploadOn"
        :files="files"
        @close="closeUpload"
      />

      <AudioUploader
        v-if="audioUpload"
        :audioDialog="audioUpload"
        :audioFile="file"
        @close="closeUpload"
      />

      <NewPdf
        v-if="pdfUpload"
        :pdfDialog="pdfUpload"
        :pdfFile="file"
        @close="closeUpload"
        @err="setSnack"
      />
      
      <NewVideo
        v-if="videoUpload"
        :videoDialog="videoUpload"
        :videoFile="file"
        @close="closeUpload"
        @err="setSnack"
      />

      <NewImage
        v-if="imgUpload"
        :imgDialog="imgUpload"
        :imgFile="file"
        @close="closeUpload"
        @err="setSnack"
      />

      <MultipleFileUploader
        :files="filesToUpload"
        :onClose="() => filesToUpload = []"
      />
      <OnBoardingTask v-if="user"/>
      <div
        id="emoji-shadow"
        class="emoji-shadow"
      ></div>
      <router-view />
    </div>
  </v-app>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { mdiUpload } from '@mdi/js';
import { MediaURL } from '@components'
import { AudioBottomPlayer } from '@components/Audio';
import { BundleUploader } from '@components/Bundle';
import NewPdf from '@components/PDF/NewPdf';
import  NewVideo from '@components/Video/NewVideo';
import NewImage from '@components/Image/NewImage';
import AudioUploader from '@components/Audio/AudioUploader';
import OnBoardingTask from '@components/Tasks/OnBoarding.vue';
import MultipleFileUploader from '@components/Upload/MultipleFileUploader.vue';
import API from '@api';
import Snackbar from '@components/Snackbar/Snackbar.vue';
import Subscribe from '@components/Subscribe/Subscribe';
import StripeService from '@api/stripe';

// Code styles for markdown
import '@assets/styles/monokai.css';
import '@assets/styles/atelier-cave-light.css';

// Custom styles
import '@assets/styles/custom-scroll.css' // global

import videojs from 'video.js/dist/video';
window.videojs = videojs;

// TODO: reinstate the EmojiMart (cdn/web version)
const pickerOptions = { onEmojiSelect: console.log }
const picker = new EmojiMart.Picker(pickerOptions)

// document.body.appendChild(picker)
import jwt_decode from "jwt-decode";
import { signOut } from '@utils'

import PersonaFunctions from '@components/Persona/PersonaFunctions.vue'

import ObjectID from 'bson-objectid'

export default {
  components: {
    BundleUploader,
    NewPdf,
    NewVideo,
    NewImage,
    AudioUploader,
    AudioBottomPlayer,
    OnBoardingTask,
    MultipleFileUploader,
    Snackbar,
    Subscribe
  },
  
  async created() {
    this.setSubscriptionRequiredTill(null)
    if (this.user) {
      this.stripeService = StripeService(this.user.id)
      this.handleSubscription()
      this.handleStripeSellerAccount()
    }

    // Sets the persona functions
    this.setPersonaFolder = PersonaFunctions.setPersonaFolder.bind(this);

    this.checkSession()

    this.getMimeType = MediaURL.getMimeType.bind(this);

    // Sets the color theme
    const swapTheme = Object.keys(this.settings).length > 0 &&
      this.$vuetify.theme.dark != this.settings.darkThemeOn
    if (swapTheme)
      this.$vuetify.theme.dark = this.settings.darkThemeOn
    
    this.loadedTheme = true

    if (this.user && this.user.id) {
      document.addEventListener('keydown', this.onKeyDown);
    }

    if (this.persona && this.persona.id) {
      const persona = await API().get(`persona/${this.user.id}`, {
        params: {
          id: this.persona.id
        }
      })

      const [connector] = await API().get('connectors', {
        params: {
          query: {
            $and: [
              // NEED TO IMPORT ObjectID from 'bson-objectid'
              // TODO: What is the type of the field modified by ObjectID
              // when it reaches the backend??
              {
                "entities": {
                  $elemMatch: {
                    "ref": "Persona",
                    "_id": ObjectID(this.persona.id)
                  }
                }
              },
              {
                "entities": {
                  $elemMatch: {
                    "ref": "Profile",
                    "_id": ObjectID(this.myProfileId)
                  }
                }
              }
            ]
          }
        }
      });

      if (connector) {
        this.SET_CONNECTOR(connector)
        persona.tools = connector.tools
      }
      this.SET_PERSONA(persona)

      const [personaFolder] = await API().get(`folders/${this.user.id}`, {
        params: {
          query: {
            persona: {
              $eq: this.persona._id
            }
          }
        }
      })

      // Sets the persona folder and its contents
      if (personaFolder) {
        this.setPersonaFolder(personaFolder)
      }
    }
  },

  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeyDown);
  },

  mounted() {
    // Sets the lang for Vuetify components
    this.$vuetify.lang.current = this.locale.split('-')[0]

    // Signals to the system that this user is online
    if (this.user) this.$socket.onlineSession.connect()

    this.SET_INNER_WIDTH(window.innerWidth)
    this.SET_INNER_HEIGHT(window.innerHeight)

    window.addEventListener('resize', () => {
      this.SET_INNER_WIDTH(window.innerWidth)
      this.SET_INNER_HEIGHT(window.innerHeight)
    })
  },

  sockets: {
    onlineSession: {
      connect() {
        this.$socket.onlineSession.emit('saveSocket', {
          profile: this.myProfileId
        })
      }
    },
    
    notification: {
      friendshipRequestAccepted () {
        this.SET_FRIENDSHIP_COUNT(this.friendshipCount + 1)
      }
    }
  },

  data() {
    return {
      mdiUpload,
      loadedTheme: false,
      uploadOn: false,
      imgUpload: false,
      videoUpload: false,
      audioUpload: false,
      pdfUpload: false,
      overlayOn: false,
      files: [],
      file: {},
      filesToUpload: [],
      openSubscribeDialog: null,
      stripeService: null
    }
  },

  watch: {
    user: {
      deep: true,
      handler() {
        if (this.user && this.user.id) {
          document.addEventListener('keydown', this.onKeyDown);
        }
      }
    },

    locale(lang) {
      this.$i18n.locale = lang
      // Captures pt in pt-br
      this.$vuetify.lang.current = lang.split('-')[0]
    },

    '$vuetify.theme.dark': {
      handler: function(dark) {
        if (this.loadedTheme) {
          this.SET_SETTINGS({ darkThemeOn: dark })
          if (this.user) {
            API().put(`/profile/${this.user.id}`, {
              'settings.darkThemeOn': dark
            }, {
              params: {
                id: this.myProfileId
              }
            })
          }
        }
      }
    },

    $route() {
      this.checkSession()
    }
  },

  computed: {
    ...mapGetters({
      user: 'user',
      myProfileId: 'profile/id',
      friendshipCount: 'profile/friendshipCount',
      settings: 'settings',
      locale: 'locale',
      auth0Token: 'auth0Token',
      persona: 'currentPersona',
      openHandleGroupRoomDialog: 'rooms/openHandleGroupRoomDialog',
      leftCardCollapse: 'leftCardCollapse',
      subscriptionRequiredTill: 'application/subscriptionRequiredTill'
    }),
    backgroundColor() {
      return this.$vuetify.theme.dark ? '' : 'background-color: #F5F5F5'
    },
  },

  methods: {
    async handleStripeSellerAccount() {
      const { stripeAccountId } = this.$route.query
      if (!stripeAccountId) return;
      const { stripeSellerAccountId } = await this.stripeService.onStripeSellerAccountCreated(stripeAccountId)
      if (stripeSellerAccountId) {
        this.setStripeSellerAccountId(stripeSellerAccountId)
      }
    },

    async handleSubscription() {
      const { stripeCheckoutSessionId, sellerAccountId } = this.$route.query
      if (!stripeCheckoutSessionId) return;
      const response = await this.stripeService.handleCheckoutSuccess(
        stripeCheckoutSessionId,
        sellerAccountId
      )
      if (response.subscription) {
        this.setStripeSubscription(response.subscription)
      }
      if (response.authorizationToken) {
        this.setAuthorizationToken(response.authorizationToken)
      }
      this.setSubscriptionRequiredTill(null)
      const query = { stripeCheckoutSessionId: undefined }
      if (!sellerAccountId) query.awaitToken = true
      this.$router.replace(
        { query },
        () => {
          this.$router.go(0);
        }
      );
    },
    ...mapMutations({
      SET_INNER_WIDTH: 'SET_INNER_WIDTH',
      SET_INNER_HEIGHT: 'SET_INNER_HEIGHT',
      SET_CONNECTOR: 'persona/SET_CONNECTOR',
      SET_SETTINGS: 'settings',
      SET_EMOJI: 'emoji',
      SET_FRIENDSHIP_COUNT: 'profile/friendshipCount',
      SET_ANNA_CHAMBER: 'setAnnaChamber',
      SET_RAG: 'folders/setRag',
      SET_CONTENTS: 'folders/setContents',
      SET_CURRENT_FOLDER: 'folders/setCurrentFolder',
      LEFT_CARD_COLLAPSE: 'LEFT_CARD_COLLAPSE',
      setStripeSubscription: 'profile/setStripeSubscription',
      setAuthorizationToken: 'authorizationToken',
      setSubscriptionRequiredTill: 'application/setSubscriptionRequiredTill',
      setStripeSellerAccountId: 'profile/setStripeSellerAccountId'
    }),

    // Sets the logout action on context 'this' so that the 'signOut' function can use it
    ...mapActions({
      SET_PERSONA: 'SET_PERSONA',
      logout: 'logout'
    }),

    onKeyDown(event) {
      // Shortcut for activating the chamber
      if ((event.ctrlKey || event.metaKey) && event.key === 'b') {
        event.preventDefault()
        event.stopPropagation()
        this.SET_ANNA_CHAMBER()

        if (this.leftCardCollapse) {
          this.LEFT_CARD_COLLAPSE()
        }
      }
    },

    checkSession() {
      // Checks if the oAuth session token is still valid
      // and signs the user out if the token expired.
      if (this.auth0Token) {
        const decoded = jwt_decode(this.auth0Token);
        const exp = new Date(decoded.exp * 1000)
        const now = new Date()
        if (exp < now) signOut(this, true)
      }
    },

    closeUpload() {
      this.uploadOn = false
      this.pdfUpload = false
      this.audioUpload = false
      this.videoUpload = false
      this.imgUpload = false
      this.files = []
      this.file = {}
      this.filesToUpload = []
    },

    dragOverHandler(ev) {
      ev.preventDefault();
      if (this.user && ev.dataTransfer.types.indexOf('Files') != -1) {
        // this.overlayOn = true
      }
    },

    async dropHandler(ev) {
      // Prevent default behavior (Prevent file from being opened)
      ev.preventDefault();

      if (ev.dataTransfer.files.length > 1) {
        return this.filesToUpload = ev.dataTransfer.files
      }

      if (this.user && ev.dataTransfer.types.indexOf('Files') != -1) {
        this.files = []
        this.overlayOn = false
        if (ev.dataTransfer.items) {
          // Use DataTransferItemList interface to access the file(s)
          for (let i = 0; i < ev.dataTransfer.items.length; i++) {
            // If dropped items aren't files, reject them
            if (ev.dataTransfer.items[i].kind === 'file') {
              const file = ev.dataTransfer.items[i].getAsFile();
              this.files.push(file)
            }
          }
        } else {
          // Use DataTransfer interface to access the file(s)
          this.files = ev.dataTransfer.files
        }

        if (this.files.length === 1) {
          const mimeType = await this.getMimeType(this.files[0])
          switch(mimeType) {
            case 'image/png':
            case 'image/gif':
            case 'image/jpeg':
            case 'image/webp':
              this.imgUpload = true;
              break;

            case 'video/mp4':
            case 'video/webm':
              this.videoUpload = true;
              break;

            case 'audio/mpeg':
            case 'audio/wav':
            case 'audio/ogg':
              this.audioUpload = true;
              break;

            case 'application/pdf':
              this.pdfUpload = true;
              break;

            default:
              break;
          }

          setTimeout(() => {
            [this.file] = this.files;
          })            
        } else {
          // TODO: review media upload.
          // This variable turns the modal for media upload
          this.uploadOn = true
        }
      }
    },

    setOpenCreateRoomDialog(open) {
      this.openCreateRoomDialog = open
    },
  }
}
</script>

<style src="@/assets/styles/v-menu.css" />

<style>
* {
  /* For firefox only */
  scrollbar-width: thin;
}

.bottom-menu {
  position: absolute;
  bottom: 0;
}

.hs-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.v-menu__content {
  border-radius: 12px;
}

.theme--dark .markdown-body {
  color: white !important;
}

/** 
* Integrating Katex and Markdown-it causes
* the styling class of latex elements to be mathnormal
* instead of mathdefault. This will mess the styling
* To solve that we override the mathnormal styles to set it
* equal to the mathdefault styles.
*/
.katex .mathnormal {
  font-family: KaTeX_Math;
  font-style: italic;
}

/* other */
input[type="file"] {
  display: none;
}

.ce-paragraph a, a {
  text-decoration: none;
}

.transparent-span > a {
  color: transparent !important;
}

/* Used in NotesPanel and Halls list */
.hs-container {
  max-width: 100%;
}

.hs-scroll-content {
  display: flex;
  flex-wrap: nowrap;
}

/* Star animation */
.hs-circle {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  box-shadow: 0px 0px 0.5px 0.5px #e9e7911a;
  background:#eeb90ae8;
}

.hs-circle-soft {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  box-shadow: 0px 0px 0.5px 0.5px #e9e7911a;
  background:#eeb90a81;
}

.hs-pulse {
  animation: pulse-animation 1.5s infinite;
}

.theme--dark {
  --pulse-shadow-start: 0 0 0 2px rgba(248, 241, 140, 0.322);
  --pulse-shadow-end: 0 0 0 8px rgba(255, 253, 151, 0.377);
}

.theme--light {
  --pulse-shadow-start: 0 0 0 2px rgba(248, 216, 140, 0.534);
  --pulse-shadow-end: 0 0 0 8px rgba(246, 209, 98, 0.764);
}

@keyframes pulse-animation {
  0% {
    box-shadow: var(--pulse-shadow-start);
  }

  100% {
    box-shadow: var(--pulse-shadow-end);
  }
}

/*
 * General rounded classes 
 */
.hs-rounded-lg {
  border-radius: 10px !important;
}

.hs-rounded-12 {
  border-radius: 12px !important;
}

.hs-rounded-loading-bottom {
  border-radius: 12px 12px 0px 0px;
}

.hs-rounded-text {
  border-radius: 15px !important;
}

.hs-rounded-xl {
  border-radius: 20px !important;
}

.theme--light .mention {
  color: rgb(30, 30, 30) !important;
  background-color: rgba(255, 171, 0, 0.2);
  border-top: 2px solid rgba(255, 171, 0, 0.3);
  border-right: medium solid rgba(255, 171, 0, 0.0);
  border-left: medium solid rgba(255, 171, 0, 0.0);
}

.theme--dark .mention {
  color: rgb(255, 255, 255) !important;
  background-color: rgba(255, 171, 0, 0.3);
  border-top: 1px solid rgba(255, 171, 0, 0.3);
  border-right: medium solid rgba(255, 171, 0, 0.0);
  border-left: medium solid rgba(255, 171, 0, 0.0);
}

.annotation {
  color: rgb(127, 42, 197);
}

.theme--light .text-box-span a:link {
  color: #741aa8;
}

.theme--light .text-box-span a:hover {
  color: #641690;
}

.theme--light .text-box-span a:visited {
  color: #741aa8;
}

.theme--dark .text-box-span a:link {
  color: #9160cc;
}

.theme--dark .text-box-span a:hover {
  color: #a56ee9;
}

.theme--dark .text-box-span a:visited {
  color: #9059d3;
}

.v-navigation-drawer__content {
  overflow:hidden !important;
}

.v-calendar .v-calendar-daily__body .v-calendar-daily__scroll-area {
  overflow-y: hidden;
}

.clickable-input > .v-input__control > .v-input__slot {
  cursor: pointer !important;
}

.theme--light ::selection {
  background: #ece0fc;
}

.theme--dark ::selection {
  background: #8248ca7a;
}

.clickable * {
  cursor: pointer !important;
}

.clickable {
  cursor: pointer !important;
}

.quote-box {
  border-left: 3px solid #c8ccd1;
  font-family: 'Times',serif;
}

.container-row {
  max-width: 100%;
}

.clamp-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/*
 * Workaround for the breaking change in Vuetify v-grid
 * (see : https://github.com/vuetifyjs/vuetify/issues/11408)
 */
.row {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

/*
 * Styles for Emoji-Mart
 */
 .emoji-shadow {
   position: absolute;
   top: -10000px;
 }

 .emoji-mart {
   max-width: 350px;
 }

.emoji-mart-emoji > span {
  cursor: pointer !important;
}

.emoji-translateX {
  transform: translateX(-2.6px) !important;
}

.emoji-mart-scroll {
  scrollbar-width: normal;
  scrollbar-color: rgb(143, 142, 142) rgba(49, 49, 49, 0.041);
}

/* Works on Chrome, Edge, and Safari */
.emoji-mart-scroll::-webkit-scrollbar {
  width: 6px;
}

.emoji-mart-scroll::-webkit-scrollbar-track {
  background: rgba(112, 112, 112, 0.089);
}

.emoji-mart-scroll::-webkit-scrollbar-thumb {
  background-color: rgb(116, 116, 116);
  border-radius: 5px;
  border: 1px solid rgba(255, 166, 0, 0);
}

.custom-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
}

.custom-scroll::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, .5);
}

.custom-scroll::-webkit-scrollbar-track { 
  border-radius: 8px; 
}

.theme--light .custom-scroll::-webkit-scrollbar-track { 
  background-color: #f1f1f1;
}

.theme--dark .custom-scroll::-webkit-scrollbar-track { 
  background-color: #302f2f; 
}

/* Svg colors */
.svg-light-color {
  filter: invert(99%) sepia(1%) saturate(2359%) hue-rotate(208deg) brightness(90%) contrast(87%);
}

.svg-dark-color {
  filter: invert(22%) sepia(1%) saturate(614%) hue-rotate(106deg) brightness(90%) contrast(80%);
}

.v-textarea textarea {
  resize:none;
}

.v-textarea textarea::-webkit-scrollbar {
  width: 5px;
}

.v-textarea textarea::-webkit-scrollbar-corner {
  background: transparent;
}

.v-textarea textarea::-webkit-scrollbar-track-piece  {
  background: transparent;
}
 
.v-textarea textarea::-webkit-scrollbar-track {
  background: transparent;
}
 
.theme--dark .v-textarea textarea::-webkit-scrollbar-thumb {
  background-color: rgb(124, 124, 124);
  min-height: 40px;
  /* color of the scroll thumb */
  border-radius: 10px;       /* roundness of the scroll thumb */
  border: 1px solid #1a1a1a;  /* creates padding around scroll thumb */
}

.theme--light .v-textarea textarea::-webkit-scrollbar-thumb {
  background-color: rgb(124, 124, 124);
  min-height: 40px;
  /* color of the scroll thumb */
  border-radius: 10px;       /* roundness of the scroll thumb */
  border: 1px solid #F5F5F5;  /* creates padding around scroll thumb */
}

.v-text-field > .v-input__control > .v-input__slot::before {
  border-style: none;
}

.v-card--link::before {
  border-radius: 24px;
}

/* Removes the underline from filled v-text field */
.v-text-field > .v-input__control > .v-input__slot:before, .v-text-field > .v-input__control > .v-input__slot:after {
  content: none !important;
}

/* Changes the border radius of rounded lists */
.v-list--rounded.v-list--two-line .v-list-item,
.v-list--rounded.v-list--two-line .v-list-item::before,
.v-application .rounded.v-list-item::before,
.v-list--rounded.v-list--two-line .v-list-item > .v-ripple__container {
border-radius: 12px !important;
}
</style>

<style>
/* Scrollbar styling */
.theme--dark pre::-webkit-scrollbar,
.theme--dark table::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}

.theme--dark pre::-webkit-scrollbar-corner,
.theme--dark table::-webkit-scrollbar-corner {
  background: transparent;
}

.theme--dark pre::-webkit-scrollbar-track-piece,
.theme--dark table::-webkit-scrollbar-track-piece {
  background: transparent;
}

.theme--dark pre::-webkit-scrollbar-track,
.theme--dark table::-webkit-scrollbar-track {
  background: transparent;
}

.theme--dark pre::-webkit-scrollbar-thumb,
.theme--dark table::-webkit-scrollbar-thumb {
  background-color: rgb(124, 124, 124);
  min-height: 40px;
  /* color of the scroll thumb */
  border-radius: 10px;       /* roundness of the scroll thumb */
  border: 1px solid #7f7f7f00;  /* creates padding around scroll thumb */
}

/* Theme dark for markdown table */
.theme--dark .markdown-body th,
.theme--dark .markdown-body td {
  padding: 8px 12px;
  text-align: left;
}

/* Style the table header */
.theme--dark .markdown-body th {
  background-color: #1e1e1e; /* Header background color */
  color: #ffffff; /* Header text color */
}

/* Style the even rows */
.theme--dark .markdown-body tbody tr:nth-child(even) {
  background-color: #2e2e2e; /* Even row background color */
}

/* Style the odd rows */
.theme--dark .markdown-body tbody tr:nth-child(odd) {
  background-color: #1e1e1e; /* Odd row background color */
}

.theme--dark .markdown-body th {
  font-weight: bold;
}

/* Style data cells */
.theme--dark .markdown-body th,
.theme--dark .markdown-body td {
  border: 1px solid #333333; /* Cell border */
  color: #ffffff; /* Cell text color */
}

/* Dark styles for markdown body */

.theme--dark .markdown-body h1, .theme--dark .markdown-body h2 {
padding-bottom: .3em;
border-bottom: 1px solid #474747;
}

.theme--dark .markdown-body {
  color: white !important;
}
.theme--dark .markdown-body blockquote {
  padding: 0 1em;
  color: #EEEEEE;
}

.hljs code {
  background-color: rgba(255, 255, 255, 0) !important;
  color: currentColor;
}

.theme--light .markdown-body .highlight pre, .theme--light .markdown-body pre {
  background-color: #F5F5F5;
  border-radius: 12px;
}

.theme--dark .markdown-body .highlight pre, .theme--dark .markdown-body pre {
  background-color: #323232;
  border-radius: 12px;
}

/* Other stuff */

.anna-text {
  transition: color 0.5s ease; 
}
.theme--light .anna-text.active {
  color: #5E35B1;
}
.theme--dark .anna-text.active {
  color: #FFAB00;
}

.theme--dark .anna-text::selection {
  background: #ffaa0036 !important;
}
@media (min-width:651px) {
  .codex-editor--narrow .ce-toolbox .ce-popover {
    right: -150px;
  }

  .ce-toolbar__actions {
    right: 100% !important;
  }
}

.ce-toolbar__plus:hover {
  background-color: #e6e6e6;
}
.ce-toolbar__settings-btn:hover {
background-color: #e6e6e6;
}

/* Editorjs Scrollbar styling */
.theme--light .ce-popover__items { /* firefox */
  scrollbar-color: #9e9e9e #a50a0a00;
  scrollbar-width: thin;
}
.theme--dark .ce-popover__items { /* firefox */
  scrollbar-color: #6b6b6b #a50a0a00;
  scrollbar-width: thin;
}

.ce-popover__items {
  max-height: 200px;
  overflow-y: auto;
}

/* Light theme */
.theme--light .ce-popover__items::-webkit-scrollbar {
  width: 6px; 
}

.theme--light .ce-popover__items::-webkit-scrollbar-track {
  background: #ddd;
}

.theme--light .ce-popover__items::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 3px;
}

/* Dark theme */
.theme--dark .ce-popover__items::-webkit-scrollbar {
  width: 6px;
}

.theme--dark .ce-popover__items::-webkit-scrollbar-track {
  background: #444;
}

.theme--dark .ce-popover__items::-webkit-scrollbar-thumb {
  background: #777;
  border-radius: 3px;
}

.v-main {
  overflow: hidden !important;
}

html, body, .v-application, .v-app-son, .v-main, .v-main__wrap, .height100 {
  height: 100% !important;
}

</style>