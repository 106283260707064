<template>
  <div class="mb-3" style="width: 100%;">
    <NewPost
      :newPostDialogOn="newPost"
      :currentRoom="room.id"
      :postType="tab === 0 ? 'POST' : 'THREAD'"
      :rerenderPostList="rerenderPostList"
      @newPost="addPost"
      @close="newPost = false"
    />
    <Header
      :currentPost="currentPost"
      :room="room"
      :tab="tab"
      @setActive="setTab"
      @post="newPost = !newPost"
      @reset="resetPost"
    ></Header>
  </div>
</template>

<script>
  import NewPost from '@components/Post/NewPost'
  import Header from '@components/Hall/Header'

  export default {
    components: {
      NewPost,
      Header
    },

    props: {
      room: {
        type: Object,
        required: true
      },
      rerenderPostList: Function
    },

    data: () => ({
      newPost: false,
      tab: 0,
      currentPost: null
    }),

    methods: {
      setTab(tab) {
        this.tab = tab
      },
      resetPost() {
        this.currentPost = null
      },

      addPost(post) {
        
      }
    }
  }
</script>