<script>
import API from '@api'
import { shuffle } from 'lodash'
import { startOfToday, endOfToday } from 'date-fns'
import { jsonStructure } from '@utils'
import { MediaURL } from '@components'
import langchainService from '@services/langchain'

const { VUE_APP_LANGCHAIN_API } = process.env


// TODO: gotta put the content id in the sources
// that will make it going from message sources to pdf easier 
async function processSource(user, source_documents, pdfSources, sources) {
  for (let i = 0; i < source_documents.length; i++) {
    const sourceArr = source_documents[i];
    const [source] = sourceArr;
    const sourceLength = source.page_content.length;
    const regex = /\/(\w+)\.pdf$/;
    const match = source.metadata.source.match(regex);
    const pdfId = match.pop();

    let pdf;
    if (!pdfSources[pdfId]) {
      pdf = await API().get(`pdf/${user.id}`, {
        params: {
          id: pdfId
        }
      });
      pdfSources[pdfId] = pdf
    } else {
      pdf = pdfSources[pdfId]
    }

    sources.push({
      title: pdf.title,
      score: sourceArr[sourceArr.length - 1],
      page_content: `${source.page_content.slice(0, 100)}HS-BREAK${source.page_content.slice(sourceLength - 100, sourceLength)}`,
      metadata: source.metadata
    });
  }
}

async function processEngagement() {
  let engagement = await API().get(`engagement/${this.user.id}`, {
    params: {
      query: {
        profile: this.myProfileId,
        createdAt: {
          $gte: startOfToday(),
          $lt: endOfToday()
        }
      }
    }
  })

  if (engagement.length === 0) {
    engagement = await API().post(`engagement/${this.user.id}`, {
      profile: this.myProfileId,
      annaCounter: 1     
    })
  } else {
    engagement = engagement[0];
    API().put(`engagement/${this.user.id}`, {
      annaCounter: engagement.annaCounter + 1
    }, {
      params: {
        id: engagement.id
      }
    })
    engagement.annaCounter += 1
  }

  if (engagement.annaCounter >= 100) {
  // Caps anna usage
    annaMsg = {
      type: 'chat_warning',
      room: this.room.id,
      conversation: conversation.id,
      sender: this.persona.id ? this.personaSender : this.annaSender,
      ...( this.persona.id ? { persona: this.persona.id } : { profile: this.anna.id } ),
      content: `Hey, you hit the limit of messages today :/ \n I'll be here to chat again tomorrow :)`,
    }

    // TODO: review code reuse
    this.messages.push({
      ...annaMsg,
      createdAt: (new Date()).toISOString()
    })

    this.$socket.conversation.emit('sendMsg', annaMsg)

    this.annaThinking = false

    return true;
  }

  return false;
}

function parseBase64ToImage(base64String) {
  // Convert base64 string to byte array
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  
  const byteArray = new Uint8Array(byteNumbers);
  
  // Create blob from byte array
  const blob = new Blob([byteArray], { type: 'image/jpeg' });
  
  // Create object URL
  const imageUrl = URL.createObjectURL(blob);

  // Return the image URL
  return imageUrl;
}

async function processSolution(question, questionGen, msgIndex) {
  this.loadingSolution = true

  const URIQuestion = encodeURIComponent(question)

  let queryStr = `question=${URIQuestion}`

  let pdfRagKeys = this.contents.map(({media}) => media).filter((_, index) => index < 20);
  if (pdfRagKeys.length > 0) {
    queryStr += `&index_name=${pdfRagKeys.map(key => `pdf/${key}.pdf`).join(',')}`
  }
  
  if (questionGen.params) {
    let createVisual;
    if (typeof questionGen.params.visualHints === 'undefined') {
      createVisual = true
    } else {
      createVisual = questionGen.params.visualHints
    }

    queryStr += `&visual_hint=${createVisual}`
  }

  const resCombo = await fetch(`${VUE_APP_LANGCHAIN_API}/answer_option_visual?${queryStr}`);
  const combo = JSON.parse(await resCombo.json())

  let answer = combo.answer
  let choices = combo.choices

  if (combo.visual) {
    console.log("Processing visual hint")

    let blobUrl = parseBase64ToImage(combo.visual)
    const [imgUrl] = await MediaURL.getMediaUrl([{
      mediaSrc: blobUrl,
      filename: blobUrl.split('/').pop(),
      type: 'image/gif'
    }], 'images')
    URL.revokeObjectURL(blobUrl)

    this.messages[msgIndex].imgUrl = imgUrl
  }

  // We make this replacements so that JSON parse works with the string
  // JSON parse has issues with string like \\( A \\), but works with \\\\( A \\\\)
  choices = choices.replace(/\\/g, '\\\\').replace(/\n/g, ' ')
    .split(`,`).slice(0, 5).map(choice => `${choice.replace(/\\\\/g, '\\').trim()}`)

  let correctChoice = choices[0]
  choices = shuffle(choices)

  const answerIndex = choices.indexOf(correctChoice)

  choices = choices.map((item, index) => `${['A', 'B', 'C', 'D', 'E'][index]}) ${item}`)
  correctChoice = choices[answerIndex]

  this.messages[msgIndex].solution = {
    choices,
    answer,
    correctChoice
  }
  this.loadingSolution = false
}

/*
* We might use this later in a less structured fashion.
* For example, if a teacher wants to create a few animations
* and keep it in a library / folder for a lesson
*/
async function visualHint(question) {
  const queryStr = encodeURIComponent(`please give me a visual hint about the latest messages: ${question}`)
  const res = await fetch(`${VUE_APP_LANGCHAIN_API}/visual_hint_gen_mat?query=${queryStr}`);
  const blob = await res.blob();
  const objectUrl = URL.createObjectURL(blob);
  const [imgUrl] = await MediaURL.getMediaUrl([{
    mediaSrc: objectUrl,
    filename: objectUrl.split('/').pop(),
    type: 'image/gif'
  }], 'images')

  URL.revokeObjectURL(objectUrl)

  return imgUrl
}

export default {
  /**
   * Encapsulates the logic of the callAnna function within a Promise.
   * 
   * @param {array} conversation - The conversation context.
   * @param {object} memory - The memory object.
   * @param {array} tools - Array of tools.
   * @param {string} type - Type of the media.
   * @param {object} message - The message object.
   * @param {object} solution - The solution object.
   * @returns {Promise} - A Promise that resolves with the result of the operation.
   */
  async callAnna(conversation, memory, assistant_name, tools, type, message, question, solution, scrollBottom) {
    return new Promise(async (resolve, reject) => {
      try {
        const block = await processEngagement.bind(this)()
        if (block) resolve();

        this.$socket.conversation.emit('anna_thinking', {
          room: this.room.id,
          conversation: conversation.id
        })

        this.annaThinking = true
        this.annaStreaming = true

        // We use the questionGen tool accross the function
        const [questionGen] = this.tools ? this.tools.filter(({type}) => type === 'question_gen') : []

        let annaMsg;
        let pdfRagKeys = this.contents.map(({media}) => media).filter((_, index) => index < 20);
        
        let langRes;
        if (type === 'test_trigger') {
          // pipeline for question generation
          let queryStr = `${VUE_APP_LANGCHAIN_API}/test_question_gen_only?`
          if (pdfRagKeys.length > 0) {
            queryStr += `index_name=${pdfRagKeys.map(key => `pdf/${key}.pdf`).join(',')}&`
          }
          
          // If the questionGen tool is defined
          if (questionGen) {
            if (questionGen.params.topics)
              queryStr += `topic=${encodeURIComponent(questionGen.params.topics)}&`
            if (questionGen.params.complexity)
              queryStr += `difficulty_level=${encodeURIComponent(questionGen.params.complexity)}&`
            if (questionGen.params.visualHints)
              queryStr += `visual_hint=${encodeURIComponent(questionGen.params.visualHints)}&`
          }

          langRes = await fetch(queryStr);

        } else {
          // Pdf case
          const pdfQuery = pdfRagKeys.map(key => `pdf/${key}.pdf`)
          const indexNames =  this.langChain || (this.rag && pdfRagKeys.length > 0) ?
            this.fetchContext ? pdfQuery : [`pdf/${this.filename}`] : []

          langRes = await langchainService(
            'hisolver_llm_call',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              withCredentials: false,
              body: JSON.stringify({
                // index_names: indexNames,
                // TODO: reactivate rag by checking for embedding existence
                index_names: [],
                username: this.myUsername,
                user_name: this.myName,
                assistant_name,
                ...( this.persona.description ? { description: this.persona.description  } : {}),
                ...( this.persona.greeting ? { greeting: this.persona.greeting  } : {}),
                ...( this.roles && this.roles.length > 0 ? { roles: this.roles } : {}),
                query: message.content,
                chat_history: memory,
                msg_type: type,
                solution,
                ...(type === 'test_choice' ? { problem: question } : {}),
                model_number: process.env.NODE_ENV === 'production' ? 2 : 1
              })
            },
            // Timeout has to be > 2000 because some model calls will take > 2 to start the streaming
            5000
          )
        }

        const reader = langRes.body.getReader();

        let botType;
        if (type === 'test_choice') botType = 'test_solution'
        if (tools.includes('question_gen')) botType = 'test_question'
        else botType = 'anna_msg'
        // other types include persona, etc

        // set the message
        annaMsg = {
          STREAMING: true, // state variable; not saved to the database;
          type: botType,
          room: this.room.id,
          conversation: conversation.id,
          sender: this.persona.id ? this.personaSender : this.annaSender,
          ...( this.persona.id ? { persona: this.persona.id } : { profile: this.anna.id } ),
          content: '',
          sources: []
        }

        this.messages.push({
          ...annaMsg,
          createdAt: (new Date()).toISOString()
        })

        const msgIndex = this.messages.length - 1

        // start reading the stream
        let loop = true;
        const decoder = new TextDecoder("utf-8", { stream: true });
        let target = false
        let langJson = '';

        /*
        * TODO: change the structure of the loop to add every few sentences
        * paragraphs instead of the continuous streaming. 
        * That should help fix a bug in safari where scroll bottom is
        * messed up.
        */
        this.annaThinking = false
        while(loop) {
          const {done, value} = await reader.read();

          if(done) {
            loop = false;

            setTimeout(() => {
              this.scrollBottom()
              if (scrollBottom) scrollBottom()
              this.stickyBottom = true
            }, 400)

            if (type === 'test_trigger') this.annaStreaming = false

          } else {
            const chunkAsString = decoder.decode(value);

            // CALL FUNCTIONS
            if (target) {
              // Now used only for the pdf case, if the stop signal u0003
              // comes before the end of the string (so this condition is a fallback)
              langJson += chunkAsString;

            } else if (chunkAsString.includes('\u0003')) {
              target = true; 
              if (chunkAsString != '\u0003')
                langJson += chunkAsString.replace('\u0003', ''); // for the PDF Rag use case
            
            } else this.messages[msgIndex].content += this.replaceLatexDelimiters(chunkAsString);

            this.scrollBottom()
            // END CALL FUNCTIONS

            // force stop the streaming at the user command
            if (this.stopStreaming) {
              loop = false
              this.annaStreaming = false
              this.stopStreaming = false
              this.messages[msgIndex].content += '🫠🔥🫠🔥'
              reader.cancel();
            }
          }
        }

        //===== Process sources ===============================================
        langJson = jsonStructure(langJson) ? JSON.parse(langJson) : {};
        const { source_documents } = langJson;

        console.log("langJson:  ", langJson)
        
        // for now, works only for pdfs
        const sources = [];
        const pdfSources = {};

        if (source_documents) await processSource(this.user, source_documents, pdfSources, sources)
        //===== Process sources ===============================================


        //===== Process solution ===============================================
        if (type === 'test_trigger') {
          question = this.messages[msgIndex]['content']
          await processSolution.bind(this)(question, questionGen, msgIndex)
        }
        //===== Process solution ===============================================

        this.messages[msgIndex].sources = sources;
        // deletes the streaming that was set before
        delete this.messages[msgIndex]['STREAMING']
        this.$socket.conversation.emit('sendMsg', this.messages[msgIndex])
        if (scrollBottom) scrollBottom()
        resolve();

      } catch (error) {
        reject(error);
      }
    });
  },

  async callPersona({
    persona,
    memory,
    type,
    message
  }) {
    try {
      this.$socket.conversation.emit('anna_thinking', {
        room: this.room.id,
        conversation: this.conversation.id
      })
      this.annaThinking = true
      this.annaStreaming = true

      const langRes = await langchainService(
        'hisolver_llm_call',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: false,
          body: JSON.stringify({
            // index_names: indexNames,
            // TODO: reactivate rag by checking for embedding existence
            index_names: [],
            username: this.myUsername,
            user_name: this.myName,
            assistant_name: persona.name,
            ...( persona.description ? { description: persona.description  } : {}),
            ...( persona.greeting ? { greeting: persona.greeting  } : {}),
            ...( this.roles && this.roles.length > 0 ? { roles: this.roles } : {}),
            query: message.content,
            chat_history: memory,
            msg_type: type,
            solution: {},
            model_number: process.env.NODE_ENV === 'production' ? 2 : 1
          })
        },
        // Timeout has to be > 2000 because some model calls will take > 2 to start the streaming
        5000
      )

      const reader = langRes.body.getReader();
      // set the message
      const personaMessage = {
        STREAMING: true, // state variable; not saved to the database;
        type: 'anna_msg',
        room: this.room.id,
        conversation: this.conversation.id,
        sender: {
          _id: persona._id,
          name: persona.name,
          username: persona.username,
          avatar: persona.avatar,
        },
        persona: persona._id,
        content: '',
        sources: []
      }

      this.messages.push({
        ...personaMessage,
        createdAt: (new Date()).toISOString()
      })

      const msgIndex = this.messages.length - 1

      // start reading the stream
      let loop = true;
      const decoder = new TextDecoder("utf-8", { stream: true });
      let target = false
      let langJson = '';

      /*
      * TODO: change the structure of the loop to add every few sentences
      * paragraphs instead of the continuous streaming. 
      * That should help fix a bug in safari where scroll bottom is
      * messed up.
      */
      this.annaThinking = false
      while(loop) {
        const {done, value} = await reader.read();

        if(done) {
          loop = false;

          setTimeout(() => {
            this.scrollBottom()
            this.stickyBottom = true
          }, 400)

        } else {
          const chunkAsString = decoder.decode(value);

          // CALL FUNCTIONS
          if (target) {
            // Now used only for the pdf case, if the stop signal u0003
            // comes before the end of the string (so this condition is a fallback)
            langJson += chunkAsString;

          } else if (chunkAsString.includes('\u0003')) {
            target = true; 
            if (chunkAsString != '\u0003')
              langJson += chunkAsString.replace('\u0003', ''); // for the PDF Rag use case
          
          } else this.messages[msgIndex].content += this.replaceLatexDelimiters(chunkAsString);

          this.scrollBottom()
          // END CALL FUNCTIONS

          // force stop the streaming at the user command
          if (this.stopStreaming) {
            loop = false
            this.annaStreaming = false
            this.stopStreaming = false
            this.messages[msgIndex].content += '🫠🔥🫠🔥'
            reader.cancel();
          }
        }
      }

      //===== Process sources ===============================================
      langJson = jsonStructure(langJson) ? JSON.parse(langJson) : {};
      const { source_documents } = langJson;
      
      // for now, works only for pdfs
      const sources = [];
      const pdfSources = {};

      if (source_documents) await processSource(this.user, source_documents, pdfSources, sources)
      //===== Process sources ===============================================


      //===== Process solution ===============================================

      this.messages[msgIndex].sources = sources;
      // deletes the streaming that was set before
      delete this.messages[msgIndex]['STREAMING']
      this.$socket.conversation.emit('sendMsg', this.messages[msgIndex])
    } catch (error) {
      console.error(error);
    }
  }
}
</script>