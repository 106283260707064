import API from '@api';

export default (userId) => {
  const apiRequest = API()
  return {
    create (roomId, chatName) {
      return apiRequest.post(
        `conversation/${userId}`,
        { room: roomId, name: chatName }
      )
    }
  }
}
