import { withLayout } from '@utils'
import Study from '@layouts/Study'
const Conversation = () => import('@views/rooms/conversation')

export default {
  path: 'rooms',
  component: {
    render: h => h('router-view')
  },
  children: [
    {
      path: '',
      name: 'rooms',
      component: withLayout(Conversation, Study),
      meta: {
        app: 'rooms',
        locked: true
      }
    },
    {
      path: 'conversation',
      name: 'rooms.conversation',
      component: withLayout(Conversation, Study),
      meta: {
        app: 'rooms',
        locked: true
      }
    },
  ]
};
