import API from '@api';

export default (userId) => {
  const apiRequest = API()
  return {
    listByChatRoom (roomId) {
      return apiRequest.get(`members/${roomId}/${userId}`)
    },
    update (id, body) {
      return apiRequest.put(`member/${userId}?id=${id}`, body)
    },
    requestCommunityMembership (body) {
      return apiRequest.post(`member/community/request/${userId}`, body)
    },
    acceptCommunityInvitation (memberId) {
      return apiRequest.put(`member/${userId}?id=${memberId}`, { status: 'active' })
    },
    declineCommunityInvitation (memberId) {
      return apiRequest.put(`member/${userId}?id=${memberId}`, { status: 'request_declined' })
    },
    acceptCommunityRequest (body) {
      return apiRequest.post(`member/community/accept/${userId}`, body)
    },
    declineCommunityRequest (body) {
      return apiRequest.post(`member/community/decline/${userId}`, body)
    },
    getCommunityMembership (communityId, profile) {
      return apiRequest.get(
        `member/community/${userId}`,
        { params: { community: communityId, profile } }
      )
    },
    listByCommunity (communityId) {
      return apiRequest.get(
        `members/community/${userId}`,
        { params: { community: communityId } }
      )
    },
    count (params) {
      return apiRequest.get(`members/count/${userId}`, { params })
    }

  }
}
