var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.toolbar)?_c('v-app-bar',{attrs:{"app":"","dark":"","fixed":"","height":"60","clipped-left":"","elevation":"2","color":_vm.toolbarColor}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push('/home')}}},[_c('v-avatar',{attrs:{"size":"40"}},[_c('v-img',{attrs:{"src":require("@assets/images/hs-logo--white.png")}})],1)],1),_c('SearchBox',{staticClass:"mx-2",attrs:{"avatarOn":false,"lighterViewerOn":true,"setFocusOn":_vm.setFocusOn},on:{"submit":_vm.searchPdf}}),_c('v-btn',{staticStyle:{"border-radius":"12px"},attrs:{"text":""},on:{"click":_vm.routeBack}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.mdiKeyboardBackspace)+" ")]),_vm._v(" "+_vm._s(_vm.$t('goBack'))+" ")],1),_c('v-spacer'),_c('v-avatar',{attrs:{"size":_vm.avatarSize}},[(_vm.avatar)?_c('v-img',{attrs:{"src":_vm.getResourceUrl(_vm.avatar)},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"height":"40","width":"40","type":"image"}})]},proxy:true}],null,false,3745767024)}):_c('v-btn',{attrs:{"icon":"","color":"white"}},[_c('v-icon',{attrs:{"dark":"","size":"30"}},[_vm._v(" "+_vm._s(_vm.mdiAccount)+" ")])],1)],1),_c('v-divider',{staticClass:"mx-3 my-9",attrs:{"vertical":"","inset":""}}),_c('v-btn',{staticStyle:{"border-radius":"10px"},attrs:{"text":""},on:{"click":_vm.SET_ANNA_CHAMBER}},[_vm._v(" "+_vm._s(_vm.$t('call_anna'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" "+_vm._s(_vm.mdiCompassRose)+" ")])],1),_c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm.settings.tooltipsOn},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{staticStyle:{"transform":"translateY(8%)"}},[_vm._v(" "+_vm._s(_vm.mdiMessageTextOutline)+" ")])],1)]}}],null,false,1563905661)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('chat_with_friends_soon'))+" ")])]),_c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm.settings.tooltipsOn},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.SET_NOTES_ON(true)}}},on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.mdiSquareEditOutline)+" ")])],1)]}}],null,false,4181362063)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('annotations'))+" ")])]),_c('Actions'),_c('PDFPaginator',{staticClass:"mt-6"}),_c('v-speed-dial',{attrs:{"direction":"bottom","transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"disabled":"","dark":"","icon":""},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[(_vm.fab)?_c('v-icon',[_vm._v(_vm._s(_vm.mdiClose))]):_c('v-icon',[_vm._v(_vm._s(_vm.mdiDotsVertical))])],1)]},proxy:true}],null,false,1872482646),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[_c('v-tooltip',{attrs:{"left":"","disabled":!_vm.settings.tooltipsOn},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","small":"","color":_vm.fabColor}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.mdiBookmarkOutline))])],1)]}}],null,false,3499134789)},[_c('span',[_vm._v(" Save ")])]),_c('v-tooltip',{attrs:{"left":"","disabled":!_vm.settings.tooltipsOn},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":_vm.fabColor},on:{"~click":function($event){return _vm.clickVideoInput.apply(null, arguments)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiShieldLockOutline))])],1)],1)]}}],null,false,1042768527)},[_c('span',[_vm._v(" Share ")])]),_c('v-tooltip',{attrs:{"left":"","disabled":!_vm.settings.tooltipsOn},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":_vm.fabColor},on:{"~click":function($event){_vm.edit = true}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiPencil))])],1)],1)]}}],null,false,3220257406)},[_c('span',[_vm._v(" Edit ")])])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }