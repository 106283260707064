<template>
  <v-menu>
    <template v-slot:activator="{ on }">
      <div v-on="on">
        <v-text-field
          v-model="chosenTxt"
          :append-icon="mdiChevronDown"
          class="clickable hs-select hs-rounded-lg"
          :label="label"
          filled
          :background-color="selectColor"
          hide-details
          readonly
          dense
          @click="customizeScroll"
          :data-cy="dataCy"
        />
      </div>
    </template>

    <v-list
      class="customScroll py-0"
      max-height="400"
    >
      <v-list-item-group
        v-model="chosenIndex"
        color="primary"
        class="px-2 py-2"
        active-class="primary--text text-darken-1"
        @change="setChosenIndex"
      >
        <v-list-item
          v-for="({ content, icon }, index) in items"
          :key="index"
          :class="{ 'pr-6': iconPadding, 'rounded py-0': true }"
          :data-cy="`select-${content}-item`"
        >
          <v-list-item-title>
            {{ itemText(content) }}
          </v-list-item-title>

          <v-list-item-icon v-if="icon">
            <v-icon>{{ mdiIcons[icon] }}</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { mdiChevronDown } from '@mdi/js'

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    itemSelected: {
      type: String,
      default: ''
    },
    mdiIcons: {
      type: Object,
      default() {
        return {}
      }
    },
    label: {
      type: String,
      default: ''
    },
    localeKey: {
      type: String,
      default: ''
    },
    iconPadding: {
      type: Boolean,
      default :false
    },
    valueSelector: {
      type: Boolean,
      default: true
    },
    dataCy: {
      type: String,
      default: 'select-button'
    }
  },

  mounted() {
    if (this.itemSelected) {
      const idx = this.valueSelector
        ? this.items.map(({ value }) => value).indexOf(this.itemSelected)
       : this.items.map(({ content }) => content).indexOf(this.itemSelected)
      this.chosenIndex = idx > -1 ? idx : 0
    }
    
    this.chosenTxt = this.localeKey
      ? this.$t(`${this.localeKey}.${this.items[this.chosenIndex].content}`)
      : this.items[this.chosenIndex].content
  },

  data() {
    return {
      mdiChevronDown,
      chosenIndex: 0,
      chosenTxt: ''
    }
  },

  computed: {
    selectColor() {
      return this.$vuetify.theme.dark ? '#333333' : 'grey lighten-3'
    }
  },

  methods: {
    itemText(content) {
      return this.localeKey ? this.$t(`${this.localeKey}.${content}`) : content
    },

    setChosenIndex(index) {
      if (index != undefined) {
        const { content } = this.items[index]
        this.chosenTxt = this.localeKey ? this.$t(`${this.localeKey}.${content}`) : content
        this.$emit('setData', content)
        this.$emit('chosenIndex', index)
      }
    },

    customizeScroll() {
      setTimeout(() => {
        const [el] = document.getElementsByClassName('customScroll')
        OverlayScrollbars(el, {})
      }, 0)
    }
  }
}
</script>