<template>
  <v-app-bar
    :class="barClass"
    :app="!invertScroll"
    dark
    fixed
    elevation="2"
    color="#4A148C"
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-btn
        icon
        large
        @click="$router.push('/')"
      >
        <v-avatar size="40">
          <v-img src="@assets/images/hs-logo--white.png" />
        </v-avatar>
      </v-btn>

      <v-toolbar-title @click="$router.push('/')">
        <div style="font-size: 40px;" class="font-weight-black pointer">HiSolver</div>
      </v-toolbar-title>

      <v-spacer/>

      <v-btn
        large
        outlined
        style="border-radius: 12px;"
        class="mx-2"
        to="/signup"
      >
        <span
          class="font-weight-bold"
          v-text="$t('signup')"
        ></span>
      </v-btn>

      <v-btn
        large
        outlined
        style="border-radius: 12px;"
        class="mx-2"
        :to="enter"
      >
        <span
          class="font-weight-bold"
          v-text="enterBtnText"
        ></span>
      </v-btn>
    </v-row>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    scrollTop: {
      type: Number,
      default: 0
    },
    invertScroll: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      user: 'user'
    }),

    barClass() {
      return !this.invertScroll || this.scrollTop > 10 ? 'openBar' : 'closedBar'
    },

    enterBtnText() {
      return this.user
        ? this.$t('enterHiSolver')
        : this.$t('signin')
    },
    
    enter() {
      return this.user
        ? '/home'
        : '/signin'
    }
  }
}
</script>

<style scoped>
.closedBar {
  transform: translateY(-64px) !important;
  transition: transform .2s ease-in-out;
}

.openBar {
  transition: transform .2s ease-in-out;
}

.pointer {
  cursor: pointer;
}
</style>