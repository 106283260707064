<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <div v-on="on" :style="iconContainerStyle" class="hs-rounded-lg">
        <v-badge
          overlap
          color="red"
          :content="invitationCount"
          :value="invitationCount > 0"
        >
          <v-btn
            icon
            @click="toggleShowInvites"
          >
            <v-icon small>
              {{ showInvites ? mdiClose : mdiAccountMultiplePlus }}
            </v-icon>
          </v-btn>
        </v-badge>
      </div>
    </template>
    <span>Invitations</span>
  </v-tooltip>
</template>

<script>
  import { mdiClose, mdiAccountMultiplePlus } from '@mdi/js';

  export default {
    props: {
      showInvites: Boolean,
      toggleShowInvites: Function
    },

    data() {
      return {
        invitationCount: 0,
        mdiClose,
        mdiAccountMultiplePlus
      }
    },

    computed: {
      iconContainerStyle() {
        return `
          height: 2em;
          width: 2em;
          background-color: ${this.btnColor};
          display: flex;
          align-items: center;
          justify-content: center;
        `;
      },
      btnColor() {
        return this.$vuetify.theme.dark ? '#3b3b3bfa' : '#EEEEEE'
      }
    },
  }
</script>