import store from '@store'
import * as Cookies from 'js-cookie'
import { isMobile, deviceDetect } from 'mobile-device-detect'

const { os, osName } = deviceDetect()
const deviceOs = osName ? osName : os
const isMobileDevice = isMobile || deviceOs === 'Android' || deviceOs === 'iOS'
  
export default (to, from, next) => {
  store.commit('from', from.fullPath)

  const routeName = to.name ? to.name.split('.').pop() : ''
  const mobileAllowed = ['landing', 'welcome', 'terms', 'privacy', 'demo', 'manim'].indexOf(routeName) === -1

  if (isMobileDevice && mobileAllowed)
    next({ path: '/' })
  
  else {
    const { user } = store.getters
    
    if (user === null || !user.id) {
      const cookie = Cookies.get('_hisolverSession')

      if (cookie && JSON.parse(cookie).user != null) {
        const parsedCookie = JSON.parse(cookie)
        store.commit('setState', parsedCookie)
        store.commit('setUser', parsedCookie.user)

        if (to.meta.app) next()
        else next({path: 'signin'})

      } else if ( 
        to.meta.hasOwnProperty('locked') &&
        to.meta.locked || !to.meta.app
      ) {

        if (to.meta.app === 'personas') next()
        else next({path: 'signin'})


      }
      else next()
        
    } else {
      // TODO: after the /main is done, we revert back to routing there
      if (to.meta.app) next()
      else next({path: 'folders'})
    }
  }
}