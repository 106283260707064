import { withLayout } from '@utils'
import PrivateRouteLayout from '@layouts/PrivateRoute'

const List = () => import('@views/subscription/list')

export default {
  path: 'subscriptions',
  component: {
    render: h => h('router-view')
  },
  children: [
    {
      path: '',
      name: 'subscription.list',
      component: withLayout(List, PrivateRouteLayout),
      meta: {
        app: 'subscription',
        locked: true
      }
    }
  ]
};
