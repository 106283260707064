<template>
  <overlay-scrollbars
    ref="overlayScrollbars"
    :class="scrollbarClass"
    :options="options"
    v-scroll.immediate="emitScroll"
  >
    <slot></slot>
  </overlay-scrollbars>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import scroll from '@directives/scroll'

export default {
  props: {
    autoHide: {
      type: String,
      default: 'never'
    },
    visibility: {
      type: String,
      default: 'auto'
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    theaterOn: {
      type: Boolean,
      default: false
    },
    darkBackground: {
      type: Boolean,
      default: false
    },
    dynamicScrolling: {
      type: Boolean,
      default: false
    },
    scrollUpdateOn: {
      type: Boolean,
      default: true
    },
    emitInstance: {
      type: Boolean,
      default: false
    },
    flexible: {
      type: Boolean,
      default: false
    },
    thin: {
      type: Boolean,
      default: false
    },
    scrollBottom: {
      type: Boolean,
      default: false
    },
    animateScroll: {
      type: Boolean,
      default: false
    },
    // removes the predefined height
    basic: {
      type: Boolean,
      default: false
    },
    onScrollDown: {
      type: Function,
      default: null,
    }
  },

  directives: {
    scroll
  },

  created() {
    this.isLanding = this.$route.name === 'open.landing' || this.$route.name === 'open.home'
  },

  mounted() {
    if (this.emitInstance) {
      this.$emit('osInstance', this.$refs.overlayScrollbars.osInstance())
    }
  },

  data() {
    return {
      isLanding: false
    }
  },

  watch: {
    scrollTop(v) {
      if (this.dynamicScrolling && !this.notesOn) {
        this.$refs.overlayScrollbars.osInstance().scroll({ y: `${v}px` })
      }
    },
    scrollBottom() {
      setTimeout(() =>{
        if (this.animateScroll)
          this.$refs.overlayScrollbars.osInstance().scroll({ y: '100%' }, 500, {  y : "" })
        else
          this.$refs.overlayScrollbars.osInstance().scroll({ y: '100%' })
      })
    },
  },

  computed: {
    ...mapGetters({
      scrollTop: 'scrollTop',
      notesOn: 'note/notesOn'
    }),
    
    options() {
      return {
        scrollbars: {
          autoHide: this.autoHide,
          visibility: this.visibility
        },
        callbacks : {
          onScroll: (e) => {
            this.fetchMoreOnScrollBottom(e);
            this.$emit('scrolling', e.target.scrollTop)
          }
        }
      }
    },

    scrollbarClass() {
      if (this.isMobile) return 'mobile-scrollbar';

      const pageClass = this.basic
        ? ''
        : this.isLanding
        ? 'scrollbar-landing'
        : !this.flexible
        ? 'scrollbar'
        : 'scrollbar-flexible'

      const themeClass = this.$vuetify.theme.dark
        ? 'scrollbar-dark'
        : 'scrollbar-light'

      const theaterClass = this.theaterOn
        ? 'scrollbarTheater'
        : ''

      const backgroundClass = this.darkBackground
        ? 'dark-background'
        : ''

      const thinClass = this.thin ? 'os-scrollbar-thin' : ''

      return `${theaterClass} ${thinClass} ${themeClass} ${pageClass} ${backgroundClass}`
    }
  },

  methods: {
    ...mapMutations({
      SET_SCROLLTOP: 'scrollTop'
    }),

    emitScroll(e) {
      if (e && this.scrollUpdateOn) {
        this.SET_SCROLLTOP(e.target.scrollTop)
      }
    },
    async fetchMoreOnScrollBottom(e) {
      if (this.onScrollDown) {
        const scrollTop = e.target.scrollTop
        const clientHeight = e.target.clientHeight
        const scrollHeight = e.target.scrollHeight
        if (scrollTop + clientHeight >= (scrollHeight - 2)) {
          this.onScrollDown()
        }
      }
    }
  }
}
</script>

<style>
.os-scrollbar-thin .os-scrollbar-handle {
  width: 4px;
  margin-left: 2px;
}

.mobile-scrollbar .os-scrollbar .os-scrollbar-track .os-scrollbar-handle {
  background-color: transparent;
}

.scrollbarTheater.scrollbar-dark .os-scrollbar-vertical {
  background-color: #212121;  
}

.scrollbarTheater.scrollbar-light .os-scrollbar-vertical {
  background-color: #E0E0E0;
}

.theme--dark .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  background-color: #414141 !important;
}

.mobile-scrollbar {
  height: 100vh;
}

.scrollbar-landing {
  height: 100vh;
}

/*
controls the bottom horizontal scroll - the hell this mean? what's the point of height: calc(100vh - 60px); It was causing spacing bug when refreshing on certain pages
*/
.scrollbar {
  height: 100vh;
  height: calc(100vh - 60px);
}

.scrollbar-flexible {
  height: 100vh;
}
.dark-background .os-scrollbar .os-scrollbar-track .os-scrollbar-handle {
  background: #616161;
}
</style>