<template>
  <v-card
    class="px-3 fill-height"
    rounded="xl"
    :outlined="!$vuetify.theme.dark"
  >
    <CreateRoomDialog
      :open="openCreateRoomDialog"
      :onClose="onCloseCreateRoomDialog"
      :onCreate="reloadRoomList"
      :communityId="community._id"
    />

    <v-container class="px-0">
      <v-list-item>
        <v-list-item-avatar size="40" class="hs-rounded-lg">
          <v-img :src="getResourceUrl(community.thumbnail)" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ community.name }}
          </v-list-item-title>

          <v-list-item-subtitle>
            {{ community.about }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-row v-if="showRequestMembershipButton" class="px-4" justify="center">
        <RequestMembershipButton />
      </v-row>
      <v-row v-if="showSubscribeButton" class="px-4" justify="center">
        <SubscribeMembershipButton />
      </v-row>

      <v-row v-if="isInvited" class="px-4" justify="center">
        <HandleInviteButtons />
      </v-row>
      
      <v-divider class="my-3"/>

      <SidebarLinks
        :links="sidebarLinks"
        :clearNav="clearNav"  
      />
      
      <v-divider class="my-3"/>
      
      <v-row class="px-4 d-flex" style="align-items: center; justify-content: space-between">
        <h3>{{ $t('community.rooms') }}</h3>
        <v-btn
          v-if="showCreateRoomButton"
          icon
          small
          class="ml-2"
          :style="`background-color: ${primaryColor}`"
          @click="() => setOpenCreateRoomDialog(true)"
        >
          <v-icon :size="20" color="white">
            {{ mdiPlus }}
          </v-icon>
        </v-btn>
      </v-row>
      
      <v-row>
        <CommunityRoomList
          :community="community"
          :key="roomListUpdatesLength"
          @enter="clearNav = !clearNav"
        />
      </v-row>

      <v-row v-if="isOwner" class="px-4" justify="center">
        <InviteFriendsButton
          :community="community"
        />
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
  import CommunityService from '@api/community'
  import CommunityRoomList from '@components/Community/RoomList'
  import { mapGetters } from 'vuex'
  import { getResourceUrl } from '@utils'
  import { mdiPlus, mdiHomeVariantOutline, mdiAccountMultipleOutline, mdiCogOutline } from '@mdi/js'
  import CreateRoomDialog from '@components/Community/CreateRoomDialog'
  import SidebarLinks from '@components/Community/SidebarLinks'
  import RequestMembershipButton from '@components/Community/RequestMembershipButton'
  import InviteFriendsButton from '@components/Community/InviteFriendsButton'
  import HandleInviteButtons from '@components/Community/HandleInviteButtons'
  import SubscribeMembershipButton from '@components/Community/SubscribeMembershipButton'

  export default {

    components: {
      CommunityRoomList,
      CreateRoomDialog,
      SidebarLinks,
      RequestMembershipButton,
      InviteFriendsButton,
      HandleInviteButtons,
      SubscribeMembershipButton
    },

    data() {
      return {
        clearNav: false,
        community: {},
        communityService: null,
        mdiPlus,
        mdiHomeVariantOutline,
        mdiAccountMultipleOutline,
        openCreateRoomDialog: false,
        roomListUpdatesLength: 0
      }
    },

    async created() {
      this.communityService = CommunityService(this.user.id)
      this.community = await this.communityService.getById(this.$route.params.id)
    },

    computed: {
      ...mapGetters({
        user: 'user',
        profileId: 'profile/id',
        membership: 'community/membership',
        isModeratorOrUp: 'community/isModeratorOrUp',
        isOwner: 'community/isOwner',
        isInvited: 'community/isInvited',
        isActive: 'community/isActive'
      }),

      primaryColor() {
        const { isDark, themes: { light, dark } } = this.$vuetify.theme
        return isDark ? dark.primary : light.primary
      },

      sidebarLinks() {
        const links = [{
          icon: mdiHomeVariantOutline,
          path: `/community/${this.community._id}`,
          label: 'community.home'
        }, {
          icon: mdiAccountMultipleOutline,
          path: `/community/${this.community._id}/members`,
          label: 'community.members'
        }]

        if (this.isModeratorOrUp) {
          links.push({
            icon: mdiCogOutline,
            path: `/community/${this.community._id}/settings`,
            label: 'community.settings'
          })
        }

        return links
      },

      showCreateRoomButton() {
        return (
          this.isModeratorOrUp ||
          this.community.canMemberCreateRoom &&
          this.isActive
        )
      },
      showRequestMembershipButton() {
        return (
          !this.membership && !this.community.isPurchasable
        )
      },
      showSubscribeButton() {
        return (
          (!this.membership || !this.isActive) && this.community.isPurchasable
        )
      }
    },

    methods: {
      getResourceUrl,
      
      setOpenCreateRoomDialog(open) {
        this.openCreateRoomDialog = open
      },

      onCloseCreateRoomDialog(newRoom) {
        if (!this.community.rooms) {
          this.community.rooms = []
        }
        this.community = {
          ...this.community,
          rooms: [...this.community.rooms, newRoom]
        }
        this.openCreateRoomDialog = false
      },

      reloadRoomList() {
        this.roomListUpdatesLength = this.roomListUpdatesLength + 1
      }
    }
  }
</script>