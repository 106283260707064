import { withLayout } from '@utils'
import Open from '@layouts/Open'

const Wallet = () => import('@views/Wallet')

export default {
  path: 'wallet',
  component: {
    render: h => h('router-view')
  }, 
  children: [
    {
      path: 'transaction',
      name: 'wallet.transaction',
      component: withLayout(Wallet, Open),
      meta: {
        app: 'wallet',
        locked: false
      }
    }
  ]
}