<template>
  <v-container
    ref="textContainer"
    class="py-1"
  >
    <v-dialog
      v-model="mentionCardOn"
      hide-overlay
      max-width="0"
      @click:outside="mentionCardOn = false"
    >
      <MentionCard
        :mentionCardOn="mentionCardOn"
        :mentionCardStyle="mentionCardStyle"
        :upperHalfOn="mentionCardUpperHalfOn"
        @hideMentionCard="mentionCardOn = false"
        @doMention="doMention"
      />
    </v-dialog>

    <v-dialog
      v-model="emojiPickerLightOn"
      hide-overlay
      max-width="0"
      @click:outside="emojiPickerOn = false"
    >
      <v-card
        rounded="lg"
        elevation="10"
        :style="`position: absolute; max-width: 350px; left: ${emojiPickerLeft}px; top: ${emojiPickerTop}px;`"
      >
        <emoji-picker-light @click="emojiClick" />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="emojiPickerDarkOn"
      hide-overlay
      max-width="0"
      @click:outside="emojiPickerOn = false"
    >
      <v-card
        rounded="lg"
        elevation="20"
        :style="`position: absolute; max-width: 350px; left: ${emojiPickerLeft}px; top: ${emojiPickerTop}px;`"
      >
        <emoji-picker-dark @click="emojiClick" />
      </v-card>
    </v-dialog>

    <v-row
      justify="center"
      align="start"
      class="px-2"
    >
      <v-col
        v-if="avatarOn"
        class="py-0"
        cols="1"
      >
        <v-avatar
          :class="avatarClass"
          :size="avatarSize"
        >
          <v-img
            v-if="profileAvatar"
            :src="profileAvatar"
          >
            <template v-slot:placeholder>
              <v-skeleton-loader
                class="mx-auto"
                height="40"
                width="40"
                type="image"
              />
            </template>             
          </v-img>
          <v-btn
            v-else
            icon
            color="white"
          >
            <v-icon
              dark
              size="30"
            >
              {{ mdiAccount }}
            </v-icon>
          </v-btn>
        </v-avatar>
      </v-col>

      <v-col
        :cols="avatarOn ? 11 : 12"
        :class="`py-0 ${avatarOn ? '' : 'px-0'}`"
      >
        <v-container :class="`pr-0 pl-${dense ? 2 : 0} py-0`">
          <v-card
            :class="colorClass"
            :style="`min-height: ${avatarOn ? 40 : 48}px;`"
            elevation="0"
            rounded="lg"      
          >
            <v-row
              :class="textContainerClass"
              align="start"
            >
              <v-btn
                icon
                v-if="!avatarOn && !annotation"
                class="ml-2"
                :style="`margin-top: ${avatarOn ? 1 : 4}px;`"
                @click="setEmojiOn"
              >
                <v-icon >
                  {{ mdiEmoticonExcitedOutline }}
                </v-icon>
              </v-btn>

              <div
                v-if="!avatarOn"
                ref="emojiIcon"
              />

              <TextInput
                :style="`margin-bottom: 2px; width: ${textInputWidth}%;`"
                :index="0"
                :label="label"
                :content="text"
                :latexOn="true"
                :activeFocusOn="focusOn"
                :focusOn="true"
                :syncContentOn="syncContentOn"
                :syncSwitch="syncSwitch"
                :syncCarriageReturn="syncCarriageReturn"
                :syncCarriageStart="syncCarriageStart"
                :syncCarriageEnd="syncCarriageEnd"
                :syncCarriageUp="syncCarriageUp"
                :syncCarriageDown="syncCarriageDown"
                :previousCaretLeft="previousCaretLeft"
                :syncEmoji="syncEmoji"
                :syncLink="syncLink"
                :syncRangeContainer="syncRangeContainer"
                :syncRangeOffset="syncRangeOffset"
                :mentionCardOn="mentionCardOn"
                :syncMention="syncMention"
                :mentionData="mentionData"
                :syncSubmission="syncSubmission"
                @mention="setMentionCard"
                @focus="focusOn = true"
                @blur="focusOn = false"
                @textInput="setTextContent"
                @overrideSyncContent="overrideSyncContent"
                @setRangeState="setRangeState"
                @enterKey="submit"
              />

              <div
                v-if="avatarOn"
                ref="emojiIcon"
              />

              <v-btn
                v-if="avatarOn && !annotation"
                icon
                :style="`margin-top: ${avatarOn ? 1 : 6}px;`"
                @click="setEmojiOn"
              >
                <v-icon >
                  {{ mdiEmoticonExcitedOutline }}
                </v-icon>
              </v-btn>

              <v-btn
                v-if="!avatarOn && !annotation"
                icon
                :style="`margin-top: ${avatarOn ? 1 : 4}px;`"
                @click="setEmojiOn"
              >
                <v-icon >
                  {{ mdiAttachment }}
                </v-icon>
              </v-btn>
            </v-row>
          </v-card>
        </v-container>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { MentionCard } from '@components'
import TextInput from './TextInput'
import { uniq } from 'lodash'

import {
  mdiEmoticonExcitedOutline,
  mdiAccount,
  mdiAttachment
} from '@mdi/js'

export default {

  components: {
    TextInput,
    MentionCard
  },

  props: {
    inputWidth: {
      type: Number,
      default: 0
    },
    annotation: {
      type: Boolean,
      default: false
    },
    avatarOn: {
      type: Boolean,
      default: true
    },
    lighterViewerOn: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Comentário...'
    },
    dense: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      mdiAccount,
      mdiAttachment,
      mdiEmoticonExcitedOutline,

      mentionCardUpperHalfOn: true,

      mentionCardOn: false,
      mentionCardLeft: null,
      mentionCardTop: null,
      syncMention: false,
      mentionData: {},
      mentions: [],

      contentMentions: [],

      text: '',

      emojiPickerOn: false,
      emojiPickerTop: 0,
      emojiPickerLeft: 0,

      focusOn: false,

      syncContentOn: false,
      syncSwitch: false,
      syncCarriageReturn: false,
      syncCarriageStart: false,
      syncCarriageEnd: false,
      syncCarriageUp: false,
      syncCarriageDown: false,
      previousCaretLeft: null,
      syncEmoji: false,
      syncLink: false,
      syncRangeContainer: {},
      syncRangeOffset: 0,
      syncSubmission: false
    }
  },

  computed: {

    ...mapGetters({
      profileName: 'profile/name',
      profileAvatar: 'profile/avatar',
      scrollTop: 'scrollTop'
    }),

    textContainerClass() {
      
      const { xl, width } = this.$vuetify.breakpoint

      return xl || width < 1300
        ? 'px-3'
        : 'pl-3'
        
    },

    colorClass() {
      return this.$vuetify.theme.dark
        ? 'viewer-dark'
        : this.lighterViewerOn
        ? 'viewer-lighter'
        : 'viewer-light'
    },

    textInputWidth() {
      return this.inputWidth
        ? this.inputWidth
        : this.$vuetify.breakpoint.xl
        ? this.avatarOn
        ? this.dense ? 91 : 94
        : this.dense ? 90 : 92
        : this.avatarOn
        ? this.dense ? 88 : 90
        : this.dense ? 84 : 86
    },

    mentionCardStyle() {
      return `position: absolute;
        z-index: 100;
        left: ${this.mentionCardLeft}px;
        top: ${this.mentionCardTop}px;`
    },

    avatarClass() {
      return this.$vuetify.breakpoint.xl ? 'ml-2' : 'mr-4'
    },

    avatarSize() {
      return this.profileAvatar ? '40px' : ''
    },

    emojiPickerLightOn() {
      return this.emojiPickerOn && !this.$vuetify.theme.dark
    },

    emojiPickerDarkOn() {
      return this.emojiPickerOn && this.$vuetify.theme.dark
    }

  },

  methods: {

    submit() {

      const mentionEls = this.$refs.textContainer.querySelectorAll('a')
      const mentionProfiles = []

      for (let el of mentionEls) {

        const { profile } = el.dataset

        if (mentionProfiles.indexOf(profile) === -1) {
          mentionProfiles.unshift(profile)
        }

      }

      const mentions = uniq(this.mentions.filter(({id}) => {
        return mentionProfiles.indexOf(id) != -1
      }))
      .map(profileData => {
        return {
          profile: profileData.id,

          ...profileData
        }
      })

      const contentMentions = {
        comments: this.contentMentions.map(({ id }) => id)
      }

      this.$emit('submit', {
        paragraph: this.text,
        contentMentions,
        mentions
      })

      this.syncContentOn = true
      this.text = ''
      
      setTimeout(() => {
        this.syncContentOn = false
      })
    
    },

    setTextContent({ textContent }) {
      this.text = textContent
    },

    doMention({ username, avatar, bio, name, id }) {

      this.mentions.unshift({ username, name, avatar, bio, id })

      this.mentionData = {
        username,
        id
      }
      
      this.syncMention = true

      setTimeout(() => {
        this.mentionCardOn = false
        this.syncMention = false
      })

    },

    setMentionCard({ x }) {

      const { textContainer } = this.$refs
      const { y } = textContainer.getBoundingClientRect()

      this.mentionCardLeft = x
      this.mentionCardUpperHalfOn = y < window.innerHeight / 2
      this.mentionCardTop = !this.mentionCardUpperHalfOn ? y - 400 : y
      
      this.mentionCardOn = true

    },

    emojiClick(e) {
      const { parentElement } = e.target
      if (parentElement.classList.contains('emoji-mart-emoji')) {
        this.emojiPickerOn = false
        this.$root.$emitter.emit('SET_EMOJI')
        this.syncEmoji = true
        setTimeout(() => {
          this.syncEmoji = false
        })
      }
    },

    setEmojiOn() {
      const { x, y } = this.$refs.emojiIcon.getBoundingClientRect()
      this.emojiPickerTop = y > window.innerHeight / 2 ? y - 450 : y
      const leftAdjustment = this.avatarOn ? 300 : 39
      this.emojiPickerLeft = x - leftAdjustment
      this.emojiPickerOn = true
    }

  }
}
</script>

<style scoped>
.viewer-dark {
 border: thin solid rgba(255, 255, 255, 0.12);
 background-color: rgb(39, 39, 39);
}

.viewer-light {
 border: thin solid rgba(0, 0, 0, 0.12);
 background-color: rgba(202, 199, 199, 0.12);
}

.viewer-lighter {
  border: thin solid rgba(0, 0, 0, 0.12);
  background-color: rgb(255, 255, 255);
}
</style>