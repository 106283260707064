<template>
  <div>
    <div
      v-if="contentToShow === 'groups'"
      class="d-flex"
      style="align-items: center;"
    >
      <SearchGroups
        @search="filterGroups"
        @clear="onClearSearch"
        :debounceDelay="300"
        :type="`groups`"
      />
      <CreateRoomButton :onGroupCreate="onGroupCreate"/>
    </div>
    <Scrollbar
      v-if="contentToShow === 'groups'"
      :thin="true"
      autoHide="leave"
      :style="`height: ${rootHeight}px;`"
    >
      <v-container>
        <v-row>
          <RoomCard
            v-for="(room, idx) in rooms"
            :key="`room_${idx}`"
            class="mb-4"
            style="width: 100%;"
            :room="room"
            @snack="setSnack"
            @setCurrentRoom="setCurrentRoom(room)"
          />
        </v-row>
      </v-container>
    </Scrollbar>
    <Conversations
      v-if="contentToShow === 'conversations'"
      :onBack="showGroups"
      :room="currentRoom"
    />
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex'
  import SearchGroups from '@components/Chat/Search.vue';
  import CreateRoomButton from '@components/Chat/CreateRoomButton.vue';
  import RoomCard from '@components/Room/RoomCard.vue';
  import RoomService from '@api/room'
  import MemberService from '@api/member'
  import Conversations from '@components/Chat/GroupConversations.vue'
  import { Scrollbar } from '@components/App'

  export default {
    components: {
      SearchGroups,
      CreateRoomButton,
      RoomCard,
      Conversations,
      Scrollbar
    },

    data() {
      return {
        roomService: null,
        contentToShow: 'groups',
        initialRooms: [],
        rooms: [],
        distanceToTop: 290
      }
    },

    async created() {
      this.roomService = RoomService(this.user.id);
      this.memberService = MemberService(this.user.id)
      this.initRooms()
    },

    computed: {
      ...mapGetters({
        user: 'user',
        myProfileId: 'profile/id',
        currentRoom: 'rooms/currentRoom',
        innerHeight: 'innerHeight',
        openHandleGroupRoomDialog: 'rooms/openHandleGroupRoomDialog'
      }),

      rootHeight() {
        return this.innerHeight * (1 - (this.distanceToTop / this.innerHeight)) - 55
      }
    },

    methods: {
      ...mapMutations({
        SET_CURRENT_ROOM: 'rooms/setCurrentRoom',
      }),
      async onGroupCreate(roomId, conversationId) {
        await this.initRooms()
        await this.setCurrentRoom(this.rooms.find(r => r._id === roomId))
        this.$router.push(`/rooms/conversation?id=${conversationId}`)
      },
      async initRooms() {
        this.initialRooms = await this.roomService.listChatRooms(this.myProfileId)
        this.rooms = this.initialRooms
      },
      async setCurrentRoom(room) {
        const members = await this.memberService.listByChatRoom(room._id)
        this.SET_CURRENT_ROOM({
          ...room,
          members
        })
        this.showConversations()
      },
      showGroups() {
        this.contentToShow = 'groups'
      },
      showConversations() {
        this.contentToShow = 'conversations'
      },
      forceRerender() {
        this.$forceUpdate()
      },
      filterGroups(value) {
        this.rooms = this.initialRooms.filter(
          room => room.name.toLowerCase().includes(value.toLowerCase())
        )
      },
      onClearSearch() {
        this.rooms = this.initialRooms
      }
    }
  }
</script>