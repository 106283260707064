<template>
  <v-hover v-slot:default="{ hover }">
    <v-sheet
      v-ripple
      class="mx-3 py-1 clickable"
      :style="`background-color: ${
          !hover && !create ? 'transparent' : 
          $vuetify.theme.dark ? '#272727' : '#F5F5F5'
        };
        transition: all 0.3s ease;
        width: 100%;
        border-radius: 12px;`"
      @click="setPersona"
    >
      <v-list-item class="px-1 pr-2">
        <v-avatar
          size="60"
          class="mr-4"
          tile
        >
          <v-img
            style="border-radius: 12px"
            :src="getResourceUrl(avatar)"
          >
            <template v-slot:placeholder>
              <v-skeleton-loader
                class="mx-auto"
                height="60"
                width="60"
                style="border-radius: 12px;"
                type="image"
              />
            </template>   
          </v-img>
        </v-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ caption }}
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action v-if="currentPersona.id">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                icon
                style="background-color: rgba(183, 28, 28, 0.1);"
                color="danger"
                @click.prevent="resetPersona"
              >
                <v-icon>
                  {{ mdiClose }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('reset') }}</span>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
    </v-sheet>
  </v-hover>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { mdiClose } from '@mdi/js';
import { getResourceUrl } from '@utils'
import PersonaFunctions from './PersonaFunctions.vue'


export default {
  props: {
    create: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    caption: {
      type: String,
      default: ''
    },
    avatar: {
      type: String,
      default: ''
    },
    currentPersona: {
      type: Object,
      default: () => ({})
    }
  },

  created() {
    this.resetPersona = PersonaFunctions.resetPersona.bind(this);
  },

  data: () => ({
    mdiClose
  }),

  computed: {
    ...mapGetters({
      user: 'user',
      myProfileId: 'profile/id',
      annaChamber: 'annaChamber',
      myFolders: 'folders/myFolders'
    })
  },

  methods: {
    ...mapMutations({
      SET_ANNA_CHAMBER: 'setAnnaChamber',

      // TODO: put these mutations on PersonaFunctions
      RESET_PERSONA: 'RESET_PERSONA',
      SET_CONTENTS: 'folders/setContents',
      SET_CURRENT_FOLDER: 'folders/setCurrentFolder',
      SET_RAG: 'folders/setRag',
      SET_ROLES: 'persona/SET_ROLES',
      SET_TOOLS: 'persona/SET_TOOLS'
    }),

    getResourceUrl,
    
    setPersona() {
      // Either goes to the persona page
      // or emits the signal to activate the persona
      if (this.currentPersona && this.currentPersona.id) {
        if (this.annaChamber) this.SET_ANNA_CHAMBER()
        this.$router.push(`/personas?id=${this.currentPersona.id}`)
      } else this.$emit('persona')
    }
  }
}
</script>