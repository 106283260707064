<template>
  <div
    :class="`d-flex
      hover-layer
      v-card--reveal
      display-3
      white--text
      transition-fast-in-fast-out`"
    :style="`background: rgba(${hoverStyle})`"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    customColor: {
      type: String,
      default: ''
    },
    opacity: {
      type: Number,
      default: 0.4
    }
  },

  computed: {
    hoverStyle() {
      if (this.customColor) {
        
        return `${this.customColor}, ${this.opacity}`

      } else {

        return this.$vuetify.theme.dark
        ? '97, 97, 97, 0.3'
        : '117, 117, 117, 0.2'

      }
    }
  }
}
</script>

<style scoped>
.hover-layer {
  width: 100%;
  cursor: pointer;
}
</style>