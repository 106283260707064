<template>
  <v-btn 
    small
    rounded
    depressed
    color="primary"
    @click="requestMembership"
    :loading="requestingMembership"
  >
    {{ $t('community.requestMembership') }}
  </v-btn>
</template>

<script>
  import MemberService from '@api/member'
  import { mapGetters, mapMutations } from 'vuex'

  export default {
    props: {
      onRequest: Function
    },

    data() {
      return {
        memberService: null,
        requestingMembership: false
      }
    },

    created() {
      this.memberService = MemberService(this.user.id)
    },

    computed: {
      ...mapGetters({
        user: 'user',
        profileId: 'profile/id'
      }),
    },

    methods: {
      ...mapMutations({
        setMembership: 'community/setMembership'
      }),
      async requestMembership() {
        this.requestingMembership = true
        try {
          const { notifications } = await this.memberService.requestCommunityMembership({
            community: this.$route.params.id,
            profile: this.profileId
          })
          notifications.forEach((notification) => {
            this.$socket.notification.emit('communityInvitation', {
              invitation: {
                ...notification
              },
              member: notification.profile,
              community: this.$route.params.id
            })
          })
          this.loadUserMember()
        } catch (error) {
          console.log(error)
        }
        this.requestingMembership = false
      },
      async loadUserMember() {
        const member = await this.memberService.getCommunityMembership(
          this.$route.params.id,
          this.profileId
        )
        this.setMembership(member)
      },
    }  
  }
</script>