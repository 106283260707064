<template>
  <PDFScrollingDocument
    class="pdf-preview"
    @pages-fetch="onPagesFetch"
    v-bind="{pages, pageCount, currentPage}"
    v-slot="{page, isPageFocused}"
    :isParentVisible="isPreviewEnabled"
  >    
    <PDFThumbnail
      v-bind="{scale, page, isPageFocused}"
      @thumbnail-rendered="onThumbnailRendered"
      @thumbnail-errored="onThumbnailErrored"
      @page-focus="onPageFocused"
    />
  </PDFScrollingDocument>
</template>

<script>
import PDFScrollingDocument from '@components/PDF/ScrollingDocument'
import PDFThumbnail from '@components/PDF/Thumbnail'
import Scrollbar from '@components/App/Scrollbar'

export default {
  name: 'PDFPreview',

  components: {
    PDFScrollingDocument,
    PDFThumbnail,
    Scrollbar
  },

  props: {
    pages: {
      required: true
    },
    pageCount: {
      type: Number,
      default: 0
    },
    scale: {
      type: Number,
      default: 1.0
    },
    currentPage: {
      type: Number,
      default: 1
    },
    isPreviewEnabled: {
      default: false
    }
  },

  methods: {
    onPagesFetch(currentPage) {
      this.$emit('pages-fetch', currentPage)
    },

    onPageFocused(pageNumber) {
      this.$emit('page-focus', pageNumber)
    },

    onThumbnailRendered(payload) {
      this.$el.dispatchEvent(new Event('scroll'))
      this.$emit('thumbnail-rendered', payload)
    },

    onThumbnailErrored(payload) {
      this.$emit('thumbnail-errored', payload)
    }
  }
}
</script>

<style scoped>
.pdf-preview {
  padding: 1em 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.scrolling-page {
  margin-bottom: 1em;
}
</style>
