<template>
  <div>
    <v-tooltip
      v-model="recording"
      top
    >
      <template>
        <v-btn
          ref="recordAudio"
          icon
          :color="color ? color : recording ? 'primary' : ''"
          :class="recording ? 'v-btn--active' : ''"
          :disabled="!isActive || (notAllowed && permissionPrompted)"
          @click="startRecording"
        >
          <v-icon>
            {{ recording ? mdiMicrophoneSettings : mdiMicrophone }}
          </v-icon>
        </v-btn>
       </template>
       <span>{{ recordTime }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import {
  mdiMicrophone,
  mdiMicrophoneSettings
} from '@mdi/js'

export default {
  props: {
    isActive: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: ''
    }
  },

  mounted () {
    //this.connectUserMedia()
  },

  beforeDestroy () {
    this.clearTimer()
  },

  data () {
    return {
      mdiMicrophone,
      mdiMicrophoneSettings,
      
      /* Permission Control */
      snackbar: false,
      notAllowed: true,
      permissionPrompted: false,

      /* Recording and Streamming */
      stream: {},
      recorder: {},
      recorded: false,
      recording: false,
      recordTime: '0m 0s',
      timer: 0
    }
  },

  watch: {
    async recording (recOn) {
      if (recOn) {
        const items = []
        this.recorder = new MediaRecorder(this.stream);

        this.recorder.ondataavailable = ({ data }) => {
          items.push(data)
          if (this.recorder.state === 'inactive') {
            const audioBlob = new Blob(items, { 'type' : 'audio/ogg; codecs=opus' })
            this.$emit('audioBlob', audioBlob)
          }
        }
        this.recorder.start()

        const startDate = new Date().getTime()
        this.timer = setInterval(() => {
          const now = new Date().getTime()
          const distance = now - startDate

          const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
          const seconds = Math.floor((distance % (1000 * 60)) / 1000)

          this.recordTime = `${minutes}m ${seconds}s`
          if (distance > 600000) this.clearTimer()
        }, 1000)
      }
    }
  },

  methods: {
    async connectUserMedia (startRecording = false) {

      try {

        const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
        
        this.stream = stream
        this.snackbar = false
        this.notAllowed = false
        this.permissionPrompted = true

        if (startRecording) {

          this.recording = true

        }
      
      } catch(err) {
      
        this.clearRecordingErr(err)
      
      }
    },

    startRecording () {

      if (!this.permissionPrompted) {

        this.connectUserMedia(true)
        

      } else if (this.recording) {
        
        this.recording = false
        
        this.clearTimer()
        this.connectUserMedia()

      } else if (this.stream.active) {
        
        this.recording = true
      
      } else {

        this.stream = {}      
      
      }
    },

    clearTimer () {
      if (this.recording || this.recorder.state === 'recording') {
        
        this.recording = false
        
        clearInterval(this.timer)
        
        this.recordTime = '0m 0s'
        this.timer = 0
        
        if ('stop' in this.recorder) {
         
          this.recorder.stop()
        
        }
      }

      if ('getTracks' in this.stream) {
      
        this.stream.getTracks().forEach(track => track.stop())
      
      }

    },

    clearRecordingErr () {
      this.stream = {}
      this.snackbar = true
      this.notAllowed = true
      this.permissionPrompted = true
    }
  }
}
</script>