import API from '@api';

export default (userId) => {
  const apiRequest = API()
  return {
    listPending (profileId) {
      return apiRequest.get(
        `onboarding-pending/${userId}`,
        { params: { profileId } }
      )
    },

    handleOnBoardings (body) {
      return apiRequest.post(
        `onboarding/handle/${userId}`,
        body
      )
    },
  }
}
