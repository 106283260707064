<template>
  <v-app-bar
    app
    dark
    fixed
    height="60"
    clipped-left
    elevation="2"
    :color="toolbarColor"
    v-if="!toolbar"
  >
    <v-row align="center">
      
      <v-btn
        icon
        @click="$router.push('/home')"
      >
        <v-avatar size="40">
          <v-img src="@assets/images/hs-logo--white.png" />  
        </v-avatar>
      </v-btn>

      <SearchBox
        class="mx-2"
        :avatarOn="false"
        :lighterViewerOn="true"
        :setFocusOn="setFocusOn"
        @submit="searchPdf"
      />

      <v-btn
        text
        style="border-radius: 12px;"
        @click="routeBack"
      >
        <v-icon left>
          {{ mdiKeyboardBackspace }} 
        </v-icon>
        {{ $t('goBack') }}
      </v-btn>

      <v-spacer />
      
      <v-avatar :size="avatarSize">
        <v-img
          v-if="avatar"
          :src="getResourceUrl(avatar)"
        >
          <template v-slot:placeholder>
            <v-skeleton-loader
              class="mx-auto"
              height="40"
              width="40"
              type="image"
            />
          </template>           
        </v-img>
        <v-btn
          v-else
          icon
          color="white"
        >
          <v-icon
            dark
            size="30"
          >
            {{ mdiAccount }}
          </v-icon>
        </v-btn>
      </v-avatar>

      <v-divider
        vertical
        inset
        class="mx-3 my-9"
      />

      <v-btn
        text
        style="border-radius: 10px"
        @click="SET_ANNA_CHAMBER"
      >
        {{ $t('call_anna') }}
        <v-icon right>
          {{ mdiCompassRose }}
        </v-icon>
      </v-btn>

      <v-tooltip
        bottom
        :disabled="!settings.tooltipsOn"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            icon
          >
            <v-icon style="transform: translateY(8%)">
              {{ mdiMessageTextOutline }}
            </v-icon>
          </v-btn>
        </template>
        <span>
          {{  $t('chat_with_friends_soon') }}
        </span>
      </v-tooltip>

      <v-tooltip
        bottom
        :disabled="!settings.tooltipsOn"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            icon
            @click="SET_NOTES_ON(true)"
          >
            <v-icon>
              {{ mdiSquareEditOutline }}
            </v-icon>
          </v-btn>
        </template>
        <span>
          {{ $t('annotations') }}
        </span>
      </v-tooltip>

      <Actions />

      <PDFPaginator class="mt-6" />

      <v-speed-dial
        v-model="fab"
        direction="bottom"
        transition="slide-y-reverse-transition"
      >
        <template v-slot:activator>
          <v-btn
            v-model="fab"
            disabled
            dark
            icon
          >
            <v-icon v-if="fab">{{ mdiClose }}</v-icon>
            <v-icon v-else>{{ mdiDotsVertical }}</v-icon>
          </v-btn>
        </template>

        <v-tooltip
          left
          :disabled="!settings.tooltipsOn"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              fab
              dark
              small
              :color="fabColor"
              v-on="on"
            >
              <v-icon>{{ mdiBookmarkOutline }}</v-icon>
            </v-btn>
          </template>
          <span>
            Save
          </span>
        </v-tooltip>

        <v-tooltip
          left
          :disabled="!settings.tooltipsOn"
        >
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-btn
                fab
                dark
                small
                :color="fabColor"
                @click.once="clickVideoInput"  
              >
                <v-icon>{{ mdiShieldLockOutline }}</v-icon>
              </v-btn>
            </div>
          </template>
          <span>
            Share
          </span>
        </v-tooltip>

        <v-tooltip
          left
          :disabled="!settings.tooltipsOn"
        >
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-btn
                fab
                dark
                small
                :color="fabColor"
                @click.once="edit = true"  
              >
                <v-icon>{{ mdiPencil }}</v-icon>
              </v-btn>
            </div>
          </template>
          <span>
            Edit
          </span>
        </v-tooltip>
      </v-speed-dial>
    </v-row>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import PDFZoom from '@components/PDF/Zoom.vue'
import PDFRotate from '@components/PDF/Rotate.vue'
import PDFPaginator from '@components/PDF/Paginator.vue'
import SearchBox from '@components/PDF/SearchBox'
import Actions from '@components/PDF/Actions'
import { getResourceUrl } from '@utils'
import {
  mdiKeyboardBackspace,
  mdiPageLayoutBody,
  mdiFormatLetterMatches,
  mdiCompassRose,
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiClose,
  mdiMagnify,
  mdiViewList,
  mdiAccount,
  mdiMenuUp,
  mdiMenuDown,
  mdiRotateLeft,
  mdiRotateRight,
  mdiMagnifyMinusOutline,
  mdiMagnifyPlusOutline,
  mdiFullscreen,
  mdiFullscreenExit,
  mdiPageFirst,
  mdiPageLast,
  mdiAppsBox,
  mdiMessageTextOutline,
  mdiBookmarkOutline,
  mdiShieldLockOutline,
  mdiPencil
} from '@mdi/js'

export default {
  components: {
    Actions,
    PDFZoom,
    PDFRotate,
    PDFPaginator,
    SearchBox
  },

  created () {
    window.addEventListener('keydown', this.focusSearchTxt)
  },

  beforeDestroy () {
    window.removeEventListener('keydown', this.focusSearchTxt)
    this.resetQuery()
    this.resetReader()
  },

  data () {
    return {
      mdiKeyboardBackspace,
      mdiPageLayoutBody,
      mdiFormatLetterMatches,
      mdiCompassRose,
      mdiSquareEditOutline,
      mdiDotsVertical,
      mdiClose,
      mdiMagnify,
      mdiViewList,
      mdiAccount,
      mdiMenuUp,
      mdiMenuDown,
      mdiRotateLeft,
      mdiRotateRight,
      mdiMagnifyMinusOutline,
      mdiMagnifyPlusOutline,
      mdiFullscreen,
      mdiFullscreenExit,
      mdiPageFirst,
      mdiPageLast,
      mdiAppsBox,
      mdiMessageTextOutline,
      mdiBookmarkOutline,
      mdiShieldLockOutline,
      mdiPencil,

      queryInput: '',
      setFocusOn: false,

      toolbarSignal: false,
      fab: false
    }
  },

  computed: {
    ...mapGetters({
      isSearchListEnabled: 'pdf/isSearchListEnabled',
      isPreviewEnabled: 'pdf/isPreviewEnabled',
      querySwitch: 'pdf/querySwitch',
      textQuery: 'pdf/textQuery',
      matches: 'pdf/matches',
      from: 'from',
      settings: 'settings',
      avatar: 'profile/avatar',
      scale: 'pdf/scale',
      fit: 'pdf/fit'
    }),

    fabColor() {
      const darkTheme = this.$vuetify.theme.dark
      return darkTheme ? 'grey darken-4' : 'primary'
    },

    isDisabled() {
      return !this.scale
    },

    avatarSize () {
      return this.avatar ? '40px' : ''
    },

    toolbarColor () {
      return !this.$vuetify.theme.dark ? 'primary' : 'grey darken-4'
    },

    txtQueryOn () {
      return this.textQuery
    }
    
  },

  methods: {
    ...mapMutations({
      SET_TEXT_QUERY: 'pdf/textQuery',
      SET_PDF_SETTINGS: 'pdf/SET_PDF_SETTINGS',
      setIsPreviewEnabled: 'pdf/isPreviewEnabled',
      setIsSearchListEnabled: 'pdf/isSearchListEnabled',
      setQuerySwitch: 'pdf/querySwitch',
      setIsSearchListQuery: 'pdf/isSearchListQuery' ,
      SET_NOTES_ON: 'note/SET_NOTES_ON',
      searchPrevious: 'pdf/searchPrevious',
      searchNext: 'pdf/searchNext',
      toggleOutline: 'pdf/isOutlineEnabled',
      SET_ANNA_CHAMBER: 'setAnnaChamber'
    }),

    ...mapActions({
      resetQuery: 'pdf/resetQuery',
      resetReader: 'pdf/reset'
    }),

    getResourceUrl,

    routeBack() {
      if (this.from != '/') this.$router.go(-1)
      else this.$router.push('/home')
    },

    setSearchList() {
      if (this.textQuery != undefined && this.textQuery != '') {
        this.setIsPreviewEnabled(false)
        this.setIsSearchListEnabled(!this.isSearchListEnabled)
      }
    },

    searchPdf(searchStr) {
      if (this.textQuery && this.textQuery === searchStr) {
        this.setQuerySwitch(!this.querySwitch)
        this.setIsSearchListQuery(false)
      
      } else {
        this.resetQuery()

        if (searchStr != '')
          this.SET_TEXT_QUERY(searchStr)
      }
    },

    focusSearchTxt(e) {
      if(e.ctrlKey && e.key === 'f') {
        e.preventDefault()
        
        this.$nextTick(() => {
          this.setFocusOn = true

          this.$nextTick(() => {
            this.setFocusOn = false
          })
        })
      }
    },

    togglePreview() {
      this.setIsPreviewEnabled(!this.isPreviewEnabled)
      this.setIsSearchListEnabled(false)
    }
  }
}
</script>

<style scoped>
.default {
  cursor: default;
}

.pointer {
  cursor: pointer;
}
</style>