<script>
  import { mapGetters, mapActions } from 'vuex'
  import OnBoarding from '@api/onboarding'

  export default {
    async created() {
      if (!this.user) return;
      
      this.onBoardingService = OnBoarding(this.user.id)
      
      if (!this.pendingOnBoarding.length && !this.onBoardingDone.length) {
        const response = await this.onBoardingService.listPending(
          this.profileId
        )
        const { pending, done } = response
        this.setPendingOnBoarding(pending)
        this.setOnBoardingDone(done)
      }
    },

    data() {
      return {
        onBoardingService: null,
      }
    },

    computed: {
      ...mapGetters({
        user: 'user',
        pendingOnBoarding: 'pendingOnBoarding',
        profileId: 'profile/id',
        onBoardingDone: 'profile/onBoardingDone'
      }),
    },

    methods: {
      ...mapActions({
        setPendingOnBoarding: 'SET_PENDING_ONBOARDING',
        setOnBoardingDone: 'profile/SET_ONBOARDING_DONE'
      })
    }
  }
</script>