<script>
  import { mapGetters, mapMutations } from 'vuex'

  export default {
    created() {
      if(!this.$socket.notification.id) {

        this.$socket.notification.connect()

      }

      if(this.$route.name === 'message.list') {
      
      this.$socket.conversation.connect()
      
      } else {

        this.$socket.conversation.disconnect()
        
      }
    },

    sockets: {
      notification: {
        connect () {
          this.$socket.notification.emit(
            'saveSocket',
            {
              profile: this.profile
            }
          )
            
          this.$socket.notification.emit(
            'loadNotifications',
            {
              senderSocket: this.$socket.notification.id,
              profile: this.profile
            }
          )
        },

        notifications (notifications) {
          this.setNotifications(notifications)
        },

        newNotification (notification) {
          this.addNotification(notification)
        }
      }
    },

    computed: {
      ...mapGetters({
        profile: 'profile/id'
      }),
    },

    methods: {
      ...mapMutations({
        setNotifications: 'notification/setNotifications',
        addNotification: 'notification/addNotification'
      })
    }
  }
</script>
