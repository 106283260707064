<template>
  <div class="d-flex" style="justify-content: space-around; width: 100%;">
    <v-btn 
      small
      rounded
      depressed
      color="primary"
      @click="onAccept"
      :loading="accepting"
      :disabled="accepting || declining"
    >
      {{ $t('community.join') }}
    </v-btn>
    <v-btn 
      small
      rounded
      depressed
      color="secondary"
      @click="onDecline"
      :loading="declining"
      :disabled="accepting || declining"
    >
      {{ $t('community.decline') }}
    </v-btn>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex'
  import MemberService from '@api/member'
  import InvitationDialog from '@components/Hall/InvitationDialog'

  export default {
    components: {
      InvitationDialog
    },

    props: {
      community: Object,
      member: String
    },

    data() {
      return {
        inviting: false,
        open: false,
        memberService: null,
        accepting: false,
        declining: false
      }
    },

    created() {
      this.memberService = MemberService(this.user.id)
    },

    computed: {
      ...mapGetters({
        user: 'user',
        profileId: 'profile/id',
        membership: 'community/membership',
      }),
    },

    methods: {
      ...mapMutations({
        setMembershipStatus: 'community/setMembershipStatus'
      }),

      async onAccept() {
        this.accepting = true
        await this.memberService.acceptCommunityInvitation(this.membership._id)
        this.setMembershipStatus('active')
        this.accepting = false
      },

      async onDecline() {
        this.declining = true
        await this.memberService.declineCommunityInvitation(this.membership._id)
        this.setMembershipStatus('invite_declined')
        this.declining = false
      },
    }
  }
</script>