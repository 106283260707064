export default {
  namespaced: true,

  state: {
    contextConversation: false,
    conversationId: '',
    isRecruitment: '',
    receiver: {
      id: '',
      name: '',
      bio: '',
      avatar: '',
    },
    online: [],
    currentMsg: ''
  },

  getters: {
    isContextConversation: ({ contextConversation }) => contextConversation,
    conversationId: ({conversationId}) => conversationId,
    isRecruitment: ({isRecruitment}) => isRecruitment,
    receiver: ({receiver}) => receiver,
    online: ({online}) => online,
    isOnline: state => profile => {
      return state.online.indexOf(profile) != - 1
    },
    currentMsg: ({currentMsg}) => currentMsg
  },
  
  mutations: {
    setContextConversation: (state, payload) => {
      state.contextConversation = payload
    },
    setConversationId: (state, payload) => {
      state.conversationId = payload
    },
    setRecruitment: (state, payload) => {
      state.isRecruitment = payload
    },
    setReceiver: (state, payload) => {
      state.receiver = payload
    },
    addOnlineProfile: (state, payload) => {
      state.online.push(payload)
    },
    removeOnlineProfile: (state, payload) => {
      const index = state.online.indexOf(payload)
      if (index > -1) state.online.splice(index, 1)
    },
    clearOnline: state => {
      state.online = []
    }, 
    setCurrentMsg: (state, payload) => {
      state.currentMsg = payload
    }
  }
}