import { withLayout } from '@utils'
import StudyLayout from '@layouts/Study'

const Search = () => import('@views/search/index.vue')

export default {
  path: 'search',
  component: {
    render: h => h('router-view')
  },
  children: [
    {
      path: '',
      name: 'search',
      component: withLayout(Search, StudyLayout),
      meta: {
        locked: true,
        app: 'search'
      }
    }
  ]
};
