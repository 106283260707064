import { FILE_CHUNK_SIZE } from '@utils/constants'
import { s3Url, s3MultipartUpload } from '@aws'
import { toBase64 } from '@utils'

export default async function ({file, namespace, filename, size, contentExtension, contentType}) {
  
  return new Promise(async (resolve, reject) => {

    if (size > FILE_CHUNK_SIZE) {

      s3MultipartUpload({
        namespace,
        filename,
        numOfParts: Math.ceil(size / FILE_CHUNK_SIZE),
        file
      })
      .then(({ Location }) => resolve(Location))
      .catch(reject)

    } else {

      const file64 = await toBase64(file)
      const params = {
        filename,
        namespace,
        contentExtension,
        contentType
      }
      const res = await s3Url(params, file64)

      try {

        const [url] = res.url.split('?')
        resolve(url)

      } catch (err) {

        reject(err)

      }

    }

  })

}