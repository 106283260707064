var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{ref:"textContainer",staticClass:"py-1"},[_c('MentionCard',{attrs:{"getPosition":_vm.getPosition,"editor":_vm.editor,"setOpen":_vm.setMentionCardOpen,"setMentions":_vm.setMentions,"getCaretIndex":_vm.getCaretIndex,"isSingleChat":!!_vm.$route.query.selectedProfileId}}),_c('v-card',{class:_vm.colorClass,staticStyle:{"min-height":"48px","max-height":"300px","border-radius":"15px"},attrs:{"id":"textarea-container","elevation":_vm.$vuetify.theme.dark ? 4 : 0,"outlined":!_vm.$vuetify.theme.dark}},[_c('v-container',{staticClass:"py-2",attrs:{"id":"editorContainer"}},[_c('v-row',{staticClass:"px-4",attrs:{"justify":"space-between"}},[_c('div',{ref:"textarea",class:{
            'px-1 hs-chat--textarea codex-editor--narrow': true,
            menuClass: true,
            hiddenClass: true
          },style:(("width: " + _vm.textAreaWidth + "%;")),attrs:{"id":("editorjs_" + _vm.EDITOR_ID),"spellcheck":"false"},on:{"keydown":_vm.onKeyDown,"click":_vm.onClick}}),_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],style:("z-index: 1;"),attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('shimmer')}}},on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.mdiEmoticonExcitedOutline)+" ")])],1)]}}])},[_c('span',[_vm._v("Emoji")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],style:("z-index: 1;"),attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('setOptions')}}},on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.mdiCog)+" ")])],1)]}}])},[_c('span',[_vm._v("Options")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({style:(("z-index: 1;\n                  background-color: " + (_vm.streaming ? '' :
                    _vm.$vuetify.theme.dark ?
                      'rgba(94, 53, 177, 0.48)' : 'rgba(125, 33, 237, 0.157)'))),attrs:{"disabled":"","icon":"","color":_vm.$vuetify.theme.dark ? '#B388FF' : '#6200EA'},on:{"click":function($event){return _vm.$emit(_vm.streaming ? 'stop' : 'setStudy')}}},_vm.getOnObject(on)),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.streaming ? _vm.mdiStopCircleOutline : _vm.mdiShimmer)+" ")])],1)]}}])},[_c('span',[_vm._v("Hierarchical Solver")])])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }