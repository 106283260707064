import { withLayout } from '@utils'
import StudyLayout from '@layouts/Study'

const List = () => import('@views/notebooks/List')
const Note = () => import('@views/notebooks/Note')

export default {
  path: 'notebooks',
  component: {
    render: h => h('router-view')
  },
  children: [
    {
      path: '',
      name: 'notebooks.list',
      component: withLayout(List, StudyLayout),
      meta: {
        locked: true,
        app: 'notebooks'
      }
    },
    {
      path: 'note',
      name: 'notebooks.note',
      component: withLayout(Note, StudyLayout),
      meta: {
        locked: true,
        app: 'notebooks'
      }
    }
  ]
}
