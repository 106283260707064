<script>
import ObjectID from 'bson-objectid'
import API from '@api'

export default {
  async setPersonaFolder(personaFolder) {
    this.SET_CURRENT_FOLDER(personaFolder)

    // Loads the default folder contents
    let contents = await API().get(`contents/${this.user.id}`, {
      params: {
        query: {
          folder: {
            $in: personaFolder.id
          }
        }
      }
    })

    // Loads the folder connector
    const [folderConnector] = await API().get('connectors', {
      params: {
        query: {
          type: {
            $eq: 'folder_sync'
          },
          entities: {
            $elemMatch: {
              ref: 'Persona',
              _id: ObjectID(this.persona.id)
            }
          }
        }
      }
    });

    if (folderConnector) {
      const sourceFolderId = folderConnector.entities[0]._id
      const sourceFolderContents = await API().get(`contents/${this.user.id}`, {
        params: {
          query: {
            folder: {
              $in: sourceFolderId
            }
          },
          sort: {
            createdAt: -1
          }
        }
      })
      contents = contents.concat(sourceFolderContents)
    }

    this.SET_CONTENTS(contents)
    this.SET_RAG(true)
  },

  async resetPersona(event) {
    if (event) {
      // we use this for the persona card
      // since the reset button sits on top of the clickable persona card
      // we cancel the clicking on the persona card
      event.preventDefault()
      event.stopPropagation()
    }

    this.SET_CURRENT_FOLDER(null)
    // sets My Library contents
    const myContents = await API().get(`contents/${this.user.id}`, {
      params: {
        query: {
          sender: {
            $eq: this.myProfileId
          },
          folder: {
            $exists: false
          }
        },
        sort: {
          createdAt: -1
        }
      }
    })

    // TODO: wouldn't this be the same as this.SET_PERSONA({}) ??
    this.SET_CONTENTS(myContents)
    this.RESET_PERSONA()
    this.SET_RAG(false)

    this.SET_ROLES([])
    this.SET_TOOLS([])

    if (this.annaChamber)
      this.SET_ANNA_CHAMBER()
  },
}
</script>