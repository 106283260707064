<template>
  <div style="width: 100%">
    <InvitationDialog
      :inviteOn="open"
      :members="[]"
      :community="community"
      @close="open = false"
      @newInvitees="$emit('newInvitees', $event)"
      @snack="emitSnack"
    />
    <v-btn
      block
      depressed
      color="primary"
      class="hs-rounded-12"
      @click="() => open = true"
      :loading="inviting"
    >
      {{ $t('community.inviteFriends') }}
    </v-btn>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import InvitationDialog from '@components/Hall/InvitationDialog'

  export default {
    components: {
      InvitationDialog
    },

    props: {
      community: Object,
    },

    data() {
      return {
        inviting: false,
        open: false,
      }
    },

    computed: {
      ...mapGetters({
        user: 'user',
        profileId: 'profile/id'
      }),
    },
  }
</script>