<script>
import store from '@store'
import { s3FileUrl } from '@aws'
import * as durationFns from 'duration-fns'
import API from '@api'
import axios from 'axios'
import { canvasToBlob, getPdfTextContent } from '@utils'
import { v4 as uuidv4 } from 'uuid';

const pdfjsLib = require('pdfjs-dist/legacy/build/pdf');
const worker = require('pdfjs-dist/legacy/build/pdf.worker.min')
window.pdfjsWorker = worker

export default {
  /**
   * Returns the thumbnail url for the input video.
   * 
   * @param {object} the video file.
   */
  async getVideoThumbnail(videoFile) {

    // TODO: Review this

    if (!videoFile) return;

    const formData = new FormData();
    const config = {
      header: {
        'Content-Type': 'multipart/form-data',
        'Content-Length': videoFile.size
      }
    };
    
    formData.append('video', videoFile);
    const postUrl = `video/thumbnail/${store.getters.user.id}`;

    const { thumbnail } = await API().post(postUrl, formData, config);

    const bstr = atob(thumbnail);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
        
    while(n--) u8arr[n] = bstr.charCodeAt(n);

    const [filename] = videoFile.name.split('.')
    const thumbnailName = `${filename}-${Date.now()}.jpeg`
    const thummbnailFile = new File([u8arr], thumbnailName, { type: 'image/jpeg' })
    const thumbnailSrc = URL.createObjectURL(thummbnailFile)

    return thumbnailSrc
  },

  /**
   * Returns a promise with the mime type for a file.
   * 
   * @param {object} the file.
   */
  getMimeType(file) {
    let mimeType;
    const fr = new FileReader();

    return new Promise(async (resolve) => {
      fr.onloadend = (e) => {
        const fileBase64Str = e.target.result.split(',').pop();
        const binary = atob(fileBase64Str);
        const array = [];
        
        for (let i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i));
        }

        const uintArr = new Uint8Array(array);
        const arr = uintArr.subarray(0, 4);
        let header = '';

        for (let i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }

        switch (header) {
          case '89504e47':
            mimeType = 'image/png';
            break;

          case '47494638':
            mimeType = 'image/gif';
            break;

          case 'ffd8ffe0':
          case 'ffd8ffe1':
          case 'ffd8ffe2':
          case 'ffd8ffe3':
          case 'ffd8ffe8':
            mimeType = 'image/jpeg';
            break;

          default:
            mimeType = file.type;
            break;
        }

        resolve(mimeType)

      }

      fr.readAsDataURL(file)
    })
  },

  /**
   * Returns the duration in ms of a video (ISO 8601)
   * 
   * @param {string} the video source.
   */
  async getVideoDuration(videoSrc) {
    if (!videoSrc) return;

    const video = document.createElement('video');
    video.preload = 'metadata';
    video.src = videoSrc;

    return await new Promise((resolve) => {
      video.onloadedmetadata = () => {
        const normalizedDuration = durationFns.normalize({
          seconds: Math.ceil(video.duration)
        });
        
        video.remove();

        resolve(durationFns.toString(normalizedDuration));
      }
    })
  },

  /**
   * Returns the media url for the input Blob or File object.
   * 
   * @param {array} an array of object urls for the media
   * @param {string} the destination of the media in the S3 Bucket or DO Space
   * @param {string} type of the media
   */
  async getMediaUrl(mediaSources, namespace) {
    if (!mediaSources.length || !mediaSources[0].mediaSrc) return [];

    const promises = await Promise.all(mediaSources.map(({mediaSrc}) => fetch(mediaSrc)));
    const blobs = await Promise.all(promises.map(r => r.blob()));

    mediaSources.forEach(({mediaSrc, keep}) => keep ? null : URL.revokeObjectURL(mediaSrc));

    return await Promise.all(blobs.map(async (blob, i) => {
      let { filename, type, s3Filename, contentExtension } = mediaSources[i];

      if (!filename || !type) throw 'File not accepted';

      // If we want to save a file without its content extension
      if (contentExtension === 'undefined')
        contentExtension = undefined
      else
        contentExtension = filename.split('.').pop();

      // Set a default s3Filename if none was passed
      if (!s3Filename) {
        // Generate a UUID for the filename
        const uniqueId = uuidv4();
        
        // Get the current date and time
        const currentDate = new Date();
        const timestamp = currentDate.toISOString().replace(/[:.]/g, '-');

        // Combine UUID and timestamp to create a unique filename
        s3Filename = `${uniqueId}_${timestamp}`;
      }

      /*
      * Setting contentExtension = 'undefined'
      * will make the system save a file without extension to AWS S3
      */
      const finalS3Filename = contentExtension ?
        `${s3Filename}.${contentExtension}` :
        `${s3Filename}`

      const fileData = {
        file: blob,
        namespace,
        filename: finalS3Filename,
        size: blob.size,
        contentExtension,
        contentType: type
      }

      await s3FileUrl(fileData)

      return `${namespace}/${finalS3Filename}`
    })) 
  },

  /**
   * Retrieve the transcription of a PDF file.
   * 
   * @param {Blob} pdf - The PDF file to transcribe.
   * @returns {Promise<Object>} - A promise that resolves to an object containing the PDF document and the transcription.
   * The object has the following properties:
   *   - pdfDoc: The PDF document object.
   *   - transcription: The transcription of the PDF file.
   */
  async getPdfTranscription(pdf) {
    const pdfUrl = URL.createObjectURL(pdf)
    const pdfBlob = await fetch(pdfUrl).then(r => r.blob())
    const pdfBuffer = await pdfBlob.arrayBuffer()

    const pdfDoc = await pdfjsLib.getDocument(pdfBuffer).promise
    const transcription = await getPdfTextContent(pdfDoc)
    return { pdfDoc, transcription }
  },

  /**
   * Create a thumbnail image of a specific page in a PDF document.
   * 
   * @param {File} pdfFile - The PDF file from which to create the thumbnail.
   * @param {PDFDocumentProxy} pdfDoc - The PDF document that contains the page.
   * @param {number} pageNum - The page number to create the thumbnail of.
   * 
   * @returns {Promise<Object>} - A promise that resolves to an object containing the thumbnail details.
   * The object has the following properties:
   *   - mediaSrc: The URL of the thumbnail image.
   *   - filename: The name of the thumbnail file.
   *   - type: The MIME type of the thumbnail image.
   */
  async createPDFThumbnail(pdfFile, pdfDoc, pageNum) {
    const page = await pdfDoc.getPage(Number(pageNum))
    const canvas = document.createElement('canvas')
    const viewport = page.getViewport({ scale: 1.0 })
    const context = canvas.getContext('2d')

    canvas.height = viewport.height
    canvas.width = viewport.width

    const renderContext = {
      canvasContext: context,
      viewport: viewport
    }

    await page.render(renderContext).promise

    const blobData = await canvasToBlob(canvas);
    const [pdfName] = pdfFile.name.split('.');
    const thumbnailFile = new File([blobData], `${pdfName}.jpeg`, {
      type: 'image/jpeg'
    });

    let thumbnailObj = {
      mediaSrc: URL.createObjectURL(thumbnailFile),
      filename: `${pdfName}.jpeg`,
      type: blobData.type
    }

    return thumbnailObj
  },

  async saveContent({
    pdfFile,
    user,
    sender,
    title,
    about,
    thumbnailUrl,
    pdfUrl,
    currentFolder,
    embeddingStatus,
    transcriptionLen,
    isAnonymous = false,
    isPublic = true,
    isUnlisted = false
  }) {
    try {
      // Creates the pdf record
      const pdf = await API().post(`pdf/${user.id}`, {
        url: pdfUrl,
        thumbnail: thumbnailUrl,
        sender,
        title,
        about,
        isAnonymous,
        isPublic,
        isUnlisted
      })

      console.log("Pdf record created", currentFolder)

      // Creates the content associated with the pdf / file
      const content = await API().post(`content/${this.user.id}`, {
        sender: pdf.sender.profile,
        name: pdf.title,
        thumbnail: pdf.thumbnail,
        caption: pdf.caption,
        about: pdf.about,
        type: 'pdf',
        // folder reference
        ...(currentFolder && currentFolder.id ? { folder: currentFolder.id } : {}),
        media: pdf._id,
        permissions: {
          [pdf.sender.profile]: 'owner'
        }
      })

      console.log("Content record created")

      // If there's no transcription, then there is no embedding
      if (transcriptionLen > 0) {
        /*
        * Submits the pdf to the AI server
        */
        let formData = new FormData();

        // Rename the form data file to pdf._id
        let renamedPdfFile = new File([pdfFile], `${pdf._id}.pdf`, {
          type: 'application/pdf',
        });

        // Assuming `this.pdfFile` is the PDF file to upload
        // Append renamed pdf file to formDatas
        formData.append('file', renamedPdfFile);

        const response = await axios.post(`${process.env.VUE_APP_LANGCHAIN_API}/upload_pdf`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          withCredentials: false
        })

        embeddingStatus = response.data === -1 ? 'MISSING' : 'CREATED'

        console.log("Embedding status set")

      } else {

        console.log("No embedding")

        embeddingStatus = 'UNDEFINED'
      }

      await API().put(`pdf/${user.id}`, {
        embeddingStatus
      }, {
        params: {
          id: pdf._id
        }
      })

      return content

    } catch (err) {
      this.$emit('err', 'errServer')
    }
  }
}
</script>