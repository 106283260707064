import API from '@api';

export default () => {
  const apiRequest = API()
  return {
    freeTrial () {
      return apiRequest.post(
        `free-trial-expired`,
      )
    }
  }
}
