export default (html) => {
  const replacements = [
    { search: /<h1>(.*?)<\/h1>/g, replace: '# $1\n' },
    { search: /<h2>(.*?)<\/h2>/g, replace: '## $1\n' },
    { search: /<h3>(.*?)<\/h3>/g, replace: '### $1\n' },
    { search: /<h4>(.*?)<\/h4>/g, replace: '#### $1\n' },
    { search: /<h5>(.*?)<\/h5>/g, replace: '##### $1\n' },
    { search: /<h6>(.*?)<\/h6>/g, replace: '###### $1\n' },
    { search: /<p>(.*?)<\/p>/g, replace: '$1\n\n' },
    { search: /<strong>(.*?)<\/strong>/g, replace: '**$1**' },
    { search: /<em>(.*?)<\/em>/g, replace: '_$1_' },
    { search: /<a href="([^"]*)">(.*?)<\/a>/g, replace: '[$2]($1)' },
    { search: /<br\s*\/?>/g, replace: '  \n' }, // Two spaces and a new line
    { search: /<blockquote>(.*?)<\/blockquote>/g, replace: '> $1\n' }
  ];

  let markdown = html;
  for (const replacement of replacements) {
    markdown = markdown.replace(replacement.search, replacement.replace);
  }

  return markdown.trim();
}
