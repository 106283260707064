<template>
  <div>
    <Toolbar
      :scrollTop="scrollTop"
      :invertScroll="true"  
    />
    
    <v-main>
      <Scrollbar
        :emitInstance="true"
        @osInstance="setOSInstance"
        @scrolling="setScrollTop"
        flexible="true"
      >
        <div class="fillHeight">
          <slot></slot>
        </div>

        <Footer></Footer>
      </Scrollbar>
    </v-main>
  </div>
</template>

<script>
import Toolbar from '@components/Open/web/Toolbar_Landing'
import Scrollbar from '@components/App/Scrollbar'
import Footer from '@components/Open/web/Footer'
import { mapGetters } from 'vuex'

export default {
  components: {
    Toolbar,
    Footer,
    Scrollbar
  },

  data() {
    return {
      osInstance: {},
      scrollTop: 0
    }
  },

  created() {
    if (this.user && this.$route.name != 'open.home') {
      // TODO: route back to main after it is finished
      this.$router.push({
        path: 'home',
        query: this.$route.query
      })
    }
  },

  computed: {
    ...mapGetters({
      user: 'user',
      landingScroll: 'landingScroll'
    })
  },

  watch: {
    landingScroll() {
      this.osInstance.scroll({ y : `${window.innerHeight}px` }, 500)
    }
  },

  methods: {
    setOSInstance(osInstance) {
      this.osInstance = osInstance
    },

    setScrollTop(scrollTop) {
      this.scrollTop = scrollTop
    }
  }
}
</script>

<style scoped>
.fillHeight {
  min-height: 100vh;
}
/*
  Sets the height of the image skeleton loader to 100% of the parent component.
*/
::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading .v-skeleton-loader__image {
  height: 100% !important;
}

/*
  Sets the height and width of the avatar skeleton loader to 100% of the parent component.
*/
::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading .v-skeleton-loader__avatar {
  height: 100% !important;
  width: 100% !important;
}

/*
  Stops autocomplete and combobox icons from rotating.
  There might be an option to set this within Vuetify 3.
*/
::v-deep .v-select.v-select--is-menu-active
  .v-input__icon--append
  .v-icon {
  transform: rotate(0) !important;
}
</style>