<template>
  <v-chip
    v-if="label"
    x-small
  >
    {{ label }}
  </v-chip>
</template>

<script>
  import { format, isWithinInterval, getDay, subDays, isToday, parseISO } from 'date-fns'
  export default {
    props: {
      messageDate: {
        type: String,
      },
      beforeMessageDate: {
        type: String,
      }
    },

    computed: {
      label() {
        const messageDate = parseISO(this.messageDate)
        const beforeMessageDate = parseISO(this.beforeMessageDate)

        if (getDay(messageDate) === getDay(beforeMessageDate)) return ''

        if (
          isToday(messageDate) &&
          !isToday(beforeMessageDate)
        ) return this.$t(`weekdays.today`)

        const sentLastWeek = isWithinInterval(messageDate, {
          start: subDays(new Date(), 7),
          end: new Date(),
        })

        if (sentLastWeek) {
          const todayName = format(messageDate, 'EEEE').toLocaleLowerCase()
          return this.$t(`weekdays.${todayName}`)
        }

        return format(messageDate, 'dd/MM/yyyy')
      }
    }
  }
</script>