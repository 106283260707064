<template>
  <v-card
    outlined
    rounded="lg"
  >
    <v-list-item>
      <v-list-item-avatar size="30">
        <v-btn
          icon
          x-large
          color="primary"
          :loading="!loaded"
          @click="playPause"
        >
          <v-icon x-large>
            {{ $data[playIcon] }}
          </v-icon>
        </v-btn>
      </v-list-item-avatar>
      
      <v-list-item-content>
        <div :id="`waveform_${WF_ID}`" />
      </v-list-item-content>

      <v-list-item-action v-if="isAuthor">
        <v-btn
          icon
          large
          color="primary"
          @click="clearAudio"
        >
          <v-icon>
            {{ mdiDelete }}
          </v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-card>
</template>

<script>
import WaveSurfer from 'wavesurfer.js'
import { v4 as uuidv4 } from 'uuid'

import {
  mdiDelete,
  mdiPauseCircle,
  mdiPlayCircle
} from '@mdi/js'

export default {
  props: {
    src: {
      type: String,
      required: true
    },
    isAuthor: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 30
    }
  },

  created() {
    this.WF_ID = uuidv4()
  },

  mounted () {
    const wavesurfer = WaveSurfer.create({
      container: `#waveform_${this.WF_ID}`,
      waveColor: '#B39DDB',
      progressColor: '#8E24AA',
      height: this.height
    })

    this.wavesurfer = wavesurfer
    this.wavesurfer.load(this.src)

    this.wavesurfer.on('ready', () => {
      this.$emit('audioDuration', this.wavesurfer.getDuration())
      this.loaded = true
     })  

    window.addEventListener('resize', () => {
        const currentProgress = this.wavesurfer.getCurrentTime() / this.wavesurfer.getDuration()

        this.wavesurfer.empty()
        this.wavesurfer.drawBuffer()

        this.wavesurfer.seekTo(currentProgress)

    }, false)
  },

  beforeDestroy () {
    this.wavesurfer.empty()
  },

  data () {
    return {
      mdiDelete,
      mdiPauseCircle,
      mdiPlayCircle,

      WF_ID: null,
      isPlaying: false,
      wavesurfer: {},
      loaded: false
    }
  },

  computed: {
    playIcon () {
      return this.isPlaying
        ? 'mdiPauseCircle'
        : 'mdiPlayCircle'
    }
  },

  methods: {
    play () {
      this.wavesurfer.play()
      this.isPlaying = true
    },

    pause () {
      this.wavesurfer.pause()
      this.isPlaying = false
    },

    playPause () {
      if (this.isPlaying) this.pause()
      else this.play()
    },

    clearAudio () {
      this.$emit('clear')
    }
  }
}
</script>