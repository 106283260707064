var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"app":"","dark":"","fixed":"","height":"60","elevation":"1","clipped-left":"","clipped-right":"","color":_vm.toolbarColor}},[_c('div',{staticClass:"d-flex",staticStyle:{"width":"100%","min-width":"715px","justify-content":"center"}},[_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.showEditProfile),callback:function ($$v) {_vm.showEditProfile=$$v},expression:"showEditProfile"}},[_c('v-card',[_c('v-container',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('EditProfile',{attrs:{"signup":false},on:{"newProfile":_vm.submit,"close":function($event){_vm.showEditProfile = false}}})],1)],1)],1)],1)],1),_c('v-container',{staticClass:"mx-0",staticStyle:{"max-width":"1750px"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"xs":12,"sm":6}},[_c('v-row',{staticClass:"d-flex",attrs:{"justify":"start","align":"center"}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm.tooltipsOn},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","large":"","color":"white"},on:{"click":_vm.LEFT_CARD_COLLAPSE}},on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.mdiMenu)+" ")])],1)]}}],null,false,4095976082)},[_c('span',[_vm._v("Menu")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.onHomeClick}},on),[_c('v-avatar',{attrs:{"size":"36"}},[_c('v-img',{attrs:{"src":require("@assets/images/hs-logo--white.png")}})],1)],1)]}}])},[_c('span',[_vm._v("Personas")])]),_c('SemanticSearch',{attrs:{"avatarOn":false,"lighterViewerOn":true,"setFocusOn":_vm.setFocusOn}})],1)],1),_c('v-col',{attrs:{"sm":6}},[_c('v-row',{attrs:{"align":"center","justify":"end"}},[_c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm.tooltipsOn},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","large":"","color":"amber accent-4","to":"/notebooks","disabled":_vm.isDisabled}},on),[_c('v-icon',{attrs:{"color":_vm.studyColor}},[_vm._v(" "+_vm._s(_vm.mdiLightbulb)+" ")])],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('studySession'))}})]),_c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm.tooltipsOn},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","large":"","color":"amber accent-4","to":"/rooms","disabled":_vm.isDisabled}},on),[(!_vm.roomsOn)?_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.mdiFireplaceOff)+" ")]):_c('v-icon',[_vm._v(" $fireplace ")])],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s(_vm.$tc('navigation.room', 2))}})]),_c('v-menu',_vm._g({attrs:{"offset-y":"","rounded":"lg"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","text":"","large":"","disabled":_vm.isDisabled}},on),[_c('v-badge',{attrs:{"overlap":"","color":"red","offset-x":"15","value":Boolean(_vm.notificationsLen)}},[_c('span',{attrs:{"slot":"badge"},slot:"badge"},[_vm._v(" "+_vm._s(_vm.notificationsLen)+" ")]),_c('v-icon',[_vm._v(" "+_vm._s(_vm.$data[_vm.bellIcon])+" ")])],1)],1)]}}]),model:{value:(_vm.bellOn),callback:function ($$v) {_vm.bellOn=$$v},expression:"bellOn"}},_vm.on),[_c('Notifications')],1),_c('v-menu',{attrs:{"offset-y":"","rounded":"lg"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","text":""}},on),[_c('v-avatar',{attrs:{"size":_vm.avatarSize}},[(_vm.avatar)?_c('v-img',{attrs:{"src":_vm.getResourceUrl(_vm.avatar)},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"height":"40","width":"40","type":"image"}})]},proxy:true}],null,true)}):_c('v-btn',{attrs:{"icon":"","color":"white"}},[_c('v-icon',{attrs:{"dark":"","size":"30"}},[_vm._v(" "+_vm._s(_vm.mdiAccount)+" ")])],1)],1)],1)]}}])},[_c('v-list',{staticClass:"pa-0"},_vm._l((_vm.menuItems),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":item.to},on:{"click":item.function}},[(item.icon)?_c('v-list-item-action',[_c('v-icon',[_vm._v(" "+_vm._s(_vm.$data[item.icon])+" ")])],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t(("toolbar." + (item.title))))+" ")])],1)],1)}),1)],1),(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm.tooltipsOn},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","large":"","color":"white"},on:{"click":_vm.RIGHT_CARD_COLLAPSE}},on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.mdiMenu)+" ")])],1)]}}],null,false,345359337)},[_c('span',[_vm._v("Menu")])]):_vm._e()],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }