import { render, staticRenderFns } from "./CustomOverlay.vue?vue&type=template&id=7b17795a&scoped=true&"
import script from "./CustomOverlay.vue?vue&type=script&lang=js&"
export * from "./CustomOverlay.vue?vue&type=script&lang=js&"
import style0 from "./CustomOverlay.vue?vue&type=style&index=0&id=7b17795a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b17795a",
  null
  
)

export default component.exports