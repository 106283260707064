<template>
  <div>
    <div class="d-flex" style="align-items: center;">
      <v-btn
        icon
        @click="onBack"
      >
        <v-icon small>
          {{ mdiArrowLeft }}
        </v-icon>
      </v-btn>
      <v-list-item style="max-width: 80%;">
        <v-list-item-avatar size="30">
          <v-img :src="getResourceUrl(room.thumbnail)">
            <template v-slot:placeholder>
              <v-skeleton-loader
                class="mx-auto"
                height="40"
                width="40"
                type="avatar"
              />
            </template>
          </v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="room.name" />
        </v-list-item-content>
      </v-list-item>
    </div>
    <div class="d-flex my-3" style="align-items: center;">
      <v-btn
        small
        elevation="0"
        :class="{ 'grey': true, 'darken-3': $vuetify.theme.dark, 'lighten-3': !$vuetify.theme.dark }"
        style="border-radius: 8px !important;"
        :disabled="false"
        @click="addChat"
      >
        new chat
      </v-btn>
      <v-divider :class="`mb-1 mt-2 mx-4 mr-0`"/>
    </div>
    <ChatCard
      v-if="!!conversationToCreate"
      :cardColor="getCardColor()"
      :conversation="conversationToCreate"
      :onlyCreate="true"
      @addConversation="addConversation"
    />
    <ScrollPagination
      v-if="currentRoom"
      :requestData="{
        'url': `conversations/${this.user.id}`,
        'params': {
          query: {
            room: {
              $eq: currentRoom._id
            },
            state: 'ACTIVE'
          },
          sort: {
            createdAt: -1
          }
        }
      }"
      :countData="{
        'url': `conversations/${this.user.id}/count`,
      }"
      :rootHeight="`37vh`"
      :key="listKey"
    >
      <template v-slot:child="props">
        <ChatCard
          :key="`conversation_${props.index}`"
          :active="props.item.id === $route.query.id"
          :cardColor="getCardColor(props.item._id)"
          :conversation="props.item"
          @addConversation="addConversation"
          @remove="props.items.splice(props.index, 1)"
        />
      </template>
    </ScrollPagination>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex'
  import { mdiArrowLeft } from '@mdi/js'
  import ScrollPagination from '@components/App/ScrollPagination.vue'
  import ChatCard from '@components/Conversation/Card.vue'
  import ConversationService from '@api/conversation'
  import { getResourceUrl } from '@utils'

  export default {
    components: {
      ScrollPagination,
      ChatCard
    },

    props: {
      onBack: Function,
      room: Object
    },

    data() {
      return {
        conversationService: null,
        contentToShow: 'groups',
        mdiArrowLeft,
        conversationToCreate: null,
        loading: false,
        listKey: 0,
      }
    },

    async created() {
      this.conversationService = ConversationService(this.user.id)
    },

    computed: {
      ...mapGetters({
        user: 'user',
        myProfileId: 'profile/id',
        currentRoom: 'rooms/currentRoom'
      }),
    },

    methods: {
      getResourceUrl,
      ...mapMutations({
        SET_CURRENT_ROOM: 'rooms/setCurrentRoom',
      }),
      initialConversationToCreate() {
        return { name: '' }  
      },
      setCurrentRoom(room) {
        this.SET_CURRENT_ROOM(room)
      },
      showGroups() {
        this.contentToShow = 'groups'
      },
      showConversations() {
        this.contentToShow = 'conversations'
      },
      setConversations(conversations) {
        this.conversations = conversations
      },
      addChat() {
        if (!this.conversationToCreate)
          return this.conversationToCreate = this.initialConversationToCreate()
        this.conversationToCreate = null
      },
      async addConversation(chatName) {
        this.loading = true
        await this.conversationService.create(this.currentRoom.id, chatName)
        this.conversationToCreate = null
        this.forceRerender()
        this.loading = false
      },
      getCardColor(id) {
        if (this.$vuetify.theme.dark) return '#333333';
        if (id === this.$route.query.id) return 'grey lighten-4';
        return 'grey lighten-3';
      },
      forceRerender() {
        this.listKey += 1
      }
    }
  }
</script>