<template>
  <v-dialog
    v-model="imgDialog"
    :max-width="maxWidth"
    @click:outside="onClickOutside"
  >
    <v-card rounded="xl">
      <v-container class="py-0">
        <v-row justify="center">
          <v-col :cols="$vuetify.breakpoint.lgAndDown && imgHeight > imgWidth ? 7 : 8">
            <v-card
              outlined
              rounded="xl"
            >
              <v-img
                :src="imgSrc"
                :max-height="maxHeight"
              />
            </v-card>
          </v-col>

          <v-col :cols="$vuetify.breakpoint.lgAndDown && imgHeight > imgWidth ? 5 : 4">
            <Scrollbar
              style="height: 100%"
              :thin="true"
            >
              <v-sheet
                :color="sheetColor"
                height="100%"
                width="100%"
              >
                <v-card-title>
                  Enviar Imagem
                </v-card-title>

                <v-card-text class="px-1">
                  <v-textarea
                    v-model="caption"
                    placeholder="Legenda"
                    :background-color="sheetColor"
                    :elevation="0"
                    :counter="2200"
                    :rows="imgHeight > imgWidth ? 5 : 3"
                    flat
                    solo
                  />
                </v-card-text>

                <v-divider />

                <v-list nav>
                  <v-list-group
                    :value="privsOn"
                    :ripple="false"
                    :append-icon="mdiChevronDown"
                  >
                    <template v-slot:activator>
                      <v-list-item-title>Privacy</v-list-item-title>
                    </template>

                    <v-container>
                      <PrivacySettings
                        :textSize="6"
                        @settings="setPrivacySettings"
                      />
                    </v-container>
                  </v-list-group>

                  <v-list-group
                    :value="contentOn"
                    :ripple="false"
                    :append-icon="mdiChevronDown"
                  >
                    <template v-slot:activator>
                      <v-list-item-title>Content settings</v-list-item-title>
                    </template>

                    <v-container>
                      <ContentSettings
                        :textSize="6"
                        @settings="setContentSettings"
                      />
                    </v-container>
                  </v-list-group>
                </v-list>

                <v-card-actions>
                  <v-btn
                    text
                    color="primary"
                    :loading="loading"
                    @click="submit"
                  >
                    save
                  </v-btn>
                </v-card-actions>
              </v-sheet>
            </Scrollbar>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { Scrollbar } from '@components/App'
import { TextBox } from '@components/Document'
import { PrivacySettings, ContentSettings, MediaURL } from '@components'
import { mdiChevronDown, mdiChevronUp } from '@mdi/js'
import API from '@api'

export default {
  components: {
    Scrollbar,
    TextBox,
    PrivacySettings,
    ContentSettings
  },

  props: {
    imgDialog: {
      type: Boolean,
      default: false
    },
    imgFile: {
      type: Object,
      default: {}
    }
  },

  created() {
    this.getMimeType = MediaURL.getMimeType.bind(this);
    this.getMediaUrl = MediaURL.getMediaUrl.bind(this);
  },

  data() {
    return {
      mdiChevronDown,
      mdiChevronUp,

      imgSrc: '',
      caption: '',
      imgHeight: 0,
      imgWidth: 0,
      privsOn: false,
      loading: false
    }
  },

  computed: {
    ...mapGetters({
      user: 'user',
      profile: 'profile/id',
      name: 'profile/name',
      avatar: 'profile/avatar',
      bio: 'profile/bio'
    }),

    sender() {
      return {
        profile: this.profile,
        name: this.name,
        avatar: this.avatar,
        bio: this.bio
      }
    },

    maxWidth() {
      return this.$vuetify.breakpoint.xl ? 1100 : this.imgHeight < this.imgWidth ? 900 : 700 
    },
    maxHeight() {
      return this.$vuetify.breakpoint.xl ? 700 : 500 
    },
    sheetColor() {
      return this.$vuetify.theme.dark
        ? 'grey darken-4'
        : 'white'
    },
  },

  watch: {
    imgFile: {
      handler (img) {
        const imgSrc = URL.createObjectURL(img)
        let image = new Image();

        image.onload = () => {
          this.imgHeight = image.height
          this.imgWidth = image.width

          this.imgSrc = imgSrc
        }

        image.src = imgSrc
      }, 
      deep: true
    }
  },

  methods: {
    async submit() {

      this.loading = true

      const fileObj = {
        mediaSrc: this.imgSrc,
        filename: this.imgFile.name,
        type: await this.getMimeType(this.imgFile)
      }

      const [imgUrl] = await this.getMediaUrl([fileObj], 'images')

      const imgObj = await API().post(`image/${this.user.id}`, {
        sender: this.sender,
        url: imgUrl,
        title: fileObj.filename,
        filename: fileObj.filename,
        caption: this.caption
      })

      if (this.$route.name === 'bundle.home') {

        const bundleId = this.$route.query.id

        await API().post(`bundleContent/${this.user.id}`, {
          audio: imgObj._id,
          bundle: bundleId,
          sender: this.sender,
          name: fileObj.filename,
          caption: this.caption,
          url: imgUrl,
          type: 'image'
        })

      }

      this.loading = false

    },

    onClickOutside() {
      if (this.imgFile instanceof File) {
        this.$emit('close')
      }
    }
  }
}
</script>

<style scoped>
/* Deprecated in Vue 3 */
::v-deep .v-dialog {
  border-radius: 24px !important;
}
</style>