<template>
  <v-card
    :outlined="showPanel"
    elevation="0"
    :color="navColor"
    rounded="xl"
    :style="`
      height: 100%;
      position: relative;
      border: ${borderStyle};
    `"
  >
    <v-container
      v-if="showPanel"
      style="height: 100%; width: 100%;"
    >
      <div class="hs-col">
        <PersonaCard
          v-if="!loading"
          :create="create"
          :name="persona.name"
          :about="persona.about"
          :caption="persona.caption"
          :avatar="persona.avatar"
          :currentPersona="currentPersona"
        />
      </div>

      <v-divider class="my-2" />

      <PillTabs
        style="max-width: 100%;"
        class="mx-2"
        :tabs="[{
          name: 'Persona',
          counter: 0
        }, {
          name: 'sources',
          counter: 0
        }]"
        :startIndex="pillTab"
        :showSearch="false"
        @setActive="SET_PILL_TAB"
      />

      <div class="my-2"></div>
      
      <v-fade-transition hide-on-leave>
        <div
          v-if="pillTab === 0"
          style="height: 100%;"
        >
          <Persona
            v-if="!library"
            :persona="persona"
            @edit="edit = true"
            @browse="library = true"
          />
          <PersonaLibrary
            v-else
            @back="library = false"
            @setPersona="setPersona"
          />
        </div>
      </v-fade-transition>
      
      <v-fade-transition hide-on-leave>
        <div
          v-if="pillTab === 1"
          style="height: 100%;"
        >
          <Scrollbar
            :thin="true"
            :flexible="true"
            :style="`height: ${rootHeight}px;`"
          >
            <v-container class="py-0">
              <div class="my-2"></div>
              
              <SearchSources />

              <div class="my-4"></div>

              <v-fade-transition hide-on-leave>
                <div v-if="contents.length === 0">
                  <v-container :style="`height: ${
                      $vuetify.breakpoint.xl ?
                      annaChamber ? 92 : 88  : annaChamber ? 90 : 85
                    }%;`"
                  >
                    <v-row justify="center">
                      <v-img
                        v-if="locale === 'en'"
                        src="@assets/images/pdf_upload_overlay_en.png"
                        :style="`opacity: 0.7; height: auto; max-width: 80%;`"
                      />
                      <v-img
                        v-else
                        src="@assets/images/pdf_upload_overlay_pt-br.png"
                        :style="`opacity: 0.7; height: auto; max-width: 80%;`"
                      />
                    </v-row>
                  </v-container>
                </div>
              </v-fade-transition>
              
              <Folder
                v-if="contents.length > 0"
                :contentArray="contents"
              />
            </v-container>
          </Scrollbar>

          <v-row justify="center">
            <v-container class="bottom-menu">
              <MediaUploader />
            </v-container>
          </v-row>
        </div>
      </v-fade-transition>
    </v-container>
    
    <!-- Add a button or icon to toggle the card's collapsed state -->
    <v-btn
      icon
      :class="{
        'chevron-btn': !rightCardCollapse,
        'chevron-left': rightCardCollapse
      }"
      @click="toggleCard"
    >
      <div class="chevron-bar"></div>
    </v-btn>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { Scrollbar } from '@components/App'
import { MediaUploader } from '@components/Media'
import Persona from './Persona'
import PersonaLibrary from './PersonaLibrary'
import PersonaCard from './PersonaCard'
import Folder from './Folder'
import PillTabs from '@components/PillTabs.vue'
import SearchSources from './SearchSources'
import API from '@api'
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js'
import ObjectID from 'bson-objectid'

export default {
  components: {
    PillTabs,
    Scrollbar,
    MediaUploader,
    Persona,
    PersonaLibrary,
    PersonaCard,
    Folder,
    SearchSources
  },

  props: {
    room: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  async created() {
    if (!this.user) {
      this.persona = this.template;
      return;
    }

    const myFolders = await API().get(`folders/${this.user.id}`, {
      params: {
        query: {
          sender: {
            $eq: this.myProfileId
          },
          folder: {
            $exists: false
          }
        },
        sort: {
          createdAt: -1
        }
      }
    })

    myFolders.unshift({
      id: null,
      name: 'My Library'
    })

    this.SET_MY_FOLDERS(myFolders)
    
    // Sets either folder or standalone contents
    let contents;
    if (this.currentFolder) {
      contents = await API().get(`contents/${this.user.id}`, {
        params: {
          query: {
            folder: {
              $in: this.currentFolder.id
            }
          },
          sort: {
            createdAt: -1
          }
        }
      })

      const [folderConnector] = await API().get('connectors', {
        params: {
          query: {
            type: {
              $eq: 'folder_sync'
            },
            state: {
              $eq: 'ACTIVE'
            },
            entities: {
              $elemMatch: {
                ref: 'Folder',
                _id: ObjectID(this.currentFolder.id)
              }
            }
          }
        }
      });

      if (folderConnector) {
        // If the persona is loaded already, then we retrieve and set its folder
        const sourceFolderId = folderConnector.entities[0]._id
        const sourceFolderContents = await API().get(`contents/${this.user.id}`, {
          params: {
            query: {
              folder: {
                $in: sourceFolderId
              }
            },
            sort: {
              createdAt: -1
            }
          }
        })
        contents = contents.concat(sourceFolderContents)
      }
      
    } else {
      contents = await API().get(`contents/${this.user.id}`, {
        params: {
          query: {
            sender: {
              $eq: this.myProfileId
            },
            folder: { $exists: false }
          },
          sort: {
            createdAt: -1
          }
        }
      })
    }
    
    this.SET_CONTENTS(contents)





    // if (this.currentFolder) {
    //     const [folderConnector] = await API().get('connectors', {
    //       params: {
    //         query: {
    //           type: {
    //             $eq: 'folder_sync'
    //           },
    //           state: {
    //             $eq: 'ACTIVE'
    //           },
    //           entities: {
    //             $elemMatch: {
    //               ref: 'Folder',
    //               _id: ObjectID(this.currentFolder.id)
    //             }
    //           }
    //         }
    //       }
    //     });

    //     if (folderConnector) {
    //       // If the persona is loaded already, then we retrieve and set its folder
    //       const sourceFolderId = folderConnector.entities[0]._id
    //       const sourceFolderContents = await API().get(`contents/${this.user.id}`, {
    //         params: {
    //           query: {
    //             folder: {
    //               $in: sourceFolderId
    //             }
    //           },
    //           sort: {
    //             createdAt: -1
    //           }
    //         }
    //       })
    //       return this.SET_CONTENTS(contents.concat(sourceFolderContents))
    //     }
    //   }






    if (!this.currentPersona.id) this.persona = this.template
    else this.persona = this.currentPersona
  },


  data: () => ({
    loading: false,
    fab: 0,
    template: {
      name: 'Anna (Vanilla)',
      avatar: 'https://image.lexica.art/full_jpg/dad3835d-67ba-4146-9b46-487f71557db1',
      caption: 'Your personal AI',
      about: 'Vanilla Anna for you!'
    },
    persona: {
      name: '',
      avatar: '',
      caption: '',
      about: ''
    },
    edit: false,
    create: false,
    library: false,
    mdiChevronLeft,
    mdiChevronRight,
    contentRequestData: {},
    contentCountData: {},
  }),

  watch: {
    contents: {
      deep: true,
      async handler(contents) {

      }
    },
    currentPersona: {
      deep: true,
      handler(persona) {
        if (persona && persona.id) this.persona = persona
        else this.persona = this.template
      }      
    },
  },

  computed: {
    ...mapGetters({
      innerHeight: 'innerHeight',
      user: 'user',
      myProfileId: 'profile/id',
      context: 'anna-mind/context',
      annaChamber: 'annaChamber',
      locale: 'locale',
      currentFolder: 'folders/currentFolder',
      contents: 'folders/contents',
      currentPersona: 'currentPersona',
      rightCardCollapse: 'rightCardCollapse',
      pillTab: 'pillTab'
    }),

    showPanel() {
      return !this.rightCardCollapse && this.$vuetify.breakpoint.lgAndUp ||
        this.rightCardCollapse && this.$vuetify.breakpoint.mdAndDown
    },

    rootHeight() {
      return this.innerHeight * (1 - (100 / this.innerHeight)) - 200
    },

    navColor() {
      return !this.$vuetify.theme.dark
        ? 'grey lighten-5'
        : '#1a1a1a'
    },

    borderStyle() {
      return this.$vuetify.theme.dark
        ? 'transparent !important;'
        : 'thin solid rgba(0, 0, 0, 0.12) !important;'
    },
  },

  methods: {
    async loadFolders() {
      const myFolders = await API().get(`folders/${this.user.id}`, {
        params: {
          query: {
            sender: {
              $eq: this.myProfileId
            },
            folder: {
              $exists: false
            }
          },
          sort: {
            createdAt: -1
          }
        }
      })

      myFolders.unshift({
        id: null,
        name: 'My Library'
      })

      this.SET_MY_FOLDERS(myFolders)
    },
    ...mapActions({
      SET_PERSONA: 'SET_PERSONA',
    }),

    ...mapMutations({
      SET_MY_FOLDERS: 'folders/setMyFolders',
      SET_CONTENTS: 'folders/setContents',
      RESET_CONTEXT: 'anna-mind/resetContext',
      RIGHT_CARD_COLLAPSE: 'RIGHT_CARD_COLLAPSE',
      SET_PILL_TAB: 'SET_PILL_TAB'
    }),
    setContents(contents) {
      this.contents = contents
      this.SET_CONTENTS(contents)
    },
    setPersona(persona) {
      this.library = false
      this.persona = persona
      this.SET_PERSONA(persona)
    },

    toggleCard() {
      this.RIGHT_CARD_COLLAPSE()
    }
  }
}
</script>

<style scoped>
.chevron-btn, .chevron-left {
  position: absolute;
  top: 50%;
  left: -2%;
  transform: translateY(-50%);
  display: inline-block; /* To prevent full-width bar */
  cursor: pointer;
}

.chevron-bar {
  width: 4px;
  height: 20px;
  
  border-radius: 20px; /* Full rounded corners */
  transition: all 0.5s ease;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.theme--light .chevron-bar {
  background-color: #cacaca;

}

.theme--dark .chevron-bar {
  background-color: #595959d1;
}

.chevron-bar::before,
.chevron-bar::after {
  content: '';
  position: absolute;
  top: 50%; /* Align to the middle */
  left: 0;
  width: 100%;
  height: 4px; /* Same as bar width for uniform look */
  background-color: #adadad;
  transition: inherit;
  transform-origin: left;
  border-radius: 2px; /* Half of the width for rounded corners */
  transform: translateY(-50%) scaleY(0); /* Initial state not visible */
}

.chevron-btn:hover .chevron-bar, 
.chevron-left .chevron-bar {
  background-color: transparent;
}

.chevron-btn:hover .chevron-bar::before {
  transform: translateY(-15%) translateX(50%) rotateZ(50deg) scaleY(3); /* Rotate for top part of chevron */
}

.chevron-btn:hover .chevron-bar::after {
  transform: translateY(-110%) translateX(50%) rotateZ(-50deg) scaleY(3); /* Rotate for bottom part of chevron */
}

.chevron-left .chevron-bar::before  {
  transform: translateY(85%) translateX(-50%) rotateZ(-50deg) scaleY(3); /* Rotate for top part of chevron */
}

.chevron-left .chevron-bar::after {
  transform: translateY(-175%) translateX(-50%) rotateZ(50deg) scaleY(3); /* Rotate for bottom part of chevron */
}
</style>