// this was supposed to be used by onPasteCustom of the editorjs custom Paragraph classs
export default function containsCode(text) {
  // More specific regular expressions for code patterns
  const patterns = [
    // Matches common programming keywords and proper syntax
    /\b(function|var|let|const|if|else|for|while|do|switch|case|break|continue|return|class|try|catch|finally|throw|new)\b/,
    // Matches common function calls or expressions
    /\b(console\.log|alert|document\.getElementById|addEventListener|setTimeout|clearTimeout|setInterval|clearInterval)$/,
    // Matches string literals with possible code inside
    /(['"`])(?:\\.|(?!\1)[^\\\r\n])*\1/,
    // Matches common operators
    /[=+\-*/%<>!&|~^]/,
    // Matches code-like structures
    /[{[\]();},]/,
    // Matches a typical function declaration or arrow function
    /function\s+[a-zA-Z_$][0-9a-zA-Z_$]*\s*\(|\([^$]*\)\s*=>/,
    // Matches variable assignment or object property
    /\b[a-zA-Z_$][0-9a-zA-Z_$]*\s*[:=]\s*/,
  ];

  // Check if the text contains multiple code patterns
  const codeLikeFeatures = patterns.reduce((count, pattern) => {
    return pattern.test(text) ? count + 1 : count;
  }, 0);

  // Consider it code if several patterns are found
  return codeLikeFeatures >= 4;
}