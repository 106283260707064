<script>
import { MediaURL } from '@components'
import API from '@api'

export default {
  async oauthSignin({ email, auth0Token }) {
    this.SET_AUTH0_TOKEN(auth0Token)

    setTimeout(async () => {
      try {
        const { user, profile, pendingOnBoarding, authorizationToken } = await API().post('user/oauth-login', { email })
        await this.setSession({ user, profile, pendingOnBoarding, auth0Token, authorizationToken })
        const [anna] = await API().get(`profiles/${user.id}`, {
          params: {
            query: {
                'username': {
                  $eq: 'anna'
                }
              }
          }
        })
        this.SET_ANNA(anna)

        if (profile.settings) {
          if (this.$vuetify.theme.dark != profile.settings.darkThemeOn)
            this.$vuetify.theme.dark = profile.settings.darkThemeOn

          if (profile.settings.locale && profile.settings.locale != this.locale)
            this.setLocale(profile.settings.locale)
        }

        this.$router.push('/home')
      } catch(err) {
        this.$auth.logout({
          logoutParams: {
            returnTo: `${process.env.VUE_APP_AUTH0_CALLBACK_URL}/signup`,
          }
        })
      }
    })
  },
  
  /*
  * Creates an account
  * The parent component must have
  *   loading: Boolean
  *   snackMsg: String
  *   snackOn: Boolean
  *   form: Vue Reference
  *   email: String
  *   password: String
  *   setSession: Vuex Action
  *   SET_ANNA: Vuex Mutation
  *   setUser: Vuex Mutation
  *   
  *   $socket and $auth are globally installed
  */
  async submitAccount ({
    noRedirect,
    noSession,
    // oauth id
    userId,
    name,
    email,
    username,
    avatarObj,
    birthDate,
    auth0Token
  }) {
    this.loading = true

    if (!navigator.cookieEnabled) {
      this.snackMsg = 'noCookies'
      this.snackOn = true

    } else if (auth0Token || noSession || this.$refs.form.validate()) {
      try {
        const {
          user,
          profile,
          pendingOnBoarding,
          authorizationToken
        } = await API().post('/user', {
          name,
          birthDate,
          fullName: name,
          username: username,
          email: email ? email : this.email,
          password: this.password,
          valid: {
            email: auth0Token ? true : false
          },
          oauth: auth0Token ? {
            isOnly: true,
            email: email,
            userId
          } : undefined
        })
        
        let avatarSrc;
        if (avatarObj && avatarObj.mediaSrc) {
          [avatarSrc] = await MediaURL.getMediaUrl([{
            ...avatarObj,
            keep: true,
            s3Filename: profile.id,
            filename: `${profile.id}.jpg`,
            contentExtension: 'undefined'
          }], 'images/avatars')
        }

        await this.setSession({
          newUser: true,
          user,
          profile: {
            ...profile,
            avatar: avatarSrc
          },
          pendingOnBoarding,
          authorizationToken,
          auth0Token
        })

        if (avatarSrc) {
          await API().put(`profile/${user.id}`, {
            avatar: avatarSrc,
            avatarCount: 1
          }, {
            params: {
              id: profile.id
            }
          })

          // sets the avatar
          this.setAvatar(avatarSrc)

          /*
          * The avatars are versioned, allowing the user to reactivate
          * an avatar that was previously active. 
          */
          const [vAvatar] = await MediaURL.getMediaUrl([{
            ...avatarObj,
            keep: false,
            s3Filename: `${profile.id}-v1`,
            filename: `${profile.id}.jpg`
          }], 'images/avatars')

          await API().post(`image/${user.id}`, {
            sender: profile.id,
            contentType: avatarObj.type,
            url: vAvatar,
            featureType: 'avatar'
          }, {
            params: {
              id: profile.id
            }
          })
        }

        // TODO: REVIEW
        const [anna] = await API().get(`profiles/${user.id}`, {
          params: {
            query: {
              'username': {
                $eq: 'anna'
              }
            }
          }
        })
        this.SET_ANNA(anna)
        this.$socket.onlineSession.connect()

        if (noRedirect) return true; // (Mobile) Landing OAuth: /home -> /welcome
        else if (noSession) { // (Mobile) Landing Signup: /home
          await this.setUser(user)
          this.$router.push('/welcome')

        } else { // (Web) Signup: /signup
          if (this.$route.fullPath.includes('/home')) window.location.reload();
          else this.$router.push('/home')
        }
      } catch(err) {
        setTimeout(() => {
          this.$auth.logout()
        })

        if (err.response.data.err.name === 'ValidationError') {
          this.snackMsg = 'emailDuplicate'
        } else {
          this.snackMsg = err.response.data.errMsg
        }
        this.snackOn = true

      } finally {
        this.loading = false
      }
    }
  }
}
</script>