import API from '@api';

export default (userId) => {
  const apiRequest = API()
  return {
    getById (id) {
      return apiRequest.get(
        `persona/${userId}`,
        { params: { id } }
      )
    },
    getByUsername (username) {
      return apiRequest.get(
        `persona-by-username`,
        { params: { username } }
      )
    },
    isUsernameAvailable (username) {
      return apiRequest.get(
        `persona-username-available`,
        { params: { username } }
      )
    }
  }
}
