<template>
  <div class="px-2">
    <!--
      see https://github.com/SortableJS/Vue.Draggable/issues/743
      and https://github.com/SortableJS/Sortable/issues/1571
      we use force-fallback="true" to solve safari losing drag event 
     -->
    <draggable
      tag="div"
      :list="contentArray"
      :force-fallback="true"
    >
      <ContentCard
        v-for="(content, idx) in contentArray"
        :key="`content_${idx}`"
        class="mb-4"
        :idx="idx"
        :content="content"
        :allowEdit="false"
        type="pdf"
        :activeContentIdx="activeContentIdx"
        @setActiveContent="setActiveContent"
        @remove="contentArray.splice(idx, 1)"
      />
    </draggable>
  </div>
</template>

<script>
import ContentCard from '@components/Content/Card'
import { mdiCheckboxBlankOutline } from '@mdi/js';
import draggable from 'vuedraggable';

export default {
  components: {
    ContentCard,
    draggable
  },

  props: {
    contentArray: {
      type: Array,
      default() {
        return []
      }
    }
  },

  data: () => ({
    mdiCheckboxBlankOutline,
    activeContentIdx: 0,
  }),

  methods: {
    updateEl() {
      this.contentArray.forEach((content, idx) => {
        const [titleEl] = this.$refs[`title_${idx}`]
        const [captionEl] = this.$refs[`caption_${idx}`]
        titleEl.innerHTML = content.title
        captionEl.innerHTML = content.caption
      })
    },
    
    setActiveContent(idx) {
      this.activeContentIdx = idx
      const activeContent = this.contentArray[idx]

      if (activeContent.type === 'pdf')
        this.$router.push(`/pdf/read?id=${activeContent._id}`)
    }
  }
}
</script>

<style>
.limited-width-text {
  white-space: nowrap;      /* Prevents the text from wrapping to the next line */
  overflow: hidden;         /* Hides overflowed content */
  text-overflow: ellipsis;  /* Adds an ellipsis to overflowed content */
  max-width: 40%;           /* Limits the width to 50% of the parent container */
  display: inline-block;    /* Allows width restrictions */
}
</style>