<template>
  <div>
    <v-card
      outlined
      elevation="0"
      rounded="xl"
      clickable
      @click="$emit('callAnna')"
    >
      <AnnaCard
        v-if="notes.length > 0"
        :index="0"
        :note="notes[0]"
        :noteId="notes[0].id"
        
      />
    </v-card>

    <div class="my-4"></div>

    <v-card elevation="0" style="border-radius: 18px;">
      <v-container class="hs-rounded-tabs">
        <v-row class="hs-rounded-tabs px-3">
          <v-btn
            class="tab-btn text-body-2 font-weight-medium hs-rounded-12"
            elevation="0"
            text
            :class="{
              'v-tab--active': activeTab === 0,
              'primary--text': activeTab === 0,
              'grey--text': activeTab != 0,
              'text--lighten-1': $vuetify.theme.dark && activeTab != 0
            }"
            @click="setTab(0)"
            
          >
            <span >Notes</span>
            <v-icon 
            v-if="$vuetify.breakpoint.lgAndUp"
              :right="$vuetify.breakpoint.lgAndUp"
              dense
            >
              {{ mdiText }}
            </v-icon>
          </v-btn>
          <v-btn
            class="tab-btn text-body-2 font-weight-medium hs-rounded-12"
            elevation="0"
            text
            :class="{
              'v-tab--active': activeTab === 1,
              'primary--text': activeTab === 1,
              'grey--text': activeTab != 1,
              'text--lighten-1': $vuetify.theme.dark && activeTab != 1
            }"
            @click="setTab(1)"
   
          >
            <span >Folders</span>
            <v-icon
              v-if="$vuetify.breakpoint.lgAndUp"
              :right="$vuetify.breakpoint.lgAndUp"
              dense
            >
              {{ mdiFolder }}
            </v-icon>
          </v-btn>
        </v-row>
      </v-container>
    </v-card>

    <div class="my-4"></div>

    <Scrollbar
      :thin="true"
      autoHide="leave"
      :style="`height: ${innerHeight * 0.9 * (1 - ((isSubscriptionActive ? 250 : 300) / innerHeight))}px`"
    >
      <div class="px-4">
        <span class="text-h6">Recent Personas</span>
        <div
          v-for="(persona, i) in personas"
          :key="`persona_${i}`"
          @click="$router.push(`/personas?id=${persona._id}`)"
        >
          <PersonaCard
            :key="`persona_${i}`"
            :name="persona.name"
            :avatar="persona.avatar"
            :caption="persona.caption"
            @persona="$emit('setPersona', persona)"
          />
        </div>
      </div>
    </Scrollbar>
    <div
      v-if="!isSubscriptionActive"
      class="my-4"
      style="text-align: center"
    >
      <v-btn
        small
        rounded
        depressed
        color="primary"
        @click="getSubscriptionPaymentLink"
        :loading="loadingPaymentLink"
      >
        {{ $t('subscriptions.try') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { Scrollbar } from '@components/App'
import AnnaCard from './AnnaCard'
import { mapGetters } from 'vuex'
import { mdiText, mdiFolder } from '@mdi/js'
import StripeService from '@api/stripe'

import PersonaCard from '@components/Persona/PersonaCard.vue'
import API from '@api'

export default {
  components: {
    Scrollbar,
    PersonaCard,
    AnnaCard
  },

  props: {
    notes: {
      type: Array,
      default() {
        return []
      }
    }
  },

  async created() {
    this.stripeService = StripeService(this.user.id)
    this.personas = await API().get(`personas/recent`, {
      params: {
        profileId: this.profileId
      }
    })

    if (this.$route.name.indexOf('notebook') != -1) {
      this.activeTab = 0
    } else if (this.$route.name.indexOf('folder') != -1) {
      this.activeTab = 1
    } else this.activeTab = null
  },

  data: () => ({
    mdiText,
    mdiFolder,
    activeTab: null,
    writing: false,
    personas: [],
    stripeService: null,
    loadingPaymentLink: false
  }),

  watch: {
    activeTab(value) {
      switch(value) {
        case 0:
          this.$router.replace('/notebooks')
          break;
        case 1:
          this.$router.replace('/folders')
          break;
        default:
          // TODO: move to /home
          this.$router.replace('/home')
          break;
      }
    }
  },

  computed: {
    ...mapGetters({
      user: 'user',
      innerHeight: 'innerHeight',
      pendingOnBoarding: 'pendingOnBoarding',
      isSubscriptionActive: 'profile/isSubscriptionActive',
      profileId: 'profile/id'
    }),

    selectColor() {
      return this.$vuetify.theme.dark ? 'tertiary' : 'primary'
    },

    optionColor() {
      return this.$vuetify.theme.dark ? '#333333' : '#f2f2f2'
    }
  },

  methods: {
    ...mapMutations({
      SET_STUDY_TOOL: 'setStudyTool'
    }),

    setTab(index) {
      if (this.activeTab === index) this.activeTab = null
      else this.activeTab = index
    },

    async getSubscriptionPaymentLink() {
      this.loadingPaymentLink = true
      try {
        const response = await this.stripeService.getSubscriptionPaymentLink(
          this.profileId
        )
        if (!response || !response.url) return
        window.open(response.url, '_blank')
      } catch (err) {
        console.log('err: ', err);
      }
      this.loadingPaymentLink = false
    },
  }
}
</script>

<style>
.v-application .rounded {
  border-radius: 12px !important;
}

.v-list--rounded .v-list-item, .v-list--rounded .v-list-item::before, .v-list--rounded .v-list-item > .v-ripple__container {
  border-radius: 12px !important;
}
</style>

<style scoped>
.hs-rounded-tabs {
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: 12px;
}

.tab-btn {
  width: 50%;
  padding: 8px 16px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s;
}

.v-tab--active {
  background-color: rgba(125, 33, 237, 0.157);
}

.theme--dark .v-tab--active {
  background-color: rgba(129, 55, 219, 0.379);
}

.tab-btn .v-icon {
  margin-left: 8px;
}

.v-ripple__container {
  overflow: hidden;
  border-radius: 12px;
}

.v-ripple__animation {
  border-radius: 12px !important;
}

 .v-application .theme--dark .primary--text {
  color: #b594e0 !important;
}
</style>
