<template>
  <v-date-picker
    v-model="date"
    id="datePicker"
    scrollable
    color="primary"
    :header-color="headerColor"
    :active-picker.sync="activePicker"
    :locale="locale"
    :max="today"
  >
    <template v-slot:default>
      <v-container class="py-0">
        <v-divider
          v-if="!$vuetify.theme.dark"
          class="mb-2"
        />
        
        <v-row
          justify="center"
          class="px-3"
        >
          <v-btn 
            text
            color="primary" 
            @click="emitCancel"
          >
            Back
          </v-btn>
          
          <v-spacer />

          <v-btn 
            text
            color="primary" 
            @click="emitSaveDate"
          >
            OK
          </v-btn>
        </v-row>
      </v-container>
    </template>
  </v-date-picker>
</template>

<script>
export default {
  props: {
    calendarOn: {
      type: Boolean,
      required: true
    },
    locale: {
      type: String,
      required: true
    }
  },

  mounted() {
    const datePickerEl = document.getElementById('datePicker')
    const config = { childList: true, subtree: true }
    this.observer = new MutationObserver(() => {
      const [el] = document.getElementsByClassName('v-date-picker-years')
      if (el && !el.classList.contains('os-host')) {
        OverlayScrollbars(el, {})
      }
    })
    this.observer.observe(datePickerEl, config)

    setTimeout(() => { this.activePicker = 'YEAR' })
  },

  beforeDestroy() {
    this.observer.disconnect()
  },

  data() {
    return {
      date: '',
      observer: null,
      activePicker: 'YEAR'
    }
  },

  watch: {
    calendarOn(val) {
      val && setTimeout(() => { this.activePicker = 'YEAR' })
    },
    date(v) {
      this.$emit('setDate', v)
    }
  },

  computed: {
    headerColor() {
      return this.$vuetify.theme.dark
        ? 'grey darken-4'
        : ''
    },
    today() {
      let today = new Date()
      return today.toISOString() 
    }
  },

  methods: {
    emitSaveDate() {
      this.$emit('saveDate', this.date)
    },
    emitCancel() {
      this.$emit('cancel')
    }
  }
}
</script>