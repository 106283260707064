var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"center"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.onBack}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.mdiArrowLeft)+" ")])],1),_c('v-list-item',{staticStyle:{"max-width":"80%"}},[_c('v-list-item-avatar',{attrs:{"size":"30"}},[_c('v-img',{attrs:{"src":_vm.getResourceUrl(_vm.room.thumbnail)},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"height":"40","width":"40","type":"avatar"}})]},proxy:true}])})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.room.name)}})],1)],1)],1),_c('div',{staticClass:"d-flex my-3",staticStyle:{"align-items":"center"}},[_c('v-btn',{class:{ 'grey': true, 'darken-3': _vm.$vuetify.theme.dark, 'lighten-3': !_vm.$vuetify.theme.dark },staticStyle:{"border-radius":"8px !important"},attrs:{"small":"","elevation":"0","disabled":false},on:{"click":_vm.addChat}},[_vm._v(" new chat ")]),_c('v-divider',{class:"mb-1 mt-2 mx-4 mr-0"})],1),(!!_vm.conversationToCreate)?_c('ChatCard',{attrs:{"cardColor":_vm.getCardColor(),"conversation":_vm.conversationToCreate,"onlyCreate":true},on:{"addConversation":_vm.addConversation}}):_vm._e(),(_vm.currentRoom)?_c('ScrollPagination',{key:_vm.listKey,attrs:{"requestData":{
      'url': ("conversations/" + (this.user.id)),
      'params': {
        query: {
          room: {
            $eq: _vm.currentRoom._id
          },
          state: 'ACTIVE'
        },
        sort: {
          createdAt: -1
        }
      }
    },"countData":{
      'url': ("conversations/" + (this.user.id) + "/count"),
    },"rootHeight":"37vh"},scopedSlots:_vm._u([{key:"child",fn:function(props){return [_c('ChatCard',{key:("conversation_" + (props.index)),attrs:{"active":props.item.id === _vm.$route.query.id,"cardColor":_vm.getCardColor(props.item._id),"conversation":props.item},on:{"addConversation":_vm.addConversation,"remove":function($event){return props.items.splice(props.index, 1)}}})]}}],null,false,4026493188)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }