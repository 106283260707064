<template>
  <div>
    <v-navigation-drawer
      absolute
      right
      temporary
      width="50%"
      :color="noteDrawerColor"
      :value="annaChamber"
      @input="close"
    >
      <v-container>
        <v-row
          align="center"
          justify="start"
          class="px-4"
        >
          <v-btn text @click="chamber = 'list'">
            {{ $t('all_conversations') }}
          </v-btn>

          <v-divider vertical />

          <v-btn text @click="addConversation">
            {{ $t('new_conversation') }}
          </v-btn>

          <v-spacer />

          <v-fade-transition hide-on-leave>
            <span v-if="file.embeddingStatus != 'CREATED'">
              <v-list-item>
                <v-list-item-avatar size="30">
                  <v-img :src="anna.avatar"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <div class="grey--text text--darken-1">
                    I can't read this pdf 😓
                  </div>
                </v-list-item-content>
              </v-list-item>
            </span>
          </v-fade-transition>
        </v-row>

        <v-divider class="mt-1" />

        <v-row justify="center">
          <v-col cols="12">
            <v-fade-transition hide-on-leave>
              <v-container v-if="chamber === 'list'">
                <v-row justify="center">
                  <v-col cols="8">
                    <Conversations
                      :height="$vuetify.breakpoint.xl ? 74 : 68"
                      :conversations="conversations"
                      :conversationIdx="conversationIdx"
                      @setConversation="setConversation"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-fade-transition>

            <v-fade-transition hide-on-leave>
              <ChatContainer
                v-if="chamber === 'conversation' && currentConversation && currentConversation.id"
                :height="containerHeight - heightAdjustment"
                :outerHeight="outerHeight - 70"
                :innerTop="containerHeight + 25"
                menuClass="menu--top"
                :langChain="true"
                :autoCall="true"
                :filename="`${file._id}.pdf`"
                :room="currentRoom"
                :conversation="currentConversation"
                @setReference="setReference"
                @heightChange="heightChange"
              />
            </v-fade-transition>
          </v-col>
        </v-row>
      </v-container>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mdiArrowLeft } from '@mdi/js'
import { mapGetters, mapMutations } from 'vuex'
import { Scrollbar } from '@components/App'
import Conversations from '@components/Conversation/Conversations'
import ChatContainer from '@components/Conversation/ChatContainer'
import { v4 as uuidv4 } from 'uuid';
import API from '@api'

export default {
  components: {
    Scrollbar,
    Conversations,
    ChatContainer
  },

  data: () => ({
    mdiArrowLeft,
    heightAdjustment: 35,
    chamber: 'list',
    rooms: [],
    currentRoom: {},
    currentConversation: {},
    conversations: [],
    conversationIdx: 0
  }),

  computed: {
    ...mapGetters({
      innerHeight: 'innerHeight',
      anna: 'anna',
      user: 'user',
      myProfile: 'profile/id',
      annaChamber: 'annaChamber',
      media: 'media/media',
      file: 'media/file',
      content: 'content'
    }),

    containerHeight() {
      return this.innerHeight * (1 - (100 / this.innerHeight)) - 20
    },

    outerHeight() {
      return this.innerHeight
    },

    noteDrawerColor() {
      return this.$vuetify.theme.dark
        ? '#1a1a1a'
        : 'grey lighten-4'
    },
  },

  watch: {
    async file() {
      setTimeout(async () => {
        if (this.content.id) {
          this.rooms = await API().get(`rooms/${this.user.id}`, {
            params: {
              owner: this.myProfile,
              content: this.content.id
            }
          })
          let [room] = this.rooms

          if (this.rooms.length > 0)
            this.currentRoom = this.rooms[0]

          if (room) {
            this.conversations = await API().get(`conversations/${this.user.id}`, {
              params: {
                room: room._id
              }
            })
            this.currentConversation = this.conversations[0]
          }

          if (this.conversations.length === 0)
            this.addConversation(true)
        }
      }, 200)
    }
  },

  methods: {
    ...mapMutations({
      SET_MEDIA: 'media/setMedia',
      SET_ANNA_CHAMBER: 'setAnnaChamber',
      SET_CURRENT_PAGE: 'pdf/currentPage',
      SET_PAGINATOR: 'pdf/paginatorOn'
    }),

    heightChange(heightDifference) {
      /* HEIGHT ADJUSMENT LOGIC */
      if (Math.abs(heightDifference) > 2)
        this.heightAdjustment += heightDifference
    },

    setReference(page) {
      this.SET_PAGINATOR(true)
      this.SET_CURRENT_PAGE(page)
    },

    async setConversation(index) {
      this.currentConversation = this.conversations[index]
      this.conversationIdx = index
      this.chamber = 'conversation'
    },

    // TODO: review the use of media in this component

    async addConversation(summarize = false) {
      if (this.rooms.length > 0 && this.conversations.length > 0) {
        let [room] = this.rooms
        this.conversations.unshift(await API().post(`conversation/${this.user.id}`, {
          room: room._id,
          name: `${this.media.name} convo ${this.conversations.length}`
        }))

        this.chamber = 'list'
        setTimeout(() => {
         this.chamber = 'conversation'
        })

      } else {
        let room;
        [room] = await API().get(`rooms/${this.user.id}`, {
          params: {
            owner: this.myProfile,
            content: this.content.id            
          }
        })

        if (!room) {
          room = await API().post(`room/${this.user.id}`, {
            owner: this.myProfile,
            content: this.content.id,
            type: 'MEDIA',
            name: this.content.name ? this.content.name : `${uuidv4()}`
          })
        }

        this.conversations = await API().get(`conversations/${this.user.id}`, {
          params: {
            room: room._id
          }
        })

        if (this.conversations.length === 0) {
          this.conversations.unshift(
            await API().post(`conversation/${this.user.id}`, {
              room: room._id,
              name: this.media.name,
              summaryHook: summarize
            })
          )
        }
        this.rooms = [room]
        this.chamber = 'conversation'
      }

      this.currentConversation = this.conversations[0]
    },

    close(payload) {
      if (!payload) {
        this.SET_ANNA_CHAMBER()
      }
    }
  }
}
</script>