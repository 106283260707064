<template>
  <v-main>
    <Socket />
    <Toolbar />
    <slot></slot>
  </v-main>
</template>

<script>
  import Toolbar from '@components/App/Toolbar'
  import Socket from '@components/Socket.vue'

  export default {
    components: {
      Toolbar,
      Socket
    },
  }
</script>