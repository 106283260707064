import API from '@api';

export default (userId) => {
  const apiRequest = API()
  return {
    getCreateAccountLink (profileId) {
      return apiRequest.get(
        `stripe/create-account-link/${userId}`,
        { params: { profile: profileId } }
      )
    },
    getCheckoutSessionLink (profileId, communityId) {
      return apiRequest.get(
        `stripe/checkout-session/${userId}`,
        { params: { profileId, communityId } }
      )
    },
    getSubscriptionPaymentLink (profileId) {
      return apiRequest.get(
        `stripe/subscription-payment-link/${userId}`,
        { params: { profile: profileId } }
      )
    },
    handleCheckoutSuccess (stripeCheckoutSessionId, sellerAccountId) {
      return apiRequest.post(
        `stripe/checkout-success/${
          userId
        }}?stripeCheckoutSessionId=${
          stripeCheckoutSessionId
        }${
          sellerAccountId ?
          `&sellerAccountId=${sellerAccountId}` :
          ''
        }`
      )
    },
    listSubscriptions () {
      return apiRequest.get(`stripe/subscriptions`)
    },
    cancelSubscription (subscriptionId) {
      return apiRequest.delete(`stripe/subscription/${subscriptionId}`)
    },
    onStripeSellerAccountCreated (sellerAccountId) {
      return apiRequest.post(`stripe/seller-account-created?sellerAccountId=${sellerAccountId}`)
    }
  }
}
