<template>
  <v-btn
    small
    rounded
    depressed
    color="primary"
    @click="getCheckoutSessionLink"
    :loading="loading"
  >
    <v-icon>
      {{ mdiCash }}
    </v-icon>
    {{ $t('community.subscribe') }}
  </v-btn>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { mdiCash } from '@mdi/js'
  import StripeService from '@api/stripe'

  export default {
    props: {
      onRequest: Function
    },

    data() {
      return {
        stripeService: null,
        loading: false,
        mdiCash
      }
    },

    created() {
      this.stripeService = StripeService(this.user.id)
    },

    computed: {
      ...mapGetters({
        user: 'user',
        profileId: 'profile/id'
      }),
    },

    methods: {
      async getCheckoutSessionLink() {
        this.loading = true
        try {
          const response = await this.stripeService.getCheckoutSessionLink(
            this.profileId,
            this.$route.params.id
          )
          if (!response || !response.url) return
          window.open(response.url, '_blank')
        } catch (err) {
          console.log('err: ', err);
        }
        this.loading = false
      },
    }  
  }
</script>