<template>
  <div>
    <!-- <Toolbar
      :scrollTop="scrollTop"
      :invertScroll="scrollToolbar"
    /> -->

    <v-main>
      <Scrollbar
        :isMobile="true"
        :emitInstance="true"
        @scrolling="setScrollTop"
      >
        <div class="fillHeight">
          <slot></slot>
        </div>

        <Footer></Footer>
      </Scrollbar>
    </v-main>
  </div>
</template>

<script>
import Toolbar from '@components/Open/mobile/Toolbar'
import Footer from '@components/Open/mobile/Footer'
import Scrollbar from '@components/App/Scrollbar'

export default {
  components: {
    Toolbar,
    Footer,
    Scrollbar
  },

  created() {
    this.$socket.conversation.disconnect()
  },

  data() {
    return {
      scrollTop: 0
    }
  },

  computed: {
    scrollToolbar() {
      return this.$route.name == 'open.landing'
    }
  },

  methods: {
    setScrollTop(scrollTop) {
      this.scrollTop = scrollTop
    },
  }
}
</script>

<style scoped>
.fillHeight {
  min-height: 80vh
}
/*
  Sets the height of the image skeleton loader to 100% of the parent component.
*/
::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading .v-skeleton-loader__image {
  height: 100% !important;
}

/*
  Sets the height and width of the avatar skeleton loader to 100% of the parent component.
*/
::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading .v-skeleton-loader__avatar {
  height: 100% !important;
  width: 100% !important;
}

/*
  Stops autocomplete and combobox icons from rotating.
  There might be an option to set this within Vuetify 3.
*/
::v-deep .v-select.v-select--is-menu-active
  .v-input__icon--append
  .v-icon {
  transform: rotate(0) !important;
}
</style>