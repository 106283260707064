<template>
  <div style="height: 100%;">
    <Scrollbar
      :thin="true"
      :flexible="true"
      :style="`height: ${rootHeight}px`"
    >
      <v-container class="py-2">
        <v-row justify="start" align="center" class="px-3">
          <v-btn
            icon
            small
            @click="$emit('back')"
          >
            <v-icon>
              {{ mdiArrowLeft }}
            </v-icon>
          </v-btn>

          <div class="mx-1"></div>

          <span class="text-body-2">
            {{ $t('myFavorites') }}
          </span>
        </v-row>
        
        <v-divider inset class="my-3" />
        
        <div class="hs-col">          
          <PersonaCard
            v-for="(persona, i) in personas"
            :key="`persona_${i}`"
            :name="persona.name"
            :avatar="persona.avatar"
            :caption="persona.caption"
            @persona="$emit('setPersona', persona)"
          />

          <v-alert
            v-if="personas.length === 0"
            text
            outlined
            prominent
            :color="$vuetify.theme.dark ? 'tertiary' : 'primary'"
            class="hs-rounded-lg"
          >
            <v-row>
              <v-col class="grow">
                Add your favorites in the persona page
              </v-col>
            </v-row>
          </v-alert>
        </div>
      </v-container>
    </Scrollbar>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { Scrollbar } from '@components/App'
import PersonaCard from './PersonaCard.vue'
import { mdiArrowLeft } from '@mdi/js';
import API from '@api'
import ObjectID from 'bson-objectid'

export default {
  components: {
    Scrollbar,
    PersonaCard
  },

  props: {
    scrollbarHeight: {
      type: Number,
      default: 75
    }
  },

  async created() {
    this.setFavorites()
  },

  data: () => ({
    mdiArrowLeft,
    personas: [],
    loading: true
  }),

  computed: {
    ...mapGetters({
      innerHeight: 'innerHeight',
      user: 'user',
      myProfileId: 'profile/id',
      personaFavorites: 'persona/favorites'
    }),

    rootHeight() {
      return this.innerHeight * (1 - (100 / this.innerHeight)) - 150
    },

    backgroundColor() {
      return this.$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'
    },

    optionsColor() {
      return this.$vuetify.theme.dark ? 'primary' : '#9d51f958'
    },
  },

  watch: {
    personaFavorites(favs) {
      if (!this.loading) this.setFavorites(favs)
    }
  },

  methods: {
    ...mapMutations({
      SET_FAVORITES: 'persona/SET_FAVORITES',
    }),

    async setFavorites(favorites = null) {
      if (favorites) {
        this.personas = favorites
        return;
      }

      const personaConnectors = await API().get('connectors', {
        params: {
          query: {
            type: {
              $eq: 'persona_profile'
            },
            favorite: {
              $eq: true
            },
            entities: {
              $elemMatch: {
                ref: 'Profile',
                _id: ObjectID(this.myProfileId)
              }
            }
          }
        }
      });
      
      if (personaConnectors.length > 0) {
        const favPersonas = await API().get('personas', {
          params: {
            query: {
              _id: {
                $in: personaConnectors.map(({ entities }) =>
                  entities.filter(({ ref }) => ref === 'Persona').pop()._id
                )
              }
            }
          }
        })
        this.personas = favPersonas
        this.SET_FAVORITES(favPersonas)
        setTimeout(() => {
          this.loading = false
        }, 100)
      }
    }
  }
}
</script>

<style>
.unselectable {
  user-select: none;
  -moz-user-select: none; /* Firefox */
  -webkit-user-select: none; /* Safari, Chrome */
  -ms-user-select: none; /* IE/Edge */
}
</style>