<template>
  <div>
    <v-snackbar
      top
      text
      outlined
      color="red darken-4"
      v-model="snackbar"
    >
      We need your permission to record audios :)
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          color="red darken-4"
          v-bind="attrs"
          @click="snackbar = false"
        >
          OK
        </v-btn>
      </template>
    </v-snackbar>

    <v-card 
      outlined 
      class="mx-auto"
    >
      <v-card-text>
        <v-row
          align="center"
          justify="space-between"
        >
          <v-col class="text-left">
            <v-row
              align="center"
              justify="center"
            >
              <span
                v-if="!recording"
                class="text-h5"
                v-text="'Gravar áudio'"
              />

              <span
                id="recordTime"
                v-text="recordTime"
                v-show="recording"
              />

              <v-progress-linear
                v-if="recording"
                stream
                color="primary"
                buffer-value="0"
                style="width: 50%;" 
              />
            </v-row>
          </v-col>
          <v-col class="text-right">
            <v-btn
              fab
              color="primary"
              ref="recordAudio"
              :disabled="notAllowed && permissionPrompted"
              :depressed="notAllowed && permissionPrompted"
              @mouseup="clearTimer"
              @mousedown="startRecording"
            >
              <v-icon v-text="mdiMicrophone" />
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiMicrophone } from '@mdi/js'

export default {
  mounted () {
    this.connectUserMedia()
  },

  beforeDestroy () {
    this.clearTimer()
  },

  data () {
    return {
      mdiMicrophone,
      
      /* Permission Control */
      snackbar: false,
      notAllowed: true,
      permissionPrompted: false,

      /* Recording and Streamming */
      stream: {},
      recorder: {},
      recorded: false,
      recording: false,
      recordTime: '0m 0s',
      timer: 0
    }
  },

  watch: {
    async recording (newVal) {
      if (newVal) {
        const items = []
        this.recorder = new MediaRecorder(this.stream);
        this.recorder.ondataavailable = ({ data }) => {
          items.push(data)
          if (this.recorder.state === 'inactive') {
            const blob = new Blob(items, { type: data.type })
            const audioURL = URL.createObjectURL(blob)
            this.$emit('audioURL', audioURL)
          }
        }
        this.recorder.start()

        const startDate = new Date().getTime()
        this.timer = setInterval(() => {
          const now = new Date().getTime()
          const distance = now - startDate

          const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
          const seconds = Math.floor((distance % (1000 * 60)) / 1000)

          document.getElementById('recordTime').innerHTML = minutes + "m " + seconds + "s "
          if (distance > 600000) this.clearTimer()
        }, 1000)
      }
    }
  },

  methods: {
    startRecording () {
      if (this.stream.active)
        this.recording = true
      else this.clearRecordingErr()
    },

    connectUserMedia () {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          this.stream = stream
          this.snackbar = false
          this.notAllowed = false
          this.permissionPrompted = true
        })
        .catch(this.clearRecordingErr)
    },

    clearTimer () {
      if (this.recording || this.recorder.state === 'recording') {
        this.recording = false
        clearInterval(this.timer)
        this.recordTime = '0m 0s'
        this.timer = 0
        document.getElementById('recordTime').innerHTML = this.recordTime
        if ('stop' in this.recorder) this.recorder.stop()
      }

      if ('getTracks' in this.stream)
        this.stream.getTracks().forEach( track => track.stop())
    },

    clearRecordingErr () {
      this.stream = {}
      this.snackbar = true
      this.notAllowed = true
      this.permissionPrompted = true
    }
  }
}
</script>