<template>
  <v-app-bar 
    :class="barClass"
    :app="!invertScroll"
    dark
    fixed
    elevation="2"
    color="#4A148C"
  >
    <v-fade-transition hide-on-leave>
      <div v-if="$route.name != 'open.landing'">
        <v-btn
          text
          color="white"
          style="border-radius: 12px;"
          @click="goBack"
        >
          <v-icon left>
            {{ mdiArrowLeft }}
          </v-icon>
          {{ $t('goBack') }}
        </v-btn>
      </div>
    </v-fade-transition>

    <v-fade-transition hide-on-leave>
      <v-row
        v-if="$route.name == 'open.landing'"
        align="center"
        justify="center"
      >
        <v-btn
          block
          text
          style="border-radius: 12px;"
          class="white--text"
          @click="SET_SIGNUP"
        >
          signup
        </v-btn>
      </v-row>
    </v-fade-transition>
  </v-app-bar>
</template>

<script>
import { signOut } from '@utils'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { mdiArrowLeft } from '@mdi/js'

export default {
  props: {
    scrollTop: {
      type: Number,
      default: 0
    },
    invertScroll: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      mdiArrowLeft
    }
  },

  computed: {
    ...mapGetters({
      user: 'user'
    }),
    barClass() {
      return !this.invertScroll || this.scrollTop > 10 ? 'openBar' : 'closedBar'
    }
  },

  methods: {
    ...mapMutations({
      SET_SIGNUP: 'setSignup'
    }),

    ...mapActions({
      logout: 'logout'
    }),

    goBack() {
      if (this.$auth.user || this.user) signOut(this)
      else this.$router.push('/')
    }
  }
}
</script>

<style scoped>
.closedBar {
  transform: translateY(-60px) !important;
  transition: transform .2s ease-in-out;
}

.openBar {
  transition: transform .2s ease-in-out;
}
</style>