/**
 * Build styles
 */
import containsCode from '../../utils/containsCode'
import './index.css';
import { IconText } from '@codexteam/icons'

/**
 * Custom Paragraph Block for Editor.js.
 * Represents simple paragraph
 */

/**
 * @typedef {object} ParagraphConfig
 * @property {string} placeholder - placeholder for the empty paragraph
 * @property {boolean} preserveBlank - Whether or not to keep blank paragraphs when saving editor data
 */

/**
 * @typedef {Object} ParagraphData
 * @description Tool's input and output data format
 * @property {String} text — Paragraph's content. Can include HTML tags: <a><b><i>
 */
export default class Paragraph {
  /**
   * Default placeholder for Paragraph Tool
   *
   * @return {string}
   * @constructor
   */
  static get DEFAULT_PLACEHOLDER() {
    return '';
  }

  /**
   * Render plugin`s main Element and fill it with saved data
   *
   * @param {object} params - constructor params
   * @param {ParagraphData} params.data - previously saved data
   * @param {ParagraphConfig} params.config - user config for Tool
   * @param {object} params.api - editor.js api
   * @param {boolean} readOnly - read only mode flag
   */
  constructor({data, config, api, readOnly}) {
    this.api = api;
    this.readOnly = readOnly;

    this._CSS = {
      block: this.api.styles.block,
      wrapper: 'ce-paragraph'
    };

    if (!this.readOnly) {
      this.onKeyUp = this.onKeyUp.bind(this);
      this.onFocus = this.onFocus.bind(this);
      this.onBlur = this.onBlur.bind(this);
    }

    /**
     * Placeholder for paragraph if it is first Block
     * @type {string}
     */
    this._isChat = config.isChat
    this._placeholder = config.placeholder ? config.placeholder : Paragraph.DEFAULT_PLACEHOLDER;
    this._data = {};
    this._element = this.drawView();

    setTimeout(() => {
      // this delay ensures that the element is mounted on screen
      const el = document.querySelector(`#${config.holder}`)
      if (el && el.querySelectorAll('.ce-paragraph').length === 1)
        this._element.dataset.placeholder = this.api.i18n.t(this._placeholder);
    }, 10)

    this._preserveBlank = config.preserveBlank !== undefined ? config.preserveBlank : false;

    this.data = data;
  }

  /**
   * Check if text content is empty and set empty string to inner html.
   * We need this because some browsers (e.g. Safari) insert <br> into empty contenteditanle elements
   *
   * @param {KeyboardEvent} e - key up event
   */
  onKeyUp(e) {
    if (e.code !== 'Backspace' && e.code !== 'Delete') {
      return;
    }

    const {textContent} = this._element;

    if (textContent === '') {
      this._element.innerHTML = '';
    }
  }

  /**
   * Create Tool's view
   * @return {HTMLElement}
   * @private
   */
  drawView() {
    let div = document.createElement('DIV');
    div.classList.add(this._CSS.wrapper, this._CSS.block);
    div.classList.add('placeholder-block');
    div.contentEditable = false;

    if (!this.readOnly) {
      div.contentEditable = true;
      div.addEventListener('keyup', this.onKeyUp);
      div.addEventListener('paste', (event) => this.onPasteCustom(event)); // Custom processing for paste events

      // Add focus and blur event listeners
      div.addEventListener('focus', this.onFocus);
      div.addEventListener('blur', this.onBlur);
    }

    return div;
  }

  /**
   * Handles the 'focus' event, ensuring that the placeholder is properly set 
   * or removed based on the editor's current state.
   */
  onFocus() {
     // If there's an element with the 'placeholder-block' class, remove that class
    // and clear the placeholder. This ensures the placeholder is shown only once.
    let elementWithPlaceholder = document.querySelector('.placeholder-block');
    if (elementWithPlaceholder) {
      // Using the placeholder-block helps us catch edge cases
      elementWithPlaceholder.classList.remove('placeholder-block');
      elementWithPlaceholder.dataset.placeholder = '';
    }

    // Set the placeholder for the current element
    this._element.classList.add('placeholder-block');
    this._element.dataset.placeholder = this.api.i18n.t(this._placeholder);
  }

  /**
   * Handles the 'blur' event, ensuring the placeholder is removed if 
   * necessary based on the editor's content.
   */
  async onBlur() {
    // Get the current count of blocks and the saved editor data
    const blocksCount = this.api.blocks.getBlocksCount();
    // Assume 'editor' is your Editor.js instance
    const outputData = await this.api.saver.save();
    let consolidatedText = '';
    outputData.blocks.forEach((block) => {
      // Assuming the text content is in the 'text' property of the block data
      if(block.data.text) {
        consolidatedText += block.data.text + '\n';  // Adding a newline between blocks
      }
    });

    
    setTimeout(() => {
      // Check if the active element is a paragraph
      const elIsParagaph = document.activeElement.classList.contains('ce-paragraph')

      // If there are multiple blocks and the text is not empty, or if the active element is a paragraph, 
      // remove the placeholder from the current element.
      if (blocksCount > 1 && consolidatedText.length > 0 || elIsParagaph) { 
        this._element.classList.remove('placeholder-block');
        this._element.dataset.placeholder = '';
      }
    })
  }

  /**
   * Return Tool's view
   *
   * @returns {HTMLDivElement}
   */
  render() {
    return this._element;
  }

  /**
   * Method that specified how to merge two Text blocks.
   * Called by Editor.js by backspace at the beginning of the Block
   * @param {ParagraphData} data
   * @public
   */
  merge(data) {
    let newData = {
      text : this.data.text + data.text
    };

    this.data = newData;
  }

  /**
   * Validate Paragraph block data:
   * - check for emptiness
   *
   * @param {ParagraphData} savedData — data received after saving
   * @returns {boolean} false if saved data is not correct, otherwise true
   * @public
   */
  validate(savedData) {
    if (savedData.text.trim() === '' && !this._preserveBlank) {
      return false;
    }

    return true;
  }

  /**
   * Extract Tool's data from the view
   * @param {HTMLDivElement} toolsContent - Paragraph tools rendered view
   * @returns {ParagraphData} - saved data
   * @public
   */
  save(toolsContent) {
    return {
      text: toolsContent.innerHTML
    };
  }

  /**
   * On paste callback fired from Editor.
   *
   * @param {PasteEvent} event - event with pasted data
   */
  onPaste(event) {
    // Not used. See onPasteCustom
    const data = {
      text: event.detail.data.innerHTML
    };

    this.data = data;
  }

  /**
   * Custom on paste callback fired from Editor.
   *
   * @param {PasteEvent} event - event with pasted data
  */
  async onPasteCustom(event) {
    let data;
    let textData = event.clipboardData.getData('text/plain')

    // note how we call the function to use the global "this" here
    if (this._isChat && textData.includes('\n')) {
      // BAD TODO: fix code formatting with LLM
      // rn a lot of code gets fucked up, especially html
      // gotta identify if string is or contains code, and format accordingly.
      // (scrap this, check rawHTML plugin for editorjs)
      
      event.preventDefault()
      event.stopPropagation()
      // TODO: review this, as execCommand is deprecated
      if (!containsCode(textData))
        document.execCommand('insertHTML', false, textData.replace(/\n/g, '<br>'));
      else
        document.execCommand('insertText', false, textData)
    }

    data = {
      text: event.target.innerHTML
    }
    
    event.target.data = data;
  }

  /**
   * Enable Conversion Toolbar. Paragraph can be converted to/from other tools
   */
  static get conversionConfig() {
    return {
      export: 'text', // to convert Paragraph to other block, use 'text' property of saved data
      import: 'text' // to covert other block's exported string to Paragraph, fill 'text' property of tool data
    };
  }

  /**
   * Sanitizer rules
   */
  static get sanitize() {
    return {
      text: {
        br: true,
      }
    };
  }

  /**
   * Returns true to notify the core that read-only mode is supported
   *
   * @return {boolean}
   */
  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Get current Tools`s data
   * @returns {ParagraphData} Current data
   * @private
   */
  get data() {
    let text = this._element.innerHTML;

    this._data.text = text;

    return this._data;
  }

  /**
   * Store data in plugin:
   * - at the this._data property
   * - at the HTML
   *
   * @param {ParagraphData} data — data to set
   * @private
   */
  set data(data) {
    this._data = data || {};

    this._element.innerHTML = this._data.text || '';
  }

  /**
   * Used by Editor paste handling API.
   * Provides configuration to handle P tags.
   *
   * @returns {{tags: string[]}}
   */
  static get pasteConfig() {
    return {
      tags: [ 'P' ]
    };
  }

  /**
   * Icon and title for displaying at the Toolbox
   *
   * @return {{icon: string, title: string}}
   */
  static get toolbox() {
    return {
      icon: IconText,
      title: 'Text'
    };
  }
}