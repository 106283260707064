<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <div
          v-on="on"
          :style="iconContainerStyle"
          class="hs-rounded-lg"
          @click="() => setOpenHandleGroupRoomDialog(true)"
        >
          <v-btn icon>
            <v-icon small>
              {{ mdiPlus }}
            </v-icon>
          </v-btn>
        </div>
      </template>
      <span>New room</span>
    </v-tooltip>
    <CreateChatRoomDialog
      :open="openHandleGroupRoomDialog"
      :onClose="onClose"
    />
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex'
  import { mdiPlus } from '@mdi/js';
  import CreateChatRoomDialog from '@components/Room/CreateChatRoomDialog';

  export default {
    props: {
      onGroupCreate: Function
    },

    components: {
      CreateChatRoomDialog
    },
    data() {
      return {
        mdiPlus,
        openCreateRoomDialog: false
      }
    },

    computed: {
      ...mapGetters({
        openHandleGroupRoomDialog: 'rooms/openHandleGroupRoomDialog',
      }),
      iconContainerStyle() {
        return `
          height: 2em;
          width: 2em;
          background-color: ${this.btnColor};
          display: flex;
          align-items: center;
          justify-content: center;
        `;
      },
      btnColor() {
        return this.$vuetify.theme.dark ? '#3b3b3bfa' : '#EEEEEE'
      }
    },

    methods: {
      ...mapMutations({
        setOpenHandleGroupRoomDialog: 'rooms/setOpenHandleGroupRoomDialog'
      }),
      onClose(roomId, conversationId) {
        this.onGroupCreate(roomId, conversationId)
        this.setOpenHandleGroupRoomDialog(false)
      }
    }
  }
</script>