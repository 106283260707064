<template>
  <div v-if="visible" class="custom-overlay" @click="hideOverlay">
    <div class="overlay-content">
      <!-- Slot for your content -->
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomOverlay',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  methods: {
    hideOverlay() {
      this.visible = false;
    }
  }
};
</script>

<style scoped>
.custom-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.overlay-content {
  padding: 20px;
  border-radius: 5px;
  z-index: 11;
}
</style>
