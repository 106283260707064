export default {
  namespaced: true,

  state: {
    rag: false,
    currentFolder: null,
    folders: [],
    contents: [],
    myFolders: []
  },

  getters: {
    rag: ({ rag }) => rag,
    myFolders: ({ myFolders }) => myFolders,
    currentFolder: ({ currentFolder }) => currentFolder,
    folders: ({ folders }) => folders,
    contents: ({ contents }) => contents
  },

  mutations: {
    setRag: (state, payload) => {
      if (typeof payload === 'boolean')
        state.rag = payload
      else
        state.rag = !state.rag
    },
    setMyFolders: (state, payload) => {
      state.myFolders = payload
    },
    setCurrentFolder: (state, payload) => {
      state.currentFolder = payload
    },
    setFolders: (state, payload) => {
      state.folders = payload
    },
    addFolder: (state, payload) => {
      state.folders.unshift(payload)
    },
    setContents: (state, payload) => {
      state.contents = payload
    },
    addContent: (state, payload) => {
      state.contents.unshift(payload)
    }
  }
}