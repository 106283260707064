export default {
  namespaced: true,

  state: {
    notebooks: [],
    notebook: {},

    // TODO: DELETE OLD STUFF
    notebookId: '',
    collaboration: false,
    notebookSaving: false,
    settingsOn: false,
    notebookNav: null,
    chaptersNav: null,
    caretPosition: 0,
    editorOn: false,
    miniLeft: true,
    miniRight: true
  },

  getters: {
    notebooks: ({ notebooks }) => notebooks,
    notebook: ({ notebook }) => notebook,

     // TODO: DELETE OLD STUFF
    notebookId: ({ notebookId }) => notebookId,
    collaboration: ({ collaboration }) => collaboration,
    notebookSaving: ({ notebookSaving }) => notebookSaving,
    settingsOn: ({ settingsOn }) => settingsOn,
    notebookNav: ({ notebookNav }) => notebookNav,
    chaptersNav: ({ chaptersNav }) => chaptersNav,    
    sections: ({ sections }) => sections,
    caretPosition: ({ caretPosition }) => caretPosition,
    editorOn: ({ editorOn }) => editorOn,
    miniLeft: ({ miniLeft }) => miniLeft,
    miniRight: ({ miniRight }) => miniRight
  },

  mutations: {
    notebooks: (state, payload) => state.notebooks = payload,
    notebook: (state, payload) => state.notebook = payload,
    addNotebook: (state, payload) => state.notebooks.push(payload),

     // TODO: DELETE OLD STUFF
    SET_NOTEBOOK_ID: (state, notebookId) => state.notebookId = notebookId,
    SET_COLLABORATION: (state, collaboration) => state.collaboration = collaboration,
    SET_NOTEBOOK_SAVING: (state, notebookSaving) => state.notebookSaving = notebookSaving,
    SET_NOTEBOOK_SETTINGS: (state, settingsOn) => state.settingsOn = settingsOn,
    SET_NOTEBOOK_NAV: (state, notebookNav) => state.notebookNav = notebookNav,
    SET_CHAPTERS_NAV: (state, chaptersNav) => state.chaptersNav = chaptersNav,
    SET_SECTIONS: (state, sectionsData) => state.sections = sectionsData,
    SET_CARET_POSITION: (state, caretPosition) => state.caretPosition = caretPosition,
    SET_NOTEBOOK_EDITOR_ON: (state, editorOn) => state.editorOn = editorOn,
    SET_MINI_LEFT: (state, miniLeft) => state.miniLeft = miniLeft,
    SET_MINI_RIGHT: (state, miniRight) => state.miniRight = miniRight
  }
}