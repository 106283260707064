import axios from 'axios'
import store from '@store'

axios.defaults.withCredentials = true

const baseURL = process.env.NODE_ENV != 'production'
  ? `http://${process.env.VUE_APP_LOCALHOST}:3000/api`
  : `${process.env.VUE_APP_SERVER_URI}/api`

export default (responseType = 'json', token = '') => {
  const { authorizationToken = token, auth0Token } = store.getters
  const headers = { authorizationToken, "content-type": "application/json" }
  if (auth0Token) headers.Authorization = `Bearer ${auth0Token}`
  
  const api = axios.create({ baseURL, headers, responseType })

  api.interceptors.response.use(
    response => {
      if (response.data && response.data.snackbarMessage) {
        store.commit('snackbar/setSnackbarMessage', response.data.snackbarMessage)
        store.commit('snackbar/setSnackbarSeverity', 'success')
      }
      if (response.data && response.data.authorizationToken) {
        store.commit('authorizationToken', response.data.authorizationToken)
      }
      return response
    },
    error => {
      if (error.response && error.response.data) {
        if (error.response.data.forceLogin) {
          store.dispatch('logout')
          window.location.href = '/signin'
        }
        if (error.response.data.snackbarMessage) {
          store.commit('snackbar/setSnackbarMessage', error.response.data.snackbarMessage)
          store.commit('snackbar/setSnackbarSeverity', 'error')
        }
        if (
          error.response.data.stackTrace === 'temporarily_blocked' &&
          !window.location.search.includes('stripeCheckoutSessionId') &&
          !window.location.search.includes('awaitToken')
        ) {
          console.log('Blocked till: ', error.response.data.freeAt, window.location.href, error.response);
          store.commit('application/setSubscriptionRequiredTill', error.response.data.freeAt)
        }
      }
      throw error
    }
  )

  return {
    async get(url, config) {
      try {
        const response = await api.get(url, config)
        return response.data
      } catch(err) {
        throw err
      }
    },

    async post(url, data, config) {
      try {
        if (responseType === 'stream') {
          const response = await fetch(`${baseURL}/${url}`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: headers
          });
          return response
        } else {
          const response = await api.post(url, data, config)
          return response.data
        }  
      } catch(err) {
        throw err
      }
    },

    async put(url, data, config) {
      try {
        const response = await api.put(url, data, config)
        return response.data
      } catch(err) {
        throw err
      } 
    },

    async delete(url, config) {
      try {
        await api.delete(url, config)
      } catch(err) {
        throw err
      }
    } 
  }
}