<script>
// PDFDocument renders an entire PDF inline using
// PDF.js and <canvas>. Currently does not support,
// rendering of selected pages (but could be easily
// updated to do so).
import { findIndex, range } from 'lodash'
import { mapGetters } from 'vuex'
import debug from 'debug'

const log = debug('app:components/PDFData')
const pdfjs = require('pdfjs-dist/legacy/build/pdf')
const worker = require('pdfjs-dist/legacy/build/pdf.worker.min')
window.pdfjsWorker = worker

function getDocument(url) {
  return pdfjs.getDocument(url).promise
}

// pdf: instance of PDFData
// see docs for PDF.js for more info
function getPages(pdf, first, last) {
  const allPages = range(first, last+1).map(number => pdf.getPage(number))
  return Promise.all(allPages)
}

const BUFFER_LENGTH = 10
function getDefaults() {
  return {
    pages: [],
    cursor: 0,
  }
}

export default {
  name: 'PDFData',

  props: {
    url: {
      type: String,
      required: true
    }
  },

  data() {
    return Object.assign(getDefaults(), {
      pdf: undefined,
      queryPages: undefined
    })
  },

  computed: {
    ...mapGetters({
      highlightSelectedSwitch: 'pdf/highlightSelectedSwitch',
      currentPageMatchCount: 'pdf/currentPageMatchCount',
      currentMatchIndex: 'pdf/currentMatchIndex',
      currentMatchPage: 'pdf/currentMatchPage',
      currentPage: 'pdf/currentPage',
      querySwitch: 'pdf/querySwitch',
      textQuery: 'pdf/textQuery',
      matches: 'pdf/matches'
    }),
    
    pageCount () {
      return this.pdf
        ? this.pdf.numPages
        : 0
    }
  },

  watch: {
    url: {
      handler(url) {
        getDocument(url).then(pdfDocument => {
          this.pdf = pdfDocument
        })
        .catch(response => {
          this.$emit('document-errored', {text: 'Failed to retrieve PDF', response})
          log('Failed to retrieve PDF', response)
        })
      },
      immediate: false
    },

    pdf(pdf, oldPdf) {
      if (!pdf) return;

      if (oldPdf) {
      
        Object.assign(this, getDefaults())
      
      }

      this.$emit('page-count', this.pageCount)
      this.fetchPages()
    },

    //watcher for text search
    async textQuery(newQuery, oldQuery) {
      /*redundancy: theoretically we don't need to test whether newQuery != oldQuery,
      because this watcher only runs if the query value changes. Keeping it 'just in case'.*/
      if (newQuery != '' && newQuery != oldQuery) {
        let pages = this.queryPages
          ? this.queryPages
          : await getPages(this.pdf, 1, this.pageCount)

        if (!this.queryPages)
          this.queryPages = pages
          
        let textPromises = pages.map(page => page.getTextContent())

        const textsRaw = await Promise.all(textPromises)
        const pagesTexts = textsRaw.map(textRaw => textRaw.items)
          .map(items => items
            .map((item, index) => {
              return {
                str: item.str,
                index 
              }
            })
          )

        let matches = []

        pagesTexts.forEach((pageTexts, index) => {
          matches.push([])
          pageTexts.forEach(pageText => {
            const position = pageText.str
              .toLowerCase()
              .search(newQuery.toLowerCase())
              
            if (position >= 0) {
              matches[index].push({
                ...pageText,
                position
              })
            }
          })
        })

        if (matches[this.currentPage - 1].length != 0) {
          this.$store.commit('pdf/matches', matches)
          this.$store.commit('pdf/currentMatchIndex', 0)
          this.$store.commit('pdf/currentMatchPage', this.currentPage)
          this.$store.commit('pdf/currentPageMatchCount', matches[this.currentPage - 1].length)
        } else {
          const firstMatchIndex = findIndex(matches, m => m.length > 0)
          if (firstMatchIndex != -1) {
            this.$store.commit('pdf/matches', matches)
            this.$store.commit('pdf/currentMatchIndex', 0)
            this.$store.commit('pdf/currentPage', firstMatchIndex + 1)
            this.$store.commit('pdf/currentMatchPage', firstMatchIndex + 1)
            this.$store.commit('pdf/currentPageMatchCount', matches[firstMatchIndex].length)
          } else {
            this.$emit('no-match')
          }
        }
      }
    },

    //triggered when newQuery === oldQuery
    querySwitch () {

      if (this.currentMatchIndex + 1 < this.currentPageMatchCount) {
        
        this.$store.commit('pdf/currentMatchIndex', this.currentMatchIndex + 1)
      
      } else {
      
        this.$store.commit('pdf/previousMatchPage', this.currentMatchPage)
        const gtNextMatchPageIndex = findIndex(this.matches, (m, i) => m.length > 0 && i > this.currentMatchPage -1)
        const ltNextMatchPageIndex = findIndex(this.matches, (m, i) => m.length > 0 && i < this.currentMatchPage -1)

        if (this.currentMatchIndex === 0) {
        
          this.$store.commit('pdf/highlightSelectedSwitch', !this.highlightSelectedSwitch)
        
        } else {
        
          this.$store.commit('pdf/currentMatchIndex', 0)
        
        }

        if (gtNextMatchPageIndex != -1) {
          
          this.$store.commit('pdf/currentPage', gtNextMatchPageIndex + 1)
          this.$store.commit('pdf/currentMatchPage', gtNextMatchPageIndex + 1)
          this.$store.commit('pdf/currentPageMatchCount', this.matches[gtNextMatchPageIndex].length)
        
        } else if (ltNextMatchPageIndex != -1) {
        
          this.$store.commit('pdf/currentPage', ltNextMatchPageIndex + 1)
          this.$store.commit('pdf/currentMatchPage', ltNextMatchPageIndex + 1)
          this.$store.commit('pdf/currentPageMatchCount', this.matches[ltNextMatchPageIndex].length)
        
        }

      }

    }

  },

  methods: {
    fetchPages(currentPage = 0) {
      if (!this.pdf) return;

      if (this.pageCount > 0 && this.pages.length === this.pageCount) return;

      const startIndex = this.pages.length
      if (this.cursor > startIndex) return;

      const startPage = startIndex + 1
      const endPage = Math.min(Math.max(currentPage, startIndex + BUFFER_LENGTH), this.pageCount)
      this.cursor = endPage

      log(`Fetching pages ${startPage} to ${endPage}`)
      
      getPages(this.pdf, startPage, endPage)
        .then((pages) => {
          const deleteCount = 0
          this.pages.splice(startIndex, deleteCount, ...pages)
          return this.pages
        })
        .catch((response) => {
          this.$emit('document-errored', {text: 'Failed to retrieve pages', response})
          log('Failed to retrieve pages', response)
        })
    },

    onPageRendered({text, page}) {
      log(text, page)
    },

    onPageErrored({text, response, page}) {
      log('Error!', text, response, page)
    }
  },

  created() {
    this.$on('page-rendered', this.onPageRendered)
    this.$on('page-errored', this.onPageErrored)
    this.$on('pages-fetch', this.fetchPages)
  },

  render(h) {
    return h('div', [
      this.$scopedSlots.preview({ pages: this.pages }),
      this.$scopedSlots.document({ pages: this.pages })
    ])
  }

}
</script>
