import Vue from 'vue'
import Router from 'vue-router'
import routes from '@routes'
import checkJwt from '@middlewares/checkJwt'
import ipLocale from '@middlewares/ipLocale'

Vue.use(Router);

export function createRouter() {
  const router = new Router({
    base: process.env.BASE_URL,
    mode: 'history',
    routes: [
      {
        path: '/',
        component: {
          name: 'hisolver',
          render: h => h('router-view')
        },
        children: routes
      }
    ]
  })

  router.beforeEach(checkJwt)
  router.beforeEach(ipLocale)

  return router
}
