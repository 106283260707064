export default {
  namespaced: true,

  state: {
    id: '',
    isValid: false,
    isActive: true,
    friendshipCount: 0,
    name: '',
    bio: '',
    avatar: '',
    username: '',
    onBoardingDone: [],
    stripeSellerAccountId: '',
    stripeCurrency: 'usd',
    stripeSubscription: {}
  },

  getters: {
    id: state => {
      return state.id
    },
    isValid: state => {
      return state.isValid
    },
    isActive: state => {
      return state.isActive
    },
    stripeSellerAccountId: state => {
      return state.stripeSellerAccountId
    },
    stripeCurrency: state => {
      return state.stripeCurrency
    },
    isStripeSeller: state => {
      return !!state.stripeSellerAccountId
    },
    isSubscriptionActive: state => {
      return (
        state.stripeSubscription &&
        state.stripeSubscription.status === 'active'
      )
    },
    bio: state => {
      return state.bio
    },
    name: state => {
      return state.name
    },
    username: state => {
      return state.username
    },
    avatar: state => {
      return state.avatar
    },
    friendshipCount: state => {
      return state.friendshipCount
    },
    onBoardingDone: state => {
      return state.onBoardingDone
    },
    stripeSubscription: state => {
      return state.stripeSubscription
    }
  },

  mutations:  {
    id: (state, payload) => {
      state.id = payload
    },
    isValid: (state, payload) => {
      state.isValid = payload
    },
    isActive: (state, payload) => {
      state.isActive = payload
    },
    name: (state, payload) => {
      state.name = payload
    },
    username: (state, payload) => {
      state.username = payload
    },
    avatar: (state, payload) => {
      state.avatar = payload
    },
    bio: (state, payload) => {
      state.bio = payload
    },
    friendshipCount: (state, payload) => {
      state.friendshipCount = payload
    },
    pushOnBoardingDone: (state, payload) => {
      state.onBoardingDone = [ ...state.onBoardingDone, payload]
    },
    setOnBoardingDone: (state, payload) => {
      state.onBoardingDone = payload
    },
    setStripeSellerAccountId: (state, payload) => {
      state.stripeSellerAccountId = payload
    },
    setStripeCurrency: (state, payload) => {
      state.stripeCurrency = payload
    },
    setStripeSubscription: (state, payload) => {
      state.stripeSubscription = payload
    }
  },

  actions: {
    SET_ONBOARDING_DONE: ({ commit }, payload) => {
      commit('setOnBoardingDone', payload)
    },
    PUSH_ONBOARDING_DONE: ({ commit }, payload) => {
      commit('pushOnBoardingDone', payload)
    },
    set: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        try {
          console.log('payload.stripeSubscription: ', payload.stripeSubscription);
          commit('id', payload.id)
          commit('bio', payload.bio)
          commit('name', payload.name)
          commit('avatar', payload.avatar)
          commit('username', payload.username)
          commit('friendshipCount', payload.friendshipCount)
          commit('isValid', payload.isValid)
          commit('isActive', payload.isActive)
          commit('setOnBoardingDone', payload.onBoardingDone)
          commit('setStripeSubscription', payload.stripeSubscription)
          commit('setStripeSellerAccountId', payload.stripeSellerAccountId)
          commit('setStripeCurrency', payload.stripeCurrency)
          resolve()
        } catch (error) {
          reject(error)
        }                
      })
    }
  }
}