import { withLayout } from '@utils'
import HallLayout from '@layouts/Hall'
import CommunityLayout from '@layouts/Community'

const Index = () => import('@views/community/index')
const PostIndex = () => import('@views/post/index')
const Room = () => import('@views/community/room')
const Members = () => import('@views/community/members')
const List = () => import('@views/community/list')
const Settings = () => import('@views/community/settings')

export default {
  path: 'community',
  component: {
    render: h => h('router-view')
  },
  children: [
    {
      path: '',
      name: 'community.list',
      component: withLayout(List, HallLayout),
      meta: {
        app: 'community',
        locked: true
      }
    },
    {
      path: ':id',
      name: 'community.index',
      component: withLayout(Index, CommunityLayout),
      meta: {
        app: 'community',
        locked: true
      }
    },
    {
      path: ':id/members',
      name: 'community.members',
      component: withLayout(Members, CommunityLayout),
      meta: {
        app: 'community',
        locked: true
      }
    },
    {
      path: ':id/settings',
      name: 'community.settings',
      component: withLayout(Settings, CommunityLayout),
      meta: {
        app: 'community',
        locked: true
      }
    },
    {
      path: ':id/room/:roomId',
      name: 'community.room',
      component: withLayout(Room, CommunityLayout),
      meta: {
        app: 'community',
        locked: true
      }
    },
    {
      path: ':id/post/:postId',
      name: 'community.post',
      component: withLayout(PostIndex, CommunityLayout),
      meta: {
        app: 'community',
        locked: true
      }
    }
  ]
};
