import API from '@api';

export default (userId) => {
  const apiRequest = API()
  return {
    getById (id) {
      return apiRequest.get(
        `community/${userId}`,
        { params: { id } }
      )
    },
    create (body) {
      return apiRequest.post(
        `community/${userId}`,
        body
      )
    },
    update (body) {
      return apiRequest.put(
        `community/${userId}`,
        body
      )
    },
    listCommunityTags (communityId) {
      return apiRequest.get(
        `community/tags/${userId}`,
        { params: { community: communityId } }
      )
    }
  }
}
