<template>
  <v-hover v-slot:default="{ hover }">
    <v-list-item-avatar
      size="80"
      rounded="xl"
      style="border: thin solid rgba(0, 0, 0, 0.12); cursor: grab;"
      :color="$vuetify.theme.dark ? 'grey darken-2' : 'grey lighten-3'"
      @click="setThumbnailInput"
    >
      <input
        ref="thumbnailInput"
        type="file"
        accept=".png,.jpg,.jpeg,.gif,.svg"
        @change="addThumbnail"
      />
      <ImageCropper
        stencilComponent="RectangleStencil"
        :imageFile="imageFile"
        :mimeType="mimeType"
        :cropperOn="cropperOn"
        @closeDialog="cropperOn = false"
        @save="saveThumbnail"
      />

      <v-img :src="thumbnailObj.mediaSrc">
        <HoverLayer v-if="!thumbnailObj.mediaSrc || hover">
          <v-row
            justify="center"
            align="center"
          >
            <v-icon
              dark
              large
              style="opacity: 0.6"
            >
              {{ mdiCamera }}
            </v-icon>
          </v-row>
        </HoverLayer>
      </v-img>
    </v-list-item-avatar>
  </v-hover>
</template>

<script>
import { getImageObj } from '@utils'
import { mdiCamera } from '@mdi/js'
import { HoverLayer } from '@components'
import ImageCropper from '@components/Image/Cropper'
import { MediaURL } from '@components'

export default {
  components: {
    HoverLayer,
    ImageCropper
  },

  props: {
    imgSrc: {
      type: String,
      default: ''
    }
  },

  created() {
    this.getMimeType = MediaURL.getMimeType.bind(this);
  },

  mounted() {
    this.thumbnailObj = getImageObj(this.imgSrc)
  },

  data() {
    return {
      mdiCamera,
      
      mimeType: '',
      imageFile: '',
      cropperOn: false,

      thumbnailObj: {
        mediaSrc: '',
        filename: '',
        type: ''
      }
    }
  },

  watch: {
    imgSrc() {
      this.thumbnailObj = getImageObj(this.imgSrc)
    }
  },

  methods: {
    setThumbnailInput() {
      this.$refs.thumbnailInput.click()
    },

    async addThumbnail(e) {
      const [imageFile] = e.target.files;
      const mimeType = await this.getMimeType(imageFile);

      if (mimeType === 'image/gif') {

        this.thumbnailObj.mediaSrc = URL.createObjectURL(imageFile);
        this.thumbnailObj.filename = imageFile.name
        this.thumbnailObj.type = 'image/gif'

      } else {
        
        this.mimeType = mimeType;
        this.imageFile = imageFile;
        this.cropperOn = true;

      }
    },

    saveThumbnail(imageFile) {

      this.cropperOn = false

      this.thumbnailObj.mediaSrc = URL.createObjectURL(imageFile)
      this.thumbnailObj.filename = imageFile.name
      this.thumbnailObj.type = imageFile.type

      this.$emit('setThumbnail', this.thumbnailObj)

    }
  }
}
</script>
