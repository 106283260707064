import API from '@api';

export default (userId) => {
  const apiRequest = API()
  return {
    list(params) {
      return apiRequest.get(
        `/profiles/${userId}`,
        { params }
      )
    },

    listByPendingFriendship (profileId) {
      return apiRequest.get(
        `/profiles/pending-friendship/${userId}`,
        {
          params: { profileId }
        }
      )
    },
    listForMentions (profileId, username) {
      return apiRequest.get(
        `/profile/mentions/${userId}`,
        {
          params: { profileId, username }
        }
      )
    }
  }
}
