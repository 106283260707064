<template>
  <v-container class="pt-0">
    <v-fade-transition hide-on-leave>
      <div v-if="!audio && loadedOn">
        <v-row justify="center">
          <v-col
            xl="6"
            lg="6"
            class="pl-0 py-0"
          >
            <input 
              type="file"
              id="audioUpload"
              accept="audio/mp3,audio/wav,audio/mpeg,audio/ogg"
              @change="addDropAudio"
            />
            <div>
              <label for="audioUpload">
                <v-card
                  outlined
                  height="114"
                  :ripple="false"
                  :color="uploadColor"
                  style="cursor: pointer;"
                  @mouseenter="mouseOver = true"
                  @mouseleave="mouseOver = false"
                  @drop.prevent="addDropAudio"
                  @dragover.prevent
                  @dragenter="dragging = true"
                  @dragleave="leaveTarget"
                >
                  <v-container fill-height>
                    <v-row 
                      justify="center"
                      align="center"
                    >
                      <v-icon x-large>
                        {{ mdiCloudUpload }}
                      </v-icon>
                    </v-row>

                    <v-row
                      justify="center"
                      align="center"
                    >
                      <span
                        v-text="'Enviar arquivo de áudio'"
                        class="text-h6 grey--text text--darken-1 font-weight-regular"
                      ></span>
                    </v-row>
                  </v-container>
                </v-card>
              </label>
            </div> 
          </v-col>

          <v-col
            xl="6"
            lg="6"
            class="pr-0 py-0"
          >
            <AudioRecorder @audioURL="setAudioURL" />
          </v-col>
        </v-row>
      </div>
    </v-fade-transition>

    <v-fade-transition hide-on-leave>
      <div v-if="audio">
        <v-row justify="center">
          <v-col
            xl="12"
            lg="12"
            class="px-0 py-0"
          >
            <AudioPlayer
              :src="audio"
              :isAuthor="true"
              @clear="clearAudio"
            />
          </v-col>
        </v-row>
      </div>
    </v-fade-transition>
  </v-container>
</template>

<script>
import AudioRecorder from './AudioRecorder'
import AudioPlayer from './AudioPlayer'
import { mdiCloudUpload } from '@mdi/js'

export default {
  components: {
    AudioRecorder,
    AudioPlayer
  },

  props: {
    src: {
      type: String,
      required: true
    }
  },

  mounted() {
    
    if (this.src) this.audio = this.src;

    setTimeout(() => {
      this.loadedOn = true
    })

  },

  data() {
    return {
      mdiCloudUpload,
      
      loadedOn: false,
      dragging: false,
      mouseOver: false,
      audio: ''
    }
  },

  computed: {
    uploadColor() {
      return this.mouseOver || this.dragging
        ? this.$vuetify.theme.dark
        ? 'grey darken-4'
        : 'grey lighten-4'
        : ''
    }
  },

  methods: {
    addDropAudio (e) {

      this.dragging = false

      const audioFile = e.type === 'change'
        ? e.target.files[0]
        : e.dataTransfer.files[0]

      const [type]  = audioFile.type.split('/')

      if (type === 'audio') {

        this.audio = URL.createObjectURL(audioFile)
        this.$emit('content', this.audio)

      } else {

        this.$emit('notAudio', 'NOT_AUDIO')

      }

    },

    leaveTarget (e) { 
      if (e.target.className === 'container fill-height')
        this.dragging = false
    },

    clearAudio () {
      this.audio = ''
    },

    setAudioURL (url) {
      this.audio = url
      this.$emit('content', url)
    }
    
  }
}
</script>