var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"center"}},[_c('SearchFriends',{on:{"search":_vm.searchProfilesToAdd,"clear":_vm.onClearSearch}}),_c('InvitationButton',{attrs:{"showInvites":_vm.contentToShow === 'invites',"toggleShowInvites":_vm.onInviteClick}})],1),(_vm.profilesToAdd.length && _vm.contentToShow === 'profilesToAdd')?_c('Scrollbar',{style:("height: 50vh;"),attrs:{"thin":true,"autoHide":"leave"}},[_c('v-container',[_c('v-row',_vm._l((_vm.profilesToAdd),function(person,idx){return _c('ProfileCard',{key:("profile_" + idx),staticClass:"mb-4",staticStyle:{"width":"100%"},attrs:{"idx":idx,"person":person},on:{"snack":_vm.setSnack,"newSenderRequest":_vm.addSenderRequest,"updateFriendship":_vm.updateFriendship}})}),1)],1)],1):_vm._e(),(_vm.contentToShow === 'friends')?_c('div',[(_vm.friends.length === 0)?_c('v-alert',{staticClass:"hs-rounded-lg",attrs:{"text":"","outlined":"","prominent":"","color":_vm.$vuetify.theme.dark ? 'tertiary' : 'primary'}},[_c('v-row',[_c('v-col',{staticClass:"grow",domProps:{"innerHTML":_vm._s(_vm.$t('find_friends'))}})],1)],1):_vm._e(),_c('ScrollPagination',{attrs:{"requestData":{
        'url': ("profile/friends-sort-by-message/" + (_vm.user.id)),
        'params': {
          profile: _vm.myProfileId,
          select: _vm.selectProfiles 
        }
      },"countData":{
        'url': ("profile/friends/" + (_vm.user.id) + "/count"),
        'params': {
          profile: _vm.myProfileId,
        }
      },"rootHeight":"50vh","setFatherItems":_vm.setFriends},scopedSlots:_vm._u([{key:"child",fn:function(props){return [_c('OnlineCard',{key:("profile_" + (props.index)),staticClass:"mb-4",style:(_vm.selectedCardStyle(props.item.id)),attrs:{"idx":props.index,"person":props.item},on:{"snack":_vm.setSnack,"setPerson":_vm.setFriendshipRoom}})]}}],null,false,3562177773)})],1):_vm._e(),(_vm.contentToShow === 'invites')?_c('div',[_vm._l((_vm.friendshipsPending),function(person,idx){return _c('ProfileCard',{key:("profile_" + idx),staticClass:"mb-4",style:(("width: " + _vm.cardWidth + "em;")),attrs:{"idx":idx,"person":person},on:{"snack":_vm.setSnack,"updateFriendship":_vm.updateFriendship}})}),(_vm.friendshipsPending.length === 0)?_c('v-alert',{staticClass:"hs-rounded-lg",attrs:{"text":"","outlined":"","prominent":"","color":_vm.$vuetify.theme.dark ? 'tertiary' : 'primary'}},[_c('v-row',[_c('v-col',{staticClass:"grow"},[_vm._v(" No friendship requests for now. Invite your friends to HiSolver! ")])],1)],1):_vm._e()],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }