<template>
  <v-container>
    <v-divider v-if="index != 0 && showDivider" />

    <v-list-item>
      <v-list-item-avatar size="40">
        <v-img :src="getResourceUrl(note.sender.avatar)" />
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title v-text="note.sender.name" />
        <!-- <v-list-item-subtitle v-text="createdAt" /> -->

        <v-list-item-subtitle>
          Searching for AI communities
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-icon>
        <v-container class="py-3">
          <v-row align="center">
            <span class="live-indicator"></span>
          </v-row>
        </v-container>
      </v-list-item-icon>
    </v-list-item>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { AudioPlayer } from '@components/Audio'
import API from '@api'
import {
  latexOptions,
  formatDate,
  truncateStr,
  getResourceUrl
} from '@utils'

import {
  mdiStarFourPointsOutline,
  mdiStarFourPoints
} from '@mdi/js'

export default {
  components: {
    AudioPlayer
  },

  props: {
    noteId: {
      type: String,
      default: ''
    },
    note: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      default: 0
    },
    showDivider: {
      type: Boolean,
      default: false
    }
  },
  
  data() {
    return {
      latexOptions,
      mdiStarFourPointsOutline,
      mdiStarFourPoints,

      audioSrc: '',
      starContent: false,
      readMore: false,
    }
  },

  computed: {
    ...mapGetters({
      user: 'user',
      profile: 'profile/id'
    }),

    createdAt() {
      return formatDate(this.note.createdAt, this.$i18n.locale)
    }
  },

  watch: {
    noteId() {
      this.setAudio()
    }
  },

  methods: {
    getResourceUrl,

    truncate(str = '', num = 200) {
      return truncateStr(str, num)
    },

    setAudio() {
      return

      this.audioSrc = ''
      if (this.note && this.note.mediaContent) {
        const [audio] = this.note.mediaContent
          ? this.note.mediaContent.audios
          : []

        if (audio) {
          this.audioSrc = audio.url
        }
      }
    },

    async doStarContent() {
      // await API().put(`engagement/${this.user.id}`, {
      //   starContent: !this.starContent
      // },
      // {
      //   params: {
      //     query: {
      //       annotation: {
      //         $eq: this.note.id
      //       },
      //       profile: {
      //         $eq: this.profile
      //       }
      //     },
      //     options: {
      //       upsert: true
      //     }
      //   }
      // })

      // this.starContent = !this.starContent
    },

    async setEngagement() {
      return
      const [engagement] = await API().get(`engagement/${this.user.id}`, {
        params: {
          annotation: this.note.id,
          profile: this.profile
        }
      })

      if (engagement) {
        this.starContent = engagement.starContent
      }
    }
  }
}

</script>

<style scoped>
.status-indicator {
  top: 10px;
  right: 10px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.offline {
  background-color: #ccc;  
}

.theme--light .live-indicator {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: #02ae02;
  box-shadow: 0 0 0 0 rgba(1, 186, 1, 0.7);
  animation: pulse 1.8s ease-in-out infinite;
}

.theme--dark .live-indicator {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: #00ff00;
  box-shadow: 0 0 0 0 rgba(0,255,0,0.7);
  animation: pulse 1.8s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 255, 0, 0.487); 
  }
  33% {
    box-shadow: 0 0 0 6px rgba(0,255,0,0);
  }
  66% {
    box-shadow: 0 0 0 8px rgba(0,255,0,0);
  }
  100% {  
    box-shadow: 0 0 0 0 rgba(0,255,0,0);
  }
}
</style>