<template>
  <div>
    <div class="d-flex" style="align-items: center;">
      <SearchFriends
        @search="searchProfilesToAdd"
        @clear="onClearSearch"
      />
      <InvitationButton
        :showInvites="contentToShow === 'invites'"
        :toggleShowInvites="onInviteClick"
      />
    </div>

    <Scrollbar
      v-if="profilesToAdd.length && contentToShow === 'profilesToAdd'"
      :thin="true"
      autoHide="leave"
      :style="`height: 50vh;`"
    >
      <v-container>
        <v-row>
          <ProfileCard
            v-for="(person, idx) in profilesToAdd"
            :key="`profile_${idx}`"
            class="mb-4"
            style="width: 100%;"
            :idx="idx"
            :person="person"
            @snack="setSnack"
            @newSenderRequest="addSenderRequest"
            @updateFriendship="updateFriendship"
          />
        </v-row>
      </v-container>
    </Scrollbar>

    <div v-if="contentToShow === 'friends'">
      <v-alert
        v-if="friends.length === 0"
        text
        outlined
        prominent
        :color="$vuetify.theme.dark ? 'tertiary' : 'primary'"
        class="hs-rounded-lg"
      >
        <v-row>
          <v-col
            class="grow"
            v-html="$t('find_friends')"
          ></v-col>
        </v-row>
      </v-alert>
      <ScrollPagination
        :requestData="{
          'url': `profile/friends-sort-by-message/${user.id}`,
          'params': {
            profile: myProfileId,
            select: selectProfiles 
          }
        }"
        :countData="{
          'url': `profile/friends/${user.id}/count`,
          'params': {
            profile: myProfileId,
          }
        }"
        :rootHeight="`50vh`"
        :setFatherItems="setFriends"
      >
        <template v-slot:child="props">
          <OnlineCard
            :key="`profile_${props.index}`"
            :idx="props.index"
            :person="props.item"
            class="mb-4"
            :style="selectedCardStyle(props.item.id)"
            @snack="setSnack"
            @setPerson="setFriendshipRoom"
          />
        </template>
      </ScrollPagination>
    </div>

    <div v-if="contentToShow === 'invites'">
      <ProfileCard
        v-for="(person, idx) in friendshipsPending"  
        :key="`profile_${idx}`"
        class="mb-4"
        :style="`width: ${cardWidth}em;`"
        :idx="idx"
        :person="person"
        @snack="setSnack"
        @updateFriendship="updateFriendship"
      />
      <v-alert
        v-if="friendshipsPending.length === 0"
        text
        outlined
        prominent
        :color="$vuetify.theme.dark ? 'tertiary' : 'primary'"
        class="hs-rounded-lg"
      >
        <v-row>
          <v-col class="grow">
            No friendship requests for now. Invite your friends to HiSolver!
          </v-col>
        </v-row>
      </v-alert>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import SearchFriends from '@components/Chat/Search.vue';
import InvitationButton from '@components/Chat/InvitationButton.vue';
import ScrollPagination from '@components/App/ScrollPagination.vue';
import OnlineCard from '@components/Profiles/OnlineCard.vue';
import API from '@api'
import RoomService from '@api/room'
import ProfileService from '@api/profile'
import { mongoSelect } from '@utils'
import ProfileCard from '@components/Profiles/Card.vue'
import { Scrollbar } from '@components/App'

  export default {
    components: {
      SearchFriends,
      InvitationButton,
      ScrollPagination,
      OnlineCard,
      ProfileCard,
      Scrollbar
    },

    data: () => ({
      roomService: null,
      profileService: null,
      contentToShow: 'friends',
      selectProfiles: mongoSelect.profiles,
      friendshipsPending: [],
      profilesToAdd: [],
      friends: [],
    }),

    created() {
      this.roomService = RoomService(this.user.id);
      this.profileService = ProfileService(this.user.id);
    },

    watch: {
      async contentToShow(value) {
        if (value === 'invites' && !this.friendshipsPending.length) {
          this.friendshipsPending = await this.profileService.listByPendingFriendship(this.myProfileId)
        }
      },
      profilesToAdd(value) {
        if (!value.length) {
          return this.contentToShow = 'friends';
        }
        this.contentToShow = 'profilesToAdd';
      }
    },

    computed: {
      ...mapGetters({
        user: 'user',
        myProfileId: 'profile/id',
      })
    },

    methods: {
      ...mapMutations({
        SET_CURRENT_ROOM: 'rooms/setCurrentRoom',
      }),
      onClearSearch() {
        this.profilesToAdd = [];
      },
      onInviteClick() {
        if (this.contentToShow === 'invites') {
          return this.showFriends();
        }
        this.contentToShow = 'invites';
      },
      showFriends() {
        this.contentToShow = 'friends';
      },
      async setFriendshipRoom(friend) {
        const room = await this.roomService.getFriendshipRoom(this.myProfileId, friend.id)
        this.SET_CURRENT_ROOM(room)
        this.$router.push(`/rooms/conversation?id=${room.conversation._id}&selectedProfileId=${friend.id}`)
      },

      async searchProfilesToAdd(value) {
        if (!value) return this.profilesToAdd = [];
        this.profilesToAdd = await API().get(`profiles/${this.user.id}`, {
          params: {
            searchQuery: value,
            query: {
              profile: this.myProfileId, // use this to return the friendship status of the profiles
              isValid: true,
              isActive: true,
              _id: {
                $ne: this.myProfileId, 
              },
              username: {
                $ne: 'anna'
              }
            },
            select: mongoSelect.profiles
          }
        })
      },
    
      addSenderRequest(friendship) {
        const idx = this.profilesToAdd.map(person => person.id).indexOf(friendship.recipient)
        if (idx != -1) this.profilesToAdd[idx].friendship = friendship
      },

      addFriendship(type, profile, friendship) {
        if (type === 'requestAccepted') {
          const idx = this.profilesToAdd.map(person => person.id).indexOf(profile.id)
          if (idx != -1) pullAt(this.profilesToAdd, idx);

          this.friends.unshift({
            ...profile,
            friendship
          })
        } else {
          this.friendshipsPending.unshift({
            ...profile,
            friendship
          })
        }
      },

      updateFriendship({data, idx}) {
        this.showFriends();
      },

      setFriends(friends) {
        this.friends = friends
      },
      selectedCardStyle(profileId) {
        if (this.$route.query.selectedProfileId !== profileId) return '';
        return `background-color: ${
          this.$vuetify.theme.dark ?
          'rgba(255, 255, 255, 0.1)' :
          'rgba(0, 0, 0, 0.1)'
        }`
      }
    }
  }
</script>