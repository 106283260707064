<template>
  <v-fade-transition hide-on-leave>
    <v-container v-if="!loading">
      <div class="text-overline">Question Difficulty</div>
      
      <v-container class="pt-8 pb-0">
        <v-slider
          v-model="complexity"
          :thumb-size="24"
          :disabled="!currentPersona.id && !enable"
          max="5"
          min="1"
          step="1"
          thumb-label="always"
          @change="save"
        ></v-slider>
      </v-container>

      <div class="text-overline">Topics</div>

      <v-textarea
        v-model="topics"
        :disabled="!currentPersona.id && !enable"
        rows="3"
        :background-color="$vuetify.theme.dark ? textBackground : 'grey lighten-4'"
        filled
        hide-details
        placeholder="What topics would you like to talk about?"
        class="hs-rounded-text"
        @change="save"
        data-cy="persona-topics-input"
      />

      <div class="my-4"></div>

      <div class="text-overline">Visual Hints</div>

      <v-switch
        v-model="visualHints"
        :disabled="!currentPersona.id && !enable"
        inset
        class="mx-1"
        :label="`Visual hints ${visualHints ? 'activated' : 'deactivated'}`"
        @change="save"
      ></v-switch>
    </v-container>
  </v-fade-transition>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import API from '@api'

export default {
  props: {
    enable: {
      type: Boolean,
      default: false
    },
    defaultParams: {
      type: Object,
      default: () => ({})
    },

    textBackground: {
      type: String,
      default: '#1a1a1a'
    }
  },

  mounted() {
    this.loading = true
    setTimeout(() => {
      this.loading = false
    }, 30)
    if (Object.keys(this.defaultParams).length > 0) {
      this.complexity = this.defaultParams.complexity ? this.defaultParams.complexity : 1
      this.topics = this.defaultParams.topics ? this.defaultParams.topics : ''
      this.visualHints = typeof this.defaultParams.visualHints === 'boolean' ? this.defaultParams.visualHints : true
    } else if (this.toolsIndex > -1) {
      this.setParams(this.toolsIndex)
    }
  },

  data: () => ({
    loading: false,
    complexity: 1,
    topics: '',
    visualHints: true
  }),

  computed: {
    ...mapGetters({
      user: 'user',
      myProfileId: 'profile/id',
      currentPersona: 'currentPersona',
      tools: 'persona/tools',
      personaConnector: 'persona/connector'
    }),

    toolsIndex() {
      return this.tools ? this.tools.map(({ type }) => type).indexOf('question_gen') : -1
    }
  },

  watch: {
    toolsIndex(index) {
      if (index > -1) {
        this.setParams(index)
      } else {
        this.complexity = 1
        this.topics = '',
        this.visualHints = true
      }
    }
  },

  methods: {
    ...mapMutations({
      SET_TOOLS: 'persona/SET_TOOLS'
    }),

    setParams(index) {
      const { params } = this.tools[index]

      if (params) {
        if (params.complexity) this.complexity = params.complexity
        if (params.topics) this.topics = params.topics
        if (params.visualHints) this.visualHints = params.visualHints
      }
    },

    save() {
      /*
      * TODO: a user without permission shouldn't change the
      * default settings of the persona, but rather of a conneector
      * component that tracks their interactions with the persona.
      */

      // Used in the Persona Creation page
      this.$emit('setParams', {
        complexity: this.complexity,
        topics: this.topics,
        visualHints: this.visualHints
      })

      if (this.personaConnector && this.personaConnector.id) {
        // Sets the params globally (in the Vuex store)
        const newTools = this.tools.map((tool) => {
          if (tool.type === 'question_gen') {
            tool.params = {
              complexity: this.complexity,
              topics: this.topics,
              visualHints: this.visualHints
            }
          }
          
          return tool
        })
        this.SET_TOOLS(newTools)

        API().put('connector', {
          tools: newTools
        }, {
          params: {
            id: this.personaConnector.id
          }
        })
      }
    }
  }
}
</script>