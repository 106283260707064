export default (context) => {
  const blocksEn = [
    {
      id: "0qQOUzqUfv",
      type: "header",
      data: {
        text: `Welcome ${context.myName.split(' ').shift()}!!`,
        level: 2
      }
    },
    {
      id: "CCmhq3MQaD",
      type: "paragraph",
      data: {
        text: "🚀 <b>Welcome to HiSolver</b>, your personalized collaborative learning space! I'm Anna, your AI companion."
      }
    },
    {
      id: "xTlml04fWv",
      type: "paragraph",
      data: {
        text: "💡 Central to your experience is the <b>notebook</b> in front of you. It's your idea workspace and, guess what, I can assist you right here. I can even craft you some animations to illustrate difficult concepts!"
      }
    },
    {
      id: "hZAjSnqYMX",
      type: "image",
      data: {
        url: "https://miro.medium.com/v2/resize:fit:1200/1*vgDjNywVZPGN2byXhnanLA.gif",
        withBorder: false,
        withBackground: false,
        stretched: true,
        caption: 'Math animations and much more!'
      },
    },
    {
      id: "zbeXAtbW4h",
      type: "paragraph",
      data: {
        text: "📁 <b>See that upload button on the right?</b> That's your key to deep insights. Upload files, ask them questions, and watch as I unearth the answers for you."
      }
    },
    {
      id: "Q7ElGlk4xZ",
      type: "paragraph",
      data: {
        text: "🗨️ <b>Over on the left is our chat corner.</b> Need a quick answer or a nifty fact? Just type in your query, and I'll respond!"
      }
    },
    {
      id: "LNzCvSPSHB",
      type: "paragraph",
      data: {
        text: "🧭 Notice the <b>compass rose symbol</b>? Click on it anytime you need my help. It's your instant link to me."
      }
    },
    {
      id: "xQULpB_nv8",
      type: "paragraph",
      data: {
        text: "🌐 Ready for the social side of learning? Check out <b>'Rooms and Halls'</b> at the top right corner. 'Rooms' connect you with peers for shared learning. 'Halls' offer a platform for you to create and learn from others' creations."
      }
    },
    {
      id: "sPrhK1PTVc",
      type: "paragraph",
      data: {
        text: "HiSolver is all about reimagining how you learn. Let's make it fun, connected, and immersive. Let's make it HiSolver. 🌟 Welcome aboard!"
      }
    }
  ]

  const blocksBr = [
    {
      "id": "0qQOUzqUfv",
      "type": "header",
      "data": {
        "text": `Boooas vindas, ${context.myName.split(' ').shift()}!!`,
        "level": 2
      }
    },
    {
      "id": "CCmhq3MQaD",
      "type": "paragraph",
      "data": {
        "text": "Bem-vindo à <b>HiSolver</b>, seu espaço personalizado de aprendizado colaborativo! Eu sou a Anna, sua companheira de IA."
      }
    },
    {
      "id": "xTlml04fWv",
      "type": "paragraph",
      "data": {
        "text": "💡 No centro da sua experiência está o <b>caderno</b> à sua frente. É o seu espaço de ideias e, adivinhe, eu posso ajudar você bem aqui. Posso até criar algumas animações para ilustrar conceitos difíceis!"
      }
    },
    {
      "id": "hZAjSnqYMX",
      "type": "image",
      "data": {
        "url": "https://miro.medium.com/v2/resize:fit:1200/1*vgDjNywVZPGN2byXhnanLA.gif",
        "withBorder": false,
        "withBackground": false,
        "stretched": true,
        "caption": "Animações matemáticas e muito mais!"
      }
    },
    {
      "id": "zbeXAtbW4h",
      "type": "paragraph",
      "data": {
        "text": "📁 <b>Veja aquele botão de upload à direita?</b> Esse é a chave para insights profundos. Envie arquivos, faça perguntas sobre eles e observe enquanto eu descubro as respostas para você."
      }
    },
    {
      "id": "Q7ElGlk4xZ",
      "type": "paragraph",
      "data": {
        "text": "🗨️ <b>À esquerda está o nosso canto de chat.</b> Precisa de uma resposta rápida ou de um fato interessante? Basta digitar sua pergunta, e eu vou responder!"
      }
    },
    {
      "id": "LNzCvSPSHB",
      "type": "paragraph",
      "data": {
        "text": "🧭 Percebe o <b>símbolo da rosa dos ventos</b>? Clique nele sempre que precisar da minha ajuda. É o seu link instantâneo para mim."
      }
    },
    {
      "id": "xQULpB_nv8",
      "type": "paragraph",
      "data": {
        "text": "🌐 Pronto(a) para o lado social do aprendizado? Confira <b>'Salas e Salões'</b> no canto superior direito. 'Salas' conectam você com colegas para aprendizado compartilhado. 'Salões' oferecem uma plataforma para você criar e aprender com as criações de outros."
      }
    },
    {
      "id": "sPrhK1PTVc",
      "type": "paragraph",
      "data": {
        "text": "HiSolver é tudo sobre reimaginar como você aprende. Vamos torná-lo divertido, conectado e imersivo. Vamos torná-lo HiSolver. 🌟 Bem-vindo(a) a bordo!"
      }
    }
  ]

  return context.locale === 'en' ? blocksEn : blocksBr
}