<template>
  <v-dialog
    v-model="invite"
    max-width="800"
    scrollable
    @click:outside="$emit('close')"
  >
    <v-card rounded="xl">
      <v-container class="py-0">
        <v-row justify="center">
          <v-col>
            <v-container class="pt-0">
              <v-row justify="center">
                <v-col cols="10">
                  <v-text-field
                    v-model="search"
                    outlined
                    dense
                    clearable
                    class="hs-rounded-lg"
                    hide-details
                    :append-icon="$data[appendIcon]"
                    :label="$t('searchFriends')"
                    @input="setTyping"
                  />
                </v-col>
              </v-row>
            </v-container>

            <v-divider />

            <Scrollbar :style="scrollStyle">
              <v-card-text>
                <v-subheader>
                  {{ myFriendsTxtCapitalize }}
                </v-subheader>
                
                <v-list rounded two-line>
                  <v-list-item-group
                    multiple
                    v-model="inviteeSearch"
                    active-class="primary--text text-darken-1"
                  >
                    <div
                      v-for="(person, idx) in friendSearch"
                      :key="`friend_item_${idx}`"
                      @mouseenter="hover = idx"
                      @mouseleave="hover = null"
                    >
                      <v-divider
                        v-if="idx != 0"
                        inset
                        :style="inviteeSearch.indexOf(idx) === -1
                          && inviteeSearch.indexOf(idx - 1) === -1
                          && hover != idx && hover != idx - 1
                          ? '' : 'border-color: transparent'"
                      />

                      <v-list-item>
                        <template v-slot:default="{ active }">
                          <ProfileAvatar
                            :avatar="person.avatar"
                            :large="true"
                          />

                          <v-list-item-content>
                            <v-list-item-title>
                              {{ person.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ person.bio }}
                            </v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-checkbox
                              :input-value="active"
                              color="primary"
                              :on-icon="mdiMinusBox"
                            />
                          </v-list-item-action>
                        </template>
                      </v-list-item>
                    </div>
                  </v-list-item-group>
                </v-list>
              </v-card-text>
            </Scrollbar>
          </v-col>
        </v-row>
      </v-container>

      <v-divider />

      <v-card-actions>
        <v-container class="py-1">
          <v-row justify="center">
            <v-btn
              large
              elevation="0"
              style="border-radius: 12px"
              :color="$vuetify.theme.dark ? 'grey darken-3' : ''"
              :disabled="loading"
              @click="cancel"
            >
              {{ $t('cancel') }}
              <v-icon right>
                {{ mdiClose }}
              </v-icon>
            </v-btn>

            <v-spacer />

            <v-btn
              style="border-radius: 12px"
              large
              elevation="0"
              color="primary"
              :loading="loading"
              :disabled="invitees.length === 0"
              @click="submit"
            >
              {{ $t('send_invitations') }}
              <v-icon right>
                {{ mdiSend }}
              </v-icon>
            </v-btn>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ProfileItem from './ProfileItem'
import ProfileAvatar from './ProfileAvatar'

import { Scrollbar } from '@components/App'
import { mapGetters } from 'vuex'
import API from '@api'

import {
  findIndex,
  difference,
  capitalize,
  find
} from 'lodash'

import {
  mdiDelete,
  mdiClose,
  mdiSend,
  mdiMinusBox,
  mdiMagnify
} from '@mdi/js'

export default {
  components: {
    Scrollbar,
    ProfileItem,
    ProfileAvatar
  },

  props: {
    inviteOn: {
      type: Boolean,
      required: true
    },
    members: {
      type: Array,
      required: true
    },
    community: {
      type: Object,
      required: true
    }
  },

  created() {
    this.loadProfiles()
  },

  data() {
    return {
      mdiDelete,
      mdiClose,
      mdiSend,
      mdiMinusBox,
      mdiMagnify,

      hover: null,

      loaded: false,

      loading: false,
      invite: false,
      search: '',
      isTyping: false,
      friends: [],
      friendSearch: [],
      inviteeSearch: [],
      invitees: [],
      options: {
        shouldSort: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: ['name']
      }
    }
  },

  watch: {
    inviteOn(value) {
      if (value && !this.friendSearch.length) {
        this.loadProfiles()
        this.loaded = true
      }
    },

    inviteOn(v) {
      this.invite = v
    },

    inviteeSearch(newArr, oldArr) {
      if (!this.isTyping && !this.isSearching) {

        this.invitees = newArr
      
      } else if(!this.isTyping && this.isSearching) {
        
        if (newArr.length > oldArr.length) {

          const [diffIdx] = difference(newArr, oldArr)
          const {id} = this.friendSearch[diffIdx]
          const friendIdx = findIndex(this.friends, friend => friend.id === id)
          this.invitees.push(friendIdx)
        
        } else {
        
          const [diffIdx] = difference(oldArr, newArr)
          const {id} = this.friendSearch[diffIdx]
          const friendIdx = findIndex(this.friends, friend => friend.id === id)
          this.invitees.splice(this.invitees.indexOf(friendIdx), 1)
        
        }
      }
    },

    search(name) {
      if (this.isSearching) {

        this.$search(name, this.friends, this.options).then(friends => {
          this.friendSearch = friends
          const friendsIds = friends.map(({id}) => id)
          const inviteesProfilesIds = this.inviteesProfiles.map(({id}) => id)
          const friendSearchInvited = friendsIds.filter(id => inviteesProfilesIds.indexOf(id) != -1)
          this.inviteeSearch = friendSearchInvited.map(id => friendsIds.indexOf(id))
        })
      
      } else {
        
        this.isTyping = true
        this.friendSearch = this.friends
        this.inviteeSearch = this.invitees
        this.isTyping = false
      
      }
    }
  },

  computed: {
    ...mapGetters({
      user: 'user',
      profile: 'profile/id',
      name: 'profile/name',
      avatar: 'profile/avatar'
    }),

    myFriendsTxtCapitalize() {
      return capitalize(this.$t('people.myFriends'))
    },

    inviteesTxtCapitalize() {
      return capitalize(this.$t('groups.invitees'))
    },

    isSearching() {
      return !!this.search && this.search.length > 1
    },

    inviteesProfiles() {
      return this.friends.filter((friend, idx) => this.invitees.indexOf(idx) != -1)
    },

    cleanBtnColor() {
      return this.invitees.length > 0
        ? 'red darken-4'
        : ''
    },

    appendIcon() {
      return !this.search
        ? 'mdiMagnify'
        : ''
    },

    sheetColor() {
      return this.$vuetify.theme.dark
        ? 'grey darken-4'
        : 'grey lighten-5'
    },

    scrollStyle() {
     return this.$vuetify.breakpoint.xl
      ? 'height: 500px;'
      : 'height: 400px;'
    }
  },

  methods: {
    async loadProfiles() {
      const friends = await API().get(`/profile/friends/${this.user.id}`, {
        params: {
          profile: this.profile
        }
      })

      const memberIds = this.members.map(({profile}) => profile)
      this.friends = friends.filter(({id}) => memberIds.indexOf(id) === -1) 
      this.friendSearch = this.friends
    },
    
    cancel() {
      this.cleanInvitees()
      this.$emit('close')
    },

    setTyping() {
      if (!this.isTyping) {
        setTimeout(() => {
          this.isTyping = false
        }, 1000)
      }

      this.isTyping = true
    },

    isObj(item) {
      return typeof item === 'object'
    },

    cleanInvitees() {
      this.inviteeSearch = []
      this.invitees = []
    },

    remove(id) {
      if(this.isSearching) {
        let idx = findIndex(this.friendSearch, (friend) => friend.id === id)

        if (idx != -1)
          this.inviteeSearch = this.inviteeSearch.filter(invitee => invitee != idx)

        else {
          idx = findIndex(this.friends, (friend) => friend.id === id)
          this.invitees = this.invitees.filter(invitee => invitee != idx)
        }
        
      } else {
        const idx = findIndex(this.friendSearch, (friend) => friend.id === id)
        this.inviteeSearch = this.inviteeSearch.filter(invitee => invitee != idx)
      }
    },

    async sendInvitations(members) {
      for (let invitee of this.invitees) {
        const profile = this.friends[invitee].id
        const invitation = {
          to: `/community/${this.community.id}`,
          thumbnail: {
            profile: this.profile,
            url: this.avatar
          },
          type: 'communityInvitation',
          names: [this.name, this.community.name],
          profile,
        }
        const member = find(members, member => member.profile === profile);
        const notificationData = await API().post('notification', invitation);

        this.$socket.notification.emit('communityInvitation', {
          invitation: {
            ...notificationData
          },
          member: member,
          community: this.community.id
        })
      }
    },

    async submit() {
      this.loading = true

      const invitations = []
      this.invitees.forEach(invitee => {
        invitations.push({
          role: 'member',
          status: 'invited',
          profile: this.friends[invitee].id,
          community: this.community.id
        })
      })

      try {
        this.search = ''
        const members = await API().post(`member/community/invite/${this.user.id}`, invitations)
        this.sendInvitations(members)

        if (this.invitees.length > 1)
          this.$emit('snack', 'invitationsSuccess')
        else if (this.invitees.length === 1)
          this.$emit('snack', 'invitationSuccess')

        const newInvitees = this.inviteesProfiles.map(profile => {
          const member = find(invitations, inv => inv.profile === profile.id)
          return { ...profile, member }
        })

        this.$emit('newInvitees', newInvitees)

        this.friendSearch = this.friends
        this.inviteeSearch = this.invitees
        this.friendSearch = this.friendSearch.filter((p,idx) => this.inviteeSearch.indexOf(idx) === -1)

      } catch (e) {
        this.$emit('snack', 'errServer')

      } finally {
        this.inviteeSearch = []
        this.invitees = []
        this.search = ''
        this.typing = false
        this.loading = false
        this.$emit('close')
      }
    }
  }
}
</script>

<style scoped>
/* Deprecated in Vue 3 */
::v-deep .v-dialog {
  border-radius: 24px !important;
}
</style>