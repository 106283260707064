<template>
  <v-dialog
    v-model="open"
    max-width="500"
    @click:outside="_onClose"
  >
    <v-card rounded="xl">
      <v-container class="py-2 px-5">
        <v-row justify="center" align="center" class="py-4 px-4">
          <ImageUpload
            :onUpload="onUpload"
            :imagePlaceholderUrl="room.thumbnail"
          />
        </v-row>
        <v-row class="px-8">
          <v-text-field
            v-model="room.name"
            outlined
            dense
            clearable
            :class="`hs-rounded-lg ${$vuetify.theme.dark ? 'icon-style-dark' : ''}`"
            :label="$t('groupName')"
            @input="onSearch"
          />
        </v-row>

        <v-row class="px-8">
          <v-text-field
            v-model="search"
            outlined
            dense
            clearable
            class="hs-rounded-lg"
            :append-icon="searchIcon"
            :label="$t('searchFriends')"
            @input="onSearch"
            @keydown="debounceOnKeyDown"
            @click:clear="onClear"
          />
        </v-row>
        
        <v-container class="py-0">
          <Scrollbar
            :thin="true"
            :style="`height: 20vh; max-width: 100%; width: 100%;`"
          >
            <v-container>
              <v-row>
                <v-col
                  v-for="item in items"
                  cols="12"
                  xs="12"
                  sm="6"
                  class="d-flex"
                  :key="item.id"
                  @click="onSelect(item.id)"
                >
                  <ProfileAvatar
                    :avatar="item.avatar"
                    :large="true"
                  />

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.bio }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-checkbox
                      :input-value="isSelected(item.id)"
                      color="primary"
                      :on-icon="mdiMinusBox"
                    />
                  </v-list-item-action>
                </v-col>
              </v-row>
            </v-container>
          </Scrollbar>
        </v-container>
        
        <v-row class="px-5 py-5" justify="space-between">
          <v-btn
            color="error"
            class="hs-rounded-12"
            @click="_onClose"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            class="hs-rounded-12"
            :disabled="selectedFriendsId.length === 0"
            @click="submit"
          >
            {{ submitButtonLabel }}
          </v-btn>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
  import ImageUpload from '@components/Image/ImageUpload'
  import { mapGetters, mapMutations } from 'vuex'
  import ProfileAvatar from '@components/Hall/ProfileAvatar.vue'
  import ScrollPagination from '@components/App/ScrollPagination.vue'
  import {
    mdiMagnify
  } from '@mdi/js'
  import { debounce } from 'lodash'
  import MemberService from '@api/member'
  import RoomService from '@api/room'
  import API from '@api'
  import { Scrollbar } from '@components/App'

  export default {
    components: {
      ScrollPagination,
      ProfileAvatar,
      Scrollbar,
      ImageUpload,
    },

    watch: {
      groupRoomToHandle(value) {
        if (value) return this.handleInitialEditData(value)
        this.resetForm()
      },
    },

    async created() {
      this.memberService = MemberService(this.user.id)
      this.roomService = RoomService(this.user.id)

      this.handleInitialEditData(this.groupRoomToHandle)
      this.debounceOnKeyDown = debounce((e) => this.filterFriendList(e), 1000)
      this.rawItems = await API().get(
        `profile/friends/${this.user.id}`,
        {
          params: {
            profile: this.myProfileId,
          }
        }
      )
      this.items = this.rawItems
    },

    data() {
      return {
        roomService: null,
        mdiMagnify,
        searchValue: '',
        room: {
          name: '',
          coverImageFile: null
        },
        selectedFriendsId: [],
        rawItems: [],
        items: [],
      }
    },

    props: {
      open: {
        type: Boolean,
        default: false
      },
      onClose: {
        type: Function
      }
    },

    computed: {
      ...mapGetters({
        user: 'user',
        myProfileId: 'profile/id',
        groupRoomToHandle: 'rooms/groupRoomToHandle',
      }),

      searchIcon() {
        return !this.searchValue ? this.mdiMagnify : ''
      },

      submitButtonLabel() {
        return this.groupRoomToHandle ? this.$t('update') : this.$t('create')
      }
    },
  
    methods: {
      ...mapMutations({
        setGroupRoomToHandle: 'rooms/setGroupRoomToHandle',
      }),

      onSearch(value) {
        this.searchValue = value
      },

      onClear() {
        this.searchValue = ''
        this.filterFriendList()
      },

      filterFriendList() {
        this.items = this.rawItems.filter((item) => {
          return item.name.toLowerCase().includes(this.searchValue.toLowerCase()) || this.isSelected(item.id)  
        })
      },

      isSelected(id) {
        return this.selectedFriendsId.includes(id)
      },

      onSelect(id) {
        if (this.isSelected(id)) {
          return this.selectedFriendsId = this.selectedFriendsId.filter((friend) => friend != id)
        }
        this.selectedFriendsId.push(id)
      },

      debounceOnKeyDown() {},

      async onUpload(file) {
        this.room.coverImageFile = file
      },

      async submit() {
        const formData = new FormData();
        formData.append('file', this.room.coverImageFile);
        formData.append('groupName', this.room.name);
        this.selectedFriendsId.forEach((id) => {
          formData.append(`memberIds[]`, id);
        })
        formData.append('ownerId', this.myProfileId);
        let handler = this.roomService.createChatRoom
        if (this.room.id) {
          formData.append('id', this.room.id);
          handler = this.roomService.updateChatRoom
        }
        const { room, notifications, conversation } = await handler(formData)
        notifications.forEach((notification) => {
          this.$socket.notification.emit('roomInvitation', {
            invitation: {
              ...notification
            },
            member: notification.profile,
            room: room._id
          })
        })
        this.resetForm()
        this._onClose(room._id, conversation._id)
      },

      async handleInitialEditData(groupRoomToHandle) {
        if (groupRoomToHandle) {
          this.room = { ...this.room, ...groupRoomToHandle }
          const members = await this.memberService.listByChatRoom(groupRoomToHandle.id)
          this.selectedFriendsId = members.map(member => member.profile.id)
        }
      },

      resetForm() {
        this.room = {
          name: '',
          coverImageFile: null,
          thumbnail: ''
        }
        this.selectedFriendsId = []
      },

      _onClose(roomId, conversationId) {
        this.setGroupRoomToHandle(null)
        this.onClose(roomId, conversationId)
      }
    }
  }
</script>

<style>
  .v-dialog .v-dialog--active .v-dialog__content {
    overflow: hidden !important;
  }

  .theme--dark > .v-input__control .v-input__slot > .v-input__append-inner {
    background-color: #3b3b3bfa;
  }
</style>

<style scoped>
/* Deprecated in Vue 3 */
::v-deep .v-dialog {
  border-radius: 24px !important;
}
</style>  