<template>
  <PDFScrollingList
    class="pdf-search-list"
    @pages-fetch="onPagesFetch"
    v-bind="{pages, pageCount, currentPage}"
    :isParentVisible="isSearchListEnabled"
  >
    <v-container>
      <v-list-item-group
        dark
        color="amber accent-4" 
        style="background-color: #36393f;"
        v-model="item" 
      >
        <template v-for="(txtMatch, j) in matchesExtract">
          <v-divider
            v-if="j != 0"
            :key="`divider--${j}`"
          />
          <v-list-item 
            :key="`selection--${j}`"
            @click="linkSearch"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ txtMatch.str }}  
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-container>
  </PDFScrollingList>
</template>

<script>
import PDFScrollingList from '@components/PDF/ScrollingList'
import { mapGetters } from 'vuex'
import { findIndex } from 'lodash'

export default {
  name: 'PDFPreview',

  components: {
    PDFScrollingList
  },

  props: {
    pages: {
      required: true,
    },
    pageCount: {
      type: Number,
      default: 0,
    },
    scale: {
      type: Number,
      default: 1.0,
    },
    currentPage: {
      type: Number,
      default: 1,
    }
  },

  data () {
    return {
      item: 0,
      linkSwitch: true
    }
  },

  computed: {
    ...mapGetters({
      highlightSelectedSwitch: 'pdf/highlightSelectedSwitch',
      isSearchListEnabled: 'pdf/isSearchListEnabled',
      currentMatchIndex: 'pdf/currentMatchIndex',
      currentMatchPage: 'pdf/currentMatchPage',
      querySwitch: 'pdf/querySwitch',
      textQuery: 'pdf/textQuery',
      matches: 'pdf/matches'
    }),

    matchesExtract () {
      let matches = []
      this.matches.forEach((matchPageList, index) => {
        matchPageList.forEach((match, inPageIndex) => {
          matches.push({
            pageIndex: index,
            inPageIndex,
            ...match
          })
        })
      })

      return matches
    }
  },

  watch: {
    highlightSelectedSwitch (newValue, oldValue) {
      if (this.linkSwitch) {
        this.item = findIndex(this.matchesExtract, me => 
          me.inPageIndex === this.currentMatchIndex &&
            me.pageIndex === this.currentMatchPage - 1)
      } else {
        this.linkSwitch = true
      }
    },
    
    currentMatchIndex (newValue, oldValue) {
      if (this.linkSwitch) {
        this.item = findIndex(this.matchesExtract, me => me.inPageIndex === newValue &&
          me.pageIndex === this.currentMatchPage - 1)
      } else {
        this.linkSwitch = true
      }
    },

    item (newValue, oldValue) {
      if (!this.linkSwitch) {
        const item = this.matchesExtract[newValue]
        if (this.currentMatchIndex === item.inPageIndex)
          this.$store.commit('pdf/highlightSelectedSwitch', !this.highlightSelectedSwitch)
        else
          this.$store.commit('pdf/currentMatchIndex', item.inPageIndex)
        this.$store.commit('pdf/currentPage', item.pageIndex + 1)
        this.$store.commit('pdf/currentMatchPage', item.pageIndex + 1)
        this.$store.commit('pdf/currentPageMatchCount', this.matches[item.pageIndex].length)
      }
    }
  },

  methods: {
    linkSearch () {
      this.linkSwitch = false
      this.$store.commit('pdf/previousMatchPage', this.currentMatchPage)
      this.$store.commit('pdf/previousMatchIndex', this.currentMatchIndex)
      this.$store.commit('pdf/isSearchListQuery', true)
    },

    onPagesFetch(currentPage) {
      this.$parent.$emit('pages-fetch', currentPage)
    },

    onPageFocused(pageNumber) {
      this.$parent.$emit('page-focus', pageNumber)
    },

    onThumbnailRendered(payload) {
      this.$el.dispatchEvent(new Event('scroll'));
      this.$parent.$emit('thumbnail-rendered', payload)
    },

    onThumbnailErrored(payload) {
      this.$parent.$emit('thumbnail-errored', payload)
    },
  },
};
</script>

<style scoped>
.pdf-search-list {
  outline: solid 1px;
  outline-color: #616161;
  background-color: #36393f;
  position: absolute;
  z-index: 1;
  padding: 2em 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.scrolling-page {
  margin-bottom: 1em;
}

@media print {
  .pdf-preview {
    display: none;
  }
}
</style>
