import { withLayout } from '@utils'
import PdfReaderLayout from '@layouts/PdfReader'

const PdfReader = () => import('@views/PdfReader')

export default {
  path: 'pdf',
  component: {
    render: h => h('router-view')
  },
  children: [
    {
      path: 'read',
      name: 'pdf.read',
      component: withLayout(PdfReader, PdfReaderLayout),
      meta: {
        locked: true,
        app: 'pdf'
      }
    }
  ]
}
