<template>
  <v-dialog
    v-model="dialogOn"
    max-width="500"
    @click:outside="closeDialog"
  >
    <v-card
      rounded="xl"
      class="px-2"
    >
      <v-container>
        <v-row
          justify="center"
          class="py-4"
        >
          <v-fade-transition hide-on-leave>
            <Cropper
              v-if="image"
              :stencilComponent="stencil"
              class="cropper"
              classname="upload-cropper"
              :src="image" 
              :stencil-props="{ aspectRatio: 1 }" 
              @change="change"
            />
          </v-fade-transition>
        </v-row>

        <v-card-actions>
          <v-btn
            large
            elevation="0"
            style="border-radius: 12px; flex-grow: 1;"
            @click="clear"
          >
            {{ $t('cancel') }}
          </v-btn>

          <v-btn
            large
            elevation="0"
            color="primary"
            style="border-radius: 12px; flex-grow: 1;"
            @click="submit"
          >
            {{ $t('images.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>  
</template>

<script>
import { 
  Cropper, 
  CircleStencil, 
  RectangleStencil 
} from 'vue-advanced-cropper'

import {
  mdiCheckboxMarkedOutline,
  mdiClose,
  mdiDelete,
  mdiImage
} from '@mdi/js'

export default {
  components: {
    Cropper,
    CircleStencil,
    RectangleStencil
  },

  props: {
    cropperOn: {
      type: Boolean,
      default: true
    },
    imageFile: {
      type: Object,
      required: true
    },
    mimeType: {
      type: String,
      required: true
    },
    stencilComponent: {
      type: String,
      default: 'CircleStencil'
    }
  },

  data() {
    return {
      mdiCheckboxMarkedOutline,
      mdiDelete,
      mdiImage,
      mdiClose,

      dialogOn: false,

      image: null,
      dragging: false,
      mouseOver: false,
      processedImage: null,
      mouseOverImage: false
    }
  },

  computed: { 
    stencil () {
      return this.$options.components[this.stencilComponent]
    },

    inputColor () {
      return this.mouseOver || this.dragging
        ? this.$vuetify.theme.dark
        ? 'grey darken-4'
        : 'grey lighten-3'
        : ''
    }
  },

  watch: {
    cropperOn(cropper) {
      this.dialogOn = cropper
    
      if (cropper) {
        this.image = URL.createObjectURL(this.imageFile)
      }
    }
  },

  methods: {
    closeDialog() {
      this.dialogOn = false
      this.$emit('closeDialog')
    },

    change({canvas}) {
      canvas.toBlob(blobData => {
        const { name } = this.imageFile
        this.processedImage = new File([blobData], name, {
          type: this.mimeType
        });
      }, this.mimeType);
    },

    submit() {
      this.$emit('save', this.processedImage)
    },

    clear() {
      this.closeDialog()
      setTimeout(() => {
        this.processedImage = ''
        this.image = ''
      })
    }
  }
}
</script>

<style scoped>
.upload-cropper {
	border: solid 1px #44444456;
  border-radius: 15px;
}
.cursor-pointer {
  cursor: pointer;
}
.cropper {
  width: 450px;
  height: 450px;
}

/* Deprecated in Vue 3 */
::v-deep .v-dialog {
  border-radius: 24px !important;
}
</style>