<template>
  <v-card
    :class="`${$vuetify.theme.dark ? 'viewer-dark' : lighterViewerOn || !toolbar ? 'viewer-lighter' : 'viewer-light'}`"
    style="height: 40px; width: 250px; position: relative;"
    rounded="lg"
    :outlined="!toolbar"
  >
    <v-row align="center" class="px-6" style="padding-top: 8px;">

      <input
        v-model="search"
        ref="input"
        type="text"
        spellcheck="false"
        :placeholder="placeholder"
        style="width: 87%;"
        :class="`no-outline ${inputColorClass}`"
        @focus="lighterViewerOn = true"
        @blur="lighterViewerOn = false"
        @keydown="submit"
      />

      <v-icon
        style="margin-left: 5px;"
        :color="(!lighterViewerOn && toolbar) || $vuetify.theme.dark ? 'white' : 'primary'"
      >
        {{ mdiMagnify }}
      </v-icon>
     
    </v-row>
  </v-card>
</template>

<script>

import { mdiMagnify } from '@mdi/js'

export default {

  props: {
    setFocusOn: {
      type: Boolean,
      default: false
    },
    toolbar: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: 'Search'
    },
  },

  data() {
    return {
      mdiMagnify,

      search: '',
      lighterViewerOn: false
    }
  },

  watch: {
    setFocusOn(focus) {
      if (focus) {
        this.$refs.input.focus({
          preventScroll: true
        }) 
      }
    }
  },

  computed: {
    inputColorClass() {
      return this.$vuetify.theme.dark ? 'input-light' : 'input-dark'
    }
  },

  methods: {
    submit(event) {
      if (event.key === 'Enter') this.$emit('submit', this.search)
    }
  }
}
</script>

<style scoped>
.input-dark {
 color: black;
}

.input-light {
  color: white;
}

.no-outline:focus {
  outline: none;
}

.viewer-dark {
  background-color: rgba(202, 199, 199, 0.12);
  -webkit-transition: background-color 0.1s linear;
  -ms-transition: background-color 0.1s linear;
  transition: background-color 0.1s linear;
}

.viewer-light {
  background-color: rgb(103, 58, 158);
  -webkit-transition: background-color 0.1s linear;
  -ms-transition: background-color 0.1s linear;
  transition: background-color 0.1s linear;
}

.viewer-light input::placeholder {
  color: rgb(204, 204, 204);
  opacity: 1; /* Firefox */
}

.viewer-light :-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: rgb(204, 204, 204);
}

.viewer-light input::-ms-input-placeholder { /* Microsoft Edge */
 color: rgb(204, 204, 204);
}

.viewer-lighter {
  background-color: rgb(255, 255, 255);
  -webkit-transition: background-color 0.1s linear;
  -ms-transition: background-color 0.1s linear;
  transition: background-color 0.1s linear;
}
</style>