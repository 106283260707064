<template>
  <v-card
    :width="dense ? 350 : 350"
    max-height="450"
    elevation="10"
    :color="cardColor"
    :style="mentionCardStyle"
    @keydown.up.prevent="navigateList('up')"
    @keydown.down.prevent="navigateList('down')"
  >
    <v-container class="py-0">
      <Scrollbar style="height: 370px;">
        <v-container class="py-0">
          <v-row
            :class="upperHalfOn ? 'align-start' : 'align-end'"
            style="height: 300px;"
          >
            <v-card-text class="pt-0">
              <v-list
                rounded
                color="transparent"
              >
                <v-subheader>MENTION</v-subheader>

                <v-list-item
                  v-for="(data, i) in peopleSearch"
                  :key="`item_${i}`"
                  :class="{
                    'v-list-item--link': true,
                    'v-list-item--active primary--text': selectedItem === i
                  }"
                >
                  <v-list-item-avatar>
                    <img
                      v-if="data.avatar"
                      :src="getResourceUrl(data.avatar)"
                    />
                    <v-icon
                      v-else
                      size="45"
                    >
                      {{ mdiAccountCircle }}
                    </v-icon>

                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ data.name }}
                    </v-list-item-title>

                    <v-list-item-subtitle>
                      {{ data.username }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-row>
        </v-container>
      </Scrollbar>
    </v-container>
  </v-card>
</template>

<script>
import API from '@api'
import { mapGetters } from 'vuex'
import { Scrollbar } from '@components/App'
import { mdiAt, mdiAccountCircle } from '@mdi/js'
import { getResourceUrl, delay } from '@utils'

export default {
  components: {
    Scrollbar
  },

  props: {
    dense: {
      type: Boolean,
      default: false
    },
    search: {
      type: String,
      default: ''
    },
    upperHalfOn: {
      type: Boolean,
      default: true
    },
    mentionCardStyle: {
      type: String,
      required: true
    }
  },

  async created() {
    this.people = await API().get(`profile/friends/${this.user.id}`, {
      params: {
        profile: this.myProfile
      }
    })

    const [anna] = await API().get(`profiles/${this.user.id}`, {
      params: {
        query: {
            'username': {
              $eq: 'anna'
            }
          }
      }
    })

    this.peopleSearch = [anna].concat(this.people)
    this.people = this.peopleSearch
  },

  data: () => ({
    mdiAt,
    mdiAccountCircle,

    doMention: false,

    people: [],
    peopleSearch: [],
    selectedItem: 0,
    isTyping: false,
    options: {
      shouldSort: true,
      threshold: 0.6,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['name', 'username']
    }
  }),

  watch: {
    async selectedItem(idx, old_value) {

      if (typeof idx === 'undefined') {
        this.selectedItem = old_value;
        return;
      }

      if (idx >= 0 && this.doMention && false) {
        const { username, name, avatar, id } = this.peopleSearch[idx]
        this.$emit('doMention', { username, name, avatar, id })

        this.selectedItem = 0

        await delay(1000)

        this.doMention = false        
      }
    },

    search(name) {
      if (this.isSearching) {
        this.$search(name, this.people, this.options).then(people => {
          this.peopleSearch = people
        })
      } else {
        this.isTyping = true
        this.peopleSearch = this.people
        this.isTyping = false
      }
    }
  },

  computed: {
    ...mapGetters({
      user: 'user',
      myProfile: 'profile/id'
    }),

    isSearching() {
      return this.search
    },

    selectColor() {
      return this.$vuetify.theme.dark ? 'tertiary' : 'primary'
    },

    cardColor() {
      return this.$vuetify.theme.dark ? 'grey darken-4' : ''
    }
  },

  methods: {
    getResourceUrl,
    
    setTyping() {
      if (!this.isTyping)
        setTimeout(() => {
          this.isTyping = false
        }, 1000)

      this.isTyping = true
    },

    navigateList(direction) {
      if (direction === 'up' && this.selectedItem > 0) {
        this.selectedItem -= 1
      } else if (
        direction === 'down' &&
        this.selectedItem < this.peopleSearch.length - 1
      ) {
        this.selectedItem += 1
      }
    }
  }
}
</script>