<template>
  <div>
    <v-navigation-drawer
      absolute
      right
      temporary
      width="450"
      :color="noteDrawerColor"
      :value="notesOn"
      @input="close"
    >
      <Scrollbar
        :thin="true"
        style="height: 100%"
      >
        <v-container>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="headline font-weight-light">
                Annotations ({{ notes.length }})
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-row justify="center">
            <v-col cols="12">
              <NoteBox
                :avatarOn="false"
                :lighterViewerOn="true"
                :annotation="true"
                :noteMention="noteMention"
                :syncNoteMention="syncNoteMention"
                label="Annotate..."
                @annotation="addAnnotation"
              />
            </v-col>       
          </v-row>

          <v-divider />

          <v-fade-transition hide-on-leave>
            <Scrollbar
              v-if="notes.length > 0"
              :thin="true"
              :style="`max-height: ${ $vuetify.breakpoint.xl ? 84 : 78 }vh;`"
            >
              <v-container>
                <v-list>
                  <Note
                    v-for="(note, index) in notes"
                    :key="index"
                    :index="index"
                    :note="note"
                    :noteId="note.id"
                    @mention="doNoteMention"
                  />
                </v-list>
              </v-container>
            </Scrollbar>
          </v-fade-transition>

          <v-fade-transition hide-on-leave>
            <v-container v-if="notes.length === 0">
              <v-row  justify="center">
                <span class="subtitle-1">This media has no annotations</span>
              </v-row>
            </v-container>
          </v-fade-transition>

        </v-container>
      </Scrollbar>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Note from './Note'
import { Scrollbar } from '@components/App'
import NoteBox from '@components/Document/NoteBox'
import API from '@api'

export default {

  components: {
    Scrollbar,
    NoteBox,
    Note
  },

  data () {
    return {
      notes: [],

      noteMention: {},
      syncNoteMention: false
    }
  },

  computed: {
    ...mapGetters({
      user: 'user',
      notesOn: 'note/notesOn',
      profile: 'profile/id',
      libraryId: 'note/libraryId',
      libraryType: 'note/libraryType'
    }),

    noteDrawerColor() {
      return this.$vuetify.theme.dark
        ? 'grey darken-4'
        : ''
    },
  },

  watch: {
    async libraryId(objectId) {

      if (objectId) {

        const publicNotes = await API().get(`annotations/${this.user.id}`, {
          params: {
            query: {
              [this.libraryType]: {
                $eq: this.libraryId
              },
              isPublic: true
            },
            sort: {
              createdAt: -1
            }
          }
        })

        const myPrivateNotes = await API().get(`annotations/${this.user.id}`, {
          params: {
            query: {
              [this.libraryType]: {
                $eq: this.libraryId
              },
              isPublic: false,
              'sender.profile': {
                $eq: this.profile
              }
            },
            sort: {
              createdAt: -1
            }
          }
        })

        this.notes = myPrivateNotes.concat(publicNotes)

      }
    }
  },

  methods: {
    ...mapMutations({
      SET_NOTES_ON: 'note/SET_NOTES_ON'
    }),

    close(payload) {
      if (!payload) {
        this.SET_NOTES_ON(false)  
      }
    },

    doNoteMention(note) {
      this.syncNoteMention = true
      this.noteMention = note

      setTimeout(() => {
        this.syncNoteMention = false
      this.noteMention = {}
      })
    },

    addAnnotation(annotation) {

      this.notes.unshift(annotation)

    }

  }
}
</script>