<template>
  <v-card
    elevation="0"
    class="hs-rounded-xl py-4"
    @click="$emit('select')"
  >
    <v-card-title
     class="text-truncate"
     style="display: block"
    >
      <v-text-field
        v-model="title"
        class="my-0 py-0 mx-0 px-0"
        @click.stop="() => {}"
        @keydown="debounceOnKeyDown"
      />
    </v-card-title>

    <v-card-text
      class="text-caption"
      :style="`
        line-clamp: 5;
        -webkit-line-clamp: 5; 
        height: ${cardTextHeight}em;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      `"
    >
      {{ note.about }}
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import NotebookService from '@api/notebook'
import { debounce } from 'lodash'

export default {
  props: {
    note: {
      type: Object,
      default() {
        return {}
      }
    },
    setSaving: {
      type: Function,
      default: () => {}
    }
  },

  created() {
    this.notebookService = NotebookService(this.user.id)
    this.debounceOnKeyDown = debounce((e) => this.saveTitle(), 1000)
    this.title = this.note.title
  },

  mounted() {
    for (let block of this.note.content.blocks) {
      if (block.data.text)
        this.content.push(block.data.text)
    }
  },

  data() {
    return {
      notebookService: null,
      width: window.innerWidth,
      content: [],
      title: ''
    }
  },

  computed: {
    ...mapGetters({
      user: 'user',  
    }),
    cardTextHeight() {
      return this.width > 1400 ? 12 : 8
    }
  },

  methods: {
    debounceOnKeyDown() {},
    async saveTitle() {
      if (!this.title || this.title === this.note.title) return
      this.setSaving(true)
      await this.notebookService.update(this.note.id, { title: this.title })
      this.setSaving(false)
    }
  }
}
</script>

<style>
  .v-input__slot {
    margin: 0;
  }

  .v-text-field__slot > input{
    padding: 0;
  }
</style>