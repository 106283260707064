export default (str) => {
  // Common LaTeX patterns
  const latexPatterns = [
    /\\\w+/, // commands e.g. \textbf
    /\$\$.*?\$\$/, // display math
    /\$.*?\$/, // inline math
    /\\\(.*?\\\)/, // alternate inline math
    /\\\[.*?\\\]/ // display math
  ];
  
  // Common Markdown patterns
  const markdownPatterns = [
    /#+\s*.+/, // headers, allowing for optional leading space
    /\*\*.+?\*\*/, // bold
    /\*.+?\*/, // italic
    /\[.+?\]\(.+?\)/, // links
    /`[^`]+`/, // inline code
    /```[\s\S]*?```/, // code block
    /\|.*?\|/ // tables (simple pattern, assumes at least one pipe in the line)
  ];
  
  for (let pattern of latexPatterns) {
    if (pattern.test(str)) return true;
  }
  
  for (let pattern of markdownPatterns) {
    if (pattern.test(str)) return true;
  }
  
  return false;
}