<template>
  <v-card
    v-on="on"
    :elevation="hover && dragging ? elevation : 0"
    class="hs-rounded-12 clickable"
    :color="cardColor"
    :ripple="!edit"
    @click="setActiveContent(idx)"
  >
    <v-fade-transition hide-on-leave>
      <v-list-item v-if="!edit">
        <v-list-item-avatar
          :size="avatarSize"
          :color="`grey ${ $vuetify.theme.dark ? 'darken-4' : 'lighten-3' }`"
          :style="`border-radius: 10px; border: thin solid rgba(0, 0, 0, 0.12)`"
        >
          <v-img :src="getContentUrl(content)" />               
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title style="user-select: none;">
            {{ content.name }}
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-icon class="my-3">
          <v-tooltip
            v-if="content.referenceFolder && content.referenceFolder.id"
            right
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                small
              >
                <v-icon small>
                  {{ mdiSync }}
                </v-icon>
              </v-btn>
            </template>
            <span v-if="content.referenceFolder">
              Synced from folder "{{ content.referenceFolder.name }}"
            </span>
          </v-tooltip>

          <v-btn
            icon
            small
          >
            <v-icon small>
              {{ type === 'pdf' ? mdiFilePdfBox : mdiLink }}
            </v-icon>
          </v-btn>

          <v-btn
            v-if="!annaChamber && allowEdit"
            icon
            small
            @click.stop="edit = !edit"
          >
            <v-icon small>
              {{ mdiDotsVertical }}
            </v-icon>
          </v-btn>
        </v-list-item-icon>
      </v-list-item>
    </v-fade-transition>

    <v-fade-transition hide-on-leave>
      <v-list-item v-if="edit && !confirmRemove">
        <v-list-item-content class="py-0">
          <v-text-field
            v-model="content.title"
            class="hs-rounded-lg"
            placeholder="pdf title"
            :color="inputColor"
            hide-details
            dense
            elevation="0"
            filled
          />
        </v-list-item-content>

        <v-list-item-icon
          class="pl-2"
          style="margin: 13px 0px 13px 0px;"
        >
          <v-btn
            icon
            small
            @click.stop="update"
          >
            <v-icon small>
              {{ mdiCheck }}
            </v-icon>
          </v-btn>

          <v-btn
            icon
            small
            color="danger"
            @click.stop="confirmRemove = true"
          >
            <v-icon small>
              {{ mdiDeleteOutline }}
            </v-icon>
          </v-btn>
        </v-list-item-icon>
      </v-list-item>
    </v-fade-transition>

    <v-fade-transition hide-on-leave>
      <v-list-item v-if="edit && confirmRemove">
        <v-list-item-content class="px-1">
          <span :class="{ 'grey--text text--darken-1': !$vuetify.theme.dark }">
            Are you sure?
          </span>
        </v-list-item-content>

        <v-list-item-icon>
          <v-btn
            icon
            small
            color="danger"
            @click.stop="remove"
          >
            <v-icon small>
              {{ mdiCheck }}
            </v-icon>
          </v-btn>
          
          <v-btn
            icon
            small
            @click.stop="confirmRemove = false"
          >
            <v-icon small>
              {{ mdiClose }}
            </v-icon>
          </v-btn>
        </v-list-item-icon>
      </v-list-item>
    </v-fade-transition>
  </v-card>
</template>

<script>
import {
  mdiStarFourPointsOutline,
  mdiStarFourPoints,
  mdiBookmarkOutline,
  mdiDotsVertical,
  mdiCheck,
  mdiClose,
  mdiDeleteOutline,
  mdiFilePdfBox,
  mdiLink,
  mdiSync
} from '@mdi/js'
import { getResourceUrl } from '@utils'
import { mapGetters } from 'vuex'
import API from '@api'

const AVATAR_SIZE = 40

export default {
  props: {
    dragging: {
      type: Boolean,
      default: false
    },
    hover: {
      type: Boolean,
      default: false
    },
    content: {
      type: Object,
      default: () => {
        return {}
      }
    },
    avatarSize: {
      type: Number,
      default: AVATAR_SIZE
    },
    activeContentIdx: {
      type: Number,
      default: -1
    },
    idx: {
      type: Number,
      default: -1
    },
    allowEdit: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'pdf'
    }
  },

  data() {
    return {
      mdiStarFourPointsOutline,
      mdiStarFourPoints,
      mdiBookmarkOutline,
      mdiDotsVertical,
      mdiCheck,
      mdiClose,
      mdiDeleteOutline,
      mdiFilePdfBox,
      mdiLink,
      mdiSync,
      edit: false,
      confirmRemove: false
    }
  },

  computed: {
    ...mapGetters({
      user: 'user',
      myProfileId: 'profile/id',
      annaChamber: 'annaChamber'
    }),

    elevation() {
      return this.$vuetify.theme.dark ? 15 : 6
    },

    borderStyle() {
      return this.$vuetify.theme.dark
        ? 'transparent !important;'
        : 'thin solid rgba(0, 0, 0, 0.12) !important;'
    },

    cardColor() {
      return this.$vuetify.theme.dark
          ? this.hover && !this.dragging ? '#000000' : '#151515'
          : this.hover && !this.dragging ? '#E0E0E0' : '#fdfdfd'
    },

    inputColor() {
      return this.$vuetify.theme.dark ? 'white' : ''
    }
  },

  methods: {
    getResourceUrl,
    getContentUrl(content) {
      return this.getResourceUrl(
        content.thumbnail ?
          content.thumbnail :
          content.url
      )
    },
    update() {
      // For now we only have pdfs
      API().put(`content/${this.user.id}`, {
        title: this.content.title
      }, {
        params: {
          id: this.content._id
        }
      })

      this.edit = false
    },

    async remove() {
      const rooms = await API().get(`rooms/${this.user.id}`, {
        params: {
          owner: this.myProfileId,
          media: this.content.media
        }
      })
      const conversations = await API().get(`conversations/${this.user.id}`, {
        params: {
          query: {
            room: {
              $in: rooms.map(({ id }) => id)
            }
          }
        }
      })

      // Soft deletion
      API().put(`pdf/${this.user.id}`, {
        state: 'DELETED'
      }, {
        params: {
          _id: this.content.id,
          many: true
        }
      })

      API().put(`room/${this.user.id}`, {
        state: 'DELETED'
      }, {
        params: {
          query: {
            _id: {
              $in: rooms.map(({ _id }) => _id)
            } 
          },
          many: true
        }
      })

      API().put(`conversation/${this.user.id}`, {
        state: 'DELETED'
      }, {
        params: {
          query: {
            _id: {
              $in: conversations.map(({ _id }) => _id)
            }
          },
          many: true
        }
      })

      API().put(`member/${this.user.id}`, {
        status: 'deleted'
      }, {
        params: {
          query: {
            room: {
              $in: rooms.map(({ _id }) => _id)
            }
          },
          many: true
        }
      })
      this.$emit('remove')
      this.edit = false
      this.confirmRemove = false
    },

    setActiveContent(idx) {
      this.$emit('setActiveContent', idx)
    }
  }
}
</script>

<style scoped>
.v-card--link::before {
  border-radius: 12px !important;
}
</style>