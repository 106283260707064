<script>
import EditorJS from '@editorjs/editorjs'
import Paragraph from '@plugins/editorjs-paragraph/index.js';

export default {
  newEditor(EDITOR_ID, placeholder = 'Write your post...', custom = {}) {
    return new EditorJS({
      holder: `editorjs_${EDITOR_ID}`,
      readOnly: false,
      autofocus: true,
      minHeight: 0,
      ...custom,
      tools: {
        paragraph: {
          class: Paragraph,
          config: {
            placeholder
          }
        },

        header: {
          class: Header,
          inlineToolbar: ['marker', 'link'],
          config: {
            placeholder: 'Header'
          },
          shortcut: 'CMD+SHIFT+H'
        },

        list: {
          class: List,
          inlineToolbar: true,
          shortcut: 'CMD+SHIFT+L'
        },

        checklist: {
          class: Checklist,
          inlineToolbar: true,
        },

        quote: {
          class: Quote,
          inlineToolbar: true,
          config: {
            quotePlaceholder: 'Enter a quote',
            captionPlaceholder: 'Quote\'s author',
          },
          shortcut: 'CMD+SHIFT+O'
        },

        warning: Warning,

        marker: {
          class:  Marker,
          shortcut: 'CMD+SHIFT+M'
        },

        code: {
          class:  CodeTool,
          shortcut: 'CMD+SHIFT+C'
        },

        delimiter: Delimiter,

        inlineCode: {
          class: InlineCode,
          shortcut: 'CMD+SHIFT+C'
        },

        linkTool: window.LinkTool ? LinkTool : Link,

        embed: Embed,

        table: {
          class: Table,
          inlineToolbar: true,
          shortcut: 'CMD+ALT+T'
        }
      }
    })
  }
}
</script>