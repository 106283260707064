<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="searchValue"
          dense
          clearable
          :class="{
            'hs-rounded-lg hs-chat-search': true,
            'icon-style-search': !searchValue,
            'icon-style-close': searchValue,
            'icon-style-dark': $vuetify.theme.dark
          }"
          hide-details
          :append-icon="searchIcon"
          :label="getLabel()"
          @keydown="debounceOnKeyDown"
          @click:clear="onClear"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mdiMagnify } from '@mdi/js'
  import { debounce } from 'lodash'

  export default {
    props: {
      type: {
        type: String,
        default: 'friends'
      },
      debounceDelay: {
        type: Number,
        default: 1000
      }
    },

    data: () => ({
      searchValue: '',
      mdiMagnify,
    }),

    created() {
      this.debounceOnKeyDown = debounce((e) => this.onSearch(), this.debounceDelay)
    },

    computed: {
      searchIcon() {
        return !this.searchValue ? this.mdiMagnify : ''
      },
      color() {
        return this.$vuetify.theme.dark ? '#3b3b3bfa' : '#EEEEEE'
      }
    },

    methods: {
      onSearch() {
        this.$emit('search', this.searchValue)
      },

      onClear() {
        this.searchValue = ''
        this.$emit('clear')
      },

      debounceOnKeyDown() {},

      getLabel() {
        if (this.type === 'friends') return this.$t('searchFriends')
        return this.$t('searchGroups')
      }
    },
  }
</script>

<style>
  .icon-style-search, .icon-style-close {
    margin: 0;
  }

  .icon-style-search .v-icon__svg, .icon-style-close .v-icon__svg {
    width: 16px;
  }

  .icon-style-dark .v-input__slot > .v-input__append-inner {
    background-color: #3b3b3bfa;
  }

  .icon-style-close .v-input__slot > *:nth-child(2) {
    padding: 4px;
  }

  .icon-style-search .v-input__slot > *:nth-child(3) {
    padding: 4px;
  }

  .hs-chat-search .v-input__append-inner {
    background-color: #EEEEEE;
    border-radius: 30%;
    cursor: pointer;
  }

  .hs-chat-search .v-input__append-inner-dark {
    background-color: #3b3b3bfa;
    border-radius: 30%;
    cursor: pointer;
  }
</style>
