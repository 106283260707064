<template>
  <div
    class="px-3 pt-6 pb-3"
    @click="$emit('focus')"
  >
    <v-fade-transition hide-on-leave>
      <AudioInput
        :src="src"
        @content="emitContent"
      />
    </v-fade-transition>

    <v-expand-transition hide-on-leave>
      <v-container
        v-if="meta.caption || focusOn"
        class="py-0"
      >
        <v-row
          justify="center"
          align="center"
        >
          <v-col
            xl="12"
            lg="12"
            class="px-0 py-0"
          >
            <v-fade-transition hide-on-leave>
              <p
                v-if="meta.caption && !editCaption"
                v-text="meta.caption"
                class="subtitle-1 grey--text text--darken-2 text-center clickable"
                @click="editCaption = true"
              />
            </v-fade-transition>
            <v-fade-transition hide-on-leave>
              <v-text-field
                v-if="!meta.caption || editCaption"
                v-model="$data._caption"
                dense
                outlined
                hide-details
                label="Legenda"
                @focus="$emit('setPartInput', true)"
                @blur="$emit('setPartInput', false)"
                @keydown="onKeyDown"
              />
            </v-fade-transition>
          </v-col>
        </v-row>
      </v-container>
    </v-expand-transition>
  </div>
</template>

<script>
import AudioInput from './AudioInput'

export default {
  components: {
    AudioInput
  },

  props: {
    partIdx: {
      type: Number,
      required: true
    },
    focusOn: {
      type: Boolean,
      required: true
    },
    src: {
      type: String,
      required: true
    },
    meta: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  mounted() {
    if (this.meta.caption)
      this.$data._caption = this.meta.caption
    else
      this.editCaption = true
  },

  data() {
    return {
      editCaption: false,
      _caption: ''
    }
  },

  methods: {
    onKeyDown(e) {
      if (e.key === 'Enter') {
        this.editCaption = false
        this.$emit('setMeta', { caption: this.$data._caption })
        setTimeout(() => {
          this.$emit('setPartInput', false)
        }, 0)
      }
    },
    emitContent(data) {
      this.$emit('content', data)
    },
    emitSetPartInput(v) {
      this.$emit('setPartInput', v)
    },
    emitSetMeta(v) {
      this.$emit('setMeta', v)
    }
  }
}
</script>