import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@store'

Vue.use(VueI18n)

export default new VueI18n({
  locale: store.state.locale || 'en',
  messages: {
    'pt-br': require('@locales/pt-br.json'),
    'en': require('@locales/en.json')
  }
})
