<template>
  <v-list
    nav
    dense
    rounded
    color="transparent"
    class="py-0"
  >
    <v-list-item-group v-model="linkItem">
      <v-list-item
        v-for="link in links"
        :key="link.label"
        @click="() => $router.push(link.path)"
        class="clickable"
      >
        <v-list-item-icon>
          <v-icon>{{ link.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="text-body-1 font-weight-medium">
            {{ $t(link.label) }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
  export default {
    props: {
      clearNav: {
        type: Boolean,
        default: false
      },
      links: {
        type: Array,
        required: true
      }
    },

    data: () => ({
      linkItem: null
    }),

    watch: {
      clearNav() {
        this.linkItem = null
      }
    }
  }
</script>
