<template>
  <div>
    <v-btn 
      icon
      color="white"
      @click.prevent.stop="rotateLeft"
      :disabled="isDisabled"
    >
      <v-icon 
        color="white"
        v-text="mdiFormatRotate90"
      />
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mdiFormatRotate90 } from '@mdi/js'

export default {
  
  name: 'PDFRotate',

  data () {
    return {
      mdiFormatRotate90,
      increment: 90
    }
  },

  computed: {
    ...mapGetters({
      scale: 'pdf/scale',
      rotation: 'pdf/rotation'
    }),
    isDisabled() {
      return !this.scale
    }
  },
  
  methods: {
    rotateRight () {
      this.updateRotation(this.rotation + this.increment)
    },
    rotateLeft () {
      this.updateRotation(this.rotation - this.increment)
    },
    updateRotation (rotation) {
      this.$store.commit('pdf/rotation', rotation)
    }
  }
}
</script>
