/**
 * Splits a string containing LaTeX markup into an array of substrings where 
 * each substring contains either exclusively single `$` delimiters or exclusively double `$$` delimiters.
 * 
 * @param {string} str - The string containing LaTeX markup to be split.
 * @returns {string[]} - An array of split substrings.
 */
export default (str) => {
  // Initialize an empty array to hold the resulting substrings.
  const results = [];
  // Initialize a variable `i` to traverse the string.
  let i = 0;

  // Traverse through the entire string.
  while (i < str.length) {
    // Check if the current character and the next one form a double `$$` delimiter.
    if (str[i] === '$' && i < str.length - 1 && str[i + 1] === '$') {
      // Record the starting position of the double `$$` delimited segment.
      const start = i;
      // Increment the position to skip the starting `$$`.
      i += 2;
      // Continue traversing the string until we find the ending double `$$` delimiter.
      while (i < str.length && !(str[i] === '$' && str[i + 1] === '$')) {
        i++;
      }
      // If we find the ending `$$`, slice the segment from the string and add to the results array.
      if (i < str.length) {
        results.push(str.slice(start, i + 2));
        // Move past the ending `$$` to continue with the rest of the string.
        i += 2;
      } else {
        // If there's no ending `$$` but we reached the end of the string, 
        // just take the rest of the string and add to results.
        results.push(str.slice(start));
        break;
      }
    } else {
      // Handle segments that either contain text without any dollar signs or single `$` delimiters.
      const start = i;
      // Traverse the string until we either find a single `$` or a double `$$`.
      while (i < str.length && (str[i] !== '$' || (str[i] === '$' && str[i + 1] !== '$'))) {
        i++;
      }
      // Slice the segment from the string (this could be plain text or a single `$` delimited segment) 
      // and add it to the results array.
      results.push(str.slice(start, i));
    }
  }

  // Return the array containing the split substrings.
  return results;
}